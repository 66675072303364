import { createSimpleStore } from 'react-simple-reducer'

type TutorialStateType = 'NOT_STARTED' | 'STARTED' | 'FINISHED'

type BuresStateType = 'VISIBLE' | 'INVISIBLE'

const tutoriais = {
  telaInicial: 'NOT_STARTED' as TutorialStateType,
  conteudo: 'NOT_STARTED' as TutorialStateType,
}
export const GlobalStore = createSimpleStore(
  {
    tutoriais,
    termoUso: 'INVISIBLE' as BuresStateType,
    politicaPriv: 'INVISIBLE' as BuresStateType,
  },
  {
    startTutorial(state, tipo: keyof typeof tutoriais) {
      state.tutoriais[tipo] = 'STARTED'
    },
    endTutorial(state, tipo: keyof typeof tutoriais) {
      state.tutoriais[tipo] = 'FINISHED'
    },
    showTermoUso(state) {
      state.termoUso = 'VISIBLE'
    },
    closeTermoUso(state) {
      state.termoUso = 'INVISIBLE'
    },
    showPoliticaPriv(state) {
      state.politicaPriv = 'VISIBLE'
    },
    closePoliticaPriv(state) {
      state.politicaPriv = 'INVISIBLE'
    },
  },
  {
    options: {
      cache: {
        key: 'GLOBAL_STORE',
      },
    },
  }
)
