import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useParams, useHistory } from 'react-router-dom'
import { CardDegrade, CardNumerico, CardProgresso, CardProgressoVelocimetro } from './cards'
import { Table } from './Table'
import { Card, TituloPagina, Button, TimelineItem } from '../components'
import ClipboardSvg from '../assets/icons/clipboard.svg'
import NotebooksSvg from '../assets/icons/notebooks.svg'
import useAxios from 'axios-hooks'
import { format, parseISO } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { getModulosComEstatisticas, modulosActions } from '../modulos/ModulosStore'
import { progressoActions } from '../conteudo/ProgressoReduxStore'
import { ModuloWithEstatisticaEstudo } from '../models/ModuloModel'

interface ProgressoParams {
  cursoId: string
}

const Progresso = () => {
  const { Layout, Titulo, Subtitulo, ListaCards, Divisor, LayoutInferior } = Progresso

  const { cursoId } = useParams<ProgressoParams>()
  const history = useHistory()

  let [{ data: progresso, error, loading }, fetchProgresso] = useAxios(
    `app/progressos/dashboard/curso/${cursoId}`,
    { manual: true }
  )
  const fetch = async () => {
    const response = await fetchProgresso()
    progresso = response.data
  }
  useEffect(() => {
    fetch()
  }, [cursoId])

  if (!progresso) return null

  let moduloAtivo = progresso.modulos.find((modulo) => modulo.status === 'CONTINUAR')
  if (!moduloAtivo) {
    moduloAtivo = progresso.modulos[0]
  }
  moduloAtivo.questoes = progresso.questoes.filter((questao) => questao.moduloId === moduloAtivo.id)

  const handleVerExercicios = () => {
    history.push(`/curso/${cursoId}/gabarito/${moduloAtivo.id}`)
  }

  const modulosConcluidos = progresso.modulos.filter((m) => {
    return m.status === 'APROVADO'
  })

  return (
    <Layout>
      <Titulo>Visão geral do curso</Titulo>
      <Subtitulo>Acompanhe as informações de progresso do curso que está estudando.</Subtitulo>
      <ListaCards>
        <ExerciciosRespondidos className="exercicios-respondidos" questoes={progresso.questoes} />
        <ModulosCurso
          className="modulos-curso"
          modulos={progresso.modulos}
          modulosConcluidos={progresso.modulosConcluidos}
        />
        <HorasEstudadas className="horas-estudadas" modulos={progresso.modulos} />
      </ListaCards>
      <Divisor />
      <Titulo>Progresso módulo {moduloAtivo.nome}</Titulo>
      <Subtitulo>Acompanhe o progresso do módulo que está estudando.</Subtitulo>
      <LayoutInferior>
        <HorasConcluidas className="horas-concluidas" moduloAtivo={moduloAtivo} />
        <ExerciciosRespondidos2
          className="exercicios-respondidos-modulo"
          moduloAtivo={moduloAtivo}
        />
        <BotaoVerExercicios
          className="botao-resolver-exercicios"
          onClick={handleVerExercicios}
          disabled={false}
        >
          <span>Ver exercícios &gt;</span>
        </BotaoVerExercicios>
      </LayoutInferior>
      <Divisor />
      <ModulosConcluidos modulosConcluidos={modulosConcluidos} />
    </Layout>
  )
}
Progresso.Titulo = styled(TituloPagina)``
Progresso.TituloSecundario = styled(TituloPagina)`
  margin-top: 6px;
  margin-bottom: 30px;
`
Progresso.Subtitulo = styled.div`
  padding-top: 8px;
  font-size: 16px;
  color: #828282;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`
Progresso.LayoutInferior = styled('div')`
  display: grid;
  margin-top: 18px;
  gap: 18px;
  grid-template-columns: 1.6fr 1fr;
  grid-template-rows: 1fr 1fr;
  .horas-concluidas {
    grid-area: horas-concluidas;
  }
  .exercicios-respondidos-modulo {
    grid-area: exercicios-respondidos-modulo;
  }
  .botao-resolver-exercicios {
    grid-area: botao-resolver-exercicios;
  }
  grid-template-areas:
    'horas-concluidas exercicios-respondidos-modulo'
    'horas-concluidas botao-resolver-exercicios';

  @media (max-width: 768px) {
    grid-template-areas:
      'horas-concluidas horas-concluidas'
      'exercicios-respondidos-modulo botao-resolver-exercicios';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2fr 1fr;
  }
  /* display: grid;
  grid-template-columns: repeat(1, max-content);
  grid-column-gap: 18px;
  margin-bottom: 28px;

  .layout {
    display: grid;
    grid-row-gap: 28px;
    grid-column-gap: 30px;
    grid-template-areas:
    "center center"
    "left right";
  }

  .center {
    grid-area: center;
  }

  .left {
    grid-area: left;
  }

  .right {
    grid-area: right;
  } */
`
Progresso.Divisor = styled('div')`
  /* width: 1024px; */
  margin-bottom: 31px;
  border: 1px solid #d9d9d9;
  margin-bottom: 24px;
  margin-top: 28px;
`
Progresso.ListaCards = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1.67fr;
  grid-gap: 18px;
  margin-top: 18px;
  @media (max-width: 1023px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    .exercicios-respondidos {
      grid-area: exercicios-respondidos;
    }
    .modulos-curso {
      grid-area: modulos-curso;
    }
    .horas-estudadas {
      grid-area: horas-estudadas;
    }
    grid-template-areas:
      'exercicios-respondidos modulos-curso'
      'horas-estudadas horas-estudadas';
  }
`
Progresso.Layout = styled('div')`
  /* width: 1024px;
  margin: 24px;
  display: grid;
  grid-template-rows: 2; */

  display: grid;
  grid-template-rows: 2;
  @media (max-width: 1023px) {
    max-width: 575px;
    margin: 0 auto;
  }
  @media (max-width: calc(575px + 24px)) {
    margin: 0 12px;
  }
`

const ModulosConcluidos = ({ modulosConcluidos }) => {
  const { Card, EmptyCard, Titulo, Descricao } = ModulosConcluidos
  const dispatch = useDispatch()
  const { cursoId } = useParams<ProgressoParams>()

  useEffect(() => {
    dispatch(modulosActions.loadModulos(+cursoId))
    dispatch(progressoActions.loadEstatisticasEstudo(+cursoId))
  }, [])
  const modulos = useSelector(getModulosComEstatisticas)
  modulosConcluidos = modulos.filter((m) => m.estatisticaEstudo?.moduloCompleto)
  return (
    <div>
      <Titulo>Módulos concluídos</Titulo>
      <Descricao>Acompanhe as informações dos módulos já estudados</Descricao>
      {!modulosConcluidos ? (
        <EmptyCard>
          <p className="empty-card-paragraph">Nenhum módulo ainda foi concluído</p>
        </EmptyCard>
      ) : (
        <Card>
          {modulosConcluidos.map((modulo, index) => (
            <TimelineItem key={modulo.id} omitLine={modulosConcluidos.length - 1 === index}>
              <ModuloConcluido modulo={modulo} />
            </TimelineItem>
          ))}
        </Card>
      )}
    </div>
  )
}
ModulosConcluidos.Card = styled(Card)`
  padding: 26px;
`
ModulosConcluidos.EmptyCard = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 72px 0;
  .empty-card-paragraph {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
  }
`
ModulosConcluidos.Titulo = styled(TituloPagina)`
  margin-top: 6px;
  margin-bottom: 28px;
`
ModulosConcluidos.Descricao = styled('p')`
  margin-top: 8px;
  margin-bottom: 18px;
  color: #828282;
  font-size: 16px;
  font-weight: 400;
`

const ModuloConcluido = ({ modulo }: { modulo: ModuloWithEstatisticaEstudo }) => {
  const history = useHistory()
  const { cursoId } = useParams<ProgressoParams>()
  const { formatDate, formatDesempenho } = ModuloConcluido.helpers
  const { Heading, Paragraph, Buttons } = ModuloConcluido
  const revisarConteudo = () => history.push(`/curso/${cursoId}/conteudo/${modulo.id}`)
  const revisarExercicios = () => history.push(`/curso/${cursoId}/gabarito/${modulo.id}`)

  return (
    <div>
      <Heading>{modulo.nome}</Heading>
      <Paragraph>
        Conclusão: <strong>{formatDate(modulo?.estatisticaEstudo?.dataFinalizacaoModulo)}</strong>
      </Paragraph>
      <Paragraph>
        Aproveitamento avaliação:{' '}
        <strong>{formatDesempenho(modulo.estatisticaEstudo?.desempenhoAvaliacao)}%</strong>
      </Paragraph>
      <Buttons>
        <Button
          className="first-button"
          label="Revisar conteúdo"
          fill="LINK"
          onClick={revisarConteudo}
        />
        <Button
          className="button-wider"
          label="Revisar exercícios e avaliação"
          fill="LINK"
          onClick={revisarExercicios}
        />
      </Buttons>
    </div>
  )
}
ModuloConcluido.helpers = (() => {
  function formatDesempenho(desempenho?: number): number {
    return Math.round(desempenho || 0)
  }
  function formatDate(data: string): string {
    return format(parseISO(data), 'dd/MM/yyyy HH:mm')
  }
  return {
    formatDesempenho,
    formatDate,
  }
})()
ModuloConcluido.Heading = styled('h4')`
  font-size: 14px;
  margin-top: 0;
  font-weight: bold;
`
ModuloConcluido.Paragraph = styled('p')`
  font-size: 14px;
`
ModuloConcluido.Buttons = styled('div')`
  display: flex;
  flex-direction: row;
  margin-top: 22px;
  padding-bottom: 28px;
  .button-wider {
    width: auto;
  }
  .first-button {
    padding-left: 0;
    justify-content: left;
  }
  @media (max-width: 475px) {
    flex-direction: column;
    .button-wider {
      padding-left: 0;
      justify-content: left;
    }
  }
`

const TabelaModulos = ({ modulos }) => {
  const COLUNAS = [
    {
      title: 'Módulo',
      dataIndex: 'nome',
      key: 'nome',
      width: '390px',
    },
    {
      title: 'Atividades',
      dataIndex: 'atividades',
      key: 'atividades',
      width: '136px',
    },
    {
      title: 'Horas do módulo',
      dataIndex: 'horasDoModulo',
      key: 'horasDoModulo',
      width: '336',
    },
    {
      title: 'Conclusão',
      dataIndex: 'conclusao',
      key: 'conclusao',
      width: '136',
    },
  ]
  const data = modulos
    .filter((modulo) => modulo.status === 'APROVADO')
    .map((modulo) => {
      const questoesRespondidas = modulo.questoes.filter(
        (questao) => questao.respostas.length > 0
      ).length
      const totalQuestoes = modulo.questoes.length
      const horasDoModulo = Math.round(modulo.duracao / 60)
      const conclusao = format(new Date(modulo.updatedAt), 'dd/MM/yyyy')
      return {
        id: modulo.id,
        nome: modulo.nome,
        atividades: `${questoesRespondidas}/${totalQuestoes}`,
        horasDoModulo: `${horasDoModulo}h/${horasDoModulo}h`,
        conclusao,
      }
    })
  return <Table colunas={COLUNAS} dataSource={data} />
}

const BotaoVerExercicios = styled('div')<{ disabled }>(
  (props) => `
  background: ${props.disabled ? '#D9D9D9' : '#1F3A93'};
  cursor: ${props.disabled ? 'default' : 'pointer'};
  border: 1px solid #D9D9D9;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  text-align: center;

  span {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
  }
`
)

const ExerciciosRespondidos = ({ questoes, ...props }) => {
  const value = questoes.filter((questao) => questao.respostas.length > 0).length
  const total = questoes.length
  return (
    <CardDegrade
      value={`${value}/${total}`}
      label="Exercícios respondidos"
      color="linear-gradient(261.68deg, #A481FF 2.04%, #C963BF 97.71%)"
      {...props}
    />
  )
}

const AvaliacoesFeitas = () => {
  return (
    <CardDegrade
      value="0/21"
      label="Avaliações feitas"
      color="linear-gradient(260.62deg, #FFE159 -6.87%, #E65050 95.94%);"
    />
  )
}

const ModulosCurso = ({ modulos, modulosConcluidos, ...props }) => {
  const total = modulos.length
  const value = modulosConcluidos.length

  return (
    <CardDegrade
      value={`${value}/${total}`}
      label="Módulos do curso"
      color="linear-gradient(180deg, #2DE9C3 0%, #5698D1 100%)"
    />
  )
}

const HorasEstudadas = ({ modulos, ...props }) => {
  const totalHoras = modulos.reduce((total, modulo) => total + modulo.duracao, 0) / 60 / 60
  const value = modulos.reduce((total, modulo) => {
    if (modulo.status === 'APROVADO') {
      return total + modulo.duracao / 60 / 60
    }
    const duracao = Math.round(modulo.duracao / 60 / 60)
    const valor = Math.round((modulo.progresso * duracao) / 100)
    return valor + total
  }, 0)
  return <CardProgresso value={Math.round(value)} total={Math.round(totalHoras)} {...props} />
}

const HorasConcluidas = (props) => {
  const { moduloAtivo } = props
  const total = Math.round(moduloAtivo.duracao / 60 / 60)
  const value = Math.round((moduloAtivo.progresso * total) / 100)
  return <CardProgressoVelocimetro value={value} total={total} {...props} />
}

const HorasEstudo = (props) => {
  const { Layout, Titulo } = HorasEstudo
  return (
    <Layout {...props}>
      <Titulo>Horas de estudo</Titulo>
    </Layout>
  )
}

const ExerciciosRespondidos2 = (props) => {
  const { moduloAtivo } = props

  const value = moduloAtivo.questoes.filter((questao) => questao.respostas.length > 0).length
  const total = moduloAtivo.questoes.length
  return (
    <CardNumerico
      titulo="Exercícios respondidos"
      value={`${value}/${total}`}
      color="#27AE60"
      icon={ClipboardSvg}
      {...props}
    />
  )
}

const AvaliacoesFeitas2 = (props) => {
  return (
    <CardNumerico
      {...props}
      titulo="Avaliações feitas"
      value="2/3"
      color="#EB5757"
      icon={NotebooksSvg}
    />
  )
}

HorasEstudo.Layout = styled(Card)`
  width: 502px;
  height: 402px;
`

HorasEstudo.Titulo = styled('div')`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
`

export default Progresso
