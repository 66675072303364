import React, { useState, useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import { useSpring, animated } from 'react-spring'
import useMeasure from 'react-use-measure'
import { QuestaoStore } from './QuestoesStore'
import { toast } from 'react-toastify'
import { useDecorateBlobWithSas } from '../common/hooks/useDecorateBlobWithSas'

const LETRAS = 'ABCDEFGHIJKLMNOPQ'.split('')

export const RelacionarItens = () => {
  const [key, setKey] = useState(Math.random())
  const dispatch = QuestaoStore.useDispatch()
  const { questao, respostasInformadas, showRespostaIncorreta, somenteLeitura } =
    QuestaoStore.useState()
  const alternativas = questao.questoesAlternativas || []
  const [alternativaSelecionadaEsquerda, setAlternativaSelecionadaEsquerda] = useState(null)
  const [alternativaSelecionadaDireita, setAlternativaSelecionadaDireita] = useState(null)
  const posicoes = useRef<Array<{ alternativaId; lado; posicao }>>([])

  useEffect(() => {
    if (alternativaSelecionadaEsquerda && alternativaSelecionadaDireita) {
      const alternativaId = alternativaSelecionadaEsquerda
      const respostaInformada = alternativas.find(
        (x) => x.id === alternativaSelecionadaDireita
      )!.respostaEsperada
      const questaoAlternativaEnviadaId = alternativaSelecionadaDireita
      const resposta = { questaoAlternativaEnviadaId, respostaInformada }

      dispatch(QuestaoStore.thunks.markAlternativa({ alternativaId, resposta }))

      setAlternativaSelecionadaEsquerda(null)
      setAlternativaSelecionadaDireita(null)
    }
  }, [alternativaSelecionadaDireita, alternativaSelecionadaEsquerda])
  const alternativasEsquerda = alternativas
  const alternativasDireita = [...alternativas].sort((a, b) =>
    a.ordenacao! < b.ordenacao! ? 1 : -1
  )

  const respostasAlternativas = questao.respostas?.length
    ? questao.respostas[0].respostasAlternativas
    : []
  const respostasCorretas = respostasAlternativas
    .filter((x) => x.correta)
    .map((x) => x.questaoAlternativaId)
  const respostasInformadasEsquerda = Object.keys(respostasInformadas).map((x) => +x)
  const respostasInformadasDireita = respostasInformadasEsquerda.map(
    (a) => respostasInformadas['' + a].questaoAlternativaEnviadaId
  )
  const respostasIncorretasEsquerda = respostasAlternativas
    .filter((x) => !x.correta && showRespostaIncorreta)
    .map((x) => x.questaoAlternativaId)
  const respostasIncorretasDireita = respostasAlternativas
    .filter((x) => !x.correta && showRespostaIncorreta)
    .map((x) => x.questaoAlternativaEnviadaId)
  function _getStatus(
    alternativaId,
    lado
  ): '' | 'incorreta-marcada' | 'correta-marcada' | 'marcada' {
    if (lado === 'esquerda') {
      if (respostasCorretas.includes(alternativaId)) return 'correta-marcada'
      if (respostasIncorretasEsquerda.includes(alternativaId)) return 'incorreta-marcada'
      if (alternativaSelecionadaEsquerda === alternativaId) return 'marcada'
      if (respostasInformadasEsquerda.includes(alternativaId)) return 'marcada'
    } else {
      if (respostasCorretas.includes(alternativaId)) return 'correta-marcada'
      if (respostasIncorretasDireita.includes(alternativaId)) return 'incorreta-marcada'
      if (respostasInformadasDireita.includes(alternativaId)) return 'marcada'
      if (alternativaSelecionadaDireita === alternativaId) return 'marcada'
    }
    return ''
  }

  const ref = useRef<HTMLDivElement>(null)
  function handleMeasureItem(alternativaId, lado, posicao) {
    posicoes.current = [
      ...posicoes.current.filter((x) => x.alternativaId !== alternativaId || x.lado !== lado),
      { alternativaId, lado, posicao },
    ]
  }
  function measureItems(forceRerender = false as any) {
    if (!ref.current) return
    const { top: alternativasContainerTop } = ref.current.getBoundingClientRect()
    ref.current.querySelectorAll('[data-alternativa-id]').forEach((node) => {
      const alternativaId = node.getAttribute('data-alternativa-id')!
      const lado = node.getAttribute('data-lado')
      const { top, height } = node.getBoundingClientRect()
      handleMeasureItem(+alternativaId, lado, top - alternativasContainerTop + height / 2)
    })
    if (forceRerender) setKey(Math.random())
  }

  useEffect(() => {
    measureItems()
    setTimeout(() => measureItems(true), 1000)
    const ev = window.addEventListener('resize', measureItems)
    return () => window.removeEventListener('resize', measureItems)
  }, [])

  function handleClickAlternativaEsquerda(alternativaId) {
    if (somenteLeitura) return
    if (showRespostaIncorreta) {
      return toast('Clique em Refazer Questão para tentar novamente selecionar')
    }
    if (respostasCorretas.includes(alternativaId)) {
      return toast('Não é possível selecionar alternativas que já tem correspondência correta')
    }
    setAlternativaSelecionadaEsquerda(alternativaId)
    dispatch({ type: 'unmarkAlternativa', payload: { alternativaId } })
  }

  function handleClickAlternativaDireita(alternativaId) {
    if (somenteLeitura) return
    if (showRespostaIncorreta) {
      return toast('Clique em Refazer Questão para tentar novamente selecionar')
    }
    if (!alternativaSelecionadaEsquerda) {
      return toast('Selecione uma alternativa da esquerda antes')
    }
    if (respostasCorretas.includes(alternativaId)) {
      return toast('Não é possível selecionar alternativas que já tem correspondência correta')
    }
    setAlternativaSelecionadaDireita(alternativaId)
    const alternativaIdPreviamenteMarcada = Object.keys(respostasInformadas).find(
      (x) => respostasInformadas[x].questaoAlternativaEnviadaId === alternativaId
    )
    if (alternativaIdPreviamenteMarcada) {
      dispatch({
        type: 'unmarkAlternativa',
        payload: { alternativaId: alternativaIdPreviamenteMarcada },
      })
    }
  }

  const { AlternativasContainerSide } = RelacionarItens

  return (
    <Styles.AlternativasContainer ref={ref} key={key}>
      <AlternativasContainerSide className="alternativa-container-esquerda">
        {alternativasEsquerda.map((alternativaEsquerda, i) => (
          <AlternativaContainer
            key={i}
            alternativa={alternativaEsquerda}
            lado="esquerda"
            index={i}
            onClick={handleClickAlternativaEsquerda}
            status={_getStatus(alternativaEsquerda.id, 'esquerda')}
          />
        ))}
      </AlternativasContainerSide>

      <AlternativasContainerSide className="alternativa-container-direita">
        {alternativasDireita.map((alternativaDireita, i) => (
          <AlternativaContainer
            key={i}
            alternativa={alternativaDireita}
            lado="direita"
            index={i}
            onClick={handleClickAlternativaDireita}
            status={_getStatus(alternativaDireita.id, 'direita')}
          />
        ))}
      </AlternativasContainerSide>

      <Linhas
        posicoes={posicoes.current}
        respostasAlternativas={respostasAlternativas}
        respostasInformadas={respostasInformadas}
        showRespostaIncorreta={showRespostaIncorreta}
        className="alternativa-container-linhas"
      />
    </Styles.AlternativasContainer>
  )
}
RelacionarItens.AlternativasContainerSide = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fill, auto);
  gap: 8px;
`

function Linhas({
  posicoes,
  respostasAlternativas,
  respostasInformadas,
  showRespostaIncorreta,
  className,
}) {
  const [ref, { width }] = useMeasure()
  const linhas: Array<{ y1; y2; stroke }> = []
  const respostasCorretas = respostasAlternativas.filter((r) => r.correta)

  respostasCorretas.forEach((r) => {
    const posicaoEsquerda = posicoes.find(
      (x) => x.alternativaId === r.questaoAlternativaId && x.lado === 'esquerda'
    )
    const posicaoDireita = posicoes.find(
      (x) => x.alternativaId === r.questaoAlternativaId && x.lado === 'direita'
    )

    if (posicaoEsquerda && posicaoDireita) {
      linhas.push({
        y1: posicaoEsquerda.posicao,
        y2: posicaoDireita.posicao,
        stroke: 'rgb(39, 174, 96)',
      })
    }
  })

  if (Object.keys(respostasInformadas).length) {
    Object.keys(respostasInformadas)
      .filter((r) => !respostasCorretas.some((x) => +x.questaoAlternativaId === +r))
      .forEach((r) => {
        const posicaoEsquerda = posicoes.find(
          (x) => x.alternativaId === +r && x.lado === 'esquerda'
        )
        const posicaoDireita = posicoes.find(
          (x) =>
            x.alternativaId === respostasInformadas[r].questaoAlternativaEnviadaId &&
            x.lado === 'direita'
        )

        if (posicaoEsquerda && posicaoDireita) {
          linhas.push({
            y1: posicaoEsquerda.posicao,
            y2: posicaoDireita.posicao,
            stroke: showRespostaIncorreta ? 'rgb(235, 87, 87)' : '#2F80ED',
          })
        }
      })
  }

  return (
    <div style={{ flex: 1, minWidth: 120 }} ref={ref} className={className}>
      <svg width={width + 20} height="100%" style={{ marginLeft: -5, width: 'calc(100% + 20px)' }}>
        {linhas.map((l, i) => (
          <circle key={i} cx="5" cy={l.y1} r="5" fill={l.stroke} />
        ))}
        {linhas.map((l, i) => (
          <circle key={i} cx={width + 5} cy={l.y2} r="5" fill={l.stroke} />
        ))}
        {linhas.map((l, i) => (
          <line
            key={i}
            x1="5"
            y1={l.y1}
            x2={width}
            y2={l.y2}
            style={{ stroke: l.stroke, strokeWidth: 2 }}
          />
        ))}
      </svg>
    </div>
  )
}

function AlternativaContainer({ alternativa, lado, status, index, onClick }) {
  let item = lado === 'esquerda' ? alternativa.conteudoAlternativa : alternativa.respostaEsperada
  const indicador = lado === 'esquerda' ? LETRAS[index] : index + 1
  item = JSON.parse(item)
  const { helpers, Container, Letra } = AlternativaContainer

  const springLetra = useSpring(helpers.getSpringLetra(status))

  const imagem = useDecorateBlobWithSas({ blobPath: item.tipo === 'IMAGEM' ? item.item : '' })

  return (
    <Container
      onClick={() => onClick(alternativa.id)}
      data-lado={lado}
      data-alternativa-id={alternativa.id}
    >
      <Letra style={springLetra} className="alternativa-letra">
        {' '}
        {indicador}{' '}
      </Letra>
      <div className="alternativa-descricao">
        {item.tipo === 'IMAGEM' ? <img src={imagem} /> : item.item}
      </div>
    </Container>
  )
}
AlternativaContainer.helpers = (() => {
  function getSpringLetra(statusAlternativa) {
    const background = {
      '': '#FFFFFF',
      'incorreta-marcada': '#EB5757',
      'correta-marcada': '#4AC758',
      marcada: '#2F80ED',
    }[statusAlternativa]
    const borderColor = {
      '': '#D9D9D9',
      'incorreta-marcada': '#EB5757',
      'correta-marcada': '#4AC758',
      marcada: '#2F80ED',
    }[statusAlternativa]
    const color = {
      '': '#2D2D2D',
      'incorreta-marcada': '#FFFFFF',
      'correta-marcada': '#FFFFFF',
      marcada: '#FFFFFF',
    }[statusAlternativa]

    return { background, borderColor, color }
  }
  return {
    getSpringLetra,
  }
})()
AlternativaContainer.Container = styled.div`
  background: #f7f7f7;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 18px;
  cursor: pointer;
  font-size: 18em;
  color: #2d2d2d;
  align-items: center;
  padding: 18px;
`
AlternativaContainer.Letra = styled(animated.div)`
  border-radius: 8px;
  border: 2px solid #d9d9d9;
  width: 58px;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Styles = {
  AlternativasContainer: styled('div')`
    display: grid;
    grid-template-columns: auto 120px auto;
    overflow: auto;
    margin: 28px 16px 0 16px;
    .alternativa-container-esquerda {
      grid-area: alternativa-container-esquerda;
    }
    .alternativa-container-direita {
      grid-area: alternativa-container-direita;
    }
    .alternativa-container-linhas {
      grid-area: alternativa-container-linhas;
    }
    grid-template-areas: 'alternativa-container-esquerda alternativa-container-linhas alternativa-container-direita';
    @media screen and (max-width: 425px) {
      /* margin-top: 20px; */
    }
  `,
  Item: styled('div')`
    display: flex;
    align-items: strech;
    background: white;
    cursor: pointer;
    --border-radius: 3px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.08);
    &:not(:last-child) {
      margin-bottom: 16px;
      @media screen and (max-width: 425px) {
        margin-bottom: 12px;
      }
    }
    width: 100%;
    background: #ffffff;
    .alternativa-descricao {
      border-top: 2px solid;
      border-right: 2px solid;
      border-bottom: 2px solid;
      border-color: #e0e0e0;
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      color: #4d4d4d;
      flex: 1;
      padding-left: 24px;
      padding-right: 24px;
      display: flex;
      align-items: center;
    }
    .alternativa-letra {
      display: flex;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      width: 64px;
      min-height: 64px;
      background: #bdbdbd;
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 38px;
      display: flex;
      color: #ffffff;
    }
    &:hover {
      background: #f7f7f7;
    }
    @media screen and (max-width: 425px) {
      .alternativa-descricao {
        font-size: 16px;
        line-height: 19px;
        padding: 4px 12px 4px 12px;
      }
      .alternativa-letra {
        width: 52px;
        min-height: 52px;
        font-size: 28px;
        line-height: 34px;
      }
    }
  `,
}
