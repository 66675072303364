import React from 'react'

const PdfSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.825 1.2V19.575H2.17505V5.175L7.35005 0H20.6625C21.3 0 21.825 0.5625 21.825 1.2Z"
        fill="white"
      />
      <path
        d="M6.2624 5.175H2.2124L7.3499 0L7.3124 4.05C7.3499 4.6875 6.8624 5.175 6.2624 5.175Z"
        fill="#DADEDE"
      />
      <path
        d="M13.9124 10.5001C12.8249 9.45012 12.0374 8.25012 12.0374 8.25012C12.3374 7.80012 13.0499 5.21262 11.9625 4.46262C10.875 3.71262 10.3124 5.10012 10.3124 5.10012C9.82495 6.82512 10.9874 8.40012 10.9874 8.40012L9.67495 11.2876C9.52495 11.2876 5.36245 12.9001 6.78745 14.8876C8.24995 16.8751 10.275 12.0751 10.275 12.0751C11.0625 11.8126 13.4625 11.4751 13.4625 11.4751C14.3999 12.7501 15.5249 13.1626 15.5249 13.1626C17.2499 13.6126 17.4374 12.1876 17.4374 12.1876C17.4749 10.3126 13.9124 10.5001 13.9124 10.5001ZM7.49995 14.2126C7.46245 14.2126 7.46245 14.1751 7.46245 14.1751C7.23745 13.6501 8.96245 12.6376 8.96245 12.6376C8.96245 12.6376 8.02495 14.4376 7.49995 14.2126ZM11.4 5.13762C11.8875 5.58762 11.4749 7.12512 11.4749 7.12512C11.4749 7.12512 10.9124 5.58762 11.4 5.13762ZM10.95 10.9501L11.6249 9.30012L12.6749 10.5751L10.95 10.9501ZM16.4999 12.1501C16.1999 12.6376 14.9624 11.5876 14.8499 11.4751C15.0374 11.4751 16.6499 11.5876 16.4999 12.1501ZM21.8249 18.3751V22.8001C21.8249 23.4376 21.2999 24.0001 20.6249 24.0001H3.33745C2.69995 24.0001 2.13745 23.4751 2.13745 22.8001V18.3751H21.8249Z"
        fill="#F2786B"
      />
      <path
        d="M10.4624 20.3249C10.4624 20.6249 10.3499 20.8499 10.1624 21.0374C9.97495 21.1874 9.67495 21.2624 9.29995 21.2624H8.99995V22.3499H8.51245V19.4624H9.37495C9.74995 19.4624 10.0124 19.5374 10.1999 19.6874C10.3874 19.8374 10.4624 20.0249 10.4624 20.3249ZM9.03745 20.8874H9.29995C9.52495 20.8874 9.71245 20.8499 9.82495 20.7749C9.93745 20.6999 10.0124 20.5499 10.0124 20.3624C10.0124 20.2124 9.97495 20.0624 9.86245 19.9874C9.74995 19.9124 9.59995 19.8749 9.37495 19.8749H9.03745V20.8874ZM13.4249 20.8874C13.4249 21.3749 13.2749 21.7499 13.0124 21.9749C12.7499 22.2374 12.3749 22.3499 11.8499 22.3499H11.0249V19.4624H11.9249C12.3749 19.4624 12.7499 19.5749 13.0124 19.8374C13.2749 20.0624 13.4249 20.4374 13.4249 20.8874ZM12.8999 20.8874C12.8999 20.1749 12.5624 19.8374 11.9249 19.8374H11.5124V21.9374H11.8499C12.5624 21.9749 12.8999 21.5999 12.8999 20.8874ZM14.5124 22.3499H14.0249V19.4624H15.6749V19.8749H14.5124V20.7749H15.5999V21.1874H14.5124V22.3499Z"
        fill="white"
      />
    </svg>
  )
}

export default PdfSvg
