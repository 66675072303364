import React from 'react'
import { SvgType } from './index'
const HomeSvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.66 8.53414L10.66 0.534135C10.4775 0.373816 10.2429 0.2854 10 0.2854C9.75709 0.2854 9.52249 0.373816 9.34 0.534135L0.339997 8.53414C0.187033 8.66945 0.0791648 8.84835 0.0308962 9.04679C-0.0173724 9.24522 -0.0037274 9.45368 0.0699968 9.64414C0.142515 9.83207 0.270102 9.99373 0.436046 10.1079C0.601991 10.2221 0.798556 10.2835 0.999997 10.2841H2V19.2841C2 19.5494 2.10535 19.8037 2.29289 19.9912C2.48043 20.1788 2.73478 20.2841 3 20.2841H17C17.2652 20.2841 17.5196 20.1788 17.7071 19.9912C17.8946 19.8037 18 19.5494 18 19.2841V10.2841H19C19.2014 10.2835 19.398 10.2221 19.5639 10.1079C19.7299 9.99373 19.8575 9.83207 19.93 9.64414C20.0037 9.45368 20.0174 9.24522 19.9691 9.04679C19.9208 8.84835 19.813 8.66945 19.66 8.53414ZM11 18.2841H9V15.2841C9 15.0189 9.10535 14.7646 9.29289 14.577C9.48043 14.3895 9.73478 14.2841 10 14.2841C10.2652 14.2841 10.5196 14.3895 10.7071 14.577C10.8946 14.7646 11 15.0189 11 15.2841V18.2841ZM16 18.2841H13V15.2841C13 14.4885 12.6839 13.7254 12.1213 13.1628C11.5587 12.6002 10.7956 12.2841 10 12.2841C9.20435 12.2841 8.44129 12.6002 7.87868 13.1628C7.31607 13.7254 7 14.4885 7 15.2841V18.2841H4V10.2841H16V18.2841ZM3.63 8.28414L10 2.62414L16.37 8.28414H3.63Z"
        fill={color}
      />
    </svg>
  )
}

export default HomeSvg
