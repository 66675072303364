import React from 'react'
import { SvgType } from './index'
const IncreaseFontSizeSvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.273932 9C0.204362 9 0.13914 8.97429 0.0782662 8.92286C0.0260887 8.86286 0 8.79857 0 8.73C0 8.67857 0.00434812 8.64 0.0130444 8.61429L3.05238 0.372858C3.13934 0.124286 3.31327 0 3.57415 0H5.60908C5.86996 0 6.04389 0.124286 6.13085 0.372858L9.17019 8.61429C9.17888 8.64 9.18323 8.67857 9.18323 8.73C9.18323 8.79857 9.15279 8.86286 9.09192 8.92286C9.03974 8.97429 8.97887 9 8.9093 9H7.21353C7.00482 9 6.85699 8.90571 6.77002 8.71714L6.30043 7.47H2.8828L2.41321 8.71714C2.32624 8.90571 2.17841 9 1.9697 9H0.273932ZM3.43067 5.54143H5.75256L4.59161 2.26286L3.43067 5.54143Z"
        fill={color}
      />
      <path
        d="M12.8619 7.65735C12.7749 7.65735 12.6967 7.62735 12.6271 7.56735C12.5662 7.49878 12.5358 7.42163 12.5358 7.33592V5.00878H10.24C10.153 5.00878 10.0748 4.97878 10.0052 4.91878C9.94431 4.8502 9.91387 4.77306 9.91387 4.68735V4.61571C9.91387 4.52143 9.94431 4.44429 10.0052 4.38429C10.0661 4.31571 10.1443 4.28143 10.24 4.28143H12.5358V2.03143C12.5358 1.93714 12.5662 1.86 12.6271 1.8C12.6967 1.74 12.7749 1.71 12.8619 1.71H13.052C13.1476 1.71 13.2259 1.74 13.2868 1.8C13.3563 1.86 13.3911 1.93714 13.3911 2.03143V4.28143H15.6608C15.7565 4.28143 15.8348 4.31571 15.8956 4.38429C15.9652 4.44429 16 4.52143 16 4.61571V4.68735C16 4.78163 15.9652 4.85878 15.8956 4.91878C15.8348 4.97878 15.7565 5.00878 15.6608 5.00878H13.3911V7.33592C13.3911 7.4302 13.3563 7.50735 13.2868 7.56735C13.2259 7.62735 13.1476 7.65735 13.052 7.65735H12.8619Z"
        fill={color}
      />
    </svg>
  )
}

export default IncreaseFontSizeSvg
