import { useEffect } from 'react'
import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit'
import axios, { IQueryParams, ICounter } from '../common/axios'
import { IRequest } from '../common/IRequest'
import { ModuloModel } from '../models/ModuloModel'
import { IStateRedux } from '../common/ReduxStore'
import Axios from 'axios'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { AvaliacaoModel } from '../models/AvaliacaoModel'
import { toast } from 'react-toastify'

interface ModulosStoreParams {
  cursoId?: string
  moduloId?: string
}

export interface IState {
  request: IRequest
  modulos: ModuloModel[]
}

export const initialState: IState = {
  modulos: [],
  request: {
    fetching: false,
    errorCode: null,
    message: '',
  },
}

const { reducer: modulosReducer, actions } = createSlice({
  name: 'modulos',
  initialState: initialState,
  reducers: {
    fetchStarted(state: IState) {
      state.request.fetching = false
    },
    fetchError(state: IState, { payload }: PayloadAction<{ errorCode; message }>) {
      const { errorCode, message } = payload
      state.request = { errorCode, message, fetching: false }
    },
    getModulosByCursoComProgressoSuccess(state: IState, { payload }: PayloadAction<{ modulos }>) {
      state.request = { ...initialState.request, fetching: false }
      state.modulos = payload.modulos
    },
    loadModulosSuccess(state, { payload }: PayloadAction<{ modulos }>) {
      state.modulos = payload.modulos
    },
  },
})

const modulosActions = {
  ...actions,
  loadModulos(cursoId) {
    return async (dispatch, getState) => {
      const state: IStateRedux = getState()
      // if (state.modulos.modulos.length) return
      try {
        const modulos = await Axios.get(`/app/modulos?cursoId=${cursoId}`).then((x) => x.data)
        dispatch(actions.loadModulosSuccess({ modulos }))
      } catch (error: any) {}
    }
  },
  getModulosByCursoComProgresso(cursoId) {
    return async (dispatch: any) => {
      dispatch(actions.fetchStarted())
      try {
        const modulos = await axios.Modulos.getModulosByCursoComprogresso(cursoId)
        dispatch(actions.getModulosByCursoComProgressoSuccess({ modulos }))
      } catch (error: any) {
        dispatch(
          actions.fetchError({ errorCode: 400, message: 'Falha ao buscar os cursos do aluno' })
        )
      }
    }
  },
  refazerAvaliacao(moduloId: number, onSuccess?: () => any) {
    return async (dispatch) => {
      try {
        await axios.Avaliacoes.refazerAvaliacao(moduloId)
        onSuccess?.()
      } catch (error: any) {
        toast.error('Erro ao refazer avaliação!')
      }
    }
  },
}

const getModulosComEstatisticas = createSelector(
  (s: IStateRedux) => s.modulos.modulos,
  (s: IStateRedux) => s.progresso.estatisticasEstudos,
  (modulos, estatisticasEstudo) => {
    return [...modulos].map((m) => {
      const estatisticaEstudo = estatisticasEstudo.find((e) => e.moduloId === m.id)
      return {
        ...m,
        estatisticaEstudo,
      }
    })
  }
)

const getModulosProgresso = createSelector(
  (s: IStateRedux) => s.modulos.modulos,
  (modulos) => {
    return modulos
      .map((modulo) => {
        return {
          ...modulo,
          progresso: modulo.progresso !== null ? Math.round(modulo.progresso) : null,
          desempenho: modulo.desempenho !== null ? Math.round(modulo.desempenho) : null,
          avaliacao: modulo.avaliacao !== null ? Math.round(modulo.avaliacao) : null,
        }
      })
      .map((modulo) => {
        if (modulo.nome === 'Legislação de Trânsito') {
          modulo.classe = 'modulo-legislacao'
          if (!modulo.progresso) modulo.progresso = 0
        }
        if (modulo.nome === 'Direção Defensiva') modulo.classe = 'modulo-direcao-defensiva'
        if (modulo.nome === 'Noções de Primeiros Socorros')
          modulo.classe = 'modulo-primeiros-socorros'
        if (modulo.nome === 'Relacionamento Interpessoal')
          modulo.classe = 'modulo-relacionamento-interpessoal'

        if (modulo.avaliacao != null) {
          modulo.status = 'APROVADO'
          modulo.classe += ' modulo-aprovado'
        } else if (modulo.progresso === 100) {
          modulo.status = 'AVALIACAO'
          modulo.classe += ' modulo-avaliacao'
        } else if (modulo.progresso != null) {
          modulo.status = 'CONTINUAR'
          modulo.classe += ' modulo-continuar'
        } else {
          modulo.status = 'NAO_INICIADO'
          modulo.classe += ' modulo-nao-iniciado'
        }
        return modulo
      })
  }
)

const useModuloAtual = ({ moduloId, cursoId } = { moduloId: 0, cursoId: 0 }) => {
  const { moduloId: routeModuloId, cursoId: routeCursoId } = useParams<ModulosStoreParams>()
  moduloId = moduloId || +(routeModuloId || 0)
  cursoId = cursoId || +(routeCursoId || 0)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(modulosActions.loadModulos(cursoId))
  }, [])
  const modulo = useSelector((s: IStateRedux) => s.modulos.modulos.find((x) => moduloId === x.id))

  if (!moduloId || !cursoId) throw new Error('Módulo ou Curso não presentes. Verifique sua rota')

  return modulo
}

const getModulos = createSelector(
  (s: IStateRedux) => s.modulos.modulos,
  (m) => m
)

export {
  modulosReducer,
  modulosActions,
  getModulosProgresso,
  useModuloAtual,
  getModulos,
  getModulosComEstatisticas,
}
