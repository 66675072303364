import { IonButton, IonCard, IonCardContent, IonCardHeader, IonTextarea } from '@ionic/react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useHistory, useParams } from 'react-router'
import useAxios from 'axios-hooks'

interface CadastroNotaParams {
  cursoId: string
}

function CadastroNota() {
  const { cursoId } = useParams<CadastroNotaParams>()
  const history = useHistory()

  const [conteudoNota, setConteudoNota] = useState('')
  const [{ data }, salvarNota] = useAxios(
    {
      url: 'app/bloco-notas',
      method: 'POST',
    },
    { manual: true }
  )

  const salvar = async (e) => {
    try {
      const response = await salvarNota({
        data: {
          cursoId,
          texto: conteudoNota,
        },
      })
      history.goBack()
    } catch (e) {
      /* handle error */
    }
  }
  return (
    <div>
      <IonCard>
        <IonCardHeader>
          <div>Texto da nota</div>
        </IonCardHeader>
        <IonCardContent>
          <IonTextarea
            name="conteudoNota"
            onIonChange={(e) => setConteudoNota(e.detail.value || '')}
            value={conteudoNota}
            rows={20}
            placeholder="Digite aqui o conteudo da nota"
          />
          <div className="form-button">
            <IonButton type="button" id="btn-salvar" onClick={salvar}>
              Salvar
            </IonButton>
            <IonButton type="button" color="light" onClick={() => history.goBack()}>
              {' '}
              Voltar{' '}
            </IonButton>
          </div>
        </IonCardContent>
      </IonCard>
    </div>
  )
}

const Input = styled(IonTextarea)`
  background: #ffffff;
  border: 1px solid #ededed;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  transition: 0.35s box-shadow;
  border-radius: 3px;
  height: 3em;
  padding-left: 1em;
  width: 100%;
`

export default CadastroNota
