import React from 'react'
import { SvgType } from './index'
const MenuSvg = ({ color = '#4F4F4F', ...props }: SvgType) => {
  return (
    <svg
      width="19"
      height="12"
      viewBox="0 0 19 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.330566 12H18.1254V10H0.330566V12ZM0.330566 7H18.1254V5H0.330566V7ZM0.330566 0V2H18.1254V0H0.330566Z"
        fill={color}
      />
    </svg>
  )
}

export default MenuSvg
