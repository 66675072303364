import React from 'react'
import { SvgType } from './index'
const SendSvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.3192 1.14939C15.0135 0.857396 14.6259 0.652451 14.2012 0.558248C13.7764 0.464044 13.3319 0.484431 12.919 0.617049L1.59837 4.16346C1.1418 4.30351 0.742875 4.57294 0.45667 4.93455C0.170466 5.29615 0.011095 5.7321 0.000558352 6.18221C-0.00997831 6.63233 0.128839 7.07441 0.397847 7.44742C0.666855 7.82044 1.05279 8.10601 1.50237 8.26469L5.69461 9.76423C5.79007 9.79844 5.87699 9.85072 5.94994 9.9178C6.02289 9.98487 6.08032 10.0653 6.11863 10.1541L7.71873 14.0904C7.8816 14.5058 8.17717 14.864 8.56515 15.1162C8.95313 15.3685 9.41466 15.5025 9.88685 15.5H9.94285C10.4237 15.4918 10.8897 15.3428 11.2757 15.0739C11.6616 14.8049 11.9481 14.4296 12.095 14.0004L15.8792 3.3762C16.0168 2.99302 16.0373 2.5814 15.9383 2.18788C15.8394 1.79436 15.6249 1.43464 15.3192 1.14939Z"
        fill={color}
      />
    </svg>
  )
}

export default SendSvg
