import Card from '../../components/Card'
import React, { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import SvgGauge from 'svg-gauge'

const CardDegrade = ({ color, label, value }) => {
  const { Container, Label, Value } = CardDegrade
  return (
    <Container color={color}>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </Container>
  )
}

CardDegrade.Container = styled('div')<{ color }>(
  (props) => `
  display: grid;
  height: 160px;
  padding: 16px 21px 26px;
  background: ${props.color};
  box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08);
  border-radius: 8px;
  @media (max-width: 768px) {
    height: 130px;
    padding: 11px 14px 18px;
  }
`
)

CardDegrade.Label = styled('div')`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`

CardDegrade.Value = styled('div')`
  font-size: 24px;
  font-weight: 500;
  align-self: end;
  letter-spacing: 0.01em;
  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`

const CardProgresso = ({ total, value, ...props }) => {
  const { Layout, Titulo } = CardProgresso
  return (
    <Layout {...props}>
      <Titulo>Horas estudadas</Titulo>
      <BarraProgresso value={value} total={total} />
    </Layout>
  )
}

CardProgresso.Layout = styled(Card)`
  display: grid;
  padding: 18px;
  height: 160px;
`

CardProgresso.Titulo = styled('div')`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #2d2d2d;
`

const BarraProgresso = ({ value, total }) => {
  const { Label, Layout, ValueLabel } = BarraProgresso

  const valuePercent = (value * 100) / total / 100
  const gradientWidth = valuePercent * 420

  return (
    <Layout>
      <ValueLabel percent={valuePercent * 100}> {value}h </ValueLabel>
      <svg
        style={{ width: '100%' }}
        viewBox="0 0 420 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="corGradient" x1="1" x2="0">
            <stop stopColor="#FF6B36" />
            <stop offset="1.001" stopColor="#FFEB36" />
          </linearGradient>
        </defs>
        <rect x="0" y="0" width={420} height="10" rx="4" fill="#D9D9D9" />
        <rect x="0" y="0" width={gradientWidth} height="10" rx="4" fill="url(#corGradient)" />
      </svg>
      <Label>0h</Label>
      <Label style={{ position: 'absolute', right: '0px' }}>{total}h</Label>
    </Layout>
  )
}

BarraProgresso.ValueLabel = styled('div')<{ percent }>(
  (props) => `
  margin-left: ${!props.percent ? '0' : `calc(${props.percent}% - 12px)`};
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.0939231px;
  color: #2D2D2D;
  white-space: nowrap;
`
)

BarraProgresso.Layout = styled('div')`
  position: relative;
  align-self: end;
`

BarraProgresso.Label = styled('span')`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.0939231px;
  color: #88898c;
`

const CardProgressoVelocimetro = (props) => {
  const { Layout, Titulo } = CardProgressoVelocimetro
  return (
    <Layout {...props}>
      <Titulo> Horas concluídas </Titulo>
      <Velocimetro value={props.value} max={props.total} />
    </Layout>
  )
}

const defaultOptions = {
  animDuration: 1,
  showValue: false,
  dialStartAngle: 160,
  dialEndAngle: 20,
  initialValue: 0,
  max: 100,
}

const Velocimetro = (props) => {
  const { Layout, HorasAtuais, TotalHoras, DescricaoHoras } = Velocimetro

  const gaugeEl = useRef(null)
  const gaugeRef = useRef<any>(null)
  useEffect(() => {
    if (!gaugeRef.current) {
      const options = { ...defaultOptions, ...props }
      gaugeRef.current = SvgGauge(gaugeEl.current!, options)
      gaugeRef.current.setValue(options.initialValue)
    }
    gaugeRef.current.setValueAnimated(props.value, 1)
  }, [props])

  return (
    <Layout>
      <DescricaoHoras>
        <HorasAtuais>{props.value}h</HorasAtuais>
        <TotalHoras>total {props.max}h</TotalHoras>
      </DescricaoHoras>
      <div ref={gaugeEl} className="gauge-container" />
    </Layout>
  )
}

Velocimetro.DescricaoHoras = styled('div')`
  text-align: center;
  display: inline-block;
  top: 125px;
  width: 100%;
  position: absolute;
  @media (max-width: 424px) {
    top: 86px;
  }
`

Velocimetro.HorasAtuais = styled('div')`
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.850962px;
  color: #2d2d2d;
`

Velocimetro.TotalHoras = styled('div')`
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.2px;
  color: #88898c;
`

Velocimetro.Layout = styled('div')`
  display: grid;
  position: relative;

  path {
    stroke-linecap: round;
  }

  .gauge-container {
    justify-self: center;
    width: 330px;
    height: 220px;
    @media (max-width: 424px) {
      width: 250px;
      height: 180px;
    }
  }

  .gauge-container > .gauge .value {
    stroke: url('#corGradient');
    stroke-width: 5px;
    fill: rgba(0, 0, 0, 0);
  }

  .gauge-container > .gauge .dial {
    stroke: #eee;
    stroke-width: 5;
    fill: rgba(0, 0, 0, 0);
  }
`

CardProgressoVelocimetro.Layout = styled(Card)`
  display: grid;
  padding: 24px;
`

CardProgressoVelocimetro.Titulo = styled('div')`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #2d2d2d;
`

const CardNumerico = (props) => {
  const { Layout, Titulo, Value, Icone } = CardNumerico
  return (
    <Layout {...props}>
      <div>
        <Titulo>{props.titulo}</Titulo>
        <Icone src={props.icon} />
      </div>
      <Value color={props.color}>{props.value}</Value>
    </Layout>
  )
}

CardNumerico.Layout = styled(Card)`
  position: relative;
  display: grid;
  padding: 18px 16px;
`

CardNumerico.Titulo = styled('div')`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  width: 117px;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #2d2d2d;
`

CardNumerico.Icone = styled('img')`
  position: absolute;
  top: 18px;
  right: 16px;
`

CardNumerico.Value = styled('div')<{ color }>(
  (props) => `
  align-self: end;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  color: ${props.color};
`
)

export { CardDegrade, CardProgresso, CardProgressoVelocimetro, CardNumerico }
