/* eslint-disable */
import React, { useRef, useEffect, useState } from 'react'
import { useField } from 'formik'
import Inputmask from 'inputmask'
import styled from '@emotion/styled'
import AnimateHeightComp from 'react-animate-height'
const AnimateHeight: any = AnimateHeightComp

type TextFieldInputType = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  errorMessage?: string
  mask?
  label?: string
  children?
}

export const TextFieldInput = React.memo(function textFieldInput({
  children: Children,
  className,
  style,
  ...props
}: TextFieldInputType) {
  const ref = useRef<any>(null)
  const [displayErrorMessage, setDisplayErrorMessage] = useState(props.errorMessage)
  useEffect(() => {
    if (!props.errorMessage) setTimeout(() => setDisplayErrorMessage(''), 500)
    else setDisplayErrorMessage(props.errorMessage)
  }, [props.errorMessage])
  useEffect(() => {
    if (props.mask) {
      let mask = props.mask
      if (mask === 'cpf') mask = { mask: '999.999.999-99' }
      else if (mask === 'cnpj') mask = { mask: '99.999.999/9999-99' }
      else if (mask === 'data') mask = { mask: '99/99/9999' }
      else if (mask === 'cnh') mask = { mask: '999999999-99' }
      else if (mask === 'telefone') {
        mask = {
          mask: ['(99) 9999-9999', '(99) 99999-9999'],
          keepStatic: true,
        }
      } else if (mask === 'renach') mask = { mask: 'aa999999999' }
      else mask = { mask }
      Inputmask(mask).mask(ref.current)
    }
  }, [props.mask])
  function handlePaste(e) {
    if (props.onPaste) props.onPaste(e)
    if (props.onChange) props.onChange(e)
  }
  return (
    <Styles.Label hasError={props.errorMessage} style={style} className={className}>
      {props.label}
      {props.type === 'select' ? (
        <select
          {...(props as any)}
          style={{
            color: !props.value ? '#757575' : style?.color ?? '#000000',
          }}
        >
          {Children}
        </select>
      ) : Children ? (
        React.cloneElement(Children, props)
      ) : (
        <input ref={ref} {...(props as any)} onPaste={handlePaste} />
      )}
      <AnimateHeight height={props.errorMessage ? 'auto' : 0}>
        <div className="error-message">{displayErrorMessage || props.errorMessage}</div>
      </AnimateHeight>
    </Styles.Label>
  )
})

export const TextField = React.memo(function textField(props: any) {
  const [field, meta] = useField(props.name)

  if (!props.name) throw new Error('É necessário passar o parâmetro "name" para um "TextField"')
  const errorMessage = meta.touched && meta.error ? meta.error : null

  return (
    <div>
      <TextFieldInput
        {...field}
        {...props}
        onChange={(e) => {
          if (props.onChange) props.onChange(e)
          field.onChange(e)
        }}
        onBlur={(e) => {
          if (props.onBlur) {
            props.onBlur(e)
            field.onChange(e)
          }
        }}
        errorMessage={errorMessage}
      />
      {}
    </div>
  )
})

const Styles = {
  Label: styled('label')<{ hasError }>(
    (props) => `
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
    font-size: 14px;
    input, textarea, select {
      background: #FFFFFF;
      border: 1px solid #828282;
      border-radius: 4px;
      transition: .35s box-shadow;
      height: 3em;
      padding-left: 1em;
      width: 100%;
      outline: none;
      transition: border .3s;
      margin-top: 8px;
      &:disabled {
        background: #f2f2f2;
      }
      ${
        props.hasError
          ? `
        border-color: red;
      `
          : ''
      }
    }
    .error-message {
      color: red;
      text-align: right;
    }
  `
  ),
}
