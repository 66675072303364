import React from 'react'
import { SvgType } from './index'
const OnboardingModuloFinalizadoSvg = ({ ...props }: SvgType) => {
  return (
    <svg
      width="385"
      height="268"
      viewBox="0 0 385 268"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.1"
        d="M16.8696 133.625C22.4181 148.859 34.1612 162.046 52.722 168.438C91.2942 181.715 178.283 174.867 243.203 167.008C276.787 162.943 306.3 151.727 327.742 135.932L16.8696 133.625Z"
        fill="#FF9933"
      />
      <path
        opacity="0.1"
        d="M357.32 61.8542H321.715C320.024 61.8542 318.636 60.964 318.636 59.8761C318.636 58.7882 320.024 57.898 321.715 57.898H327.874C326.179 57.898 324.795 57.0079 324.795 55.92C324.795 54.832 326.179 53.9419 327.874 53.9419H323.476C321.781 53.9419 320.397 53.0518 320.397 51.9638C320.397 50.8759 321.781 49.9858 323.476 49.9858H342.063C337.939 48.7495 333.129 47.8725 327.557 47.4143C276.853 43.2208 258.75 46 252.365 48.8385C244.08 52.5221 235.309 54.9958 226.32 56.1837C195.027 60.331 136.533 65.3257 83.5139 54.5716C57.1628 49.2341 37.5767 58.2541 25.8369 73.3302H363.509C363.067 68.9455 361.748 64.9004 359.325 61.3596C358.709 61.6899 358.02 61.8599 357.32 61.8542Z"
        fill="#FF9933"
      />
      <path
        opacity="0.1"
        d="M13.8466 101.797C12.4786 110.666 12.9488 119.72 15.2279 128.399L327.982 135.754C336.64 129.345 343.975 122.185 349.711 114.447L13.8466 101.797ZM137.545 120.774H131.399C133.091 120.774 134.475 121.667 134.475 122.752C134.475 123.836 133.091 124.73 131.399 124.73H95.7943C94.0998 124.73 92.7151 123.84 92.7151 122.752C92.7151 121.664 94.0998 120.774 95.7943 120.774H101.949C100.258 120.774 98.8702 119.883 98.8702 118.796C98.8702 117.708 100.258 116.817 101.949 116.817H97.5251C95.8339 116.817 94.4459 115.924 94.4459 114.839C94.4459 113.755 95.8339 112.861 97.5251 112.861H133.147C134.841 112.861 136.226 113.751 136.226 114.839C136.226 115.927 134.841 116.817 133.147 116.817H137.545C139.239 116.817 140.624 117.708 140.624 118.796C140.624 119.883 139.239 120.774 137.545 120.774Z"
        fill="#FF9933"
      />
      <path
        opacity="0.1"
        d="M363.522 73.4785L22.0686 78.8193C17.9041 85.7786 15.1276 93.4789 13.8926 101.494H357.538C359.736 96.8829 361.396 92.0332 362.483 87.0414C363.522 82.3139 363.93 77.7379 363.522 73.4785ZM83.2203 90.9778H77.062C78.7565 90.9778 80.1411 91.8712 80.1411 92.9558C80.1411 94.0405 78.7565 94.9339 77.062 94.9339H41.4569C39.7623 94.9339 38.3777 94.0438 38.3777 92.9558C38.3777 91.8679 39.7623 90.9778 41.4569 90.9778H47.6152C45.9207 90.9778 44.536 90.0876 44.536 88.9997C44.536 87.9118 45.9207 87.0216 47.6152 87.0216H43.2009C41.5063 87.0216 40.1217 86.1282 40.1217 85.0436C40.1217 83.9589 41.5063 83.0655 43.2009 83.0655H78.806C80.4972 83.0655 81.8818 83.9556 81.8818 85.0436C81.8818 86.1315 80.4972 87.0216 78.806 87.0216H83.2038C84.8951 87.0216 86.2797 87.9118 86.2797 88.9997C86.2797 90.0876 84.9149 90.9778 83.2203 90.9778Z"
        fill="#FF9933"
      />
      <path
        d="M195.027 200.087C194.186 200.74 193.152 201.094 192.088 201.094C191.024 201.094 189.989 200.74 189.149 200.087C188.328 194.67 190.412 184.025 190.903 181.625L191.005 181.144H193.379C193.537 181.3 193.661 181.488 193.745 181.694C195.357 185.166 195.027 200.087 195.027 200.087Z"
        fill="#535F89"
      />
      <path
        opacity="0.1"
        d="M192.881 178.961V181.147H191.892V178.961C192.051 178.934 192.212 178.921 192.373 178.921C192.543 178.921 192.713 178.934 192.881 178.961Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M193.728 181.704C193.31 181.927 192.843 182.04 192.37 182.033C191.856 182.046 191.349 181.911 190.909 181.644L191.011 181.163H193.378C193.529 181.319 193.648 181.502 193.728 181.704Z"
        fill="black"
      />
      <path
        d="M192.37 181.651C193.464 181.651 194.351 181.124 194.351 180.474C194.351 179.824 193.464 179.297 192.37 179.297C191.275 179.297 190.388 179.824 190.388 180.474C190.388 181.124 191.275 181.651 192.37 181.651Z"
        fill="#DCE2ED"
      />
      <path
        d="M265.805 208.602C265.25 209.034 264.567 209.268 263.863 209.268C263.16 209.268 262.477 209.034 261.922 208.602C261.378 205.022 262.756 197.98 263.079 196.404C263.122 196.196 263.148 196.074 263.148 196.074H264.701C264.805 196.178 264.888 196.301 264.945 196.437C266.019 198.741 265.805 208.602 265.805 208.602Z"
        fill="#535F89"
      />
      <path
        opacity="0.1"
        d="M264.381 194.637V196.085H263.722V194.637C263.83 194.617 263.941 194.608 264.051 194.611C264.162 194.611 264.272 194.62 264.381 194.637Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M264.945 196.434C264.667 196.578 264.357 196.65 264.045 196.645C263.705 196.652 263.37 196.564 263.079 196.391C263.121 196.183 263.148 196.061 263.148 196.061H264.701C264.806 196.167 264.889 196.294 264.945 196.434Z"
        fill="black"
      />
      <path
        d="M264.045 196.414C264.767 196.414 265.353 196.066 265.353 195.636C265.353 195.206 264.767 194.858 264.045 194.858C263.322 194.858 262.736 195.206 262.736 195.636C262.736 196.066 263.322 196.414 264.045 196.414Z"
        fill="#DCE2ED"
      />
      <path
        opacity="0.1"
        d="M146.706 268C227.73 268 293.412 261.505 293.412 253.494C293.412 245.483 227.73 238.988 146.706 238.988C65.6826 238.988 0 245.483 0 253.494C0 261.505 65.6826 268 146.706 268Z"
        fill="#FF9933"
      />
      <path
        opacity="0.1"
        d="M130.842 213.557C127.76 211.986 124.224 211.544 120.85 212.308C115.081 213.557 106.242 212.895 105.438 212.756"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M112.384 145.709C112.384 145.709 110.979 167.857 113.475 172.225L112.384 145.709Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M107.201 172.226C107.201 172.226 109.001 192.969 110.247 197.024C111.494 201.079 107.201 172.226 107.201 172.226Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M105.447 121.221C104.171 121.689 98.7117 132.76 99.8062 136.04C100.901 139.32 105.447 121.221 105.447 121.221Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M95.6494 146.919C96.2824 153.351 96.6384 157.561 96.2231 162.711C95.8842 166.71 95.9417 170.733 96.3945 174.721"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M83.8535 0.58252C84.2265 1.30729 84.7179 1.96469 85.3074 2.52761C85.8217 3.01554 86.4085 3.43093 86.8734 3.96501C87.241 4.43082 87.5717 4.92464 87.8624 5.44196C89.0558 7.35079 90.5558 9.04862 92.0526 10.7168"
        fill="black"
      />
      <path
        d="M76.086 202.899C75.0374 203.714 73.7468 204.157 72.4183 204.157C71.0898 204.157 69.7993 203.714 68.7507 202.899C67.7287 196.137 70.3265 182.848 70.9397 179.867L71.0683 179.264H74.0024C74.1975 179.461 74.3531 179.694 74.4607 179.95C76.4915 184.275 76.086 202.899 76.086 202.899Z"
        fill="#535F89"
      />
      <path
        opacity="0.1"
        d="M73.3994 176.534V179.264H72.1631V176.531C72.3625 176.499 72.5643 176.483 72.7664 176.485C72.9784 176.484 73.19 176.501 73.3994 176.534Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M74.4609 179.95C73.9385 180.223 73.3557 180.36 72.7663 180.349C72.1242 180.365 71.4907 180.198 70.9399 179.868L71.0685 179.264H74.0026C74.1978 179.461 74.3533 179.694 74.4609 179.95Z"
        fill="black"
      />
      <path
        d="M72.7665 179.884C74.1321 179.884 75.2391 179.227 75.2391 178.417C75.2391 177.607 74.1321 176.95 72.7665 176.95C71.401 176.95 70.2939 177.607 70.2939 178.417C70.2939 179.227 71.401 179.884 72.7665 179.884Z"
        fill="#DCE2ED"
      />
      <path
        opacity="0.1"
        d="M218.503 169.882C220.272 168.98 222.301 168.727 224.236 169.166C227.533 169.882 232.627 169.496 233.088 169.423"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M232.079 146.142C232.079 146.142 231.047 158.056 230.332 160.387C229.617 162.718 232.079 146.142 232.079 146.142Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M233.088 116.85C233.82 117.12 236.956 123.479 236.329 125.359C235.703 127.238 233.088 116.85 233.088 116.85Z"
        fill="black"
      />
      <path
        d="M125.637 115.9C120.223 123.199 115.766 144.671 114.517 151.143L105.088 150.77C105.681 145.202 117.082 122.718 120.253 116.55C121.951 116.322 123.767 116.092 125.637 115.9Z"
        fill="#FF9933"
      />
      <path d="M243.437 113.309H239.256V119.197H243.437V113.309Z" fill="#DCE0ED" />
      <path
        opacity="0.1"
        d="M243.437 113.309V115.225C243.142 115.308 242.837 115.349 242.53 115.346L239.26 115.32V113.309H243.437Z"
        fill="black"
      />
      <path
        d="M237.493 98.3086H241.449C242.393 98.3098 243.314 98.5929 244.096 99.1216C244.878 99.6502 245.484 100.4 245.837 101.276C246.991 104.121 247.97 108.644 245.398 113.144C245.112 113.648 244.696 114.067 244.193 114.357C243.691 114.647 243.12 114.797 242.54 114.792L237.503 114.75L237.493 98.3086Z"
        fill="#DCE0ED"
      />
      <path
        d="M237.493 114.75C239.827 114.75 241.719 111.07 241.719 106.531C241.719 101.992 239.827 98.312 237.493 98.312C235.158 98.312 233.266 101.992 233.266 106.531C233.266 111.07 235.158 114.75 237.493 114.75Z"
        fill="#F0F0F4"
      />
      <path d="M206.315 113.378H202.135V118.531H206.315V113.378Z" fill="#DCE0ED" />
      <path
        opacity="0.1"
        d="M206.315 113.378V115.027C206.019 115.111 205.713 115.152 205.405 115.149L202.135 115.119V113.388L206.315 113.378Z"
        fill="black"
      />
      <path
        d="M200.371 98.3813H204.327C205.271 98.3819 206.192 98.6648 206.974 99.1936C207.755 99.7223 208.361 100.473 208.712 101.348C209.869 104.194 210.848 108.717 208.277 113.217C207.989 113.721 207.573 114.14 207.07 114.43C206.567 114.719 205.996 114.87 205.415 114.865L200.381 114.819L200.371 98.3813Z"
        fill="#DCE0ED"
      />
      <path
        d="M200.371 114.819C202.705 114.819 204.597 111.139 204.597 106.6C204.597 102.061 202.705 98.3813 200.371 98.3813C198.037 98.3813 196.145 102.061 196.145 106.6C196.145 111.139 198.037 114.819 200.371 114.819Z"
        fill="#F0F0F4"
      />
      <path
        d="M201.426 101.385C201.426 101.385 199.867 101.635 200.678 103.693C201.108 104.691 201.421 105.737 201.611 106.808C201.611 106.808 203.17 107.619 203.107 104.939C203.045 102.258 201.426 101.385 201.426 101.385Z"
        fill="#DCE0ED"
      />
      <path
        d="M43.056 245.852C56.6734 245.852 67.7125 233.754 67.7125 218.829C67.7125 203.904 56.6734 191.805 43.056 191.805C29.4385 191.805 18.3994 203.904 18.3994 218.829C18.3994 233.754 29.4385 245.852 43.056 245.852Z"
        fill="#636780"
      />
      <path
        opacity="0.1"
        d="M52.2936 193.49C39.3933 194.357 29.1733 206.1 29.1733 220.461C29.1733 232.036 35.813 241.909 45.1462 245.757C58.0498 244.89 68.2698 233.147 68.2698 218.786C68.2698 207.211 61.6268 197.337 52.2936 193.49Z"
        fill="black"
      />
      <path
        d="M236.029 185.858C247.081 185.858 256.04 174.175 256.04 159.764C256.04 145.353 247.081 133.67 236.029 133.67C224.977 133.67 216.018 145.353 216.018 159.764C216.018 174.175 224.977 185.858 236.029 185.858Z"
        fill="#636780"
      />
      <path
        opacity="0.1"
        d="M230.437 134.761C222.136 137.943 216.077 147.948 216.077 159.8C216.077 172.466 223 183.023 232.178 185.396C240.476 182.215 246.539 172.209 246.539 160.357C246.539 147.691 239.616 137.135 230.437 134.761Z"
        fill="black"
      />
      <path
        d="M160.856 154.192L161.228 144.721C161.228 144.721 159.369 140.633 157.513 140.263C155.657 139.894 151.384 139.66 151.384 139.66C151.384 139.66 148.041 153.263 150.27 157.351C152.499 161.439 160.856 154.192 160.856 154.192Z"
        fill="#FF9933"
      />
      <path
        opacity="0.1"
        d="M160.856 154.192L161.228 144.721C161.228 144.721 159.369 140.633 157.513 140.263C155.657 139.894 151.384 139.66 151.384 139.66C151.384 139.66 148.041 153.263 150.27 157.351C152.499 161.439 160.856 154.192 160.856 154.192Z"
        fill="black"
      />
      <path
        d="M126.589 153.17C126.589 153.17 125.439 140.036 132.655 139.799C133.585 139.769 134.515 139.746 135.441 139.657C140.442 139.172 154.088 138.121 152.452 141.236C152.452 141.236 149.202 152.659 151.43 155.537L126.589 153.17Z"
        fill="#FF9933"
      />
      <path
        d="M228.881 177.524L238.561 177.804C238.561 177.804 240.232 159.972 240.789 158.58C241.346 157.189 241.626 150.5 239.817 149.528C238.007 148.555 231.951 147.368 231.951 147.368L227.487 157.532L227.141 166.727L228.881 177.524Z"
        fill="#FF9933"
      />
      <path
        opacity="0.1"
        d="M228.881 177.524L238.561 177.804C238.561 177.804 240.232 159.972 240.789 158.58C241.346 157.189 241.626 150.5 239.817 149.528C238.007 148.555 231.951 147.368 231.951 147.368L227.487 157.532L227.141 166.727L228.881 177.524Z"
        fill="black"
      />
      <path
        d="M205.477 147.925L205.435 145.947C205.431 145.791 205.46 145.635 205.519 145.49C205.578 145.345 205.667 145.214 205.778 145.105C205.89 144.996 206.023 144.91 206.17 144.854C206.316 144.798 206.472 144.773 206.628 144.78C211.428 144.981 228.888 145.818 231.944 147.378C231.944 147.378 228.601 172.242 232.293 178.163L231.041 198.082L188.275 194.878L205.477 147.925Z"
        fill="#FF9933"
      />
      <path
        opacity="0.1"
        d="M202.135 116.524C203.322 116.405 204.736 116.28 206.315 116.154V118.531H202.135V116.524Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M243.437 118.894V119.194H239.26V117.387C240.709 117.714 242.113 118.22 243.437 118.894Z"
        fill="black"
      />
      <path
        d="M125.617 148.031C125.635 147.931 125.692 147.842 125.775 147.783C125.867 147.75 125.966 147.74 126.062 147.757C126.062 147.862 126.062 147.925 126.062 147.925C125.85 148.115 125.65 148.319 125.465 148.535C125.524 148.367 125.567 148.199 125.617 148.031ZM140.746 143.593C140.747 143.57 140.747 143.547 140.746 143.524C140.956 142.996 141.197 142.481 141.468 141.981C141.204 142.489 140.96 143.026 140.746 143.593ZM143.001 139.327C142.645 139.858 142.213 140.544 141.787 141.338C142.106 140.619 142.513 139.944 143.001 139.327ZM84.0972 185.462C85.7752 185.528 87.1731 185.498 87.1731 185.498C86.8698 185.637 85.5214 185.574 84.0972 185.462ZM87.1764 185.495C87.239 185.456 87.8588 185.093 89.3061 185.238C89.3061 185.225 87.8819 185.175 87.1764 185.495ZM143.489 160.136L144.102 149.587C144.102 149.587 143.874 154.974 143.95 157.304C143.978 158.27 143.822 159.233 143.489 160.14V160.136Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M108.405 180.339C108.405 180.339 108.23 180.88 107.999 181.565C107.623 182.683 107.092 184.203 106.911 184.344C106.73 184.486 105.325 184.579 103.796 184.674C101.424 184.812 99.0854 184.069 97.2285 182.587C97.2285 182.587 98.0296 182.01 98.8769 181.341C99.7242 180.672 100.614 179.914 100.796 179.541C101.049 179.03 102.5 178.15 103.374 177.655C103.766 177.431 104.033 177.286 104.033 177.286L108.405 180.339Z"
        fill="#F899A5"
      />
      <path
        d="M106.552 184.997C106.552 184.997 91.1358 184.288 90.216 184.084C89.2962 183.879 89.1083 182.798 89.1083 182.798C88.8017 182.079 90.427 182.234 90.427 182.234C90.5919 182.261 92.4051 182.564 94.1128 182.782C95.481 182.956 96.7766 183.075 97.0799 182.946C97.7689 182.66 99.1437 182.782 99.1437 182.782C98.3623 180.197 95.9953 179.58 94.9832 179.435L95.0458 179.373C95.315 179.122 95.61 178.901 95.926 178.713C99.0678 178.842 100.729 183.174 100.729 183.174H100.756C101.112 183.286 104.9 184.46 105.651 184.568C106.436 184.69 106.552 184.997 106.552 184.997Z"
        fill="#F3B4B4"
      />
      <path
        d="M98.4911 186.372C98.4317 186.567 98.1614 186.702 97.7196 186.738C97.4804 186.755 97.2402 186.755 97.0009 186.738C95.5355 186.612 94.0938 186.288 92.7151 185.775C92.4777 185.693 92.2536 185.62 92.036 185.558H92.0096C91.4862 185.401 90.9501 185.291 90.4074 185.228C89.0095 185.083 88.4128 185.446 88.3535 185.485C88.3535 185.485 87.0018 185.515 85.3864 185.449C84.1441 185.415 82.9064 185.285 81.6841 185.06C81.5094 185.024 81.3544 184.981 81.1929 184.938C80.9039 184.865 80.6257 184.754 80.3654 184.608C78.6643 183.586 84.3941 182.897 84.3941 182.897C85.0487 182.817 85.6584 182.523 86.1282 182.06L86.1875 181.994L86.3326 181.849C86.5542 181.656 86.7864 181.475 87.0282 181.308L87.2392 181.166C88.3799 180.395 89.9162 179.571 89.9162 179.571C90.9849 179.28 91.9619 178.722 92.7547 177.949C92.9005 177.805 93.0329 177.648 93.1503 177.481L96.3185 176.663L98.5009 181.905C98.5009 181.905 98.5174 182.188 98.5339 182.614C98.5702 183.754 98.6328 185.911 98.4911 186.372Z"
        fill="#F899A5"
      />
      <path
        opacity="0.1"
        d="M97.7196 186.738C97.4803 186.755 97.2401 186.755 97.0009 186.738C95.5354 186.612 94.0938 186.288 92.7151 185.775C92.4777 185.693 92.2535 185.62 92.0359 185.558H92.0096C91.4862 185.401 90.95 185.291 90.4073 185.228C89.0095 185.083 88.4128 185.446 88.3534 185.485C88.3534 185.485 87.0018 185.515 85.3864 185.449C84.1441 185.415 82.9063 185.285 81.6841 185.06C81.5094 185.024 81.3544 184.981 81.1929 184.938C81.4039 184.912 81.6167 184.903 81.8292 184.912C81.9907 184.931 83.8237 185.155 85.5314 185.297C86.9095 185.413 88.2084 185.475 88.4985 185.334C89.1743 185.004 90.5524 185.076 90.5524 185.076C89.6623 182.528 87.2556 182.017 86.2501 181.915C86.2699 181.892 86.293 181.872 86.3095 181.849C86.5661 181.593 86.8478 181.363 87.1501 181.163H87.2161C90.3183 181.213 92.1447 185.406 92.1447 185.406H92.1711C92.5305 185.502 96.3679 186.514 97.1163 186.59C97.3242 186.605 97.528 186.656 97.7196 186.738Z"
        fill="black"
      />
      <path
        d="M97.9008 187.13C97.9008 187.13 82.4686 187.091 81.5422 186.929C80.6158 186.767 80.3784 185.689 80.3784 185.689C80.0488 184.987 81.6609 185.073 81.6609 185.073C81.8224 185.093 83.639 185.317 85.3632 185.462C86.7379 185.574 88.0401 185.637 88.3303 185.498C88.9896 185.169 90.3842 185.241 90.3842 185.241C89.4907 182.693 87.0874 182.182 86.0819 182.079L86.1412 182.013C86.4014 181.754 86.6863 181.521 86.9918 181.318C90.1369 181.318 91.9864 185.564 91.9864 185.564H92.0128C92.3721 185.656 96.2096 186.669 96.9579 186.744C97.7722 186.827 97.9008 187.13 97.9008 187.13Z"
        fill="#F3B4B4"
      />
      <path
        opacity="0.1"
        d="M108.405 180.339C108.405 180.339 108.23 180.88 107.999 181.565C107.907 181.503 104.87 179.482 103.713 178.156C103.577 178.007 103.46 177.842 103.364 177.665C103.756 177.441 104.023 177.296 104.023 177.296L108.405 180.339Z"
        fill="black"
      />
      <path
        d="M117.286 173.653L116.739 175.424L111.497 178.279C111.497 178.279 110.079 179.284 109.848 179.759C109.661 180.075 109.362 180.31 109.011 180.418C108.751 180.741 108.509 181.078 108.286 181.427C108.286 181.427 105.174 179.363 104 178.018C103.637 177.603 103.462 177.253 103.627 177.059C104.313 176.235 108.427 173.274 108.427 173.274C108.427 173.274 110.94 169.977 111.048 170.037C111.157 170.096 112.42 168.88 113.162 168.191L113.669 167.716L117.286 173.653Z"
        fill="#BDD3E8"
      />
      <path
        d="M125.136 169.044V169.918C125.136 169.918 128.433 173.073 127.997 175.645C127.562 178.216 128.482 181.42 128.482 181.42L131.799 181.183L131.297 169.433L125.136 169.044Z"
        fill="#BDD3E8"
      />
      <path
        opacity="0.05"
        d="M131.799 181.183L128.482 181.42C128.482 181.42 127.562 178.219 127.997 175.645C128.433 173.07 125.136 169.918 125.136 169.918V169.044L131.297 169.433L131.33 170.198L131.799 181.183Z"
        fill="black"
      />
      <path
        opacity="0.05"
        d="M113.435 168.704C112.386 169.679 111.414 170.734 110.528 171.859C109.361 173.412 107.373 175.644 107.373 175.644L104.996 177.052L104.007 178.011C103.644 177.596 103.469 177.246 103.634 177.052C104.32 176.228 108.434 173.267 108.434 173.267C108.434 173.267 110.946 169.97 111.055 170.03C111.164 170.089 112.427 168.873 113.168 168.184L113.435 168.704Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M98.5239 182.614C98.4276 182.696 98.3362 182.784 98.2503 182.877C98.2503 182.877 98.2008 184.427 96.9876 180.787C95.7744 177.147 92.7645 178.021 92.7645 178.021C92.7538 178.014 92.7456 178.004 92.7414 177.992C92.7372 177.98 92.7372 177.967 92.7414 177.955C92.7414 177.856 92.9162 177.685 93.137 177.487L96.3052 176.669L98.4877 181.911C98.4877 181.911 98.5074 182.188 98.5239 182.614Z"
        fill="black"
      />
      <path
        d="M109.703 161.91L107.857 161.96L106.594 163.756C106.594 163.756 104.507 165.988 103.878 166.545C103.248 167.103 99.1699 170.937 98.0062 172.928C98.0062 172.928 96.938 173.462 96.938 173.996C96.938 174.53 94.9006 176.083 94.9006 176.083C94.9006 176.083 92.7643 177.537 93.0544 177.731C93.0544 177.731 96.0644 176.858 97.2776 180.497C98.4908 184.137 98.5402 182.584 98.5402 182.584C98.7499 182.354 98.9961 182.16 99.2688 182.011C99.559 181.905 100.94 179.917 101.366 179.868C101.679 179.806 101.975 179.672 102.229 179.479L104.702 177.052L107.082 175.644C107.082 175.644 109.06 173.413 110.234 171.86C111.122 170.735 112.095 169.68 113.145 168.705L109.703 161.91Z"
        fill="#BDD3E8"
      />
      <path
        opacity="0.1"
        d="M100.482 173.656C100.482 173.656 103.779 175.255 103.637 175.595C103.496 175.934 100.482 173.656 100.482 173.656Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M103.087 173.267C103.087 173.267 103.344 174.708 104.656 174.642C105.968 174.576 103.087 173.267 103.087 173.267Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M98.4907 177.312C98.4907 177.312 98.6853 178.137 99.8987 178.282C101.112 178.427 98.4907 177.312 98.4907 177.312Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M110.844 173.656C110.844 173.656 112.443 174.316 112.588 175.232C112.733 176.148 110.844 173.656 110.844 173.656Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M106.911 177.052C107.531 177.319 109.314 179.32 109.278 179.538C109.242 179.755 106.911 177.052 106.911 177.052Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M109.75 175.631C109.936 175.565 110.136 175.552 110.329 175.592C110.523 175.633 110.701 175.726 110.844 175.862C111.289 176.324 109.75 175.631 109.75 175.631Z"
        fill="black"
      />
      <path
        d="M121.509 159.777C121.509 159.777 115.321 157.773 115.796 159.777C116.271 161.782 121.509 162.906 121.509 162.906V159.777Z"
        fill="#F899A5"
      />
      <path
        opacity="0.1"
        d="M131.33 170.198L129.428 170.066L125.132 169.707V169.047L131.297 169.433L131.33 170.198Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M137.558 156.282C137.558 156.282 139.246 157.519 141.052 157.568C142.859 157.617 137.558 156.282 137.558 156.282Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M139.74 152.362C139.74 152.362 141.075 151.703 141.827 152.254C142.579 152.804 139.74 152.362 139.74 152.362Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M139.11 154.565C139.11 154.565 139.196 155.343 141.174 155.439C143.152 155.534 139.11 154.565 139.11 154.565Z"
        fill="black"
      />
      <path
        d="M128.482 181.42C128.482 181.42 125.399 182.439 124.196 181.905C122.993 181.371 120.375 179.333 120.375 179.333C120.375 179.333 118.694 177.685 117.095 175.842L116.89 175.605C115.506 173.993 114.253 172.308 114.253 171.474C114.261 171.128 114.218 170.782 114.124 170.448C113.985 169.966 113.762 169.512 113.465 169.107C113.299 168.874 113.109 168.659 112.898 168.467L107.851 161.963C107.851 161.963 109.308 160.41 109.308 159.985C109.308 159.559 111.055 157.172 113.287 158.435C114.227 158.963 116.472 160.931 118.76 163.031C121.908 165.919 125.129 169.061 125.129 169.061C125.129 169.061 125.356 169.305 125.689 169.684C126.543 170.673 128.086 172.535 128.208 173.478C128.386 174.771 128.482 181.42 128.482 181.42Z"
        fill="#BDD3E8"
      />
      <path
        opacity="0.05"
        d="M125.696 169.667C123.306 172.047 119.709 175.239 117.098 175.842L117.029 175.858L116.894 175.605L114.137 170.448L113.438 169.13L113.475 169.094L118.769 163.004C121.918 165.892 125.139 169.034 125.139 169.034C125.139 169.034 125.363 169.288 125.696 169.667Z"
        fill="black"
      />
      <path
        d="M145.018 174.128V175.232C145.018 175.232 146.548 180.632 143.782 182.156C142.384 182.93 139.407 183.115 136.803 183.108C135.066 183.113 133.382 182.506 132.048 181.394C130.714 180.282 129.814 178.735 129.507 177.026C129.404 176.51 129.394 175.979 129.477 175.46C129.477 175.46 128.241 166.615 127.948 166.558C127.948 166.558 120.995 174.52 116.738 175.437L113.145 168.718L118.885 162.124C118.885 162.124 123.144 155.356 124.38 154.736C125.617 154.116 126.856 152.382 126.856 152.382C126.856 152.382 131.844 148.222 135.154 155.247C138.464 162.273 140.614 167.946 141.418 170.053C141.418 170.053 142.585 172.44 143.31 172.433H143.334C143.781 172.438 144.208 172.618 144.523 172.935C144.839 173.252 145.016 173.681 145.018 174.128Z"
        fill="#BDD3E8"
      />
      <path
        opacity="0.1"
        d="M127.803 166.182C128.47 165.445 128.978 164.577 129.293 163.634C129.843 162.032 129.128 165.259 127.948 166.545L127.803 166.182Z"
        fill="black"
      />
      <path
        d="M264.401 178.153L258.084 178.245L245.036 154.532L242.856 150.572L244.528 140.543L245.814 142.97L264.401 178.153Z"
        fill="#DCE0ED"
      />
      <path
        d="M207.288 148.993L204.69 152.893L196.916 142.383L193.359 137.57L195.495 134.692L197.13 136.677L207.288 148.993Z"
        fill="#DCE0ED"
      />
      <path
        opacity="0.1"
        d="M197.13 136.673C197.13 136.874 197.153 137.082 197.167 137.29L196.916 142.383L193.359 137.57L195.495 134.692L197.13 136.673Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M245.814 142.97L245.036 154.532L242.856 150.572L244.528 140.543L245.814 142.97Z"
        fill="black"
      />
      <path
        d="M189.752 144.071C189.752 144.071 186.633 120.779 197.994 117.295C198.14 117.252 198.289 117.221 198.439 117.202C201.053 116.889 225.407 114.08 238.05 117.417C238.05 117.417 246.687 118.719 246.595 123.351L242.764 180.101H236.543L238.584 142.584C238.584 142.584 241.551 126.239 236.636 125.111C231.72 123.984 209.332 122.144 201.439 124.37C201.439 124.37 195.68 123.067 196.609 137.276L196.237 144.892L189.752 144.071Z"
        fill="#DCE0ED"
      />
      <path
        d="M208.682 150.988C208.735 150.373 208.627 149.755 208.371 149.194C208.115 148.632 207.718 148.147 207.219 147.783C205.478 146.465 200.114 143.606 200.114 143.606L198.373 143.745L193.151 156.701L193.498 184.213L202.9 195.844L208.682 150.988Z"
        fill="#FF9933"
      />
      <path
        opacity="0.1"
        d="M208.682 150.988C208.735 150.373 208.627 149.755 208.371 149.194C208.115 148.632 207.718 148.147 207.219 147.783C205.478 146.465 200.114 143.606 200.114 143.606L198.373 143.745L193.151 156.701L193.498 184.213L202.9 195.844L208.682 150.988Z"
        fill="black"
      />
      <path
        d="M171.165 179.825L171.35 151.591C171.35 151.591 170.516 140.633 177.063 140.448C177.063 140.448 192.502 140.56 199.211 142.74C199.422 142.808 199.616 142.922 199.778 143.074C199.94 143.227 200.066 143.413 200.147 143.62C200.228 143.827 200.261 144.05 200.245 144.272C200.229 144.493 200.164 144.709 200.055 144.902C198.736 147.226 196.468 153.381 195.38 169.166V186.092L171.165 179.825Z"
        fill="#FF9933"
      />
      <path
        d="M203.737 197.865L175.642 197.209H175.615L161.809 196.889L163.408 178.43L171.069 178.71L195.449 184.143L202.899 195.844L203.737 197.865Z"
        fill="#FF9933"
      />
      <path
        opacity="0.1"
        d="M203.737 197.865L175.642 197.209H175.615L161.809 196.889L163.408 178.43L171.069 178.71L195.449 184.143L202.899 195.844L203.737 197.865Z"
        fill="black"
      />
      <path
        d="M203.737 197.865L175.642 197.209L175.18 184.839L195.449 184.144L202.899 195.844L203.737 197.865Z"
        fill="#FF9933"
      />
      <path
        opacity="0.1"
        d="M175.616 197.208L161.809 196.879L163.411 178.417C163.411 178.417 167.73 185.314 175.18 184.826L175.616 197.208Z"
        fill="white"
      />
      <path
        d="M274.71 212.139C274.153 217.434 270.391 216.597 270.391 216.597L269.689 216.534L262.729 215.901L263.01 207.682C263.01 207.682 266.798 207.425 270.068 208.108C272.679 208.648 274.957 209.789 274.71 212.139Z"
        fill="#DCE0ED"
      />
      <path
        d="M168.27 161.791L162.336 160.904L157.971 166.054C157.971 166.054 151.889 169.07 151.18 170.389C150.471 171.708 148.259 175.572 147.283 175.601C146.307 175.631 145.394 177.055 145.388 177.619C145.381 178.183 141.672 184.658 140.752 185.03C139.833 185.403 138.237 187.506 138.276 187.921C138.316 188.337 134.815 192.477 134.815 192.477C134.815 192.477 128.881 201.764 127.997 202.061C127.114 202.358 123.695 208.355 122.828 209.067C121.961 209.779 119.531 210.418 119.828 210.995C120.124 211.572 126.092 219.729 125.729 222.04C125.729 222.04 130.17 218.525 130.344 217.444C130.519 216.363 136.015 210.659 136.015 210.659L164.799 180.764L170.347 161.923L168.27 161.791Z"
        fill="#CEDDF9"
      />
      <path
        opacity="0.1"
        d="M173.338 139.499C173.338 139.499 170.499 143.735 170.839 144.622C171.178 145.509 173.338 139.499 173.338 139.499Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M173.753 153.302C173.753 153.302 175.26 153.932 175.81 154.522C176.361 155.112 173.753 153.302 173.753 153.302Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M274.71 212.139C274.152 217.434 270.391 216.597 270.391 216.597L269.689 216.534C268.934 215.347 268.549 213.964 268.581 212.558C268.581 210.715 269.168 209.087 270.068 208.107C272.679 208.648 274.957 209.789 274.71 212.139Z"
        fill="black"
      />
      <path
        d="M167.077 142.769C160.717 142.769 155.564 149.735 155.564 158.327C155.564 166.918 160.717 173.881 167.077 173.881C173.436 173.881 178.592 166.915 178.592 158.327C178.592 149.738 173.44 142.769 167.077 142.769ZM165.452 170.63C160.605 170.63 156.679 165.579 156.679 159.345C156.679 153.111 160.605 148.064 165.452 148.064C170.298 148.064 174.228 153.114 174.228 159.345C174.228 165.576 170.301 170.63 165.452 170.63Z"
        fill="#636780"
      />
      <path
        d="M167.634 163.11L173.021 168.401L174.97 163.664L167.169 159.114L167.634 163.11Z"
        fill="#636780"
      />
      <path
        d="M175.576 110.718L180.264 111.288L182.005 113.355L179.368 125.224L173.763 144.345L170.084 157.473L167.499 165.444L165.851 175.994L164.202 183.247L159.257 188.192L149.202 176.323L147.554 172.367L143.598 164.29L153.818 159.51L165.027 158.521L170.301 145.004L174.587 129.345L175.576 110.718Z"
        fill="#FF9933"
      />
      <path
        d="M80.7117 167.287L74.583 218.736L151.754 215.486L142.839 190.226L127.981 180.566L161.971 181.124L162.155 167.01L148.411 164.036L120.738 161.808L99.9352 160.881L80.0622 167.379"
        fill="#FF9933"
      />
      <path
        opacity="0.1"
        d="M80.7117 167.287L74.583 218.736L151.754 215.486L142.839 190.226L127.981 180.566L161.971 181.124L162.155 167.01L148.411 164.036L120.738 161.808L99.9352 160.881L80.0622 167.379"
        fill="black"
      />
      <path
        d="M186.145 218.559C186.145 231.344 181.896 242.981 170.11 241.709C155.245 241.379 154.078 231.344 154.078 218.559C154.078 205.774 161.255 196.259 170.11 196.259C178.965 196.259 186.145 205.774 186.145 218.559Z"
        fill="#636780"
      />
      <path
        opacity="0.1"
        d="M186.238 218.581C186.238 208.691 181.936 200.746 175.883 197.667C168.109 199.143 162.135 208.084 162.135 219.755C162.135 230.232 162.92 238.876 171.386 241.82C182.275 242.206 186.238 230.928 186.238 218.581Z"
        fill="black"
      />
      <path
        d="M201.291 198.494L196.758 203.687L204.917 202.285L211.016 196.681L216.456 185.802L224.121 181.104L242.913 183.659L260.303 184.318L266.722 178.618L259.561 177.642L252.308 177.477L234.094 177.065L212.417 176.488L207.967 188.357L204.588 196.516L201.291 198.494Z"
        fill="#FF9933"
      />
      <path
        d="M263.659 219.663C263.287 220.22 251.132 222.079 251.132 222.079L217.505 228.208L159.752 229.042L152.974 223.283L149.413 223.916L149.268 223.942L101.702 232.385L100.927 232.276L87.9645 230.427L20.2588 220.777L21.017 210.95L21.4654 205.147L22.3918 193.104L23.0512 189.946L29.0809 189.392L31.8964 189.135L75.839 185.103L81.5853 184.575L84.8063 184.282L116.195 179.996L161.977 181.11L162.162 166.994L180.271 111.278C184.263 111.938 184.171 114.621 184.171 114.621L168.752 167.093L168.564 199.319C200.625 202.556 201.66 195.57 206.731 197.924L212.863 178.331L266.722 178.612C268.875 181.216 263.659 219.663 263.659 219.663Z"
        fill="#FF9933"
      />
      <path
        d="M130.024 190.411L147.112 227.094L152.868 228.95L154.262 223.006L147.853 202.668L141.26 190.876L130.024 190.411Z"
        fill="#FF9933"
      />
      <path
        opacity="0.15"
        d="M130.024 190.411L147.112 227.094L152.868 228.95L154.262 223.006L147.853 202.668L141.26 190.876L130.024 190.411Z"
        fill="black"
      />
      <path
        d="M118.901 265.91C135.993 265.91 149.848 250.194 149.848 230.806C149.848 211.419 135.993 195.702 118.901 195.702C101.81 195.702 87.9546 211.419 87.9546 230.806C87.9546 250.194 101.81 265.91 118.901 265.91Z"
        fill="#636780"
      />
      <path
        opacity="0.1"
        d="M129.942 197.894C113.748 199.022 100.931 214.272 100.931 232.926C100.931 247.962 109.265 260.79 120.978 265.788C137.172 264.66 149.99 249.409 149.99 230.756C149.99 215.72 141.656 202.892 129.942 197.894Z"
        fill="black"
      />
      <path
        d="M35.9511 169.423L34.4181 176.653L29.7301 198.778L9.57696 196.826C9.57696 196.826 8.37034 193.114 11.5286 191.719C11.5286 191.719 12.5638 179.149 16.2892 175.189C16.6873 174.738 17.1882 174.388 17.7496 174.171L35.9511 169.423Z"
        fill="#FF9933"
      />
      <path
        opacity="0.1"
        d="M34.4181 176.653L29.7301 198.778L9.57696 196.826C9.57696 196.826 8.37034 193.114 11.5286 191.719C11.5286 191.719 12.5638 179.149 16.2892 175.189L34.4181 176.653Z"
        fill="white"
      />
      <path
        d="M81.3641 171.378C83.9619 175.737 77.6618 215.947 77.6618 215.947L28.1509 207.775L29.9146 172.578C30.9366 166.265 34.6521 165.523 34.6521 165.523C52.125 156.701 92.2269 154.842 92.2269 154.842C128.821 156.233 162.155 167.007 162.155 167.007C120.553 160.601 81.3641 171.378 81.3641 171.378Z"
        fill="#FF9933"
      />
      <path
        opacity="0.1"
        d="M77.6618 215.947L28.1509 207.775L29.9146 172.578C30.9366 166.265 34.6521 165.523 34.6521 165.523C34.6521 165.523 70.7748 162.085 81.364 171.378C83.9619 175.737 77.6618 215.947 77.6618 215.947Z"
        fill="black"
      />
      <path
        d="M72.4169 213.626L31.0293 206.654L32.4964 176.623C33.3502 171.236 36.4525 170.603 36.4525 170.603C36.4525 170.603 66.6575 167.669 75.5093 175.595C77.6951 179.317 72.4169 213.626 72.4169 213.626Z"
        fill="#FF9933"
      />
      <path
        opacity="0.1"
        d="M69.011 199.233C72.1391 199.233 74.6749 194.617 74.6749 188.924C74.6749 183.23 72.1391 178.615 69.011 178.615C65.883 178.615 63.3472 183.23 63.3472 188.924C63.3472 194.617 65.883 199.233 69.011 199.233Z"
        fill="black"
      />
      <path
        d="M69.011 198.956C72.1391 198.956 74.6749 194.34 74.6749 188.647C74.6749 182.953 72.1391 178.338 69.011 178.338C65.883 178.338 63.3472 182.953 63.3472 188.647C63.3472 194.34 65.883 198.956 69.011 198.956Z"
        fill="#DCE0ED"
      />
      <path
        d="M69.7499 181.031C69.7499 181.031 67.4421 181.4 68.6356 184.467C69.829 187.533 70.0268 189.108 70.0268 189.108C70.0268 189.108 72.3345 190.318 72.2554 186.323C72.1763 182.327 69.7499 181.031 69.7499 181.031Z"
        fill="#F0F0F4"
      />
      <path
        opacity="0.1"
        d="M38.6447 194.472C41.5689 194.472 43.9393 190.159 43.9393 184.839C43.9393 179.519 41.5689 175.206 38.6447 175.206C35.7206 175.206 33.3501 179.519 33.3501 184.839C33.3501 190.159 35.7206 194.472 38.6447 194.472Z"
        fill="black"
      />
      <path
        d="M38.6447 194.192C41.5689 194.192 43.9393 189.879 43.9393 184.559C43.9393 179.239 41.5689 174.926 38.6447 174.926C35.7206 174.926 33.3501 179.239 33.3501 184.559C33.3501 189.879 35.7206 194.192 38.6447 194.192Z"
        fill="#DCE0ED"
      />
      <path
        d="M39.3866 176.943C39.3866 176.943 37.0789 177.316 38.2723 180.382C39.4657 183.448 39.6668 185.024 39.6668 185.024C39.6668 185.024 41.9746 186.23 41.8954 182.238C41.8163 178.246 39.3866 176.943 39.3866 176.943Z"
        fill="#F0F0F4"
      />
      <path
        opacity="0.1"
        d="M62.497 180.194C62.1277 184.002 60.2683 212.232 60.2683 212.232L57.1166 211.705L55.366 211.418L53.0319 211.025L52.7484 210.979L43.5537 209.446L45.3702 179.502C45.4312 178.54 45.8606 177.638 46.5692 176.985C47.2778 176.332 48.211 175.977 49.1747 175.994C49.268 175.989 49.3616 175.989 49.4549 175.994C50.0912 176.05 50.7736 176.116 51.489 176.195C51.789 176.228 52.0956 176.261 52.4022 176.301C53.2858 176.406 54.199 176.535 55.0924 176.68C55.5474 176.759 55.9957 176.841 56.4408 176.927C57.1892 177.075 57.9144 177.24 58.5837 177.428C59.0353 177.553 59.4672 177.688 59.8628 177.837C61.5244 178.437 62.609 179.212 62.497 180.194Z"
        fill="black"
      />
      <path
        d="M49.1746 175.994L47.3745 209.621L46.3525 209.528L48.1361 176.096C48.4757 176.013 48.8253 175.979 49.1746 175.994Z"
        fill="#FF9933"
      />
      <path
        d="M52.402 176.297L50.7173 210.28H49.1382L51.4888 176.188C51.7888 176.225 52.0954 176.258 52.402 176.297Z"
        fill="#FF9933"
      />
      <path
        d="M56.4408 176.924L53.7836 211.025H52.7583V210.979L55.0891 176.693C55.5474 176.756 55.9957 176.838 56.4408 176.924Z"
        fill="#FF9933"
      />
      <path
        d="M59.8596 177.834L57.1266 211.705V211.771L55.3628 211.583V211.418L58.5673 177.425C59.0354 177.55 59.4673 177.685 59.8596 177.834Z"
        fill="#FF9933"
      />
      <path
        opacity="0.1"
        d="M22.0916 204.062C22.0916 204.062 18.6795 186.441 26.4796 185.535C34.2798 184.628 59.9122 186.524 59.144 193.753C58.3759 200.983 57.5517 212.001 57.5517 212.001L52.2604 210.817C52.2604 210.817 56.2165 194.241 50.0318 193.615C43.847 192.989 28.6489 191.245 28.6489 191.245C28.6489 191.245 24.5378 190.549 26.9082 205.091L22.0916 204.062Z"
        fill="black"
      />
      <path
        d="M22.0916 205.734C22.0916 205.734 18.6795 186.998 26.4796 186.092C34.2798 185.185 59.9122 187.081 59.144 194.31C58.3759 201.54 57.5517 212.558 57.5517 212.558L52.2604 211.375C52.2604 211.375 56.2165 194.798 50.0318 194.172C43.847 193.546 28.639 191.818 28.639 191.818C28.639 191.818 24.5279 192.237 26.8983 206.795L22.0916 205.734Z"
        fill="#DCE0ED"
      />
      <path
        d="M159.751 229.042L152.881 228.95C147.866 206.862 139.598 193.104 139.598 193.104C136.803 191.617 106.344 191.818 106.344 191.818C103.185 192.376 102.351 202.408 102.351 202.408C102.351 202.408 105.045 204.715 102.444 206.215C101.283 206.875 97.4589 207.003 93.0544 206.776C87.5883 206.492 81.219 205.661 77.9288 204.636C71.9946 202.777 76.7189 199.342 76.7189 199.342C76.7189 199.342 77.5563 198.227 78.1134 192.089C78.4959 188.51 79.6523 185.058 81.5025 181.971C81.7597 181.546 81.9212 181.312 81.9212 181.312C82.233 181.014 82.602 180.783 83.0058 180.632C90.8785 177.336 129.467 180.474 129.467 180.474L143.769 185.027C144.164 185.185 144.774 186.194 145.526 187.806C150.046 197.495 159.751 229.042 159.751 229.042Z"
        fill="#FF9933"
      />
      <path
        opacity="0.1"
        d="M159.751 229.042L152.881 228.95C147.866 206.862 139.598 193.104 139.598 193.104C136.803 191.617 106.344 191.818 106.344 191.818C103.185 192.376 102.351 202.408 102.351 202.408C102.351 202.408 105.045 204.715 102.444 206.215C101.283 206.875 97.4589 207.003 93.0544 206.776C87.5883 206.492 81.219 205.661 77.9288 204.636C71.9946 202.777 76.7189 199.342 76.7189 199.342C76.7189 199.342 77.5563 198.227 78.1134 192.089C78.4959 188.51 79.6523 185.058 81.5025 181.971C81.7597 181.546 81.9212 181.312 81.9212 181.312C82.233 181.014 82.602 180.783 83.0058 180.632C90.8785 177.336 129.467 180.474 129.467 180.474L143.769 185.027C144.164 185.185 144.774 186.194 145.526 187.806C150.046 197.495 159.751 229.042 159.751 229.042Z"
        fill="black"
      />
      <path
        d="M145.526 187.803C132.339 182.673 95.9428 185.396 95.9428 185.396C92.079 182.099 84.8096 181.875 81.5029 181.964C81.7601 181.539 81.9216 181.305 81.9216 181.305C82.2334 181.007 82.6024 180.776 83.0063 180.626C90.8789 177.329 129.468 180.467 129.468 180.467L143.769 185.02C144.165 185.182 144.775 186.191 145.526 187.803Z"
        fill="#FF9933"
      />
      <path
        d="M98.079 186.787C97.2449 189.665 93.8162 201.184 93.8162 201.184C93.4574 203.027 93.2054 204.888 93.0613 206.759C87.5952 206.476 81.2258 205.645 77.9356 204.62C72.0014 202.76 76.7257 199.325 76.7257 199.325C76.7257 199.325 77.5631 198.211 78.1203 192.072C78.5293 188.232 79.8335 184.542 81.928 181.298C82.2399 181.001 82.6089 180.77 83.0127 180.619C88.1689 181.846 98.7119 184.608 98.079 186.787Z"
        fill="#FF9933"
      />
      <path
        opacity="0.1"
        d="M98.079 186.787C97.2449 189.665 93.8162 201.184 93.8162 201.184C93.4574 203.027 93.2054 204.888 93.0613 206.759C87.5952 206.476 81.2258 205.645 77.9356 204.62C72.0014 202.76 76.7257 199.325 76.7257 199.325C76.7257 199.325 77.5631 198.211 78.1203 192.072C78.5293 188.232 79.8335 184.542 81.928 181.298C82.2399 181.001 82.6089 180.77 83.0127 180.619C88.1689 181.846 98.7119 184.608 98.079 186.787Z"
        fill="white"
      />
      <path
        d="M106.578 113.655C102.885 114.212 101.283 121.874 101.283 121.874L91.2544 155.376L162.155 167.007L180.264 111.291C163.618 103.765 106.578 113.655 106.578 113.655ZM163.612 149.455C163.612 149.455 163.193 152.659 160.477 152.939L114.51 151.143L105.081 150.77L101.919 150.648H101.695C101.695 150.648 98.2106 151.274 98.7677 148.34C98.7677 148.34 102.599 120.483 109.357 118.392C109.357 118.392 110.059 118.244 111.289 118.013C113.221 117.647 116.445 117.074 120.243 116.55C121.944 116.315 123.761 116.091 125.63 115.89C130.736 115.369 136.226 115.089 140.769 115.528C140.769 115.528 174.616 112.392 172.18 119.916C172.187 119.925 165.082 147.157 163.618 149.455H163.612Z"
        fill="#FF9933"
      />
      <path
        d="M101.926 150.648H101.702C101.702 150.648 98.2175 151.275 98.7747 148.341C98.7747 148.341 102.606 120.483 109.364 118.393C109.364 118.393 110.066 118.244 111.296 118.014C108.843 122.801 100.97 139.189 101.926 150.648Z"
        fill="#FF9933"
      />
      <path
        opacity="0.1"
        d="M101.926 150.648H101.702C101.702 150.648 98.2175 151.275 98.7747 148.341C98.7747 148.341 102.606 120.483 109.364 118.393C109.364 118.393 110.066 118.244 111.296 118.014C108.843 122.801 100.97 139.189 101.926 150.648Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M108.167 219.363C107.764 220.113 107.155 220.734 106.411 221.15C105.668 221.567 104.821 221.764 103.97 221.717L102.55 221.654L89.1613 221.077L84.3349 220.869L79.8776 220.5L21.0171 210.94L21.4655 205.137L28.2337 206.106L31.036 206.509L72.5918 212.443L78.0611 213.247L80.715 213.626L91.9537 213.544L104.119 213.455C104.733 213.443 105.343 213.556 105.912 213.785C106.957 214.199 107.801 215.001 108.269 216.023C108.518 216.548 108.639 217.125 108.621 217.705C108.604 218.286 108.448 218.854 108.167 219.363Z"
        fill="black"
      />
      <path
        d="M108.167 219.92C107.764 220.67 107.155 221.29 106.411 221.707C105.668 222.124 104.821 222.321 103.97 222.274L84.3349 221.426L79.8777 221.054L17.1072 210.87L12.162 210.069L9.48504 209.621C4.19372 208.506 5.77287 203.212 5.77287 203.212C6.14211 200.795 15.2445 198.567 18.2149 198.662C20.2127 198.725 20.4897 201.801 20.4567 203.815C20.4567 204.804 20.3512 205.533 20.3512 205.533L23.2886 205.955L80.7151 214.17L104.122 213.999C104.99 213.983 105.843 214.219 106.579 214.678C107.316 215.136 107.904 215.798 108.273 216.583C108.521 217.108 108.641 217.684 108.622 218.264C108.604 218.844 108.448 219.412 108.167 219.92Z"
        fill="#DCE0ED"
      />
      <path
        opacity="0.1"
        d="M13.9419 202.019C13.9419 202.019 16.4507 205.081 20.3508 205.546C20.3508 205.546 18.7717 202.111 13.9419 202.019Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M13.2928 210.656C13.2928 210.656 5.02783 210.468 5.02783 214.942C5.02783 219.415 10.2301 220.144 10.2301 220.144L84.058 234.346L105.045 234.716C105.045 234.716 112.381 228.867 104.765 226.266L83.5964 226.813L13.2005 213.907C13.2005 213.907 15.0566 211.398 13.2928 210.656Z"
        fill="black"
      />
      <path
        d="M105.045 235.263L84.058 234.894L18.4127 222.26L13.6093 221.334L10.2301 220.675C10.2301 220.675 5.02783 219.93 5.02783 215.472C5.02783 212.274 9.28726 211.467 11.6906 211.266C12.6368 211.19 13.2928 211.203 13.2928 211.203C15.0566 211.945 13.2005 214.45 13.2005 214.45L15.7621 214.922L83.5964 227.37L104.768 226.813C112.381 229.401 105.045 235.263 105.045 235.263Z"
        fill="#DCE0ED"
      />
      <path
        d="M19.5135 207.033L23.8784 205.081L26.107 204.989L25.9224 223.747L19.0519 222.356L16.1705 220.5L14.7793 212.7C14.7793 212.7 15.3365 209.449 15.6134 209.172C15.8903 208.895 19.5135 207.033 19.5135 207.033Z"
        fill="#DCE0ED"
      />
      <path
        opacity="0.1"
        d="M19.5135 207.033L23.8784 205.081L26.107 204.989L25.9224 223.747L19.0519 222.356L16.1705 220.5L14.7793 212.7C14.7793 212.7 15.3365 209.449 15.6134 209.172C15.8903 208.895 19.5135 207.033 19.5135 207.033Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M18.4122 222.261L18.4913 222.356L13.6648 221.426L13.6088 221.334C11.067 217.23 10.8461 213.907 11.6901 211.269C12.6362 211.193 13.2923 211.206 13.2923 211.206C15.0561 211.948 13.2 214.454 13.2 214.454L15.7616 214.925C15.5901 218.604 17.9407 221.68 18.4122 222.261Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M25.5531 205.005C24.7355 205.317 23.9805 205.647 23.2882 205.994C20.1629 207.498 18.2343 209.186 17.1068 210.897L12.1616 210.095C14.3012 205.651 19.4145 203.621 19.4145 203.621L20.4497 203.855L25.5531 205.005Z"
        fill="black"
      />
      <path
        d="M19.9685 203.598C19.9685 203.598 6.41218 208.985 14.2123 221.427L19.0421 222.356C19.0421 222.356 9.48147 211.398 26.0972 204.989L19.9685 203.598Z"
        fill="#DCE0ED"
      />
      <path
        d="M52.2967 209.634L57.3111 209.449L56.4737 229.599L48.3933 228.116L44.9581 226.444L43.379 222.448L42.2646 218.456L44.9581 214.833L48.0241 212.792L52.2967 209.634Z"
        fill="#DCE0ED"
      />
      <path
        opacity="0.1"
        d="M52.2967 209.634L57.3111 209.449L56.4737 229.599L48.3933 228.116L44.9581 226.444L43.379 222.448L42.2646 218.456L44.9581 214.833L48.0241 212.792L52.2967 209.634Z"
        fill="black"
      />
      <path
        d="M51.7394 224.12C53.0722 224.12 54.1526 222.291 54.1526 220.035C54.1526 217.779 53.0722 215.951 51.7394 215.951C50.4066 215.951 49.3262 217.779 49.3262 220.035C49.3262 222.291 50.4066 224.12 51.7394 224.12Z"
        fill="#DCE0ED"
      />
      <path
        d="M44.2194 213.069L42.3601 224.585C42.3601 224.585 40.0523 224.271 38.793 223.998C38.5153 223.959 38.2458 223.876 37.9951 223.751C37.9292 223.685 37.794 223.691 37.6127 223.727C36.9929 223.853 35.8918 224.364 35.6775 223.932C35.6621 223.899 35.6409 223.869 35.6149 223.843C35.3973 223.605 34.9753 223.777 34.5665 223.843C34.3439 223.901 34.1076 223.87 33.9071 223.757C33.5775 223.526 32.8687 223.896 32.3016 224.018C31.9423 224.097 31.6423 224.08 31.4906 223.757C31.4361 223.652 31.3575 223.561 31.2613 223.493C31.165 223.424 31.0538 223.379 30.9368 223.362C30.4027 223.226 29.6543 223.319 29.2224 223.226C29.051 223.193 28.9356 223.127 28.8928 223.012C28.807 222.761 28.329 222.643 27.818 222.544C27.1949 222.425 26.5257 222.336 26.4762 222.086C26.4762 222 26.4004 221.964 26.3081 221.96C25.9627 222.013 25.6228 222.097 25.2927 222.211C25.0158 222.283 24.7817 222.283 24.7157 222.086C24.6498 221.888 24.3234 221.888 23.9575 221.977C23.6641 222.049 23.3377 222.165 23.0871 222.224C22.8366 222.283 22.6981 222.283 22.6717 222.175C22.5794 221.805 21.3728 222.643 21.0959 221.898C20.819 221.153 17.0013 216.214 21.9465 209.818C21.9465 209.818 23.2652 209.911 24.2938 210.095C24.6348 210.141 24.9677 210.234 25.2828 210.372C25.6355 210.573 26.3905 210.53 27.0729 210.58C27.4162 210.584 27.754 210.666 28.062 210.817C28.1844 210.884 28.2825 210.988 28.3422 211.114C28.4971 211.467 28.8532 211.539 29.2422 211.52C29.7697 211.487 30.3565 211.289 30.5609 211.394H30.5807C30.9697 211.562 32.4269 211.394 32.6742 211.641L32.6939 211.665C32.7994 211.82 33.3533 211.665 33.9533 211.579C34.405 211.52 34.8599 211.513 35.1072 211.76C35.3624 211.963 35.6862 212.058 36.0105 212.027C36.4055 211.998 36.8025 212.014 37.194 212.073C37.2844 212.1 37.3655 212.151 37.4281 212.222C37.6622 212.515 38.2292 212.476 38.7468 212.502C39.0056 212.491 39.262 212.555 39.4853 212.687C39.5654 212.742 39.6564 212.781 39.7523 212.799C40.4644 212.963 41.9908 212.687 41.9908 212.687L44.2194 213.069Z"
        fill="#636780"
      />
      <path
        opacity="0.1"
        d="M23.9577 221.971C23.6643 222.043 23.3379 222.159 23.0873 222.218C19.3158 216.366 22.4906 211.922 24.2775 210.096C24.6185 210.141 24.9514 210.235 25.2665 210.373C21.4719 214.744 23.2324 220.204 23.9577 221.971Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M26.3117 221.954C25.9664 222.007 25.6265 222.09 25.2963 222.205C22.0688 216.498 25.3886 212.229 27.0667 210.583C27.4099 210.588 27.7478 210.669 28.0557 210.821C24.4458 214.77 25.5172 219.686 26.3117 221.954Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M29.2224 223.22C29.051 223.187 28.9356 223.121 28.8927 223.006C28.807 222.755 28.329 222.637 27.818 222.538C24.9861 217.368 27.518 213.396 29.2356 211.523C29.7631 211.49 30.3499 211.292 30.5543 211.398H30.5741C26.5916 215.885 28.5202 221.555 29.2224 223.22Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M32.3051 224.011C31.9457 224.09 31.6457 224.074 31.4941 223.751C31.4396 223.646 31.361 223.555 31.2647 223.486C31.1685 223.418 31.0572 223.373 30.9402 223.355C27.6435 217.596 31.0062 213.29 32.6842 211.652L32.704 211.675C32.8095 211.83 33.3633 211.675 33.9634 211.589C29.2688 216.326 31.682 222.617 32.3051 224.011Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M35.6086 223.84C35.3911 223.603 34.9691 223.774 34.5603 223.84C31.1184 218.123 34.28 213.805 36.0207 212.041C36.4157 212.012 36.8127 212.028 37.2043 212.087C33.1855 216.409 34.8438 221.918 35.6086 223.84Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M38.7931 223.998C38.5155 223.96 38.246 223.876 37.9953 223.751C37.9293 223.685 37.7942 223.692 37.6129 223.728C34.5633 218.506 36.9964 214.474 38.7469 212.519C39.0057 212.508 39.2622 212.572 39.4854 212.703C39.5655 212.759 39.6565 212.797 39.7525 212.815C36.4161 217.114 38.1008 222.297 38.7931 223.998Z"
        fill="black"
      />
      <path
        d="M128.818 254.767C137.177 254.767 143.954 244.789 143.954 232.48C143.954 220.172 137.177 210.194 128.818 210.194C120.459 210.194 113.683 220.172 113.683 232.48C113.683 244.789 120.459 254.767 128.818 254.767Z"
        fill="#636780"
      />
      <path
        opacity="0.1"
        d="M128.307 250.867C134.385 250.867 139.311 242.78 139.311 232.804C139.311 222.828 134.385 214.741 128.307 214.741C122.229 214.741 117.302 222.828 117.302 232.804C117.302 242.78 122.229 250.867 128.307 250.867Z"
        fill="black"
      />
      <path
        d="M128.307 250.31C134.385 250.31 139.311 242.223 139.311 232.247C139.311 222.271 134.385 214.184 128.307 214.184C122.229 214.184 117.302 222.271 117.302 232.247C117.302 242.223 122.229 250.31 128.307 250.31Z"
        fill="#F0F0F4"
      />
      <path
        d="M130.301 222.356C131.019 222.356 131.6 221.358 131.6 220.128C131.6 218.897 131.019 217.899 130.301 217.899C129.584 217.899 129.002 218.897 129.002 220.128C129.002 221.358 129.584 222.356 130.301 222.356Z"
        fill="#636780"
      />
      <path
        d="M135.504 241.86C136.221 241.86 136.803 240.862 136.803 239.631C136.803 238.401 136.221 237.403 135.504 237.403C134.787 237.403 134.205 238.401 134.205 239.631C134.205 240.862 134.787 241.86 135.504 241.86Z"
        fill="#636780"
      />
      <path
        d="M121.48 232.573C122.197 232.573 122.779 231.575 122.779 230.344C122.779 229.114 122.197 228.116 121.48 228.116C120.762 228.116 120.181 229.114 120.181 230.344C120.181 231.575 120.762 232.573 121.48 232.573Z"
        fill="#636780"
      />
      <path
        d="M125.287 245.572C126.005 245.572 126.586 244.574 126.586 243.343C126.586 242.113 126.005 241.115 125.287 241.115C124.57 241.115 123.988 242.113 123.988 243.343C123.988 244.574 124.57 245.572 125.287 245.572Z"
        fill="#636780"
      />
      <path
        d="M239.606 191.245C239.606 191.245 250.485 220.22 249.915 222.03C249.915 222.03 254.511 224.816 255.625 224.12C256.739 223.424 255.767 210.603 255.767 210.603L253.677 199.322L251.306 192.636L239.606 191.245Z"
        fill="#FF9933"
      />
      <path
        opacity="0.15"
        d="M239.606 191.245C239.606 191.245 250.485 220.22 249.915 222.03C249.915 222.03 254.511 224.816 255.625 224.12C256.739 223.424 255.767 210.603 255.767 210.603L253.677 199.322L251.306 192.636L239.606 191.245Z"
        fill="black"
      />
      <path
        d="M137.36 229.787C138.077 229.787 138.659 228.79 138.659 227.559C138.659 226.328 138.077 225.33 137.36 225.33C136.643 225.33 136.061 226.328 136.061 227.559C136.061 228.79 136.643 229.787 137.36 229.787Z"
        fill="#636780"
      />
      <path
        d="M130.301 238.237C132.404 238.237 134.109 235.866 134.109 232.942C134.109 230.018 132.404 227.647 130.301 227.647C128.198 227.647 126.494 230.018 126.494 232.942C126.494 235.866 128.198 238.237 130.301 238.237Z"
        fill="#636780"
      />
      <path
        d="M251.731 229.972C251.731 246.743 246.16 262.01 230.698 260.338C211.198 259.92 209.665 246.743 209.665 229.972C209.665 213.201 219.073 200.72 230.691 200.72C242.309 200.72 251.731 213.198 251.731 229.972Z"
        fill="#636780"
      />
      <path
        opacity="0.1"
        d="M251.847 229.998C251.847 217.016 246.203 206.591 238.261 202.562C228.064 204.501 220.228 216.231 220.228 231.531C220.228 245.275 221.259 256.587 232.363 260.477C246.648 260.991 251.847 246.215 251.847 229.998Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M259.943 222.867C259.943 222.867 257.158 225.234 255.625 223.563C255.625 223.563 251.863 193.473 247.129 193.335L230.83 193.196C230.83 193.196 228.881 194.03 228.044 197.653L217.494 227.654L159.751 228.485L155.34 219.254L203.12 219.392V219.372C203.378 218.446 211.362 189.939 216.726 186.405C216.908 186.282 217.107 186.185 217.316 186.118C217.316 186.118 237.235 183.481 243.783 185.281C243.783 185.281 250.607 186.814 252.279 188.907C252.503 189.141 255.087 192.052 255.888 200.446L259.943 222.867Z"
        fill="black"
      />
      <path
        d="M259.943 223.424C259.943 223.424 257.158 225.791 255.625 224.12C255.625 224.12 251.863 194.03 247.129 193.892L230.83 193.753C230.83 193.753 228.881 194.587 228.044 198.21L217.494 228.211L159.751 229.042L155.34 219.811L203.12 219.949V219.93C203.378 219.003 211.362 190.496 216.726 186.962C216.908 186.839 217.107 186.742 217.316 186.675C217.316 186.675 237.235 184.038 243.783 185.838C243.783 185.838 250.607 187.371 252.279 189.464V189.481C252.503 189.715 255.087 192.626 255.888 201.019L259.943 223.424Z"
        fill="#FF9933"
      />
      <path
        opacity="0.1"
        d="M259.943 223.424C259.943 223.424 257.158 225.791 255.625 224.12C255.625 224.12 251.863 194.03 247.129 193.892L230.83 193.753C230.83 193.753 228.881 194.587 228.044 198.21L217.494 228.211L159.751 229.042L155.34 219.811L203.12 219.949V219.93C203.378 219.003 211.362 190.496 216.726 186.962C216.908 186.839 217.107 186.742 217.316 186.675C217.316 186.675 237.235 184.038 243.783 185.838C243.783 185.838 250.607 187.371 252.279 189.464V189.481C252.503 189.715 255.087 192.626 255.888 201.019L259.943 223.424Z"
        fill="black"
      />
      <path
        d="M224.563 190.549C223.168 193.186 211.191 225.791 211.191 225.791L203.111 219.94V219.92C203.368 218.993 211.353 190.457 216.723 186.952C220.218 187.532 225.509 188.752 224.563 190.549Z"
        fill="#FF9933"
      />
      <path
        d="M208.963 175.645L202.415 197.093L168.287 196.104L168.564 199.309C168.564 199.309 195.311 203.209 206.731 197.914L212.863 178.322L266.722 178.598L256.462 176.185L208.963 175.645Z"
        fill="#FF9933"
      />
      <path
        opacity="0.1"
        d="M208.963 175.645L202.415 197.093L168.287 196.104L168.564 199.309C168.564 199.309 195.311 203.209 206.731 197.914L212.863 178.322L266.722 178.598L256.462 176.185L208.963 175.645Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M48.9538 209.446C48.9538 209.446 30.66 218.641 44.2163 227.279L47.8428 228.113C47.8428 228.113 34.5634 218.552 56.7441 209.446H48.9538Z"
        fill="black"
      />
      <path
        d="M49.5109 209.446C49.5109 209.446 31.2172 218.641 44.7735 227.279L48.3999 228.113C48.3999 228.113 35.1205 218.552 57.3012 209.446H49.5109Z"
        fill="#DCE0ED"
      />
      <path
        d="M238.768 249.752C244.384 249.752 248.936 241.956 248.936 232.339C248.936 222.722 244.384 214.925 238.768 214.925C233.153 214.925 228.601 222.722 228.601 232.339C228.601 241.956 233.153 249.752 238.768 249.752Z"
        fill="#636780"
      />
      <path
        opacity="0.1"
        d="M238.426 246.825C242.508 246.825 245.817 240.507 245.817 232.715C245.817 224.922 242.508 218.604 238.426 218.604C234.343 218.604 231.034 224.922 231.034 232.715C231.034 240.507 234.343 246.825 238.426 246.825Z"
        fill="black"
      />
      <path
        d="M238.426 246.268C242.508 246.268 245.817 239.95 245.817 232.158C245.817 224.365 242.508 218.047 238.426 218.047C234.343 218.047 231.034 224.365 231.034 232.158C231.034 239.95 234.343 246.268 238.426 246.268Z"
        fill="#F0F0F4"
      />
      <path
        d="M239.768 224.43C240.25 224.43 240.641 223.65 240.641 222.689C240.641 221.728 240.25 220.948 239.768 220.948C239.285 220.948 238.894 221.728 238.894 222.689C238.894 223.65 239.285 224.43 239.768 224.43Z"
        fill="#636780"
      />
      <path
        d="M243.262 239.667C243.744 239.667 244.135 238.888 244.135 237.927C244.135 236.965 243.744 236.186 243.262 236.186C242.779 236.186 242.388 236.965 242.388 237.927C242.388 238.888 242.779 239.667 243.262 239.667Z"
        fill="#636780"
      />
      <path
        d="M233.84 232.412C234.322 232.412 234.714 231.632 234.714 230.671C234.714 229.709 234.322 228.93 233.84 228.93C233.357 228.93 232.966 229.709 232.966 230.671C232.966 231.632 233.357 232.412 233.84 232.412Z"
        fill="#636780"
      />
      <path
        d="M236.398 242.569C236.881 242.569 237.272 241.789 237.272 240.828C237.272 239.867 236.881 239.087 236.398 239.087C235.916 239.087 235.524 239.867 235.524 240.828C235.524 241.789 235.916 242.569 236.398 242.569Z"
        fill="#636780"
      />
      <path
        d="M244.508 230.235C244.991 230.235 245.382 229.456 245.382 228.495C245.382 227.533 244.991 226.754 244.508 226.754C244.026 226.754 243.635 227.533 243.635 228.495C243.635 229.456 244.026 230.235 244.508 230.235Z"
        fill="#636780"
      />
      <path
        d="M239.767 236.836C241.18 236.836 242.326 234.985 242.326 232.701C242.326 230.418 241.18 228.567 239.767 228.567C238.354 228.567 237.209 230.418 237.209 232.701C237.209 234.985 238.354 236.836 239.767 236.836Z"
        fill="#636780"
      />
      <path
        opacity="0.1"
        d="M91.1128 154.895C91.1128 154.895 122.04 156.009 162.155 167.013L91.1128 154.895Z"
        fill="black"
      />
      <path
        d="M238.66 100.751C238.66 100.751 236.981 101.022 237.855 103.237C238.729 105.453 238.874 106.597 238.874 106.597C238.874 106.597 240.555 107.47 240.486 104.579C240.417 101.688 238.66 100.751 238.66 100.751Z"
        fill="#DCE0ED"
      />
      <path
        d="M174.917 115.498L169.994 139.954L166.51 147.312L168.817 151.598L173.927 139.565C173.927 139.565 179.532 121.927 179.532 121.432C179.532 120.938 174.917 115.498 174.917 115.498Z"
        fill="#FF9933"
      />
      <path
        d="M314.409 263C353.294 263 384.817 260.432 384.817 257.264C384.817 254.096 353.294 251.527 314.409 251.527C275.523 251.527 244 254.096 244 257.264C244 260.432 275.523 263 314.409 263Z"
        fill="#FDF1EA"
      />
      <path
        d="M355.44 257.607L331 221.285C331 221.285 368.013 234.213 363.812 257.607H355.44Z"
        fill="#3F3D56"
      />
      <path
        d="M356.4 257.607L380.84 221.285C380.84 221.285 343.827 234.213 348.028 257.607H356.4Z"
        fill="#FF9722"
      />
      <path d="M294.964 96.3916H294.448V101.165H294.964V96.3916Z" fill="#F2F2F2" />
      <path d="M327.218 108.003H326.702V112.777H327.218V108.003Z" fill="#F2F2F2" />
      <path d="M323.606 227.73H323.09V232.503H323.606V227.73Z" fill="#F2F2F2" />
      <path d="M331.089 188.251H330.573V193.024H331.089V188.251Z" fill="#F2F2F2" />
      <path
        d="M297.586 82.966C297.586 82.966 301.358 93.7017 299.037 95.3534C296.716 97.005 306.871 110.493 306.871 110.493L319.058 107.19L312.384 95.9039C312.384 95.9039 311.514 84.6176 311.514 82.966C311.514 81.3143 297.586 82.966 297.586 82.966Z"
        fill="#FFB8B8"
      />
      <path
        opacity="0.1"
        d="M297.586 82.966C297.586 82.966 301.358 93.7017 299.037 95.3534C296.716 97.005 306.871 110.493 306.871 110.493L319.058 107.19L312.384 95.9039C312.384 95.9039 311.514 84.6176 311.514 82.966C311.514 81.3143 297.586 82.966 297.586 82.966Z"
        fill="black"
      />
      <path
        d="M291.255 159.492C291.255 159.492 290.154 173.256 291.255 180.413C292.357 187.57 294.008 207.39 294.008 207.39C294.008 207.39 294.008 244.828 300.064 245.378C306.12 245.929 310.525 246.479 311.075 244.277C311.626 242.075 308.322 240.974 309.974 239.873C311.626 238.772 312.176 234.918 309.974 230.513C307.772 226.109 309.974 181.514 309.974 181.514L320.435 209.592C320.435 209.592 321.536 232.716 322.637 236.019C323.738 239.322 321.536 245.378 325.39 245.929C329.243 246.479 333.648 243.176 335.85 242.075C338.052 240.974 332.547 240.974 333.648 240.423C334.749 239.873 338.052 238.221 336.951 237.67C335.85 237.12 334.749 210.694 334.749 210.694C334.749 210.694 331.721 154.262 327.867 152.06C324.013 149.858 321.536 153.651 321.536 153.651L291.255 159.492Z"
        fill="#2F2E41"
      />
      <path
        d="M301.165 243.176V247.58C301.165 247.58 296.21 259.553 301.165 259.553C306.12 259.553 309.974 260.793 309.974 259.142V244.277L301.165 243.176Z"
        fill="#2F2E41"
      />
      <path
        d="M333.648 243.168V247.572C333.648 247.572 338.603 259.545 333.648 259.545C328.693 259.545 324.839 260.786 324.839 259.134V244.269L333.648 243.168Z"
        fill="#2F2E41"
      />
      <path
        d="M305.294 90.9487C310.767 90.9487 315.204 86.5119 315.204 81.0388C315.204 75.5657 310.767 71.1289 305.294 71.1289C299.821 71.1289 295.384 75.5657 295.384 81.0388C295.384 86.5119 299.821 90.9487 305.294 90.9487Z"
        fill="#FFB8B8"
      />
      <path
        d="M302.267 99.4824C302.267 99.4824 305.019 107.741 309.424 106.089L313.828 104.437L321.536 157.29C321.536 157.29 315.48 166.099 306.671 160.593C297.862 155.088 302.267 99.4824 302.267 99.4824Z"
        fill="#FF9933"
      />
      <path
        d="M309.974 97.8308L312.451 92.6006C312.451 92.6006 327.041 99.4825 329.243 101.685C331.445 103.887 331.445 107.19 331.445 107.19L327.591 120.954C327.591 120.954 328.692 151.234 328.692 152.335C328.692 153.436 332.546 159.492 329.793 157.29C327.041 155.088 326.49 152.886 324.288 156.189C322.086 159.492 317.131 163.346 317.131 163.346L309.974 97.8308Z"
        fill="#3F3D56"
      />
      <path
        d="M329.243 137.47L327.591 152.886C327.591 152.886 317.682 161.694 319.884 162.245C322.086 162.795 323.187 160.593 323.187 160.593C323.187 160.593 327.041 164.447 329.243 162.245C331.445 160.043 336.951 139.122 336.951 139.122L329.243 137.47Z"
        fill="#FFB8B8"
      />
      <path
        d="M305.505 67.0335C303.308 66.955 300.957 66.9161 299.105 68.1C298.253 68.6941 297.505 69.4259 296.893 70.2653C295.089 72.5459 293.53 75.4154 294.201 78.245L294.979 77.9414C294.979 78.6968 294.811 79.4426 294.487 80.1251C294.597 79.8064 294.964 80.3216 294.866 80.6439L294.008 83.4566C295.418 82.94 297.171 83.9861 297.385 85.4718C297.483 82.2048 297.822 78.4586 300.473 76.5456C301.809 75.5809 303.5 75.2864 305.128 75.0249C306.629 74.7837 308.203 74.5536 309.641 75.0479C311.079 75.5421 312.304 77.0128 311.978 78.498C312.641 78.2696 313.382 78.7317 313.71 79.3517C314.038 79.9717 314.055 80.7031 314.065 81.4043C314.771 81.9038 315.576 80.912 315.864 80.0958C316.54 78.1801 317.141 76.1409 316.777 74.1423C316.412 72.1437 314.784 70.2337 312.752 70.2269C312.999 69.9561 313.13 69.5999 313.119 69.2338L311.445 69.0923C311.87 69.0321 312.261 68.826 312.551 68.5093C311.878 69.2491 306.593 67.0724 305.505 67.0335Z"
        fill="#2F2E41"
      />
      <path
        d="M305.019 96.1792C305.019 96.1792 300.537 91.7873 298.924 92.0563C297.311 92.3253 295.109 96.1792 295.109 96.1792C295.109 96.1792 281.896 100.584 282.446 104.988C282.997 109.392 289.053 130.864 289.053 130.864C289.053 130.864 294.008 156.74 289.604 159.492C285.199 162.245 310.524 169.402 311.075 166.099C311.626 162.796 312.727 129.763 311.075 124.808C309.423 119.853 305.019 96.1792 305.019 96.1792Z"
        fill="#3F3D56"
      />
      <path
        d="M324.288 104.988H331.096C331.096 104.988 335.85 125.909 336.4 128.111C336.951 130.313 338.052 140.773 337.501 140.773C336.951 140.773 325.94 138.571 325.94 140.223L324.288 104.988Z"
        fill="#3F3D56"
      />
      <path d="M311 130H303V142H311V130Z" fill="#DADADA" />
      <path
        d="M287.401 142.425L295.109 145.729C295.109 145.729 309.974 147.931 309.974 141.875C309.974 135.819 295.109 139.122 295.109 139.122L290.154 137.538L287.401 142.425Z"
        fill="#FFB8B8"
      />
      <path
        d="M289.053 101.685L282.446 103.336L277.491 132.515C277.491 132.515 275.84 140.223 278.592 140.774C281.345 141.324 289.053 145.729 289.053 145.729C289.053 145.729 290.154 137.47 292.356 137.47L286.851 133.066L290.154 113.797L289.053 101.685Z"
        fill="#3F3D56"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="114.098"
          y1="185.578"
          x2="114.098"
          y2="139.327"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default OnboardingModuloFinalizadoSvg
