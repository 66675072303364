import React from 'react'
import { SvgType } from './index'
const CheckmarkCircleOutlineSvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.3499 9C17.3499 13.6451 13.7909 17.35 9.47358 17.35C5.15628 17.35 1.59727 13.6451 1.59727 9C1.59727 4.35486 5.15628 0.65 9.47358 0.65C13.7909 0.65 17.3499 4.35486 17.3499 9Z"
        stroke={color}
        strokeWidth="1.3"
      />
      <path
        d="M12.4315 6.24713C12.7436 5.91762 13.2497 5.91762 13.5619 6.24713C13.8741 6.57663 13.8741 7.11087 13.5619 7.44037L8.76585 12.5029C8.45369 12.8324 7.94757 12.8324 7.63541 12.5029L4.97094 9.69037C4.65878 9.36087 4.65878 8.82663 4.97094 8.49713C5.2831 8.16762 5.78922 8.16762 6.10138 8.49713L8.20063 10.713L12.4315 6.24713Z"
        fill={color}
      />
    </svg>
  )
}

export default CheckmarkCircleOutlineSvg
