import React from 'react'
import { SvgType } from './index'
const PlusCircleSvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="17" cy="17" r="17" fill={color} />
      <path
        d="M17 11.6667V22.3334"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6665 17H22.3332"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PlusCircleSvg
