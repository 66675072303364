import { useParams, useHistory, useLocation } from 'react-router'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import useAxios from 'axios-hooks'

import { Button, confirm, TituloPagina } from '../components'
import { BlocoNotaModel } from '../models/BlocoNotaModel'
import { ConteudoPaginaModel } from '../models/ConteudoPaginaModel'
import Svgs from '../svgs'
import { Show } from '../common/Show'
import { useModuloAtual } from '../modulos/ModulosStore'
import { toast } from 'react-toastify'

interface BlocoNotasParams {
  cursoId: string
}

const BlocoNotas = () => {
  const { Layout } = BlocoNotas
  const { cursoId } = useParams<BlocoNotasParams>()
  const [{ data }, fetchNotas] = useAxios(`/app/bloco-notas/curso/${cursoId}/aluno`)
  const notas = data?.rows || []

  useEffect(() => {
    fetchNotas()
  }, [])

  return (
    <Layout>
      <TituloPagina>Bloco de notas</TituloPagina>

      <div style={{ fontSize: 14, marginTop: 18 }}>Confira suas anotações</div>

      <Show condition={!notas.length}>
        <EmptyState />
      </Show>

      <Show condition={notas.length}>
        <div>
          {notas.map((n) => (
            <Nota nota={n} key={n.id} fetchNotas={fetchNotas} />
          ))}
        </div>
      </Show>
    </Layout>
  )
}

BlocoNotas.Layout = styled.div`
  @media (max-width: 768px) {
    margin: 24px;
  }
  @media (max-width: 450px) {
    margin: 12px;
  }
`

const EmptyState = () => {
  const { Layout } = EmptyState
  return (
    <Layout>
      <div>
        <strong>Nenhuma nota encontrada no momento.</strong>
      </div>
      <div>As notas que você criar enquanto estiver estudando irão aparecer aqui.</div>
    </Layout>
  )
}
EmptyState.Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  color: #333333;
`

const Nota = ({
  nota,
  fetchNotas,
}: {
  fetchNotas
  nota: BlocoNotaModel & { pagina: ConteudoPaginaModel }
}) => {
  const { cursoId } = useParams<BlocoNotasParams>()
  const history = useHistory()
  const modulo = useModuloAtual({ moduloId: nota.moduloId, cursoId: +cursoId })
  const nomeModulo = modulo?.nome
  const { Modulo, Pagina, Referencia, Texto, Layout, BotaoIrModulo, BotaoExcluir } = Nota
  const [, deleteNota] = useAxios(
    { url: `/app/bloco-notas/${nota.id}`, method: 'DELETE' },
    { manual: true }
  )

  async function handleClickExcluir() {
    try {
      if (!(await confirm({ message: 'Tem certeza que deseja excluir a nota?' }))) return
      await deleteNota()
      fetchNotas()
      toast('Nota excluída com sucesso')
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao excluir a nota'
      toast(message)
    }
  }

  function handleClickIrModulo() {
    history.push(`/curso/${cursoId}/conteudo/${nota.moduloId}`, {
      paginaIdUnico: nota.paginaIdUnico,
      notaAbertaId: nota.id,
    })
  }

  return (
    <Layout>
      <div className="bloco-nota-texto">
        <Modulo>{nomeModulo}</Modulo>
        <Pagina>Página {nota.pagina?.numero}</Pagina>
        <Show condition={nota.referencia}>
          <Referencia>{nota.referencia}</Referencia>
        </Show>
        <Show condition={nota.texto}>
          <Texto>{nota.texto}</Texto>
        </Show>
      </div>
      <div className="bloco-nota-btn-ir-modulo">
        <BotaoIrModulo
          fill="LINK"
          svgPosition="LEFT"
          label="Ir para o módulo"
          svg={<Svgs.Reply color="#007AFF" />}
          onClick={handleClickIrModulo}
        />
      </div>
      <div className="bloco-nota-btn-excluir">
        <BotaoExcluir onClick={handleClickExcluir} fill="CLEAR" svg={Svgs.Delete} />
      </div>
    </Layout>
  )
}
Nota.Layout = styled.div`
  padding: 18px 0;
  border-bottom: 1px solid #d9d9d9;
  display: grid;
  grid-template-columns: 1fr 36px;
  grid-template-rows: auto auto;
  grid-template-areas:
    'bloco-nota-texto bloco-nota-btn-excluir'
    'bloco-nota-btn-ir-modulo .';
  .bloco-nota-texto {
    grid-area: bloco-nota-texto;
  }
  .bloco-nota-btn-ir-modulo {
    grid-area: bloco-nota-btn-ir-modulo;
  }
  .bloco-nota-btn-excluir {
    grid-area: bloco-nota-btn-excluir;
  }

  @media (max-width: 475px) {
    grid-template-columns: min-content 1f auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      'bloco-nota-texto bloco-nota-texto bloco-nota-texto'
      'bloco-nota-btn-ir-modulo . bloco-nota-btn-excluir';

    .bloco-nota-btn-excluir {
      display: flex;
      align-items: flex-end;
    }
  }
`
Nota.Modulo = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
`
Nota.Pagina = styled.div`
  margin-top: 8px;
  font-size: 14px;
  line-height: 17px;
`
Nota.Referencia = styled.div`
  margin-top: 18px;
  font-size: 14px;
  line-height: 17px;
  border-left: 2px solid #007aff;
  font-style: italic;
  padding-left: 4px;
`
Nota.Texto = styled.div`
  margin-top: 12px;
  font-size: 14px;
  line-height: 17px;
`
Nota.BotaoIrModulo = styled(Button)`
  margin-top: 8px;
`
Nota.BotaoExcluir = styled(Button)`
  height: 42px;
  width: 42px;
  border-radius: 4px;
  background: white;
`

export default BlocoNotas
