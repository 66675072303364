import React from 'react'
import { IonButton } from '@ionic/react'
import { useParams, useHistory } from 'react-router'
import useAxios from 'axios-hooks'
import styled from '@emotion/styled'
import { useModuloAtual } from '../modulos/ModulosStore'
import { Button } from '../components'
import svgs from '../svgs'
import aplicacaoPadrao from '../assets/marca/aplicacao_padrao.png'

interface RegrasAvaliacaoParams {
  moduloId: string
  cursoId: string
}

export const RegrasAvaliacao = () => {
  const { moduloId, cursoId } = useParams<RegrasAvaliacaoParams>()
  const history = useHistory()
  const modulo = useModuloAtual()
  const { Layout, LogoNewDriver, Modulo, Titulo, Subtitulo, ContainerOu, Button } = RegrasAvaliacao

  function goToAvaliacao() {
    history.push(`/curso/${cursoId}/avaliacao/${moduloId}`)
  }

  function goToRevisarConteudo() {
    history.push(`/curso/${cursoId}/conteudo/${moduloId}`)
  }

  function goToTelaPrincipal() {
    history.replace(`/curso/${cursoId}/home`)
  }

  return (
    <Layout>
      <LogoNewDriver src={aplicacaoPadrao} />
      <Modulo>{modulo?.nome}</Modulo>
      <Titulo>
        O que você precisa saber antes de começar a <strong>Avaliação:</strong>
      </Titulo>
      <Regras />
      <Subtitulo>Quando estiver pronto, é só clicar no botão abaixo:</Subtitulo>
      <Button
        label="Fazer avaliação"
        svg={svgs.Play}
        svgPosition="LEFT"
        style={{ marginTop: 18 }}
        onClick={goToAvaliacao}
      />
      <ContainerOu>ou</ContainerOu>
      <Button
        label="Revisar conteúdo"
        color="#007AFF"
        style={{ marginBottom: 18 }}
        onClick={goToRevisarConteudo}
      />
      <br />
      <Button
        label="Voltar para o início"
        fill="OUTLINE"
        color="#007AFF"
        style={{ marginBottom: 18 }}
        onClick={goToTelaPrincipal}
      />
      <br />
      <br />
    </Layout>
  )
}
RegrasAvaliacao.Layout = styled.div`
  height: 100%;
  text-align: center;
  color: #2d2d2d;
  overflow: auto;
`
RegrasAvaliacao.LogoNewDriver = styled.img`
  width: 134px;
  margin-top: 18px;
`
RegrasAvaliacao.Modulo = styled.div`
  font-weight: bold;
  font-size: 24px;
  color: #f06f30;
  margin-top: 90px;
  @media (max-width: 580px) {
    font-size: 18px;
    margin-top: 8px;
  }
`
RegrasAvaliacao.Titulo = styled.div`
  margin-top: 18px;
  font-size: 24px;
  @media (max-width: 580px) {
    font-size: 18px;
  }
`
RegrasAvaliacao.Subtitulo = styled.div`
  font-size: 18px;
  margin-top: 38px;
  @media (max-width: 580px) {
    font-size: 14px;
    margin-top: 28px;
  }
`
RegrasAvaliacao.ContainerOu = styled.div`
  font-size: 18px;
  margin: 16px 0;
  @media (max-width: 580px) {
    font-size: 14px;
    margin-top: 28px;
  }
`
RegrasAvaliacao.Button = styled(Button)`
  width: 231px;
  height: 48px;
  margin: 0 auto;
  .label {
    font-size: 16px;
  }
`

const Regras = () => {
  const { Layout } = Regras
  return (
    <Layout>
      <ol>
        <li>
          O tempo de duração da avaliação é de até <strong>75 minutos</strong>.
        </li>
        <li>
          Todas as questões possuem apenas <strong>uma resposta correta</strong>, leia atentamente o
          enunciado e escolha a opção que julga ser a certa.
        </li>
        <li>
          Verifique seu <strong>sinal de internet</strong>, pois caso falte conexão enquanto
          responde a avaliação, <strong>não salvaremos</strong> as respostas. Portanto, tenha
          certeza de estar em local com <strong>conexão estável</strong>.
        </li>
        <li>
          Caso queira, você ainda <strong>pode revisar o conteúdo</strong> antes de responder a
          avaliação.
        </li>
      </ol>
    </Layout>
  )
}
Regras.Layout = styled.div`
  text-align: left;
  padding: 38px;
  background: #f5f5f5;
  border: 1px solid #f06f30;
  max-width: 990px;
  margin: 0 auto;
  margin-top: 28px;
  font-size: 18px;
  line-height: 34px;
  ol {
    margin: 0;
    padding-left: 22px;
  }
  li::marker {
    color: #f06f30;
    font-weight: bold;
    margin-right: 4px;
  }
  @media (max-width: 1040px) {
    margin: 0 24px;
    margin-top: 28px;
  }
  @media (max-width: 580px) {
    padding: 8px;
    font-size: 14px;
    line-height: 26px;
    margin-top: 12px;
  }
`
