import React from 'react'
import { SvgType } from './index'
const HandWritingSvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="52"
      height="54"
      viewBox="0 0 52 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.44943 33.0947C7.33 34.5064 7.94095 34.929 8.89795 35.5997C9.84422 36.2627 15.6092 31.2098 17.4941 30.4411C19.379 29.6725 37.0735 25.9256 36.3063 22.5049C35.5392 19.0842 30.1923 18.5789 26.0734 19.2772C21.9544 19.9754 14.0719 23.5982 11.7689 25.5535C9.46602 27.5073 7.44943 33.0947 7.44943 33.0947ZM20.6514 35.3869C20.6514 35.3869 15.2049 37.1554 15.2049 41.749C15.2049 46.3426 16.5309 48.9333 17.1587 50.1905C17.7881 51.446 19.7434 52.6649 22.449 51.4935C25.591 50.1323 21.6972 42.3002 31.6362 45.8955C36.19 47.5415 40.9137 49.7663 45.5073 48.2351C49.8651 46.782 51.5432 41.3003 51.5432 38.238C51.5432 31.9692 46.9497 33.6444 45.4185 35.1756C43.8873 36.7068 31.6377 39.7691 30.1065 39.7691C28.5753 39.7691 20.6514 35.3869 20.6514 35.3869Z"
        fill="#EF9645"
      />
      <path
        d="M27.5207 43.1189C27.5207 43.1189 27.337 41.1038 25.4153 40.8696C23.8367 40.6782 21.3485 41.2049 20.0561 43.788C18.7638 46.3727 16.467 45.9409 16.5635 48.0937C16.6584 50.2466 17.3291 51.9217 18.7164 52.352C20.1051 52.7838 22.0666 52.0182 22.3055 49.7214C22.5443 47.4246 22.8322 45.3192 24.3144 44.4097C25.7997 43.5017 27.5207 43.1189 27.5207 43.1189Z"
        fill="#F9CA55"
      />
      <path
        d="M25.5129 39.7695C25.5129 39.7695 23.9817 38.2383 20.9193 38.2383C17.8569 38.2383 10.7154 41.5043 12.8806 47.9996C14.4117 52.5932 18.7665 50.4404 19.3881 45.8943C20.0113 41.342 25.5129 39.7695 25.5129 39.7695Z"
        fill="#EF9645"
      />
      <path
        d="M23.9815 39.7695C23.9815 39.7695 22.4503 38.2383 19.3879 38.2383C16.3255 38.2383 11.908 40.581 11.7319 47.4254C11.588 53.0235 17.9042 50.4878 17.8567 45.8943C17.8092 41.3007 23.9815 39.7695 23.9815 39.7695Z"
        fill="#F9CA55"
      />
      <path
        d="M5.60742 41.2994C5.60742 46.3478 6.30718 49.3933 9.32516 48.8743C14.3306 48.0137 12.4304 41.8093 16.8112 40.0638C19.9915 38.7975 24.6218 39.0991 29.0914 41.7527C32.0358 43.5013 30.1065 36.7059 27.0441 35.1747C23.9818 33.6435 14.7946 35.1747 13.2634 35.1747C11.7322 35.1747 5.60742 41.2994 5.60742 41.2994Z"
        fill="#EF9645"
      />
      <path
        d="M5.36522 41.5664C2.92909 45.6471 4.41129 49.8655 8.66954 48.956C12.79 48.0771 10.7259 42.724 14.7943 39.7688C17.0743 38.1121 21.9879 37.5562 25.0503 39.0874C28.1126 40.6186 28.5751 36.7064 25.5127 35.1752C22.4503 33.644 12.4363 35.137 10.9081 35.2319C7.9851 35.4141 5.36522 41.5664 5.36522 41.5664Z"
        fill="#F9CA55"
      />
      <path
        d="M12.3714 44.5375C11.3317 45.7287 9.52338 45.8528 8.33058 44.8131L8.03046 44.5513C6.83766 43.5116 6.71517 41.7048 7.75485 40.512L42.2419 0.981117C43.2816 -0.210153 45.09 -0.334179 46.2828 0.705502L46.5813 0.967336C47.7741 2.00702 47.8966 3.81536 46.8585 5.00663L12.3714 44.5375Z"
        fill="#3B88C3"
      />
      <path
        d="M14.0132 42.6573C10.3996 46.7992 1.28436 54.9206 0.129841 53.9146C-1.02315 52.9087 5.78454 42.7752 9.39816 38.6318C13.0118 34.4884 11.9415 37.8019 13.2154 38.9151C14.4894 40.0267 17.6268 38.517 14.0132 42.6573Z"
        fill="#3B88C3"
      />
      <path
        d="M8.16699 38.2769C8.16699 39.8081 11.874 41.2091 13.4052 39.6779C14.9364 38.1467 18.5209 36.2664 23.2339 38.2371C34.2647 42.846 30.1059 36.7059 25.5124 35.1747C20.9188 33.6435 13.2628 35.1747 11.7316 35.1747C10.2004 35.1747 8.16699 38.2769 8.16699 38.2769Z"
        fill="#EF9645"
      />
      <path
        d="M7.43625 33.71C6.88808 32.0058 10.7865 22.5644 12.552 21.1022C14.7753 19.2617 23.376 16.4121 26.4384 16.4121C29.5008 16.4121 51.5423 27.5194 51.5423 35.1754C51.5423 42.8313 48.4799 43.7883 44.0777 44.9842C40.5575 45.9412 33.1833 45.3547 29.9616 42.3521C27.1381 39.7215 25.1767 37.9024 22.4496 36.7066C17.3936 34.4894 14.0234 37.3558 12.4923 38.887C9.42986 41.9494 3.66032 38.6435 8.47287 33.4053C11.9165 29.6554 14.9498 28.4871 17.856 27.5194C27.0432 24.457 33.168 24.457 30.1056 21.3946C29.023 20.3121 28.4335 21.9244 26.9268 22.1985C23.8951 22.7497 21.4819 23.1203 17.487 24.4524C16.1059 24.9118 9.56767 29.0812 7.43625 33.71Z"
        fill="#FFDC5D"
      />
      <path
        d="M18.1567 24.8786C19.7859 24.2845 26.8876 22.9922 30.5823 21.7121C33.5666 20.677 36.185 22.6859 30.5211 24.8174C25.0547 26.8737 19.3755 26.7053 15.2336 28.3498C13.6672 28.9715 12.3091 27.01 18.1567 24.8786Z"
        fill="#EF9645"
      />
      <path
        d="M32.3836 12.2441C32.3836 12.2441 23.3067 22.6486 19.4512 27.037C22.9025 25.6191 25.9909 25.8886 28.9415 24.1522C31.8921 22.4174 33.238 20.5662 37.7153 15.4321C39.3781 13.5273 32.3836 12.2441 32.3836 12.2441Z"
        fill="#3B88C3"
      />
    </svg>
  )
}

export default HandWritingSvg
