import React, { useRef, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router'
import { IonButton, IonContent, IonSpinner } from '@ionic/react'
import { Questao } from '../questoes/Questao'
import { useWindowSize } from '../common/hooks/useWindowSize'
import { AvaliacaoStore as Store } from './AvaliacaoStore'
import { ConteudoStore } from '../conteudo/ConteudoStore'
import { getModulosComEstatisticas, useModuloAtual } from '../modulos/ModulosStore'
import styled from '@emotion/styled'
import { responsiveSpacing } from '../common/styles/ResponsiveSpacing'
import useInterval from '../common/hooks/useInterval'
import { AvaliacaoModel } from '../models/AvaliacaoModel'
import { useDispatch, useSelector } from 'react-redux'
import { progressoActions } from '../conteudo/ProgressoReduxStore'
import { Button } from '../components'
import Svgs from '../svgs'

interface AvaliacaoParams {
  moduloId
  cursoId
}
const Avaliacao = () => {
  const scrollElement = React.useRef<any>()
  const ref = React.useRef<any>()

  function scrollToQuestao(questaoId) {
    const posicaoQuestao = _getPosicaoQuestao(questaoId)

    if (posicaoQuestao == null) return

    ref.current.scrollToPoint(0, posicaoQuestao, 300)
  }

  useEffect(() => {
    if (ref.current)
      ref.current.getScrollElement().then((e) => {
        scrollElement.current = e
      })
  }, [ref])

  const { Layout, Container } = Avaliacao
  return (
    <Store.Provider>
      <Container ref={ref}>
        <Layout>
          <Titulo />
          <Questoes />
          <BotaoEnviar />
          <>
            <Init />
          </>
        </Layout>
      </Container>
      <Contador />
      <AjusteTamanhoFonte />
      <IndiceQuestoes scrollToQuestao={scrollToQuestao} />
    </Store.Provider>
  )

  function _getPosicaoQuestao(questaoId) {
    const questaoElRef = ref.current?.querySelector(`[data-questao-id="${questaoId}"]`)
    if (!questaoElRef && scrollElement.current) return
    const elTop = questaoElRef.getBoundingClientRect().top
    const scrollTop = scrollElement.current.scrollTop
    return elTop + scrollTop
  }
}
Avaliacao.Container = styled(IonContent, { label: 'Container' })`
  --background: #f2f2f2;
  @media (max-width: 1080px) {
    --background: white;
  }
  @media (min-width: 475px) {
    padding-right: 16px;
  }
`
Avaliacao.Layout = styled('div', { label: 'Layout' })`
  max-width: 1024px;
  margin: 24px auto;
  background: white;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  @media (max-width: 1080px) {
    margin: 18px auto;
    box-shadow: none;
    border-radius: 0;
  }
`

const Init = () => {
  const globalDispatch = useDispatch()
  const dispatch = Store.useDispatch()
  const { avaliacao } = Store.useState()
  const history = useHistory()
  const { moduloId, cursoId } = useParams<AvaliacaoParams>()
  const modulos = useSelector(getModulosComEstatisticas)

  useEffect(() => {
    const moduloAtual = modulos.filter((m) => !m.estatisticaEstudo?.moduloCompleto)?.[0]
    if (!moduloAtual || moduloAtual.estatisticaEstudo?.percentualProgressoMaisAvancado !== 100) {
      history.replace(`/curso/${cursoId}`)
      return
    }
    dispatch(Store.thunks.loadOrCreateAvaliacao(moduloId))
  }, [moduloId])

  useEffect(() => {
    if (!avaliacao) return
    if (['APROVADA', 'REPROVADA'].includes(avaliacao.status)) {
      globalDispatch(progressoActions.getEstatisticasEstudo())
      history.replace(`/curso/${cursoId}/resultado-avaliacao/${moduloId}`)
    }
  }, [avaliacao])
  return null
}

const Titulo = () => {
  const modulo = useModuloAtual()
  return (
    <Styles.Titulo>
      <h2>Avaliação do Módulo</h2>
      <h1>{modulo?.nome}</h1>
    </Styles.Titulo>
  )
}

const Questoes = () => {
  const { questoesIds } = Store.useState()

  return (
    <div>
      {questoesIds.map((questaoId, i) => (
        <ContainerQuestao key={questaoId} questaoId={questaoId} indice={i + 1} />
      ))}
    </div>
  )
}

const ContainerQuestao = ({ questaoId, indice }) => {
  const { Container } = ContainerQuestao

  const dispatch = Store.useDispatch()
  const { fontSize } = Store.useState()

  function handleAlternativaSelecionada(questao, alternativa) {
    dispatch({
      type: 'respostaMarcada',
      payload: { questaoId: questao.id, alternativaId: alternativa.id },
    })
  }

  return (
    <Container data-questao-id={questaoId}>
      <Styles.NumeroQuestao>
        <div className="linha" />
        <div className="numero">{indice}</div>
      </Styles.NumeroQuestao>
      <Styles.ContainerQuestao style={{ fontSize: fontSize }}>
        <Questao
          questaoId={questaoId}
          showBotaoEnviar={false}
          embaralharAlternativas
          onAlternativaSelecionada={handleAlternativaSelecionada}
          isAvaliacao
        />
      </Styles.ContainerQuestao>
    </Container>
  )
}
ContainerQuestao.Container = styled.div`
  margin: 0 24px;
  @media (max-width: 475px) {
    margin: 0;
  }
`

const BotaoEnviar = () => {
  const { enviandoRespostas } = Store.useState()
  const dispatch = Store.useDispatch()

  function enviarRespostas() {
    dispatch(Store.thunks.sendRespostaAvaliacao())
  }

  return (
    <Styles.ContainerBotao>
      <IonButton onClick={enviarRespostas} shape="round" expand="block" size="large">
        {enviandoRespostas ? <IonSpinner /> : 'Enviar Respostas'}
      </IonButton>
    </Styles.ContainerBotao>
  )
}

const IndiceQuestoes = ({ scrollToQuestao }) => {
  const { questoesIds } = Store.useState()
  const scrollBarWidth = useScrollBarWidth()

  return (
    <Styles.IndiceContainer scrollBarWidth={scrollBarWidth}>
      {questoesIds.map((questaoId, i) => {
        return (
          <IndiceQuestao
            questaoId={questaoId}
            indice={i}
            key={questaoId}
            onClick={() => scrollToQuestao(questaoId)}
          />
        )
      })}
    </Styles.IndiceContainer>
  )
}

const IndiceQuestao = ({
  questaoId,
  indice,
  ...props
}: { questaoId; indice } & React.HTMLAttributes<HTMLDivElement>) => {
  const { respostasMarcadas } = Store.useState()
  const questaoPossuiRespostaMarcada = respostasMarcadas.some((x) => x.questaoId === +questaoId)

  return (
    <Styles.Indicador {...props}>
      <div className={questaoPossuiRespostaMarcada ? 'marcada' : ''}>{indice + 1}</div>
    </Styles.Indicador>
  )
}

const useScrollBarWidth = () => {
  const [scrollBarWidth, setScrollBarWidth] = useState(0)
  const windowSize = useWindowSize()
  function calculaScrollBarWidth() {
    const clientWidth = document.getElementById('container-avaliacao')?.clientWidth ?? 0
    if (!clientWidth) return
    setScrollBarWidth(window.innerWidth - clientWidth)
  }
  useEffect(() => {
    calculaScrollBarWidth()
    setTimeout(() => {
      calculaScrollBarWidth()
    }, 1000)
  }, [windowSize])
  return scrollBarWidth
}

const Contador = () => {
  const { avaliacao } = Store.useState()
  const [tempoRestante, setTempoRestante] = useState(_getTempoRestante(avaliacao))
  const scrollBarWidth = useScrollBarWidth()

  useEffect(() => {
    setTempoRestante(_getTempoRestante(avaliacao))
  }, [avaliacao])
  useInterval(() => {
    setTempoRestante(Math.max(0, tempoRestante - 1))
  }, 1000)

  const getDashArray = () => {
    return (tempoRestante / (avaliacao?.duracao || 0)) * 62
  }

  return (
    <Styles.Contador scrollBarWidth={scrollBarWidth}>
      <div className="contador">
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="20" cy="20" r="10" stroke="#d4d4d4" strokeWidth="20" />
          <circle
            className="timer-circle"
            cx="20"
            cy="20"
            r="10"
            strokeWidth="20"
            style={{ strokeDasharray: getDashArray() + ', 1000' }}
          />
        </svg>
        <br />
        {_formatMinutos(tempoRestante)}
      </div>
    </Styles.Contador>
  )
}

const AjusteTamanhoFonte = () => {
  const scrollBarWidth = useScrollBarWidth()
  const { fontSize } = Store.useState()
  const dispatch = Store.useDispatch()
  const aumentaFonte = () => dispatch(Store.actions.aumentaFonte())
  const diminuiFonte = () => dispatch(Store.actions.diminuiFonte())

  const { Layout, Botao } = AjusteTamanhoFonte

  useEffect(() => {
    localStorage.setItem('conteudo_font_size', '' + fontSize)
  }, [fontSize])

  return (
    <Layout scrollBarWidth={scrollBarWidth}>
      <Botao onClick={aumentaFonte} svg={<Svgs.IncreaseFontSize />} fill="CLEAR" />
      <Botao onClick={diminuiFonte} svg={<Svgs.DecreaseFontSize />} fill="CLEAR" />
    </Layout>
  )
}
AjusteTamanhoFonte.Layout = styled('div')<{ scrollBarWidth }>(
  (props) => `
  position: fixed;
  top: 100px;
  padding-right: 3px;
  right: ${props.scrollBarWidth}px;
`
)
AjusteTamanhoFonte.Botao = styled(Button)`
  width: 32px;
  border: 1px solid #c4c4c4;
  background-color: #fff;
  color: var(--ion-color-primary);
  font-weight: bold;
  margin-bottom: 5px;
`

const _formatMinutos = (tempo) => {
  tempo = ~~tempo
  const minutos = Math.floor(tempo / 60)
    .toString()
    .padStart(2, '0')
  const segundos = (tempo % 60).toString().padStart(2, '0')
  return `${minutos}:${segundos}`
}

const _getTempoRestante = (avaliacao: AvaliacaoModel | null) => {
  if (!avaliacao) return 0
  const segundosCriacaoAvaliacao = new Date(avaliacao.createdAt).getTime() / 1000
  const segundosAgora = new Date().getTime() / 1000
  return avaliacao.duracao - (segundosAgora - segundosCriacaoAvaliacao)
}

const Styles = {
  IonContent: styled('div')`
    --background: #e7e7e7;
    font-family: 'Roboto Condensed', sans-serif;
    #container-avaliacao {
      width: 100%;
      .container-avaliacao-flutuante {
        max-width: 1280px;
        background: white;
        width: 100%;
        @media (min-width: 475px) {
          box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
          margin-top: 24px;
          width: calc(100% - 78px);
          border-radius: 8px;
          margin: 0 auto;
        }
      }
    }
  `,
  Titulo: styled('div')`
    text-align: center;
    color: var(--ion-color-primary);
    font-weight: bold;
    padding-top: 12px;
    h2 {
      margin: 0;
    }
    h1 {
      font-weight: bold;
      margin: 0;
      margin-top: 0;
      font-size: 2.5em;
    }
    @media (min-width: 476px) {
      padding-top: 12px;
    }
  `,
  NumeroQuestao: styled('div')`
    text-align: center;
    position: relative;
    height: 36px;
    margin: 8px 0;
    @media (min-width: 475px) {
      margin-right: 12px;
    }
    .numero {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: var(--ion-color-primary);
      color: var(--ion-color-primary-contrast);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 2px solid var(--ion-color-primary-shade);
      position: absolute;
    }
    .linha {
      height: 2px;
      background: #d9d9d9;
      width: 100%;
      top: 50%;
      position: absolute;
    }
  `,
  IndiceContainer: styled('div')<{ scrollBarWidth }>(
    (props) => `
    position: fixed;
    right: ${props.scrollBarWidth}px;
    top: 60%;
    margin-top: -25vh;
    /* @media(min-width: 476px) {
      top: calc(50vh - 180px);
    } */
  `
  ),
  Indicador: styled('div')`
    padding: 3px 4px;
    cursor: pointer;
    & > div {
      background: #767676;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      font-size: 0.7em;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      &.marcada {
        background: var(--ion-color-primary);
      }
      &:hover {
        background: #565656;
        &.marcada {
          background: var(--ion-color-primary-shade);
        }
      }
    }

    @media (min-width: 476px) {
      padding: 5px 6px;
      & > div {
        width: 21px;
        height: 21px;
        font-size: 0.8em;
      }
    }
  `,
  Contador: styled('div')<{ scrollBarWidth }>(
    (props) => `
    position: fixed;
    top: 9px;
    padding-right: 3px;
    right: ${props.scrollBarWidth}px;
    .contador {
      padding: 3px;
      background: white;
      border-radius: 6px;
      box-shadow: 1px 1px 3px rgba(0,0,0,.25);
      text-align: center;
    }
    .timer-circle {
      stroke: var(--ion-color-primary);
      transform: rotate(-90deg);
      transform-origin: center;
    }

    @media(max-width: 475px) {
      .contador {
        width: 48px;
        font-size: 0.9em;
        svg {
          max-width: 100%;
        }
      }
    }
  `
  ),
  ContainerQuestao: styled('div')`
    @media (max-width: 1080px) {
      padding-right: 12px;
    }
  `,
  ContainerBotao: styled('div')`
    ${responsiveSpacing('padding')}
    padding-top: 0;
  `,
}

export { Avaliacao }
