import React from 'react'
import styled from '@emotion/styled'
import { IonContent } from '@ionic/react'
import { CorModuloModel } from '../../models/CorModuloModel'

export const CommonStyles = {}

export function modularize(comp) {
  return (additionalStyles?: string) => {
    return styled(comp)<CorModuloModel>`
      --primary-color: ${(props) => props.corPrimaria};
      --primary-color-shade: ${(props) => props.corPrimariaSombra};
      --secondary-color: ${(props) => props.corSecundaria};
      --url-image: ${(props) => props.imagem};
      ${additionalStyles || ''}
    `
  }
}

export const gridAreas = (areas) =>
  areas
    .map(
      (a) => `
  .${a} {
    grid-area: ${a};
  }
`
    )
    .join('')
