import React from 'react'
import { QuestaoModel } from '../models/QuestaoModel'
import { createSimpleStore } from 'react-simple-reducer'
import Axios from 'axios'
import { AvaliacaoModel } from '../models/AvaliacaoModel'
import { toast } from 'react-toastify'

const AvaliacaoStore = createSimpleStore(
  {
    avaliacao: null as AvaliacaoModel | null,
    questoesIds: [] as number[],
    respostasMarcadas: [] as Array<{ questaoId; alternativaId }>,
    enviandoRespostas: false,
    fontSize: _getFontSize(),
  },
  {
    loadOrCreateAvaliacaoSuccess(state, avaliacao: AvaliacaoModel) {
      state.avaliacao = avaliacao
      state.questoesIds = avaliacao.avaliacoesQuestoes.map((x) => x.questaoId)
    },
    respostaMarcada(state, payload: { questaoId; alternativaId }) {
      const questaoId = +payload.questaoId
      const alternativaId = +payload.alternativaId
      state.respostasMarcadas = [
        ...state.respostasMarcadas.filter((x) => x.questaoId !== questaoId),
        { questaoId, alternativaId },
      ]
    },
    respostasEnviadas(state, payload: { avaliacaoRetorno }) {
      state.avaliacao = payload.avaliacaoRetorno
    },
    sendRespostaAvaliacaoStarted(state) {
      state.enviandoRespostas = true
    },
    sendRespostaAvaliacaoError(state, payload: { message }) {
      state.enviandoRespostas = false
    },
    diminuiFonte(state) {
      if (state.fontSize < 0.4) return
      state.fontSize -= 0.1
    },
    aumentaFonte(state) {
      if (state.fontSize > 2) return
      state.fontSize += 0.1
    },
  },
  {
    thunks: {
      loadOrCreateAvaliacao(moduloId) {
        return async (dispatch) => {
          try {
            const avaliacao = await Axios.post('/app/avaliacoes/aleatorias', {
              moduloId,
              quantidadeQuestoes: 15,
            }).then((x) => x.data)
            dispatch({ type: 'loadOrCreateAvaliacaoSuccess', payload: avaliacao })
          } catch (error: any) {}
        }
      },
      sendRespostaAvaliacao() {
        return async (dispatch, getState) => {
          const { respostasMarcadas, avaliacao, enviandoRespostas } = getState()
          if (enviandoRespostas) return

          try {
            dispatch({ type: 'sendRespostaAvaliacaoStarted' })
            const avaliacaoRetorno = await Axios.post('/app/avaliacoes/respostas', {
              avaliacaoId: avaliacao?.id,
              respostasMarcadas,
            }).then((x) => x.data)

            dispatch({ type: 'respostasEnviadas', payload: { avaliacaoRetorno } })
          } catch (error: any) {
            const message =
              error?.response?.data.message ?? 'Ocorreu um erro ao responder a avaliação'
            toast(message)
            dispatch({ type: 'sendRespostaAvaliacaoError', payload: { message } })
          }
        }
      },
    },
  }
)

function _getFontSize() {
  const fs = localStorage.getItem('conteudo_font_size')
  if (fs && fs !== 'undefined' && !isNaN(+fs)) return +fs
  return 1
}

export { AvaliacaoStore }
