import React, { useEffect } from 'react'
import { Show } from '../components/Show'
import { useDispatch, useSelector } from 'react-redux'
import { modulosActions, getModulosComEstatisticas } from './ModulosStore'
import { useHistory, useParams } from 'react-router'
import styled from '@emotion/styled'
import { Button, TituloPagina } from '../components'
import { Flex } from '../components/Flex'
import { ModuloModel } from '../models/ModuloModel'
import { progressoActions } from '../conteudo/ProgressoReduxStore'
import { EstatisticaEstudoModel } from '../models/EstatisticaEstudoModel'
import useMeasure from 'react-use-measure'
import useMedia from 'use-media'
import Svgs from '../svgs'
import { animated, useSpring } from 'react-spring'
import { AvaliacaoModel } from '../models/AvaliacaoModel'
import AnimateHeightComp from 'react-animate-height'
const AnimateHeight: any = AnimateHeightComp

interface ModulosParams {
  cursoId: string
}

interface ModuloWithEstatistica extends ModuloModel {
  estatisticaEstudo?: EstatisticaEstudoModel
}

type PanelTypes = 'ANDAMENTOS' | 'NAO_INICIADOS' | 'FINALIZADOS'

export const Modulos = () => {
  const dispatch = useDispatch()
  const { cursoId } = useParams<ModulosParams>()
  useEffect(() => {
    dispatch(modulosActions.loadModulos(+cursoId))
    dispatch(progressoActions.loadEstatisticasEstudo(+cursoId))
  }, [])
  const isWide = useMedia({ minWidth: '769px' })
  const { Layout, Separador } = Modulos

  const [openPanels, setOpenPanels] = React.useState(['ANDAMENTOS', 'NAO_INICIADOS', 'FINALIZADOS'])

  function toggle(panel: PanelTypes) {
    if (isOpen(panel)) setOpenPanels([...openPanels.filter((x) => x !== panel)])
    else setOpenPanels([...openPanels, panel])
  }

  function isOpen(panel: PanelTypes) {
    if (isWide) return true
    return openPanels.some((x) => x === panel)
  }

  return (
    <Layout>
      <Titulo label="Módulos em andamento" panel="ANDAMENTOS" toggle={toggle} isOpen={isOpen} />
      <AnimateHeight height={isOpen('ANDAMENTOS') ? 'auto' : '0'}>
        <ListaModulosAndamento>
          {(modulo) => <ModuloCard modulo={modulo} key={modulo.id} />}
        </ListaModulosAndamento>
      </AnimateHeight>

      <Separador />

      <Titulo label="Módulos não iniciados" panel="NAO_INICIADOS" toggle={toggle} isOpen={isOpen} />
      <AnimateHeight height={isOpen('NAO_INICIADOS') ? 'auto' : '0'}>
        <ListaModulosNaoIniciados>
          {(modulo) => <ModuloCard modulo={modulo} key={modulo.id} />}
        </ListaModulosNaoIniciados>
      </AnimateHeight>

      <Separador />

      <Titulo label="Módulos concluídos" panel="FINALIZADOS" toggle={toggle} isOpen={isOpen} />
      <AnimateHeight height={isOpen('FINALIZADOS') ? 'auto' : '0'}>
        <ListaModulosConcluidos>
          {(modulo) => <ModuloCard modulo={modulo} key={modulo.id} />}
        </ListaModulosConcluidos>
      </AnimateHeight>
    </Layout>
  )
}
Modulos.Layout = styled.div`
  @media (max-width: 475px) {
  }
`
Modulos.Separador = styled.div`
  margin-bottom: 28px;
  border: 1px solid #d9d9d9;
  @media (max-width: 768px) {
    margin-bottom: 19px;
  }
`

const Titulo = ({
  label,
  panel,
  toggle,
  isOpen,
}: {
  label: string
  panel: PanelTypes
  toggle
  isOpen
}) => {
  const { Layout, TituloPagina } = Titulo
  const isWide = useMedia({ minWidth: '769px' })

  const props = useSpring({ transform: isOpen(panel) ? 'rotate(90deg)' : 'rotate(-90deg)' })

  if (isWide) {
    return (
      <Layout>
        <TituloPagina>{label}</TituloPagina>
      </Layout>
    )
  }

  return (
    <Layout onClick={() => toggle(panel)}>
      <TituloPagina>{label}</TituloPagina>
      <Show condition={!isWide}>
        <animated.div style={props}>
          <Svgs.ChevronLeft color="#2d2d2d" />
        </animated.div>
      </Show>
    </Layout>
  )
}
Titulo.Layout = styled.div`
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
  @media (max-width: 768px) {
    margin-left: 16px;
  }
`
Titulo.TituloPagina = styled(TituloPagina)`
  display: block;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 21px;
  }
`

const ListaModulosAndamento = ({ children }) => {
  const modulos = useSelector(getModulosComEstatisticas)
  const modulosAndamento = modulos.filter((m) => {
    if (!m.estatisticaEstudo?.percentualProgressoGeral) return false
    return !m.estatisticaEstudo?.moduloCompleto
  })
  if (!modulosAndamento.length) {
    return <EmptyState>Nenhum módulo em andamento</EmptyState>
  }
  return <Slider>{modulosAndamento.map((m) => children(m))}</Slider>
}
const ListaModulosNaoIniciados = ({ children }) => {
  const modulos = useSelector(getModulosComEstatisticas)
  const modulosNaoIniciados = modulos.filter((m) => {
    if (m.estatisticaEstudo?.moduloCompleto) return false
    return !m.estatisticaEstudo?.percentualProgressoGeral
  })
  if (!modulosNaoIniciados.length) {
    return <EmptyState>Nenhum módulo iniciado</EmptyState>
  }
  return <Slider>{modulosNaoIniciados.map((m) => children(m))}</Slider>
}
const ListaModulosConcluidos = ({ children }) => {
  const modulos = useSelector(getModulosComEstatisticas)
  const modulosConcluidos = modulos.filter((m) => {
    return m.estatisticaEstudo?.moduloCompleto
  })
  if (!modulosConcluidos.length) {
    return <EmptyState>Nenhum módulo finalizado</EmptyState>
  }
  return <Slider>{modulosConcluidos.map((m) => children(m))}</Slider>
}
const ModuloCard = ({ modulo }: { modulo: ModuloWithEstatistica }) => {
  const { Layout, Nome, ProgressoLabel, Progresso, Contagem } = ModuloCard

  const { estatisticaEstudo } = modulo
  const percentual = +(estatisticaEstudo?.percentualProgressoMaisAvancado.toFixed(0) ?? 0)
  const moduloIniciado = (estatisticaEstudo?.percentualProgressoMaisAvancado ?? 0) > 0
  const refazerAvaliacao =
    moduloIniciado &&
    estatisticaEstudo?.desempenhoAvaliacao &&
    estatisticaEstudo?.desempenhoAvaliacao < 70
  const height = estatisticaEstudo?.moduloCompleto || refazerAvaliacao ? 240 : 201

  return (
    <Layout color={modulo.cor} height={height}>
      <Nome>{modulo.nome}</Nome>
      <ProgressoLabel> Progresso </ProgressoLabel>
      <Progresso>
        <Contagem>{percentual}%</Contagem>
        <ProgressBar percentage={percentual} />
      </Progresso>
      <RodapeModuloCard modulo={modulo} />
    </Layout>
  )
}
ModuloCard.Layout = styled.div<{ color; height }>(
  (props) => `
  width: 100%;
  min-height: ${props.height}px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08);
  border-radius: 8px;
  border-top: 8px solid ${props.color};
`
)
ModuloCard.Nome = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  margin: 18px 18px 0 18px;
  height: 2.3em;
  overflow: hidden;
`
ModuloCard.ProgressoLabel = styled.div`
  margin: 18px 18px 0 18px;
  font-size: 14px;
  line-height: 17px;
  color: #2d2d2d;
`
ModuloCard.Progresso = styled.div`
  font-size: 14px;
  color: #2d2d2d;
  margin: 12px 18px 0 18px;
  display: flex;
  align-items: center;
`
ModuloCard.Contagem = styled.div`
  margin-right: 8px;
`

const RodapeModuloCard = ({ modulo }: { modulo: ModuloWithEstatistica }) => {
  const { moduloAnteriorConcluido, formatDesempenhoAvaliacao, Layout, Status, LayoutStatus, Nota } =
    RodapeModuloCard

  const { cursoId } = useParams<{ cursoId }>()
  const dispatch = useDispatch()
  const history = useHistory()

  const { estatisticaEstudo } = modulo

  const modulos = useSelector(getModulosComEstatisticas)
  const moduloIniciado = (estatisticaEstudo?.percentualProgressoMaisAvancado ?? 0) > 0
  const refazerAvaliacao =
    moduloIniciado &&
    estatisticaEstudo?.desempenhoAvaliacao &&
    estatisticaEstudo?.desempenhoAvaliacao < 70
  const pendenteAvaliacao =
    !refazerAvaliacao && estatisticaEstudo?.percentualProgressoMaisAvancado === 100
  const moduloConcluido = estatisticaEstudo?.moduloCompleto
  const permiteInicio = moduloAnteriorConcluido(modulos, modulo)
  const desempenhoAvaliacao = formatDesempenhoAvaliacao(estatisticaEstudo?.desempenhoAvaliacao)

  function navigate(destination) {
    history.push(`/curso/${cursoId}/${destination}`)
  }

  const handleRefazerAvaliacao = () => {
    dispatch(
      modulosActions.refazerAvaliacao(modulo.id, () => {
        dispatch(modulosActions.loadModulos(+cursoId))
        dispatch(progressoActions.loadEstatisticasEstudo(+cursoId))
        navigate(`regras-avaliacao/${modulo.id}`)
      })
    )
  }

  if (moduloConcluido) {
    return (
      <LayoutStatus>
        <Flex style={{ gap: '20px' }}>
          <label>
            Status:
            <Status>Aprovado</Status>
          </label>
          <label>
            Nota:
            <Nota>{desempenhoAvaliacao}</Nota>
          </label>
        </Flex>
        <div>
          <Button
            fill="LINK"
            onClick={() => navigate(`conteudo/${modulo.id}`)}
            label="Revisar módulo"
            style={{ justifyContent: 'left', paddingLeft: 0 }}
          />
        </div>
      </LayoutStatus>
    )
  }

  if (pendenteAvaliacao) {
    return (
      <Layout>
        <Button
          label="Fazer avaliação"
          color="#1F3A93"
          onClick={() => navigate(`regras-avaliacao/${modulo.id}`)}
        />
      </Layout>
    )
  }

  if (refazerAvaliacao) {
    return (
      <LayoutStatus>
        <Flex style={{ gap: '20px' }}>
          <label>
            Status:
            <Status style={{ backgroundColor: 'rgb(255 0 0 / 29%)', color: '#ff0000' }}>
              Reprovado
            </Status>
          </label>
          <label>
            Nota:
            <Nota>{desempenhoAvaliacao}</Nota>
          </label>
        </Flex>
        <Flex>
          <Button
            fill="LINK"
            onClick={() => navigate(`conteudo/${modulo.id}`)}
            label="Revisar módulo"
            style={{ justifyContent: 'left' }}
          />
          <Button
            fill="LINK"
            onClick={handleRefazerAvaliacao}
            label="Refazer Avaliação"
            style={{ justifyContent: 'left' }}
          />
        </Flex>
      </LayoutStatus>
    )
  }

  if (moduloIniciado) {
    return (
      <Layout>
        <Button label="Continuar" onClick={() => navigate(`conteudo/${modulo.id}`)} />
      </Layout>
    )
  }

  return (
    <Layout>
      <Button
        disabled={!permiteInicio}
        label="Iniciar"
        onClick={() => navigate(`conteudo/${modulo.id}`)}
      />
    </Layout>
  )
}
RodapeModuloCard.moduloAnteriorConcluido = (
  modulos: Array<ModuloModel & { estatisticaEstudo?: EstatisticaEstudoModel }>,
  moduloAtual: ModuloModel
) => {
  if (!moduloAtual.moduloPrecedenteId) return true
  const moduloPrecedente = modulos.find((m) => m.id === moduloAtual.moduloPrecedenteId)
  if (!moduloPrecedente) return true
  return moduloPrecedente.estatisticaEstudo?.moduloCompleto ?? false
}
RodapeModuloCard.formatDesempenhoAvaliacao = (desempenho) => {
  if (!desempenho || isNaN(desempenho)) return desempenho
  return Math.ceil(desempenho)
}

RodapeModuloCard.Layout = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #2d2d2d;
  display: grid;
  padding: 0px 18px;
  /* height: 70px; */
  grid-template-columns: auto;
  place-items: center;

  button {
    margin: 10px auto;
  }
`
RodapeModuloCard.LayoutStatus = styled(RodapeModuloCard.Layout)`
  gap: 0px;
  margin-top: 18px;
  justify-items: flex-start;
`
RodapeModuloCard.Status = styled.div`
  background: rgba(54, 255, 107, 0.19);
  border-radius: 4px;
  padding: 7px 19px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #27ae60;
  width: 110px;
`
RodapeModuloCard.Nota = styled.div`
  background: #9fa5ab;
  border-radius: 4px;
  padding: 7px 19px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  width: 80px;
`

const ProgressBar = ({ percentage }) => {
  const { Container, Progress } = ProgressBar
  const totalWidth = 132
  const widthInPercentage = (totalWidth * percentage) / 100
  return (
    <Container width={totalWidth}>
      <Progress width={widthInPercentage} />
    </Container>
  )
}
ProgressBar.Container = styled.div<{ width }>(
  (props) => `
  width: ${props.width}px;
  background: #D9D9D9;
  border-radius: 10px;
  height: 8px;
`
)
ProgressBar.Progress = styled.div<{ width }>(
  (props) => `
  height: 8px;
  background: #3B8AE8;
  border-radius: 10px;
  width: ${props.width}px;
`
)

const Slider = ({ children }) => {
  const { Layout, ScrollButton, ScrollButtonContainer, Container } = Slider
  const isWide = useMedia({ minWidth: '769px' })
  const [ref, bounds] = useMeasure()
  const intervalScroll = React.useRef(null as any)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const size = 328
  const gap = 20
  const amount = children.length

  const maxSize = size * amount + gap * (amount - 1)
  const showScrollButtons = isWide && maxSize > bounds.width

  function handleMouseDown(direction: 'LEFT' | 'RIGHT') {
    const scrollAmount = 5
    if (intervalScroll.current) cleanUp()
    intervalScroll.current = setInterval(() => {
      if (!containerRef.current) return
      containerRef.current.scrollLeft += direction === 'LEFT' ? -scrollAmount : scrollAmount
    }, 16)
  }

  function cleanUp() {
    clearInterval(intervalScroll.current)
    intervalScroll.current = null
  }

  useEffect(() => {
    document.body.addEventListener('mouseup', cleanUp)
    return () => document.body.removeEventListener('mouseup', cleanUp)
  })

  return (
    <Layout ref={ref}>
      <Show condition={showScrollButtons}>
        <ScrollButtonContainer style={{ marginRight: 12 }}>
          <ScrollButton
            color="#F2F2F2"
            onMouseDown={() => handleMouseDown('LEFT')}
            svg={<Svgs.ChevronLeft color="#2D2D2D" />}
          />
        </ScrollButtonContainer>
      </Show>
      <Container size={size} amount={amount} ref={containerRef}>
        {children}
        <div data-description="Div utilizada para definir espaço à direita" />
      </Container>
      <Show condition={showScrollButtons}>
        <ScrollButtonContainer style={{ marginLeft: 12 }}>
          <ScrollButton
            color="#F2F2F2"
            onMouseDown={() => handleMouseDown('RIGHT')}
            svg={<Svgs.ChevronLeft style={{ transform: 'rotate(180deg)' }} color="#2D2D2D" />}
          />
        </ScrollButtonContainer>
      </Show>
    </Layout>
  )
}
Slider.Layout = styled.div`
  display: flex;
  @media (max-width: 768px) {
    padding-bottom: 19px;
  }
`
Slider.Container = styled.div<{ amount; size }>(
  (props) => `
  display: grid;
  grid-template-columns: repeat(${props.amount}, ${props.size}px);
  gap: 20px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  @media (max-width: 768px) {
    grid-template-columns: repeat(${props.amount}, ${props.size}px) 1px;
    padding: 0 16px;
  }
  @media (max-width: 475px) {
    grid-template-columns: repeat(${props.amount}, calc(100vw - 56px)) 1px;
  }
`
)
Slider.ScrollButton = styled(Button)`
  width: 30px;
  height: 30px;
  background: #f2f2f2;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`
Slider.ScrollButtonContainer = styled.div`
  display: flex;
  align-items: center;
`

const EmptyState = styled.div`
  width: 100%;
  height: 150px;
  display: grid;
  place-items: center;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #2d2d2d;
`
