import React from 'react'
import { SvgType } from './index'
const SendCircleSvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="11" cy="11" r="11" fill={color} />
      <path
        d="M15.5739 6.43306C15.3829 6.23834 15.1407 6.10167 14.8752 6.03884C14.6098 5.97602 14.332 5.98962 14.0739 6.07806L6.99893 8.44306C6.71358 8.53646 6.46427 8.71613 6.2854 8.95727C6.10653 9.19842 6.00693 9.48914 6.00035 9.78931C5.99376 10.0895 6.08052 10.3843 6.24864 10.633C6.41676 10.8818 6.65795 11.0722 6.93893 11.1781L9.55893 12.1781C9.61859 12.2009 9.67291 12.2357 9.7185 12.2805C9.76409 12.3252 9.79998 12.3788 9.82393 12.4381L10.8239 15.0631C10.9257 15.3401 11.1104 15.5789 11.3529 15.7471C11.5954 15.9154 11.8838 16.0047 12.1789 16.0031H12.2139C12.5144 15.9976 12.8057 15.8982 13.0469 15.7189C13.2881 15.5396 13.4671 15.2893 13.5589 15.0031L15.9239 7.91806C16.0099 7.66253 16.0227 7.38803 15.9609 7.1256C15.899 6.86317 15.765 6.62328 15.5739 6.43306Z"
        fill="white"
      />
    </svg>
  )
}

export default SendCircleSvg
