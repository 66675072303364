import React from 'react'
import styled from '@emotion/styled'
import { UsuarioModel } from '../models/UsuarioModel'
import { useDecorateBlobWithSas } from '../common/hooks/useDecorateBlobWithSas'

interface AppAvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  usuario: Partial<UsuarioModel>
  tamanho?: number
  circular?: boolean
  imagemPreview?: string | null
}

const AppAvatar = ({
  usuario = {} as any,
  tamanho = 120,
  circular = false,
  imagemPreview,
  ...props
}: AppAvatarProps) => {
  const { helpers } = AppAvatar
  const iniciais = helpers.getIniciais({ nome: usuario.nome, imagemPreview })
  const blobUrl = useDecorateBlobWithSas({ blobPath: usuario.avatar })

  const img = helpers.getImagem({ blobUrl, imagemPreview })
  return (
    <Styles.AvatarWrapper
      backgroundImage={img}
      background={usuario?.cor || '#ff9933'}
      tamanho={tamanho}
      circular={circular}
      {...props}
    >
      {!img && iniciais}
    </Styles.AvatarWrapper>
  )
}
AppAvatar.helpers = (() => {
  function getImagem({
    imagemPreview,
    blobUrl,
  }: {
    imagemPreview?: string | null
    blobUrl?: string | null
  }): string | null {
    if (imagemPreview) return imagemPreview
    if (imagemPreview === '') return null
    if (!blobUrl) return null
    if (blobUrl) return blobUrl
    return null
  }
  function getIniciais({ imagemPreview, nome }) {
    if (imagemPreview) return
    return nome
      ?.split(' ')
      .map((x) => x.charAt(0))
      .slice(0, 2)
      .join('')
      .toUpperCase()
  }
  return {
    getImagem,
    getIniciais,
  }
})()

const Styles = {
  AvatarWrapper: styled('div')<{ backgroundImage; background; tamanho; circular }>(
    (props) => `
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props.background};
    background-image: ${props.backgroundImage ? `url(${props.backgroundImage})` : ''};
    background-size: cover;
    width: 100%;
    height: 100%;
    color: white;
    width: ${props.tamanho}px;
    height: ${props.tamanho}px;
    border-radius: ${props.circular ? '50%' : ''};
    font-size: calc(0.025em * ${props.tamanho});
    `
  ),
}

export { AppAvatar }
