import styled from '@emotion/styled'

interface TimelineItemProps {
  omitLine?: boolean
}
const TimelineItem = styled('div')<TimelineItemProps>(
  (props) => `
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    top: 0px;
    left: -9px;
    width: 10.29px;
    height: 10.29px;
    background-color: #F06F30;
    border: 3px solid #F2F2F2;
    border-radius: 50%;
  }
  border-left: 2px solid ${props.omitLine ? 'transparent;' : '#E5E5E5;'}
  padding-left: 22px;
`
)

export default TimelineItem
