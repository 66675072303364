import React, { useEffect, useMemo, useRef, useState, useCallback, useReducer } from 'react'
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  IonButton,
  IonIcon,
  IonFooter,
  IonModal,
  useIonViewDidEnter,
  IonPage,
  IonFabButton,
  IonFab,
  IonSplitPane,
  IonMenuButton,
} from '@ionic/react'
import { useHistory, useParams, useLocation, Redirect } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { IStateRedux } from '../common/ReduxStore'
import { ConteudoPaginaModel } from '../models/ConteudoPaginaModel'
import styled from '@emotion/styled'
import { CommonStyles, modularize } from '../common/styles/CommonStyles'
import { Show } from '../common/Show'
import { ConteudoItemModel } from '../models/ConteudoItemModel'
import { Questao } from '../questoes/Questao'
import { CorModuloModel } from '../models/CorModuloModel'
import { responsiveSpacing } from '../common/styles/ResponsiveSpacing'
import useTimeout from '../common/hooks/useTimeout'
import useInterval from '../common/hooks/useInterval'
import { validacaoFacialActions } from '../validacao-facial/ValidacaoFacialReduxStore'
import { ValidacaoFacial } from '../validacao-facial/ValidacaoFacial'
import { ValidacaoFacialStore } from '../validacao-facial/ValidacaoFacialStore'
import { ConteudoStore as Store, ConteudoSelectors, ConteudoStore } from './ConteudoStore'
import { progressoActions, TIPO_BLOQUEIO } from './ProgressoReduxStore'
import { ProgressoModel } from '../models/ProgressoModel'
import { time, hourglass, timer, chevronBack, chevronForward, arrowForward } from 'ionicons/icons'
import { toast } from 'react-toastify'
import { selectCursosUsuario, cursosActions } from '../cursos/CursosStore'
import { Indice } from './Indice'
import Mark from 'mark.js'
import Svgs from '../svgs'
import { AcoesPagina } from './AcoesPagina'
import { BlocoNota } from './BlocoNota'
import { boolean } from 'yup'
import { Flex } from '../common/styles/Flex'
import { BlocoNotaModel } from '../models/BlocoNotaModel'
import { Stream } from '@cloudflare/stream-react'
import { useDecorateBlobWithSas } from '../common/hooks/useDecorateBlobWithSas'
import { ModalIniciarTutorial } from '../components'
import { Tutorial } from './Tutorial'
import { GlobalStore } from '../GlobalStore'
import { parseISO } from 'date-fns'
import { useOpenJivoChat } from '../common/hooks/useJivoApi'
import logoNewDriverSmall from '../assets/marca/aplicacao_padrao_small.png'
import { useWindowSize } from '../common/hooks/useWindowSize'

const _conteudoResponsiveSpacing = (attr) => responsiveSpacing(attr, [1800, 1420, 1024])

export interface ConteudoParamsType {
  moduloId
  cursoId
}

const Conteudo = () => {
  const ionContentRef = useRef<any>(null)
  return (
    <Store.Provider>
      <IonContent>
        <IonSplitPane
          contentId="main"
          when="(min-width: 750px)"
          style={{ '--side-max-width': '280px' }}
        >
          <Indice />
          <IonPage id="main">
            <Header />
            <AcoesPagina />
            <ContainerConteudo ionContentRef={ionContentRef}>
              <ListaItens ionContentRef={ionContentRef} />
              <AdicionarComoNota />
            </ContainerConteudo>
            <BlocoNota />
            <Progresso />
          </IonPage>
        </IonSplitPane>
        <Modais>
          <PagamentoPendente />
          <PeriodoMaximoDiario />
          <PeriodoMaximoConsecutivo />
          <ValidacaoFacialConteudo />
          <Inatividade />
          <MatriculaInexistente />
          <ErroGenerico />
        </Modais>
        <>
          <Init ionContentRef={ionContentRef} />
          <IniciaTutorial />
        </>
      </IonContent>
    </Store.Provider>
  )
}

const AdicionarComoNota = () => {
  const { cursoId, moduloId } = useParams<ConteudoParamsType>()
  const { Container } = AdicionarComoNota
  const dispatch = Store.useDispatch()
  const { paginaAtualId, paginas, posicaoSelecaoTexto } = Store.useState()
  const paginaIdUnico = paginas.find((x) => x.id === paginaAtualId)?.identificadorUnico

  function handleClick() {
    const selection = document.getSelection()
    const selectionText = selection?.toString()
    const nota: Partial<BlocoNotaModel> = {
      cursoId: +cursoId,
      moduloId: +moduloId,
      paginaIdUnico,
      referencia: selectionText,
    }
    dispatch(Store.thunks.createOrUpdateNota(nota as BlocoNotaModel))
  }

  if (!posicaoSelecaoTexto) return null

  return (
    <Container
      top={posicaoSelecaoTexto.top}
      left={posicaoSelecaoTexto.left}
      onMouseDown={handleClick}
    >
      <Flex>
        <Svgs.Note color="white" height={14} />
        Adicionar como nota
      </Flex>
    </Container>
  )
}
AdicionarComoNota.Container = styled('div', { label: 'ContainerAdicionarComoNota' })<{ top; left }>(
  (props) => `
  width: 157px;
  height: 30px;
  background: #2D2D2D;
  border-radius: 4px;
  position: absolute;
  top: ${props.top}px;
  left: ${props.left}px;
  font-size: 12px;
  color: white;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: background .35s;
  &:hover {
    background: #4a4a4a;
  }
`
)

const Header = () => {
  const { moduloAtual } = Store.useState()
  return (
    <Styles.ModuloHeader {...moduloAtual?.corModulo}>
      <IonToolbar>
        <IonTitle>
          <div>{moduloAtual?.nome}</div>
        </IonTitle>
        <IonButtons slot="end" className="menu">
          <IonMenuButton id="main" mode="md" />
        </IonButtons>
      </IonToolbar>
    </Styles.ModuloHeader>
  )
}

const ContainerConteudo = ({ children, ionContentRef }) => {
  const state = Store.useState()
  const dispatch = Store.useDispatch()
  const { moduloAtual, videoPlaying, isTopbarActionsVisible } = state
  const reduxDispatch = useDispatch()
  const numeroPaginaAtual = ConteudoSelectors.getNumeroPaginaAtual(state)
  const totalPaginas = ConteudoSelectors.getTotalPaginas(state)
  const ultimaPagina = numeroPaginaAtual === totalPaginas
  const primeiraPagina = numeroPaginaAtual === 1
  const scrollTopDelta = useRef(0)

  const handleScrollEnded = async () => {
    if (videoPlaying) return
    const { conteudoItemId, porcentagem } = await _getConteudoAtualAndPorcentagem(
      ionContentRef.current
    )
    reduxDispatch(progressoActions.setProgressoAtual({ conteudoItemId, porcentagem }))
  }
  const handleScroll = (e) => {
    // se tiver visível, ao rolar pra baixo acima do thresold, deve atualizar o scrolltop e fechar
    // se não tiver visível e rolar pra baixo deve atualizar o scrolltop
    // se tiver visível e rolar pra cima deve atualizar o scrolltop
    // se não tiver visível ao rolar pra cima acima do thresold deve atualizar o scrolltop e abrir
    if (e.detail.deltaY === 0) return
    const { scrollTop } = e.detail
    const direction = e.detail.deltaY <= 0 ? 'UP' : 'DOWN'
    if (direction === 'DOWN' && !isTopbarActionsVisible) {
      scrollTopDelta.current = scrollTop
    } else if (
      direction === 'DOWN' &&
      isTopbarActionsVisible &&
      _efetuouScrollAlemDoLimite(scrollTop, scrollTopDelta.current, 250)
    ) {
      dispatch(Store.actions.closeTopbarActions())
      scrollTopDelta.current = scrollTop
    } else if (direction === 'UP' && isTopbarActionsVisible) {
      scrollTopDelta.current = scrollTop
    } else if (
      direction === 'UP' &&
      !isTopbarActionsVisible &&
      _efetuouScrollAlemDoLimite(scrollTop, scrollTopDelta.current, -40)
    ) {
      dispatch(Store.actions.openTopbarActions())
      scrollTopDelta.current = scrollTop
    }
  }

  return (
    <Styles.ModuloContent
      {...moduloAtual?.corModulo}
      scrollEvents
      onIonScrollEnd={handleScrollEnded}
      onIonScroll={handleScroll}
      ref={ionContentRef}
    >
      <div className="conteudo-container">
        <div className="conteudo">
          {children}
          <div className="buttons-container">
            <Show condition={!primeiraPagina}>
              <BotaoPaginaAnterior />
            </Show>
            <div />
            <Show condition={!ultimaPagina}>
              <BotaoProximaPagina />
            </Show>
            <Show condition={ultimaPagina}>
              <BotaoFinalizarModulo />
            </Show>
          </div>
        </div>
      </div>
    </Styles.ModuloContent>
  )

  function _efetuouScrollAlemDoLimite(scrollTop, scrollTopDelta, threshold) {
    return scrollTop - scrollTopDelta > threshold
  }
}

const BotaoPaginaAnterior = () => {
  const dispatch = Store.useDispatch()
  const { width } = useWindowSize()
  function handleClick() {
    dispatch({ type: 'paginaAnterior' })
  }
  return (
    <Styles.BotaoMudaPagina>
      <IonButton shape="round" color="light" onClick={handleClick}>
        {(width ?? 0) > 425 ? 'Página anterior' : <IonIcon icon={chevronBack} />}
      </IonButton>

      {/* <Button
        onClick={handleClick}
        label={windowWidth > 425 ? 'Página anterior' : ''}
        color="#f2f2f2"
      /> */}
    </Styles.BotaoMudaPagina>
  )
}

const BotaoProximaPagina = () => {
  const dispatch = Store.useDispatch()
  const state = Store.useState()
  const { width } = useWindowSize()
  const paginaAtual = ConteudoSelectors.getNumeroPaginaAtual(state)
  const { bloqueio } = useSelector((s: IStateRedux) => s.progresso)
  function handleClickProximaPagina() {
    if (ConteudoSelectors.getQuestoesNaoRespondidasPagina(state)) {
      return toast('Você precisa responder todas as questões')
    }
    dispatch({ type: 'proximaPagina' })
  }
  const showTextoProximaPagina = (width ?? 0) > 425 || paginaAtual === 1
  if (bloqueio) {
    return (
      <div>
        <div>Existem bloqueios impedindo o avanço do conteúdo.</div>
        <div>Tente atualizar a página ou entrar em contato com o suporte!</div>
      </div>
    )
  }
  return (
    <Styles.BotaoMudaPagina>
      <IonButton shape="round" onClick={handleClickProximaPagina}>
        {showTextoProximaPagina ? 'Próxima página' : <IonIcon icon={chevronForward} />}
      </IonButton>
    </Styles.BotaoMudaPagina>
  )
}

const BotaoFinalizarModulo = () => {
  const { cursoId, moduloId } = useParams<ConteudoParamsType>()
  const state = Store.useState()
  const reduxDispatch = useDispatch()
  const history = useHistory()
  function handleClickFinalizarModulo() {
    if (ConteudoSelectors.getQuestoesNaoRespondidasPagina(state)) {
      return toast('Você precisa responder todas as questões')
    }
    const ultimoConteudoId = _getUltimoConteudoPagina()
    reduxDispatch(
      progressoActions.createProgresso({
        moduloId,
        conteudoItemId: ultimoConteudoId,
        porcentagem: 100,
      })
    )
    history.push(`/curso/${cursoId}/modulo-finalizado/${moduloId}`)
  }
  return (
    <div className="proxima-pagina">
      <IonButton shape="round" size="large" onClick={handleClickFinalizarModulo}>
        Finalizar Módulo &nbsp;
        <IonIcon icon={arrowForward} />
      </IonButton>
    </div>
  )
}

const ListaItens = ({ ionContentRef }) => {
  const { paginas, paginaAtualId, notaAtiva, fontSize } = Store.useState()
  const { helpers } = ListaItens
  const dispatch = Store.useDispatch()
  const ref = useRef<HTMLDivElement>(null)
  const debouncedSelectionChangeTimeout = useRef(null as any)

  const debouncedSelectionChange = useCallback(() => {
    clearTimeout(debouncedSelectionChangeTimeout.current)
    debouncedSelectionChangeTimeout.current = setTimeout(handleSelectionChange, 500)
  }, [handleSelectionChange])

  useEffect(() => {
    document.addEventListener('selectionchange', debouncedSelectionChange)
    return () => document.removeEventListener('selectionchange', debouncedSelectionChange)
  }, [ref.current, debouncedSelectionChange])
  useEffect(() => {
    if (!ref.current || !notaAtiva?.referencia) return
    const markInstance = new Mark(ref.current)
    helpers.markAndScrollIntoView(notaAtiva.referencia, markInstance, ref.current, ionContentRef)
    setTimeout(() => {
      helpers.markAndScrollIntoView(notaAtiva.referencia, markInstance, ref.current, ionContentRef)
    }, 1000)
    return () => markInstance.unmark()
  }, [ref.current, notaAtiva])

  const pagina = useMemo(() => {
    return paginas.find((p) => p.id === paginaAtualId)
  }, [paginas, paginaAtualId])
  const conteudosItens = pagina?.conteudosItens

  if (!conteudosItens || !conteudosItens.length) return null

  const _isSelectionInsideText = (selection) => {
    const selectionText = selection?.toString()
    if (!selection || !selectionText || !ref.current?.parentElement) return false
    if (selectionText.split(' ').map(boolean).length < 2) return false
    const path = _getPath()
    if (path?.some((x) => x.getAttribute('data-conteudo-id'))) return true

    return false

    function _getPath() {
      const path: any[] = []
      let el = selection.getRangeAt(0).startContainer.parentNode
      if (!el?.parentElement) return
      while (el.parentElement) {
        path.push(el)
        el = el.parentElement
      }
      return path
    }
  }

  function handleSelectionChange() {
    const selection = document.getSelection()
    if (!_isSelectionInsideText?.(selection)) return dispatch(Store.actions.removeSelecaoTexto())
    const parentElementsBoundingRects = ref.current?.parentElement?.getBoundingClientRect()
    const selectionBoundingRects = selection?.getRangeAt(0).getBoundingClientRect()
    if (!selectionBoundingRects || !parentElementsBoundingRects) return
    const selectionTop = selectionBoundingRects.top
    const selectionLeft = selectionBoundingRects.left
    const selectionWidth = selectionBoundingRects.width
    const parentElementTop = parentElementsBoundingRects.top
    const parentElementLeft = parentElementsBoundingRects.left
    const top = selectionTop - parentElementTop - 32
    const containerAdicionarNotaSize = 157
    const left =
      selectionLeft - parentElementLeft + selectionWidth / 2 - containerAdicionarNotaSize / 2
    dispatch(Store.actions.changePosicaoSelecaoTexto({ top, left }))
  }

  return (
    <div ref={ref} style={{ fontSize }}>
      {conteudosItens.map((conteudo) => {
        if (conteudo.tipo === 'IMAGEM') return <Imagem key={conteudo.id} conteudo={conteudo} />
        if (conteudo.tipo === 'QUESTAO')
          return (
            <QuestaoContainer key={conteudo.id} conteudo={conteudo} ionContentRef={ionContentRef} />
          )
        if (conteudo.tipo === 'VIDEO') return <Video key={conteudo.id} conteudo={conteudo} />
        if (conteudo.tipo === 'TITULO') return <Titulo key={conteudo.id} conteudo={conteudo} />
        if (conteudo.tipo === 'SUBTITULO')
          return <Subtitulo key={conteudo.id} conteudo={conteudo} />
        return <Texto key={conteudo.id} conteudo={conteudo} />
      })}
    </div>
  )
}
ListaItens.helpers = (() => {
  function markAndScrollIntoView(referencia, markInstance, containerEl, ionContentRef) {
    let markedEl = containerEl.querySelector('mark')
    if (markedEl) return
    markInstance.mark(referencia, { acrossElements: true, separateWordSearch: false })
    markedEl = containerEl.querySelector('mark')
    if (!containerEl || !markedEl) return
    const containerTop = containerEl.getBoundingClientRect().top
    const elTop = markedEl.getBoundingClientRect().top
    ionContentRef?.current?.scrollToPoint(0, Math.abs(containerTop - elTop) - 100, 300)
  }
  return {
    markAndScrollIntoView,
  }
})()

function Progresso() {
  const state = Store.useState()
  const progressoAtual = useSelector((s: IStateRedux) => s.progresso.progressoAtual)
  const progresso = useMemo(
    () => ConteudoSelectors.getDuracaoTotalPaginaAtual(state, progressoAtual),
    [state.paginas, state.paginaAtualId, progressoAtual]
  )

  return (
    <Styles.ProgressoContainer progresso={progresso}>
      <div className="progresso-barra" />
      <div style={{ margin: '0 7px', position: 'relative' }}>
        <div className="progresso-circulo" />
      </div>
    </Styles.ProgressoContainer>
  )
}

const Modais = ({ children }) => {
  const { cursoId } = useParams<ConteudoParamsType>()
  const { bloqueio } = useSelector((s: IStateRedux) => s.progresso)
  if (bloqueio && bloqueio.tipo === TIPO_BLOQUEIO.VALIDACAO_FACIAL_INICIAL) {
    return <Redirect to={`/curso/${cursoId}/validacao-facial`} />
  }
  return (
    <IonModal
      isOpen={!!bloqueio}
      backdropDismiss={false}
      keyboardClose={false}
      swipeToClose={false}
    >
      {children}
    </IonModal>
  )
}

const PagamentoPendente = () => {
  const history = useHistory()
  const { cursoId } = useParams<ConteudoParamsType>()
  const { bloqueio } = useSelector((s: IStateRedux) => s.progresso)

  function handleClickPararEstudar() {
    history.push(`/curso/${cursoId}/modulos`)
  }

  if (!bloqueio || bloqueio.tipo !== TIPO_BLOQUEIO.PAGAMENTO_PENDENTE) return null

  return (
    <Styles.ModalContent>
      <div>
        <IonIcon
          icon={time}
          style={{
            fontSize: '150px',
            color: 'var(--ion-color-warning)',
          }}
        />
        <h2 style={{ fontSize: 30 }}> {bloqueio?.mensagem} </h2>
      </div>
      <div className="botoes-verticais">
        <IonButton onClick={handleClickPararEstudar} size="large" fill="outline">
          Parar de Estudar
        </IonButton>
      </div>
    </Styles.ModalContent>
  )
}

const Inatividade = () => {
  const reduxDispatch = useDispatch()
  const { moduloId, cursoId } = useParams<ConteudoParamsType>()
  const { progressoAtual, bloqueio } = useSelector((s: IStateRedux) => s.progresso)
  const history = useHistory()

  function handleClickContinuarEstudando() {
    reduxDispatch(
      progressoActions.createProgresso({
        moduloId,
        conteudoItemId: progressoAtual.conteudoItemId,
        porcentagem: progressoAtual.porcentagem,
      })
    )
  }

  function handleClickPararEstudar() {
    history.push(`/curso/${cursoId}/modulos`)
  }

  if (!bloqueio || bloqueio.tipo !== TIPO_BLOQUEIO.INATIVIDADE) return null

  return (
    <Styles.ModalContent>
      <div>
        <IonIcon
          icon={time}
          style={{
            fontSize: '150px',
            color: 'var(--ion-color-warning)',
          }}
        />
        <h2 style={{ fontSize: 30 }}> Você ficou inativo por mais do que 5 minutos </h2>
      </div>
      <div className="botoes-verticais">
        <IonButton onClick={handleClickContinuarEstudando} size="large">
          Continuar Estudando
        </IonButton>
        <IonButton onClick={handleClickPararEstudar} size="large" fill="outline">
          Parar de Estudar
        </IonButton>
      </div>
    </Styles.ModalContent>
  )
}

const MatriculaInexistente = () => {
  const { bloqueio } = useSelector((s: IStateRedux) => s.progresso)
  const dispatch = Store.useDispatch()
  const { cursoId } = useParams<ConteudoParamsType>()
  const history = useHistory()
  const { openJivoChat } = useOpenJivoChat()

  function handleClickPararEstudar() {
    history.push(`/curso/${cursoId}/modulos`)
  }

  function handleClickFalarComSuporte() {
    dispatch(Store.actions.openChatTutor())
    openJivoChat({
      customData: [
        { title: 'Local', content: 'Conteúdo' },
        { title: 'Motivo', content: 'Matrícula não encontrada' },
      ],
    })
  }

  if (!bloqueio || bloqueio.tipo !== TIPO_BLOQUEIO.ALUNO_NAO_MATRICULADO) return null

  return (
    <Styles.ModalContent>
      <div>
        <h2>Matrícula não encontrada!</h2>
        <div>Entre em contato com o suporte!</div>
        <div style={{ marginTop: 24 }}>
          <IonButton onClick={handleClickFalarComSuporte} size="large" style={{ width: 320 }}>
            Abrir Chat do Suporte
          </IonButton>
        </div>
        <div style={{ marginTop: 24 }}>
          <IonButton
            onClick={handleClickPararEstudar}
            size="large"
            fill="outline"
            style={{ width: 320 }}
          >
            Parar de Estudar
          </IonButton>
        </div>
      </div>
    </Styles.ModalContent>
  )
}

const ErroGenerico = () => {
  const { bloqueio } = useSelector((s: IStateRedux) => s.progresso)
  const { cursoId } = useParams<ConteudoParamsType>()
  const history = useHistory()
  function handleClickPararEstudar() {
    history.push(`/curso/${cursoId}/modulos`)
  }

  if (!bloqueio || bloqueio.tipo) return null
  return (
    <div>
      Ocorreu um erro ao salvar o progresso!
      <div>{bloqueio.mensagem}</div>
      <IonButton onClick={handleClickPararEstudar} size="large" fill="outline">
        Parar de Estudar
      </IonButton>
    </div>
  )
}

const PeriodoMaximoDiario = () => {
  const { cursoId } = useParams<ConteudoParamsType>()
  const history = useHistory()
  const { bloqueio } = useSelector((s: IStateRedux) => s.progresso)
  if (!bloqueio || bloqueio.tipo !== TIPO_BLOQUEIO.PERIODO_MAXIMO_DIARIO) return null

  return (
    <Styles.ContainerPeriodoMax>
      <div />
      <div className="icone">
        <IonIcon icon={timer} color="danger" />
      </div>
      <div>
        <p>Você pode estudar por no máximo 8 horas por dia.</p>
        <p>Volte aos seus estudos amanhã</p>
      </div>
      <IonButton onClick={() => history.replace(`/curso/${cursoId}/home`)}>
        Fechar o conteúdo
      </IonButton>
    </Styles.ContainerPeriodoMax>
  )
}

const PeriodoMaximoConsecutivo = () => {
  const { cursoId } = useParams<ConteudoParamsType>()
  const reduxDispatch = useDispatch()
  const history = useHistory()
  const { bloqueio } = useSelector((s: IStateRedux) => s.progresso)
  const pausa = bloqueio?.infoAdicional?.periodoRestantePausa
  React.useEffect(() => {
    if (pausa) {
      setTimeout(() => {
        reduxDispatch(progressoActions.validatePermissaoAlunoEstudar(cursoId))
      }, pausa * 1000)
    }
  }, [pausa])
  if (!bloqueio || bloqueio.tipo !== TIPO_BLOQUEIO.PERIODO_MAXIMO_CONSECUTIVO) return null
  return (
    <Styles.ContainerPeriodoMax>
      <div className="icone">
        {' '}
        <IonIcon icon={hourglass} color="warning" />{' '}
      </div>
      <div className="contador-regressivo">
        <ContadorRegressivo tempo={pausa} />
      </div>
      <div>
        <p>Você só pode estudar por 4 horas consecutivas.</p>
        <p>Aguarde 1 hora para continuar seus estudos.</p>
      </div>
      <IonButton onClick={() => history.replace(`/curso/${cursoId}/home`)}>
        Fechar o conteúdo
      </IonButton>
    </Styles.ContainerPeriodoMax>
  )
}

function ContadorRegressivo({ tempo }) {
  const [tempoRestante, setTempoRestante] = useState(tempo)
  useEffect(() => {
    setTempoRestante(Math.max(tempo, 0))
  }, [tempo])
  useInterval(() => {
    setTempoRestante(Math.max(tempoRestante - 1, 0))
  }, 1000)

  const minutos = ('' + ~~(tempoRestante / 60)).padStart(2, '0')
  const segundos = ('' + ~~(tempoRestante % 60)).padStart(2, '0')

  return (
    <>
      {minutos}:{segundos}
    </>
  )
}

function ValidacaoFacialConteudo() {
  const { ContainerTitulo, LogoNewDriver, ValidacaoFacialStyled } = ValidacaoFacialConteudo

  const { bloqueio } = useSelector((s: IStateRedux) => s.progresso)
  const history = useHistory()
  const { cursoId } = useParams<ConteudoParamsType>()

  if (!bloqueio || bloqueio.tipo !== 'VALIDACAO_FACIAL') return null

  const segundosUltimaValidacao =
    new Date(parseISO(bloqueio.infoAdicional?.dataUltimaValidacaoFacial)).getTime() / 1000
  const segundosAgora = new Date().getTime() / 1000
  const segundosDesdeUltimaValidacao = segundosAgora - segundosUltimaValidacao

  let status: 'PRIMEIRA_VALIDACAO_PERIODO' | 'PERIODO_AULA_ENCERRADO' | '' = ''
  if (segundosDesdeUltimaValidacao > 120 * 60) {
    status = 'PRIMEIRA_VALIDACAO_PERIODO'
  } else if (segundosDesdeUltimaValidacao > 50 * 60) {
    status = 'PERIODO_AULA_ENCERRADO'
  }
  return (
    <Styles.ModalContent>
      <Show condition={status === 'PRIMEIRA_VALIDACAO_PERIODO'}>
        <ContainerTitulo>
          <div className="validacao-facial-conteudo-logo">
            <LogoNewDriver src={logoNewDriverSmall} />
          </div>
          <div className="validacao-facial-conteudo-titulo">
            <h2>Uma validação biométrica é necessária antes de iniciar os estudos</h2>
          </div>
          <div className="validacao-facial-conteudo-fechar">
            <IonFabButton
              size="small"
              color="light"
              onClick={() => history.replace(`/curso/${cursoId}/modulos`)}
            >
              X
            </IonFabButton>
          </div>
        </ContainerTitulo>
      </Show>
      <Show condition={status === 'PERIODO_AULA_ENCERRADO'}>
        <ContainerTitulo>
          <div className="validacao-facial-conteudo-logo">
            <LogoNewDriver src={logoNewDriverSmall} />
          </div>
          <div className="validacao-facial-conteudo-titulo">
            <h2>A cada 50 minutos é necessário uma nova validação biométrica</h2>
          </div>
          <div className="validacao-facial-conteudo-fechar">
            <IonFabButton
              size="small"
              color="light"
              onClick={() => history.replace(`/curso/${cursoId}/modulos`)}
            >
              X
            </IonFabButton>
          </div>
        </ContainerTitulo>
      </Show>
      <ValidacaoFacialStyled servico="AZURE">
        <ValidacaoFacial.Success>
          <SucessoValidacao />
        </ValidacaoFacial.Success>
        <ValidacaoFacial.Fechar>
          <FecharValidacao />
        </ValidacaoFacial.Fechar>
      </ValidacaoFacialStyled>
    </Styles.ModalContent>
  )
}
ValidacaoFacialConteudo.ContainerTitulo = styled.div`
  display: grid;
  gap: 18px;
  padding: 18px;
  align-items: center;
  grid-template-columns: 60px 1fr 50px;
  grid-template-areas: 'logo titulo fechar';
  @media (max-width: 450px) {
    grid-template-areas:
      'logo . fechar'
      'titulo titulo titulo';
  }
  .validacao-facial-conteudo-logo {
    grid-area: logo;
  }
  .validacao-facial-conteudo-titulo {
    grid-area: titulo;
  }
  .validacao-facial-conteudo-fechar {
    grid-area: fechar;
  }
  h2 {
    font-size: 22px;
  }
`
ValidacaoFacialConteudo.LogoNewDriver = styled.img`
  height: 60px;
`
ValidacaoFacialConteudo.ValidacaoFacialStyled = styled(ValidacaoFacial)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  @media (max-height: 768px) {
    .react-html5-camera-photo > video {
      max-height: 387px !important;
    }
  }
`

function SucessoValidacao() {
  const reduxDispatch = useDispatch()
  const { ultimaValidacao } = ValidacaoFacialStore.useState()
  const { cursoId } = useParams<ConteudoParamsType>()
  useTimeout(() => {
    reduxDispatch(validacaoFacialActions.changeUltimaValidacao({ ultimaValidacao }))
    reduxDispatch(progressoActions.validatePermissaoAlunoEstudar(cursoId))
  }, 1500)
  return <>Validação efetuada com sucesso</>
}

function FecharValidacao() {
  const { cursoId } = useParams<ConteudoParamsType>()
  const history = useHistory()
  return (
    <div style={{ textAlign: 'right', marginTop: 12 }}>
      <IonButton fill="outline" onClick={() => history.replace(`/curso/${cursoId}/modulos`)}>
        Parar de Estudar
      </IonButton>
    </div>
  )
}

const Init = ({ ionContentRef }) => {
  const { cursoId, moduloId } = useParams<ConteudoParamsType>()
  const { state: locationState } = useLocation<{ notaAbertaId?; paginaIdUnico? }>()
  const dispatch = Store.useDispatch()
  const state = Store.useState()
  const totalPaginas = ConteudoSelectors.getTotalPaginas(state)
  const reduxDispatch = useDispatch()
  const conteudosPagina = ConteudoSelectors.geConteudosPagina(state)
  const { progressoAtual, ultimoProgressoSalvo } = useSelector((s: IStateRedux) => s.progresso)
  const estatisticaEstudo = useSelector((s: IStateRedux) => {
    return s.progresso.estatisticasEstudos.find((x) => '' + x.moduloId === '' + moduloId)
  })
  const estatisticaEstudoCarregada = useSelector((s: IStateRedux) => {
    return !s.progresso.carregandoEstatisticaEstudo
  })
  const [loaded, setLoaded] = useState(false)
  const [loadedNota, setLoadedNota] = useState(false)
  const { helpers } = Init

  /** Inicia, busca as estatisticas e periodos de estudo
   *  @when o moduloId mudar
   */
  useEffect(() => {
    dispatch(Store.thunks.init(moduloId))
    reduxDispatch(progressoActions.getEstatisticasEstudo())
    reduxDispatch(progressoActions.validatePermissaoAlunoEstudar(cursoId))
  }, [moduloId])

  /** Abre nota
   *  Pode ser que tenha recebido uma nota a partir de outra página, mas as notas ainda não tenham sido buscadas
   *  @when receber uma nota a partir de outra página
   */
  useEffect(() => {
    if (loadedNota || !state.notas.length) return
    if (locationState?.notaAbertaId) {
      const nota = helpers.getNotaById(state.notas, locationState.notaAbertaId)
      dispatch(Store.actions.openNota(nota))
      setLoadedNota(true)
    }
  }, [locationState, state.notas])

  /** Carrega a pagina correta e identifica a página como completamente carregada
   *  As estatisticas podem existir ou, na primeira vez, estarem vazias após carregamento
   *  para evitar trocar de página por conta de carregamento de algum dado, existe a variável `loading` para controlar a não necessidade de entrar nesse efeito novamente
   *  @when Receber a página que deve ser aberta a partir de outro local
   */
  useEffect(() => {
    if (loaded || !totalPaginas) return
    if (locationState?.paginaIdUnico) {
      const paginaNumero = helpers.getPaginaNumeroByIdUnico(
        locationState.paginaIdUnico,
        state.paginas
      )
      dispatch({ type: 'setPagina', payload: paginaNumero })
      setTimeout(() => setLoaded(true))
    } else if (estatisticaEstudo) {
      dispatch({ type: 'setPagina', payload: estatisticaEstudo.paginaNumero })
      setLoaded(true)
    } else if (estatisticaEstudoCarregada) {
      setLoaded(true)
    }
  }, [
    estatisticaEstudoCarregada,
    estatisticaEstudo,
    totalPaginas,
    loaded,
    locationState?.paginaIdUnico,
  ])

  /** Rola para o topo da página
   *  Somente após já ter identificado qual a página correta deve-se estar (loaded === true)
   *  @when mudança na pagina atual
   */
  useEffect(() => {
    if (loaded) setTimeout(() => ionContentRef.current?.scrollToTop?.(300), 100)
  }, [state.paginaAtualId])

  /** Salvar progresso ao trocar de página
   *  Cria um progresso no início da página e abre o libras se necessário
   *  @when mudança na página atual
   */
  useEffect(() => {
    if (loaded) {
      const conteudoItemId = conteudosPagina[0]?.id
      _createProgresso({ conteudoItemId, porcentagem: 0 })
    }
    if (document.querySelector('.vw-text')) {
      setTimeout(() => document.querySelector<HTMLDivElement>('[vw-access-button]')?.click())
    }
  }, [state.paginaAtualId])

  /** Armazena em cache o fontSize
   *  @when mudança no fontSize
   */
  useEffect(() => {
    localStorage.setItem('conteudo_font_size', '' + state.fontSize)
  }, [state.fontSize])

  /** Salva progresso de 1 em 1 minuto. Somente se houver progresso novo
   */
  useInterval(() => {
    if (!ultimoProgressoSalvo || progressoAtual.id !== ultimoProgressoSalvo?.id) {
      reduxDispatch(progressoActions.createProgresso({ ...progressoAtual, moduloId } as any))
    }
  }, 1000 * 60)

  function _createProgresso({ conteudoItemId, porcentagem }: Partial<ProgressoModel>) {
    if (!conteudoItemId) return
    reduxDispatch(
      progressoActions.createProgresso({
        moduloId,
        conteudoItemId,
        porcentagem,
      })
    )
  }

  useIonViewDidEnter(() => setLoaded(false))

  return null
}
Init.helpers = (() => {
  function getPaginaNumeroByIdUnico(paginaIdUnico, paginas: ConteudoPaginaModel[]): number {
    return paginas.findIndex((p) => p.identificadorUnico === paginaIdUnico) + 1
  }
  function getNotaById(notas: BlocoNotaModel[], notaId): BlocoNotaModel {
    return notas.find((n) => n.id === notaId)!
  }
  return {
    getPaginaNumeroByIdUnico,
    getNotaById,
  }
})()

function IniciaTutorial() {
  const { Container } = IniciaTutorial
  const { tutoriais } = GlobalStore.useState()
  const dispatch = GlobalStore.useDispatch()

  if (tutoriais.conteudo === 'FINISHED') return null

  if (tutoriais.conteudo === 'NOT_STARTED') {
    return (
      <ModalIniciarTutorial
        titulo="Essa é sua Área de Estudos"
        onStart={() => dispatch(GlobalStore.actions.startTutorial('conteudo'))}
        onEnd={() => dispatch(GlobalStore.actions.endTutorial('conteudo'))}
      >
        <Container>
          Que tal começar com um tutorial pelas principais funcionalidades desta tela?
        </Container>
      </ModalIniciarTutorial>
    )
  }

  return <Tutorial />
}
IniciaTutorial.Container = styled.div`
  margin: 24px auto;
  max-width: 300px;
`

function Imagem({ conteudo }: { conteudo: ConteudoItemModel }) {
  const imagem = useDecorateBlobWithSas({ blobPath: conteudo.conteudo })
  return (
    <Styles.ImagemContainer data-conteudo-id={conteudo.id}>
      <img src={imagem} alt="" />
    </Styles.ImagemContainer>
  )
}

function QuestaoContainer({
  conteudo,
  ionContentRef,
}: {
  conteudo: ConteudoItemModel
  ionContentRef
}) {
  const dispatch = Store.useDispatch()
  useEffect(() => {
    dispatch({ type: 'addQuestaoPagina', payload: +conteudo.conteudo })
    return () => {
      dispatch({ type: 'removeQuestaoPagina', payload: +conteudo.conteudo })
    }
  }, [])
  function handleRespostaCorretaEnviada() {
    dispatch({ type: 'questaoPaginaCorreta', payload: +conteudo.conteudo })
  }

  return (
    <Styles.QuestaoContainer data-conteudo-id={conteudo.id}>
      <Questao
        questaoId={+conteudo.conteudo}
        onRespostaCorretaEnviada={handleRespostaCorretaEnviada}
        ionContentRef={ionContentRef}
        isAvaliacao={false}
      />
    </Styles.QuestaoContainer>
  )
}

function Video({ conteudo }: { conteudo: ConteudoItemModel }) {
  const { getSrcFromConteudo } = Video
  const { moduloId } = useParams<ConteudoParamsType>()
  const reduxDispatch = useDispatch()
  const dispatch = Store.useDispatch()
  const subtitulos = JSON.parse(conteudo.configuracao || '{}').subtitles ?? ''
  const ref = React.useRef<HTMLVideoElement | null>(null)
  const getProgressoByEvent = (event) => {
    const { currentTime } = event.target
    const duration = conteudo.duracao
    const porcentagem = (currentTime / duration) * 100
    return { conteudoItemId: conteudo.id, porcentagem, moduloId }
  }
  function handleProgress(e) {
    reduxDispatch(progressoActions.setProgressoAtual(getProgressoByEvent(e)))
  }
  function handlePause() {
    dispatch({ type: 'setVideoPlaying', payload: false })
  }
  function handlePlay() {
    dispatch({ type: 'setVideoPlaying', payload: true })
  }
  function handleSeeked(e) {
    reduxDispatch(progressoActions.createProgresso(getProgressoByEvent(e)))
  }
  const { bloqueio } = useSelector((s: IStateRedux) => s.progresso)
  React.useEffect(() => {
    if (bloqueio) {
      ref?.current?.pause?.()
    }
  }, [bloqueio])

  const src = getSrcFromConteudo(conteudo.conteudo)

  return (
    <Styles.VideoContainer data-conteudo-id={conteudo.id}>
      <Stream
        controls
        src={src}
        onPause={handlePause}
        onTimeUpdate={handleProgress}
        onPlay={handlePlay}
        streamRef={ref}
        onSeeked={handleSeeked}
      />
    </Styles.VideoContainer>
  )
}
Video.getSrcFromConteudo = (conteudo: string): string => {
  return conteudo.replace(/(https:\/\/videodelivery.net\/)|(\/manifest\/video.m3u8)/g, '')
}

function Texto({ conteudo }: { conteudo: ConteudoItemModel }) {
  const configuracao = JSON.parse(conteudo.configuracao || '{}')
  let estilos: any = {}

  if (configuracao.estilos?.length) {
    estilos = configuracao.estilos.map((x) => {
      return { [x.campo]: x.valor }
    })
  }
  return (
    <Styles.TextoContainer variacao={configuracao.variacao} data-conteudo-id={conteudo.id}>
      <div dangerouslySetInnerHTML={{ __html: conteudo.conteudo }} style={estilos} />
    </Styles.TextoContainer>
  )
}

const Titulo = ({ conteudo }: { conteudo: ConteudoItemModel }) => {
  return (
    <Styles.TextoContainer data-conteudo-id={conteudo.id} variacao="TITULO">
      <div dangerouslySetInnerHTML={{ __html: conteudo.conteudo }} />
    </Styles.TextoContainer>
  )
}
const Subtitulo = ({ conteudo }: { conteudo: ConteudoItemModel }) => {
  return (
    <Styles.TextoContainer data-conteudo-id={conteudo.id} variacao="SUBTITULO">
      <div dangerouslySetInnerHTML={{ __html: conteudo.conteudo }} />
    </Styles.TextoContainer>
  )
}

const Styles = {
  ...CommonStyles,
  ModuloHeader: modularize(IonHeader)(`
    ion-toolbar {
      --background: var(--ion-color-primary);
      font-style: italic;
    }
    .menu {
      @media (min-width: 750px) {
        display: none;
      }
    }
  `),
  ProgressoContainer: styled('div')<{ progresso }>(
    (props) => `
    position: absolute;
    background: #E5E5E5;
    width: 100%;
    bottom: 0;
    .progresso-barra {
      background: var(--ion-color-secondary-shade);
      height: 7px;
      width: ${props.progresso}%;
      transition: width .3s;
    }
    .progresso-circulo {
      background: var(--ion-color-secondary-shade);
      width: 19px;
      height: 19px;
      border-radius: 50%;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
      position: absolute;
      top: -12px;
      transition: left .3s;
      left: calc(${props.progresso}% - 9px);
    }
  `
  ),
  ModuloFooter: modularize(IonFooter)(`
    background: var(--secondary-color);
    --width: 0%;
    .footer-container {
      display: flex;
      align-items: center;
      height: 48px;
      .botoes {
        flex: 1;
        text-align: center;
      }
      .paginas {
        color: rgba(255,255,255,.9);
        font-weight: bold;
        text-align: right;
        margin-right: 32px;
      }
      &>div {
        white-space: nowrap;
      }
    }
  `),
  ModuloContent: styled(IonContent)(
    (props) => `
    ${moduloProps(props as any)}
    position: relative;
    --background: #f7f7f7;
    .conteudo-container {
      background: white;
      padding-bottom: 180px;
    }
    .conteudo {
      position: relative;
      max-width: 1280px;
      padding-top: 48px;
      margin: 0 auto;
      // @media (max-width: 1280px) {
      //   padding-top: calc(18px + 32px);
      // }
      // @media (max-width: 1280px) {
      //   padding-top: calc(18px + 24px);
      // }
      // @media (max-width: 768px) {
      //   padding-top: calc(18px + 18px);
      // }
      // @media (max-width: 425px) {
      //   padding-top: calc(18px + 14px);
      // }
      padding-top: 64px;
      @media(max-width: 845px) {
        padding-top: 44px;
      }
      .buttons-container {
        ${_conteudoResponsiveSpacing('padding')}
        display: flex;
        justify-content: space-between;
      }
    }
    ion-button {
      --background: var(--primary-color);
      --background-focused: var(--primary-color-shade);
      --background-hover: var(--primary-color-shade);
      --color: var(--primary-color-contrast,#fff);
      --background-activated: var(--primary-color-shade,#fff);
    }
    `
  ) as any,
  ImagemContainer: styled('div')`
    ${_conteudoResponsiveSpacing('margin')}
    text-align: center;
  `,
  VideoContainer: styled('div')`
    ${_conteudoResponsiveSpacing('margin')}
  `,
  QuestaoContainer: styled('div')`
    @media (min-width: 750px) {
      ${_conteudoResponsiveSpacing('padding')}
    }
    @media (max-width: 749px) {
      border-top: 1px solid #d9d9d9;
    }
  `,
  ColunasContainer: styled('div')``,
  TextoContainer: styled('div')<{ variacao }>(
    (props) => `
    font-size: 18em;
    line-height: 1.5em;
    font-family: 'Rubik', serif;
    color: #222;
    text-align: justify;
    ${_conteudoResponsiveSpacing('margin')}
    @media (max-width: 425px) {
      font-size: 14em
      line-height: 1.5em;
    }
    ${
      props.variacao === 'TITULO'
        ? `
      font-family: Rubik;
      font-weight: bold;
      font-size: 32em;
      border-bottom: 3px solid var(--primary-color);
      text-align: left;
      @media (max-width: 425px) {
        font-size: 28em;
      }
    `
        : ''
    }
    ${
      props.variacao === 'SUBTITULO'
        ? `
      font-family: Rubik;
      font-weight: bold;
      font-size: 24em;
      @media (max-width: 425px) {
        font-size: 21em;
      }
    `
        : ''
    }
    ul {
      margin: 0;
    }
  `
  ),
  ContainerPeriodoMax: styled('div')(
    () => `
    font-family: 'Rubik', serif;
    padding: 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    text-align: center;
    ion-icon {
      font-size: 10em;
    }
    p {
      font-size: 21px;
    }
    .contador-regressivo {
      font-size: 40px;
    }
  `
  ),
  ModalContent: styled('div')(
    () => `
    font-family: 'Rubik', serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    align-items: center;
    h2 {
      font-size: 27px;
    }
    h3 {
      color: #444;
      font-size: 19px;
    }
    .botoes-verticais {
      display: flex;
      flex-direction: column;
    }
  `
  ),
  BlocoNotaContainer: styled('div')<{ pontoPagina }>(
    ({ pontoPagina }) => `
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    padding: 12px;
    right: 0;
    top: calc(${pontoPagina}% + 30px);
    display: flex;
    flex-direction: column;
    min-width: 240px;
    width: 30vw;
    min-height: 120px;
    height: 15vh;
    input, textarea {
      border: 0;
      width: 100%;
      padding: 8px;
      outline: none;
    }
    input {
      border-bottom: 1px solid #ccc;
      font-size: 19em;
    }
    textarea {
      flex: 1;
      margin-top: 6px;
    }
    .close-button {
      position: absolute;
      right: 0px;
      top: -30px;
    }
    .save-button {
      position: absolute;
      right: 0px;
      bottom: -30px;
    }
  `
  ),
  IonFab: styled(IonFab)`
    bottom: 62px;
    right: 12px;
  `,
  NotaSalvaIcon: styled('div')<{ pontoPagina }>(
    ({ pontoPagina }) => `
    position: absolute;
    right: 0px;
    top: calc(${pontoPagina}%);
  `
  ),
  AreaNovaNota: styled('div')`
    position: absolute;
    width: 60px;
    height: 100%;
    right: 0px;
    top: 0;
  `,
  IonFabButtonNovaNota: styled(IonFabButton)`
    opacity: 0;
    transition: opacity 0.2s;
    &:hover {
      opacity: 1;
    }
  `,
  Container: styled('div', { label: 'Container' })`
    display: grid;
    height: 100%;
    grid-template-columns: 234px 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'indice container-conteudo';
    @media (max-width: 750px) {
      grid-template-areas: 'container-conteudo';
      grid-template-columns: 1fr;
    }
    .container-conteudo {
      grid-area: container-conteudo;
      position: relative;
    }
    .indice {
      grid-area: indice;
    }
  `,
  SairConteudo: styled('div')<{ variation }>((props) => {
    let computedStyle = `
      ion-button {
        --border-width: 1px;
      }
    `

    if (props.variation === 'MOBILE') {
      computedStyle += `
      text-align: center;
      margin-top: 19px
    `
    }

    if (props.variation === 'DESKTOP') {
      computedStyle += `
      ${_conteudoResponsiveSpacing('padding')}
      padding-top: 0;
      padding-bottom: 0;
      text-align: right;
    `
    }

    return computedStyle
  }),
  PaginaAtual: styled('div')`
    ${_conteudoResponsiveSpacing('padding')}
    padding-bottom: 0px !important;
    font-size: 14px;
    @media (max-width: 425px) {
      font-size: 12px;
    }
  `,
  BotaoMudaPagina: styled('div')``,
}

function moduloProps(props: CorModuloModel) {
  return `
    --primary-color: ${props.corPrimaria};
    --primary-color-shade: ${props.corPrimariaSombra};
    --secondary-color: ${props.corSecundaria};
    --url-image: ${props.imagem};
  `
}

async function _getConteudoAtualAndPorcentagem(ionContentElement) {
  const scrollElement = await ionContentElement.getScrollElement()
  if (scrollElement.scrollHeight - scrollElement.offsetHeight - scrollElement.scrollTop < 125) {
    const lastConteudo = Array.from(
      document.querySelectorAll('.conteudo > div > [data-conteudo-id]')
    ).pop()
    const conteudoItemId = lastConteudo?.getAttribute('data-conteudo-id')
    return { conteudoItemId, porcentagem: 100 }
  } else {
    let firstVisibleElement = Array.from(
      document.querySelectorAll('.conteudo > div > [data-conteudo-id]')
    ).find((conteudo) => {
      return conteudo.getBoundingClientRect().bottom > 0 && !!conteudo.innerHTML
    })

    if (!firstVisibleElement)
      firstVisibleElement = Array.from(
        document.querySelectorAll('.conteudo > div > [data-conteudo-id]')
      ).pop()
    const conteudoItemId = firstVisibleElement?.getAttribute('data-conteudo-id')
    const { bottom, height } = firstVisibleElement?.getBoundingClientRect() || {
      bottom: 0,
      height: 0,
    }
    const porcentagem = 100 - Math.min(100, (bottom / height) * 100)
    return { conteudoItemId, porcentagem }
  }
}

function _getTempoDesdeUltimoProgresso(progresso: ProgressoModel | null) {
  if (!progresso) return Infinity
  const segundosUltimoProgresso = new Date(progresso.createdAt).getTime() / 1000
  const segundosAgora = new Date().getTime() / 1000
  return segundosAgora - segundosUltimoProgresso
}

function _getUltimoConteudoPagina() {
  const lastConteudo = Array.from(document.querySelectorAll('.conteudo [data-conteudo-id]')).pop()
  return lastConteudo?.getAttribute('data-conteudo-id')
}

export { Conteudo }
