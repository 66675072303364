import React from 'react'
import { SvgType } from './index'
const BooksSvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.47 16.8199L20.47 12.9599L17.32 1.36991C17.2519 1.11425 17.0853 0.895982 16.8567 0.762908C16.628 0.629833 16.3559 0.592796 16.1 0.659908L12.23 1.65991C12.1376 1.55755 12.0251 1.4754 11.8995 1.41861C11.7739 1.36182 11.6379 1.33162 11.5 1.32991H1.5C1.23478 1.32991 0.98043 1.43527 0.792893 1.6228C0.605357 1.81034 0.5 2.06469 0.5 2.32991V18.3299C0.5 18.5951 0.605357 18.8495 0.792893 19.037C0.98043 19.2246 1.23478 19.3299 1.5 19.3299H11.5C11.7652 19.3299 12.0196 19.2246 12.2071 19.037C12.3946 18.8495 12.5 18.5951 12.5 18.3299V10.3299L14.7 18.5499C14.7586 18.7681 14.8893 18.96 15.0709 19.0943C15.2525 19.2287 15.4742 19.2976 15.7 19.2899C15.7864 19.2997 15.8736 19.2997 15.96 19.2899L20.79 17.9999C20.9177 17.9658 21.0374 17.9067 21.1421 17.826C21.2468 17.7453 21.3344 17.6447 21.4 17.5299C21.5055 17.3087 21.5302 17.0575 21.47 16.8199ZM5.47 17.3699H2.47V15.3699H5.47V17.3699ZM5.47 13.3699H2.47V7.36991H5.47V13.3699ZM5.47 5.36991H2.47V3.36991H5.47V5.36991ZM10.47 17.3699H7.47V15.3699H10.47V17.3699ZM10.47 13.3699H7.47V7.36991H10.47V13.3699ZM10.47 5.36991H7.47V3.36991H10.47V5.36991ZM12.72 3.62991L15.62 2.84991L16.14 4.77991L13.24 5.55991L12.72 3.62991ZM15.31 13.2899L13.76 7.48991L16.66 6.70991L18.21 12.5099L15.31 13.2899ZM16.31 17.1499L15.79 15.2199L18.69 14.4399L19.21 16.3699L16.31 17.1499Z"
        fill={color}
      />
    </svg>
  )
}

export default BooksSvg
