export const ESTADOS: { [key: string]: { nome: string; hifenizado: string; url: string } } = {
  AC: { nome: 'Acre', hifenizado: 'acre', url: 'https://newdriver.com.br/acre' },
  AL: { nome: 'Alagoas', hifenizado: 'alagoas', url: 'https://newdriver.com.br/alagoas' },
  AM: { nome: 'Amazonas', hifenizado: 'amazonas', url: 'https://newdriver.com.br/amazonas' },
  AP: { nome: 'Amapá', hifenizado: 'amapa', url: 'https://newdriver.com.br/amapa' },
  BA: { nome: 'Bahia', hifenizado: 'bahia', url: 'https://newdriver.com.br/bahia' },
  CE: { nome: 'Ceará', hifenizado: 'ceara', url: 'https://newdriver.com.br/ceara' },
  DF: {
    nome: 'Distrito Federal',
    hifenizado: 'distrito-federal',
    url: 'https://newdriver.com.br/distrito-federal',
  },
  ES: {
    nome: 'Espírito Santo',
    hifenizado: 'espirito-santo',
    url: 'https://newdriver.com.br/espirito-santo',
  },
  GO: { nome: 'Goiás', hifenizado: 'goias', url: 'https://newdriver.com.br/goias' },
  MA: { nome: 'Maranhão', hifenizado: 'maranhao', url: 'https://newdriver.com.br/maranhao' },
  MG: {
    nome: 'Minas Gerais',
    hifenizado: 'minas-gerais',
    url: 'https://newdriver.com.br/minas-gerais',
  },
  MT: {
    nome: 'Mato Grosso',
    hifenizado: 'mato-grosso',
    url: 'https://newdriver.com.br/mato-grosso',
  },
  MS: {
    nome: 'Mato Grosso do Sul',
    hifenizado: 'mato-grosso-do-sul',
    url: 'https://newdriver.com.br/mato-grosso-do-sul',
  },
  PA: { nome: 'Pará', hifenizado: 'para', url: 'https://newdriver.com.br/para' },
  PB: { nome: 'Paraíba', hifenizado: 'paraiba', url: 'https://newdriver.com.br/paraiba' },
  PE: {
    nome: 'Pernambuco',
    hifenizado: 'pernambuco',
    url: 'https://www.newdriver.com.br/pernambuco',
  },
  PI: { nome: 'Piauí', hifenizado: 'piaui', url: 'https://newdriver.com.br/piaui' },
  PR: { nome: 'Paraná', hifenizado: 'parana', url: 'https://newdriver.com.br/parana' },
  RJ: {
    nome: 'Rio de Janeiro',
    hifenizado: 'rio-de-janeiro',
    url: 'https://newdriver.com.br/rio-de-janeiro',
  },
  RN: {
    nome: 'Rio Grande do Norte',
    hifenizado: 'rio-grande-do-norte',
    url: 'https://newdriver.com.br/rio-grande-do-norte',
  },
  RO: { nome: 'Rondônia', hifenizado: 'rondonia', url: 'https://newdriver.com.br/rondonia' },
  RR: { nome: 'Roraima', hifenizado: 'roraima', url: 'https://newdriver.com.br/roraima' },
  RS: {
    nome: 'Rio Grande do Sul',
    hifenizado: 'rio-grande-do-sul',
    url: 'https://newdriver.com.br/rio-grande-do-sul',
  },
  SC: {
    nome: 'Santa Catarina',
    hifenizado: 'santa-catarina',
    url: 'https://newdriver.com.br/santa-catarina',
  },
  SE: { nome: 'Sergipe', hifenizado: 'sergipe', url: 'https://newdriver.com.br/sergipe' },
  SP: { nome: 'São Paulo', hifenizado: 'sao-paulo', url: 'https://newdriver.com.br/sao-paulo' },
  TO: { nome: 'Tocantins', hifenizado: 'tocantins', url: 'https://newdriver.com.br/tocantins' },
}

export enum ETAPAS {
  CURSO = 0,
  DADOS_ALUNO = 1,
  CONTATO = 2,
  PAGAMENTO = 3,
  SUCESSO = 4,
}
