import React from 'react'
import { Redirect } from 'react-router'
import * as svgs from './index'

export const SvgsPreview = () => {
  if (process.env.NODE_ENV === 'production') return <Redirect to="/" />

  const previews = Object.keys(svgs.default).map((s) => ({
    svg: svgs.default[s]({ color: 'rgb(255, 153, 51)' }),
    nome: s,
  }))
  return (
    <div style={{ overflow: 'auto', background: '#efefef' }}>
      {previews
        .sort((a, b) => (a.nome > b.nome ? 1 : -1))
        .map((p, i) => (
          <div key={i} style={{ borderBottom: '1px solid #e0e0e0' }}>
            {p.nome}: {p.svg}
          </div>
        ))}
    </div>
  )
}
