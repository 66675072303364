import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { EstatisticaEstudoModel } from '../models/EstatisticaEstudoModel'
import Axios from 'axios'
import { ProgressoModel } from '../models/ProgressoModel'
import { IStateRedux } from '../common/ReduxStore'
import { formatISO } from 'date-fns/esm'

const initialState = {
  carregandoEstatisticaEstudo: false,
  estatisticasEstudos: [] as EstatisticaEstudoModel[],
  progressoAtual: {} as Partial<ProgressoModel>,
  ultimoProgressoSalvo: null as ProgressoModel | null,
  bloqueio: null as null | {
    tipo: TIPO_BLOQUEIO
    mensagem: string
    infoAdicional: any
  },
}

const { reducer: progressoReducer, actions } = createSlice({
  name: 'progresso',
  initialState,
  reducers: {
    setBloqueio(state, { payload }) {
      state.bloqueio = {
        mensagem: payload.message,
        tipo: payload.tipo,
        infoAdicional: payload.infoAdicional,
      }
    },
    getEstatisticasEstudoStarted(state) {
      state.carregandoEstatisticaEstudo = true
    },
    getEstatisticasEstudoSuccess(state, { payload }: PayloadAction<{ estatisticasEstudos }>) {
      state.estatisticasEstudos = payload.estatisticasEstudos
      state.carregandoEstatisticaEstudo = false
    },
    getEstatisticasEstudoError(state) {
      state.carregandoEstatisticaEstudo = false
    },
    setProgressoAtual(state, { payload }: PayloadAction<{ conteudoItemId; porcentagem }>) {
      const { conteudoItemId, porcentagem } = payload
      state.progressoAtual = { conteudoItemId, porcentagem }
    },
    atualizaEstatisticaEstudo(
      state,
      {
        payload,
      }: PayloadAction<{
        progresso
        estatisticaEstudo
        moduloId
      }>
    ) {
      state.ultimoProgressoSalvo = payload.progresso
      state.progressoAtual = payload.progresso
      state.bloqueio = null
      if (state.estatisticasEstudos.some((x) => '' + x.moduloId === '' + payload.moduloId)) {
        state.estatisticasEstudos = state.estatisticasEstudos.map((e) => {
          return '' + e.moduloId !== '' + payload.moduloId ? e : payload.estatisticaEstudo
        })
      } else {
        state.estatisticasEstudos = [...state.estatisticasEstudos, payload.estatisticaEstudo]
      }
    },
    updateProgressoAtual(state, { payload }: PayloadAction<{ conteudoItemId; paginaNumero }>) {
      state.progressoAtual.conteudoItemId = payload.conteudoItemId
      if (state.estatisticasEstudos[0]) {
        state.estatisticasEstudos[0].paginaNumero = payload.paginaNumero
      }
    },
    loadEstatisticasEstudoSuccess(state, { payload }: PayloadAction<{ estatisticasEstudo }>) {
      state.estatisticasEstudos = payload.estatisticasEstudo
    },
    validatePermissaoAlunoEstudarSuccess(state) {
      state.bloqueio = null
    },
  },
})

const progressoActions = {
  ...actions,
  loadEstatisticasEstudo(cursoId: number) {
    return async (dispatch, getState) => {
      const state: IStateRedux = getState()
      // if (state.progresso.estatisticasEstudos.length) return
      try {
        const estatisticasEstudo = await Axios.get(
          `/app/progressos/estatisticas-estudos/aluno?cursoId=${cursoId}`
        ).then((x) => x.data)
        dispatch(actions.loadEstatisticasEstudoSuccess({ estatisticasEstudo }))
      } catch (error: any) {}
    }
  },
  getEstatisticasEstudo() {
    return async (dispatch) => {
      try {
        dispatch(actions.getEstatisticasEstudoStarted())
        const estatisticasEstudos = await Axios.get(
          '/app/progressos/estatisticas-estudos/aluno'
        ).then((x) => x.data)

        dispatch(actions.getEstatisticasEstudoSuccess({ estatisticasEstudos }))
      } catch (e) {
        dispatch(actions.getEstatisticasEstudoError())
      }
    }
  },
  validatePermissaoAlunoEstudar(cursoId) {
    return async (dispatch) => {
      try {
        await Axios.post('/app/progressos/valida-permissao-estudar', { cursoId }).then(
          (x) => x.data
        )
        dispatch(actions.validatePermissaoAlunoEstudarSuccess())
      } catch (error: any) {
        dispatch(
          actions.setBloqueio({
            mensagemBloqueio: error.response?.data?.message,
            tipo: error.response?.data?.type,
            infoAdicional: error.response?.data?.additionalInfo,
          })
        )
      }
    }
  },
  createProgresso({ moduloId, conteudoItemId, porcentagem }) {
    return async (dispatch) => {
      if (!conteudoItemId || !moduloId) return
      try {
        const retornoProgresso = await Axios.post('/app/progressos', {
          conteudoItemId,
          porcentagem,
        }).then((x) => x.data)

        const { estatisticaEstudo, progresso } = retornoProgresso
        dispatch(
          actions.atualizaEstatisticaEstudo({
            progresso,
            estatisticaEstudo,
            moduloId,
          })
        )
      } catch (error: any) {
        dispatch(
          actions.setBloqueio({
            mensagemBloqueio: error.response?.data?.message,
            tipo: error.response?.data?.type,
            infoAdicional: error.response?.data?.additionalInfo,
          })
        )
      }
    }
  },
}

export enum TIPO_BLOQUEIO {
  ALUNO_NAO_MATRICULADO = 'ALUNO_NAO_MATRICULADO',
  PAGAMENTO_PENDENTE = 'PAGAMENTO_PENDENTE',
  PERIODO_MAXIMO_DIARIO = 'PERIODO_MAXIMO_DIARIO',
  PERIODO_MAXIMO_CONSECUTIVO = 'PERIODO_MAXIMO_CONSECUTIVO',
  VALIDACAO_FACIAL = 'VALIDACAO_FACIAL',
  VALIDACAO_FACIAL_INICIAL = 'VALIDACAO_FACIAL_INICIAL',
  INATIVIDADE = 'INATIVIDADE',
}

export type IState = typeof initialState
export { progressoReducer, progressoActions }
