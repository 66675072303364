import React, { useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { IonToolbar, IonButtons, IonTitle } from '@ionic/react'
import styled from '@emotion/styled'
import CloseSvg from '../svgs/close'

interface NDModalProps extends React.HTMLAttributes<HTMLDivElement> {
  isOpen: boolean
  title?: string
  children: React.ReactNode
  closeModal: any
  backdropDismiss?: boolean
}
const Modal = ({ isOpen, title, children, closeModal, backdropDismiss, ...rest }: NDModalProps) => {
  const modalContainerEl = document.getElementById('modal-nd')
  const { Layout, DialogBox, Backdrop, Header, Heading, CloseButton, Content } = Modal

  if (isOpen) {
    return createPortal(
      <Layout>
        <Backdrop onClick={backdropDismiss && closeModal} />
        <DialogBox {...rest}>
          <Header>
            <Heading>{title && title}</Heading>
            <CloseButton onClick={closeModal}>
              <CloseSvg color="#000" />
            </CloseButton>
          </Header>
          <Content>{children && children}</Content>
        </DialogBox>
      </Layout>,
      modalContainerEl!
    )
  }
  return null
}
Modal.Layout = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
Modal.DialogBox = styled('div')`
  height: fit-content;
  z-index: 2000;
  overflow: hidden;
  border-radius: 8px;
  max-width: 467px;
  width: fill-available;

  @media (max-width: 585px) {
    max-width: 90vw;
  }
`
Modal.Backdrop = styled('div')`
  background-color: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
Modal.Header = styled('div')`
  padding: 18px 9px 9px 9px;
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr;
  background-color: #fff;
  color: #000;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`
Modal.CloseButton = styled('div')`
  cursor: pointer;
  grid-column: 3 / 4;
  width: 34px;
  height: 34px;
  display: flex;
  margin-right: 3px;
  justify-self: end;
`
Modal.Heading = styled('h4')`
  justify-self: center;
  grid-column: 2 / 3;
  margin: 0;
  color: #000;
  font-weight: 700;
`
Modal.Content = styled('div')`
  padding: 1px 18px 18px 18px;
  background-color: #fff;
  width: auto;
  height: auto;
`

export default Modal
