import React, { useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from '@emotion/styled'
import Card from '../components/Card'

interface TableParams {
  cursoId: string
}

const Table = (props) => {
  const { Tabela } = Table
  const { colunas, dataSource } = props
  return (
    <TableCard>
      <Tabela>
        <TableHeader colunas={colunas} />
        <TableBody colunas={colunas} dataSource={dataSource} />
      </Tabela>
    </TableCard>
  )
}

Table.Tabela = styled('table')`
  width: 1024px;
  border: 1px solid #d9d9d9;
  border-right: none;
  text-align: left;

  table,
  th,
  td {
    border: 1px solid #d9d9d9;
    border-left: none;
    border-right: none;
    text-align: left;
  }

  th {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #2d2d2d;
  }
`

const TableHeader = ({ colunas }) => {
  return (
    <thead>
      <tr style={{ padding: '3px' }}>
        {colunas.map((coluna) => (
          <th key={coluna.dataIndex} style={{ padding: '10px', width: coluna.width }}>
            {coluna.title}
          </th>
        ))}
        <th style={{ padding: '10px' }}> {'   '} </th>
      </tr>
    </thead>
  )
}

const TableBody = ({ colunas, dataSource }) => {
  const { cursoId } = useParams<TableParams>()
  const history = useHistory()
  const goGabarito = (id) => {
    history.push(`/curso/${cursoId}/gabarito/${id}`)
  }
  return (
    <tbody>
      {dataSource.map((data, index) => {
        return (
          <tr key={index} style={{ padding: '3px' }}>
            {colunas.map((coluna) => (
              <td key={coluna.dataIndex} style={{ padding: '10px' }}>
                {data[coluna.dataIndex]}
              </td>
            ))}
            <td>
              <a style={{ cursor: 'pointer' }} onClick={() => goGabarito(data.id)}>
                {' '}
                Gabarito{' '}
              </a>
            </td>
          </tr>
        )
      })}
    </tbody>
  )
}

const TableCard = styled(Card)`
  width: 1024px;
  padding: 0px;
`

export { Table }
