import React from 'react'

const LaptopSvg = ({ color = '#FF9933' }) => {
  return (
    <svg width="16" height="14" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.72241 14.0037H18.7224C19.5505 14.0028 20.2216 13.3317 20.2224 12.5037V2.00366C20.2216 1.17558 19.5505 0.504489 18.7224 0.503662H3.72241C2.89433 0.504489 2.22324 1.17558 2.22241 2.00366V12.5037C2.22365 13.3316 2.8945 14.0024 3.72241 14.0037ZM18.7224 2.00366V12.5037H3.72241V2.00366H18.7224ZM21.7224 15.5037H0.722412V17.0037H21.7224V15.5037Z"
        fill="white"
      />
    </svg>
  )
}

export default LaptopSvg
