import React from 'react'
import styled from '@emotion/styled'
import { Button as ButtonDefault } from '../components'
import { keyframes } from '@emotion/react'
import Svgs from '../svgs'

const TimelineItemNumber = styled('div')<{ omitLine: boolean; number: number }>(
  (props) => `
  position: relative;
  &:after {
    content: '${props.number}';
    position: absolute;
    top: 0px;
    left: -24.5px;
    width: 39px;
    height: 39px;
    background-color: white;
    border: 3px solid #F06F30;
    color: #F06F30;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    @media (max-width: 590px) {
      font-size: 14px;
      width: 20px;
      height: 20px;
      border: 1px solid #F06F30;
      left: -11.5px;
    }
  }
  border-left: 2px solid ${props.omitLine ? 'transparent;' : '#F06F30;'}
  padding-left: 33px;
  padding-bottom: ${props.omitLine ? '0' : '38px'};
  margin-left: 24.5px;
  @media (max-width: 590px) {
    padding-left: 20px;
    border-left-width: 1px;
  }
`
)

const Spin = ({ children, loading, message }) => {
  const { Container, Layout } = Spin
  if (!loading) return children
  return (
    <Layout>
      <Container>
        <Svgs.LoadingSpinner width={66} height={66} className="rotating" color="#F06F30" />
        <div style={{ marginTop: 32 }}> {message} </div>
      </Container>
      {children}
    </Layout>
  )
}
const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`
Spin.Layout = styled.div`
  position: relative;
`
Spin.Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #4f4f4f;
  .rotating {
    animation: ${rotate} 1s cubic-bezier(0.29, 0.46, 0.65, 0.39) infinite;
  }
`

const Button = styled(ButtonDefault)`
  @media (min-width: 458px) {
    height: 55px;
    min-width: 200px;
    border-radius: 8px;
    .label {
      font-weight: bold;
      font-size: 18px;
    }
  }
`

const ContainerButtons = styled.div`
  margin-top: 38px;
  display: flex;
  justify-content: center;
  & > *:first-child {
    margin-right: 28px;
  }
  @media (max-width: 458px) {
    justify-content: center;
    & > *:first-child {
      margin-right: 12px;
    }
  }
`

export { Button, ContainerButtons, Spin, TimelineItemNumber }
