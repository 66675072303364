import React from 'react'
import { SvgType } from './index'
const GraphBarsSvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="22" y="3" width="6" height="43" fill="#FFE6CF" />
      <path
        d="M11.5 27.25H2.5C1.90326 27.25 1.33097 27.4871 0.90901 27.909C0.487053 28.331 0.25 28.9033 0.25 29.5V47.5C0.25 48.0967 0.487053 48.669 0.90901 49.091C1.33097 49.5129 1.90326 49.75 2.5 49.75H11.5C12.0967 49.75 12.669 49.5129 13.091 49.091C13.5129 48.669 13.75 48.0967 13.75 47.5V29.5C13.75 28.9033 13.5129 28.331 13.091 27.909C12.669 27.4871 12.0967 27.25 11.5 27.25ZM9.25 45.25H4.75V31.75H9.25V45.25ZM47.5 18.25H38.5C37.9033 18.25 37.331 18.4871 36.909 18.909C36.487 19.331 36.25 19.9033 36.25 20.5V47.5C36.25 48.0967 36.487 48.669 36.909 49.091C37.331 49.5129 37.9033 49.75 38.5 49.75H47.5C48.0967 49.75 48.669 49.5129 49.091 49.091C49.5129 48.669 49.75 48.0967 49.75 47.5V20.5C49.75 19.9033 49.5129 19.331 49.091 18.909C48.669 18.4871 48.0967 18.25 47.5 18.25ZM45.25 45.25H40.75V22.75H45.25V45.25ZM29.5 0.25H20.5C19.9033 0.25 19.331 0.487053 18.909 0.90901C18.4871 1.33097 18.25 1.90326 18.25 2.5V47.5C18.25 48.0967 18.4871 48.669 18.909 49.091C19.331 49.5129 19.9033 49.75 20.5 49.75H29.5C30.0967 49.75 30.669 49.5129 31.091 49.091C31.5129 48.669 31.75 48.0967 31.75 47.5V2.5C31.75 1.90326 31.5129 1.33097 31.091 0.90901C30.669 0.487053 30.0967 0.25 29.5 0.25ZM27.25 45.25H22.75V4.75H27.25V45.25Z"
        fill="#F06F30"
      />
    </svg>
  )
}

export default GraphBarsSvg
