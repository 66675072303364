import React from 'react'
import { useSelector } from 'react-redux'
import { getUsuarioLogado } from '../../auth/AuthStore'

export interface CustomDataType {
  /**
   * Content of data field. Tags will be insulated
   */
  content?: string
  /**
   * Title shown above a data field
   */
  title?: string
  /**
   * URL that opens when you click on a data field
   */
  link?: string
  /**
   * Description of the data field, bold text before a colon
   */
  key?: string
}

export const useOpenJivoChat = () => {
  const usuario = useSelector(getUsuarioLogado)

  const openJivoChat = ({ customData }: { customData?: CustomDataType[] }) => {
    const jivoApi = window.jivo_api
    if (!jivoApi) return
    jivoApi.open()
    jivoApi.setContactInfo({ name: usuario.nome, email: usuario.email })
    if (customData?.length) jivoApi.setCustomData(customData)
  }

  return {
    openJivoChat,
  }
}
