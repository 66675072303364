import React from 'react'
import { SvgType } from './index'
const PencilLineSvg = ({ color = '#F06F30', ...props }: SvgType) => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.1665 30.0002H13.9398C14.1811 30.0016 14.4203 29.9554 14.6437 29.8641C14.867 29.7729 15.0702 29.6385 15.2415 29.4685L27.9282 16.7635L33.1348 11.6669C33.3067 11.4964 33.4431 11.2937 33.5361 11.0703C33.6292 10.8469 33.6771 10.6072 33.6771 10.3652C33.6771 10.1232 33.6292 9.88357 33.5361 9.66016C33.4431 9.43675 33.3067 9.23398 33.1348 9.06355L25.3615 1.19855C25.1911 1.02671 24.9883 0.890322 24.7649 0.797246C24.5415 0.70417 24.3019 0.65625 24.0598 0.65625C23.8178 0.65625 23.5782 0.70417 23.3548 0.797246C23.1314 0.890322 22.9286 1.02671 22.7582 1.19855L17.5882 6.38688L4.86484 19.0919C4.69492 19.2632 4.56049 19.4664 4.46926 19.6897C4.37802 19.9131 4.33178 20.1523 4.33317 20.3935V28.1669C4.33317 28.6531 4.52633 29.1194 4.87014 29.4632C5.21396 29.8071 5.68027 30.0002 6.1665 30.0002ZM24.0598 5.08521L29.2482 10.2735L26.6448 12.8769L21.4565 7.68855L24.0598 5.08521ZM7.99984 21.1452L18.8715 10.2735L24.0598 15.4619L13.1882 26.3335H7.99984V21.1452ZM35.4998 33.6669H2.49984C2.01361 33.6669 1.54729 33.86 1.20347 34.2039C0.859658 34.5477 0.666504 35.014 0.666504 35.5002C0.666504 35.9864 0.859658 36.4528 1.20347 36.7966C1.54729 37.1404 2.01361 37.3335 2.49984 37.3335H35.4998C35.9861 37.3335 36.4524 37.1404 36.7962 36.7966C37.14 36.4528 37.3332 35.9864 37.3332 35.5002C37.3332 35.014 37.14 34.5477 36.7962 34.2039C36.4524 33.86 35.9861 33.6669 35.4998 33.6669Z"
        fill={color}
      />
    </svg>
  )
}

export default PencilLineSvg
