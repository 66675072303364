import React from 'react'
import styled from '@emotion/styled'
import { IonRippleEffect } from '@ionic/react'
import { darken } from 'polished'
import Svgs from '../svgs'

type SvgPositionType = 'LEFT' | 'RIGHT'
type FillType = 'CLEAR' | 'SOLID' | 'OUTLINE' | 'LINK'
interface NDButtonProps extends Omit<React.HTMLAttributes<HTMLButtonElement>, 'children'> {
  label?: string
  svgPosition?: SvgPositionType
  svg?: any
  fill?: FillType
  color?: string
  loading?: boolean
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset' | undefined
}
const NDButton = (props: NDButtonProps) => {
  const { renderSvg } = NDButton.helpers
  const {
    label,
    svg: Svg,
    svgPosition = 'RIGHT',
    fill = 'SOLID',
    loading = false,
    color = '#F06F30',
    disabled = false,
    ...rest
  } = props
  return (
    <Button
      {...rest}
      svgPosition={svgPosition}
      svgOnly={Svg && !label}
      fill={fill}
      color={color}
      isLoading={loading}
      className={(rest.className || '') + ' ion-activatable'}
      disabled={disabled}
    >
      {fill !== 'LINK' ? <IonRippleEffect /> : null}
      {!loading ? <span className="label">{label}</span> : null}
      {renderSvg({ Svg, loading })}
    </Button>
  )
}
NDButton.helpers = (() => {
  const renderSvg = ({ Svg, loading }) => {
    if (loading)
      return (
        <span className="icone">
          <Svgs.LoadingSpinner className="loading-spinner-icon" />
        </span>
      )
    if (Svg) return <span className="icone">{typeof Svg === 'function' ? <Svg /> : Svg}</span>
    return null
  }
  return {
    renderSvg,
  }
})()

interface ButtonType {
  svgPosition: SvgPositionType
  fill: FillType
  color: string
  svgOnly: boolean
  disabled: boolean
  isLoading: boolean
}
const Button = styled('button')<ButtonType>(
  (props) => `
  --background-color: ${props.color};
  --background-color-hover: ${darken(0.075, props.color)};
  --text-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  width: 156px;
  height: 38px;
  ${
    props.fill === 'CLEAR'
      ? `
  --background-color: #FFFFFF;
  --background-color-hover: #F2F2F2;
  --text-color: ${props.color};
  `
      : ''
  }
  ${
    props.fill === 'OUTLINE'
      ? `
  --background-color: transparent;
  --background-color-hover: ${props.color};
  --text-color: ${props.color};
    :hover {
      .label {
        color: #FFF;
      }
    }
  border: 1px solid ${props.color};
  `
      : ''
  }
  ${
    props.fill === 'LINK'
      ? `
    --background-color: transparent;
    --background-color-hover: none;
    --text-color: #007AFF;
    border: none;
    :hover {
      .label {
        text-decoration: underline;
      }
    }
  `
      : ''
  }
  ${
    props.disabled
      ? `
    --background-color: #9FA5AB;
    --background-color-hover: #9FA5AB;
    cursor: pointer;
  `
      : ''
  }
  ${
    props.isLoading
      ? `
    .loading-spinner-icon {
      -webkit-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite;
      path {
        stroke: var(--text-color);
      }
    }
  `
      : ''
  }
  justify-content: center;
  padding:  7px;
  position: relative;
  overflow: hidden;
  background-color: var(--background-color);
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  flex-direction: ${props.svgPosition === 'LEFT' ? 'row-reverse' : 'row'};
  transition: .25s background-color;
  position: relative;

  .label {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: var(--text-color);

  }

  .icone {
    ${_getSvgMargins(props.svgPosition, props.svgOnly)}
  }

  &:hover {
    background-color: var(--background-color-hover);
  }
`
)

function _getSvgMargins(svgPosition: SvgPositionType, svgOnly: boolean) {
  if (svgOnly) return ''
  if (svgPosition === 'LEFT') return 'margin-right: 8px;'
  if (svgPosition === 'RIGHT') return 'margin-left: 8px;'
  return ''
}

export default NDButton
