import React from 'react'
import { animated, useTransition } from 'react-spring'

export const AnimateEnter = ({ children, delay }) => {
  const [show, set] = React.useState(false)
  const transitions = useTransition(show, null, {
    from: { transform: 'translate3d(0,-40px,0)', opacity: 0 },
    enter: { transform: 'translate3d(0,0px,0)', opacity: 1 },
    leave: { transform: 'translate3d(0,-40px,0)', opacity: 0 },
  })

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      set(true)
    }, delay)
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [])

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={props}>
              {children}
            </animated.div>
          )
      )}
    </>
  )
}
