import React, { useEffect } from 'react'
import { IonPage, IonContent, IonButton, IonSpinner } from '@ionic/react'
import styled from '@emotion/styled'
import { Form, useFormik, FormikConsumer, FormikProvider } from 'formik'
import Header from '../components/Header'
import { useParams, useHistory } from 'react-router'
import { TextField } from '../common/TextField'
import { Yup } from '../common/Yup'
import { useSelector, useDispatch } from 'react-redux'
import { getModulos, modulosActions } from '../modulos/ModulosStore'
import useAxios from 'axios-hooks'
import { toast } from 'react-toastify'
import { ForunsStore } from './ForunsStore'
import { Flex } from '../common/styles/Flex'

interface NovoForumParams {
  cursoId
}
const NovoForum = () => {
  const { cursoId } = useParams<NovoForumParams>()
  const dispatch = ForunsStore.useDispatch()

  const history = useHistory()
  const [{ loading }, reqCreateForum] = useAxios(
    {
      method: 'POST',
      url: '/app/foruns',
    },
    { manual: true }
  )

  async function handleSubmit(values) {
    if (loading) return
    try {
      await reqCreateForum({ data: values })
      toast('Fórum criado com sucesso')

      history.replace(`/curso/${cursoId}/foruns`)
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao criar o fórum'
      toast(message)
    }
  }

  const { Layout } = NovoForum

  return (
    <Layout>
      <div className="container">
        <h2>NOVO FÓRUM</h2>
        <FormNovoForum onSubmit={handleSubmit}>
          <Titulo />
          <Modulo />
          <Texto />
          <Flex gap={12}>
            <Publicar loading={loading} />
            <Cancelar />
          </Flex>
        </FormNovoForum>
      </div>
    </Layout>
  )
}
NovoForum.Layout = styled.div`
  margin: 24px auto;
  max-width: 768px;
`

const FormNovoForum = ({ children, onSubmit }: { children: React.ReactNode; onSubmit }) => {
  const formik = useFormik({
    initialValues: { titulo: '', moduloId: '', texto: '', pontos: 1 },
    validationSchema: Yup.object().shape({
      titulo: Yup.string().required(),
      moduloId: Yup.number().required(),
      texto: Yup.string().required(),
    }),
    onSubmit: onSubmit,
  })

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>{children}</form>
    </FormikProvider>
  )
}
const Titulo = () => {
  return <TextField name="titulo" placeholder="Título" label="Título" />
}
const Modulo = () => {
  const modulos = useSelector(getModulos)
  const { cursoId } = useParams<NovoForumParams>()
  const reduxDispatch = useDispatch()
  useEffect(() => {
    reduxDispatch(modulosActions.loadModulos(cursoId))
  }, [])
  return (
    <TextField name="moduloId" label="Módulo">
      <select>
        <option value="">Selecione um módulo</option>
        {modulos.map((m) => (
          <option key={m.id} value={m.id}>
            {m.nome}
          </option>
        ))}
      </select>
    </TextField>
  )
}
const Texto = () => {
  return (
    <TextField name="texto" placeholder="Escreva o texto" label="Texto do fórum">
      <textarea style={{ paddingTop: 16, height: 200 }} />
    </TextField>
  )
}
const Publicar = ({ loading }: { loading: boolean }) => {
  return <IonButton type="submit">{loading ? <IonSpinner /> : 'PUBLICAR'}</IonButton>
}

const Cancelar = () => {
  const { cursoId } = useParams<NovoForumParams>()
  const history = useHistory()
  function handleClick() {
    history.replace(`/curso/${cursoId}/foruns`)
  }

  return <IonButton onClick={handleClick}>CANCELAR</IonButton>
}

const Styles = {
  IonContent: styled(IonContent)`
    font-family: 'Roboto Condensed', sans-serif;
    --background: #f7f7f7;
    @media (min-width: 425px) {
      font-size: 1.2em;
    }
    .container {
      background: white;
      padding: 24px;
      max-width: 800px;
      @media (min-width: 800px) {
        margin: 12px auto;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
      }
    }
  `,
}

export { NovoForum }
