import React from 'react'
import { Route, Switch } from 'react-router'
import { NovoForum } from './NovoForum'
import { ForunsLista } from './ForunsLista'
import { IonRouterOutlet, IonPage } from '@ionic/react'
import { ForunsStore } from './ForunsStore'
import { ForumDetail } from './ForumDetail'

const Foruns = () => {
  return (
    <ForunsStore.Provider>
      <Switch>
        <Route exact path="/curso/:cursoId/foruns" component={ForunsLista} />
        <Route path="/curso/:cursoId/foruns/novo" component={NovoForum} />
        <Route path="/curso/:cursoId/foruns/:forumId" component={ForumDetail} />
      </Switch>
    </ForunsStore.Provider>
  )
}

export { Foruns }
