import React from 'react'
import { SvgType } from './index'
const ClockSvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.75 6.75H8.25V3.75C8.25 3.55109 8.17099 3.36032 8.03033 3.21967C7.88968 3.07902 7.69892 3 7.5 3C7.30109 3 7.11033 3.07902 6.96967 3.21967C6.82902 3.36032 6.75 3.55109 6.75 3.75V7.5C6.75 7.69891 6.82902 7.88968 6.96967 8.03033C7.11033 8.17098 7.30109 8.25 7.5 8.25H9.75C9.94892 8.25 10.1397 8.17098 10.2803 8.03033C10.421 7.88968 10.5 7.69891 10.5 7.5C10.5 7.30109 10.421 7.11032 10.2803 6.96967C10.1397 6.82902 9.94892 6.75 9.75 6.75ZM7.5 0C6.01664 0 4.5666 0.439867 3.33323 1.26398C2.09986 2.08809 1.13856 3.25943 0.570907 4.62987C0.00324963 6.00032 -0.145275 7.50832 0.144114 8.96318C0.433503 10.418 1.14781 11.7544 2.1967 12.8033C3.2456 13.8522 4.58197 14.5665 6.03683 14.8559C7.49168 15.1453 8.99968 14.9968 10.3701 14.4291C11.7406 13.8614 12.9119 12.9001 13.736 11.6668C14.5601 10.4334 15 8.98336 15 7.5C14.9978 5.51156 14.2069 3.60519 12.8009 2.19914C11.3948 0.793098 9.48845 0.00221143 7.5 0ZM7.5 13.5C6.31332 13.5 5.15328 13.1481 4.16658 12.4888C3.17989 11.8295 2.41085 10.8925 1.95673 9.7961C1.5026 8.69974 1.38378 7.49334 1.61529 6.32946C1.8468 5.16557 2.41825 4.09647 3.25736 3.25736C4.09648 2.41824 5.16558 1.8468 6.32946 1.61529C7.49335 1.38378 8.69975 1.5026 9.7961 1.95672C10.8925 2.41085 11.8295 3.17988 12.4888 4.16658C13.1481 5.15327 13.5 6.31331 13.5 7.5C13.4982 9.09074 12.8655 10.6158 11.7406 11.7406C10.6158 12.8655 9.09074 13.4982 7.5 13.5Z"
        fill={color}
      />
    </svg>
  )
}

export default ClockSvg
