import React, { useEffect, useMemo } from 'react'
import {
  IonTitle,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonRippleEffect,
  IonPage,
  IonFooter,
} from '@ionic/react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { forunsActions, ForunsStore, ForunsSelectors } from './ForunsStore'
import { Show } from '../common/Show'
import styled from '@emotion/styled'
import { useParams, useHistory } from 'react-router'
import { getUsuarioLogado } from '../auth/AuthStore'
import Header from '../components/Header'
import { ForumModel } from '../models/ForumModel'
import { AppAvatar } from '../components/AppAvatar'
import { getModulos, modulosActions } from '../modulos/ModulosStore'
import { add, close } from 'ionicons/icons'

interface ForunsListaParams {
  cursoId
}

const ForunsLista = () => {
  const { Layout, Abas, SpacerAba } = ForunsLista
  return (
    <>
      <Layout>
        <div className="container">
          <Modulos />
          <Abas>
            <Popular />
            <Novo />
            <SeusForuns />
            <SpacerAba />
          </Abas>
          <ListaForuns />
        </div>
      </Layout>
      <NovoForum />
      <>
        <Init />
      </>
    </>
  )
}
ForunsLista.Layout = styled('div')`
  background: white;
  max-width: 1024px;
  margin: 24px auto;
`
ForunsLista.Abas = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto 1fr;
`
ForunsLista.SpacerAba = styled('div')`
  border-bottom: 2px solid #afafaf;
`

const Init = () => {
  const { cursoId } = useParams<ForunsListaParams>()
  const dispatch = ForunsStore.useDispatch()
  const reduxDispatch = useDispatch()
  useEffect(() => {
    dispatch(ForunsStore.thunks.getForuns({ cursoId, moduloId: null }))
    reduxDispatch(modulosActions.loadModulos(cursoId))
  }, [])
  return null
}

const Modulos = () => {
  return null
}

const Aba = ({ aba, titulo }: { aba; titulo }) => {
  const { activeTab } = ForunsStore.useState()
  const dispatch = ForunsStore.useDispatch()
  const abaAtiva = activeTab === aba

  function handleClick() {
    dispatch({ type: 'alteraAba', payload: aba })
  }

  return (
    <Styles.AbaTitulo ativa={abaAtiva} className="ion-activatable" onClick={handleClick}>
      <IonRippleEffect />
      {titulo}
    </Styles.AbaTitulo>
  )
}

const Popular = () => <Aba aba="POPULAR" titulo="POPULAR" />
const Novo = () => <Aba aba="NOVO" titulo="NOVO" />
const SeusForuns = () => <Aba aba="SEUS_FORUNS" titulo="SEUS FÓRUNS" />

const ListaForuns = () => {
  const { id: usuarioId } = useSelector(getUsuarioLogado) || { id: null }
  const state = ForunsStore.useState()
  const stateWithUsuarioId = useMemo(() => ({ ...state, usuarioId }), [state, usuarioId])
  const foruns = ForunsSelectors.getForuns(stateWithUsuarioId)
  if (!foruns.length) {
    return <EmptyState />
  }
  return (
    <>
      {foruns.map((forum) => (
        <ForumItem key={forum.id} forum={forum} />
      ))}
    </>
  )
}

const EmptyState = () => {
  const { Layout } = EmptyState
  return (
    <Layout>
      <div>
        <strong>Nenhuma mensagem no fórum encontrada no momento.</strong>
      </div>
      <div>Aqui você encontra mensagens com dúvidas criadas por você e por outros alunos.</div>
    </Layout>
  )
}
EmptyState.Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  color: #333333;
`

const ForumItem = ({ forum }: { forum: ForumModel }) => {
  const { cursoId } = useParams<ForunsListaParams>()
  const history = useHistory()
  const modulos = useSelector(getModulos)

  return (
    <Styles.ForumItem
      className="ion-activatable"
      onClick={() => history.push(`/curso/${cursoId}/foruns/${forum.id}`)}
    >
      <IonRippleEffect />
      <div className="avatar">
        <AppAvatar usuario={forum.usuario} circular tamanho={45} />
      </div>
      <div className="titulo">{forum.titulo}</div>
      <div className="modulo">{modulos?.find((x) => +x.id === +forum.moduloId)?.nome}</div>
      <div className="texto">{forum.texto}</div>
      <div className="qtd-respostas">
        <QtdRespostasForuns forum={forum} />
      </div>
      <div className="participantes">
        <Participantes forum={forum} />
      </div>
    </Styles.ForumItem>
  )
}

const QtdRespostasForuns = ({ forum }: { forum: ForumModel }) => {
  if (!forum.forunsMensagens.length) return null
  return <> + {forum.forunsMensagens.length} respostas</>
}

const Participantes = ({ forum }: { forum: ForumModel }) => {
  if (!forum.forunsMensagens.length) return null
  return (
    <Styles.Participantes>
      {forum.forunsMensagens.slice(0, 3).map((fm) => (
        <div key={fm.id} className="avatar-container">
          <AppAvatar usuario={fm.usuario} circular tamanho={33} />
        </div>
      ))}
    </Styles.Participantes>
  )
}

const NovoForum = () => {
  const history = useHistory()
  const { cursoId } = useParams<ForunsListaParams>()
  return (
    <IonFab vertical="bottom" horizontal="end" slot="fixed">
      <IonFabButton onClick={() => history.push(`/curso/${cursoId}/foruns/novo`)}>
        <IonIcon icon={add} />
      </IonFabButton>
    </IonFab>
  )
}

const Styles = {
  IonContent: styled('div')`
    font-family: 'Roboto Condensed', sans-serif;
    --background: #f7f7f7;
    @media (min-width: 425px) {
      font-size: 1.2em;
    }
    font-size: 1em;
    .container {
      background: white;
      max-width: 800px;
      @media (min-width: 800px) {
        margin: 12px auto;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
      }
      @media (max-width: 425px) {
        padding: 8px;
      }
    }
  `,
  Abas: styled('div')`
    width: 100%;
    display: flex;
    .spacer {
      border-bottom: 2px solid #afafaf;
      flex: 1;
    }
  `,
  AbaTitulo: styled('div')<{ ativa }>(
    (props) => `
    border-bottom: 2px solid #AFAFAF;
    display: inline-block;
    text-align: center;
    padding: 8px;
    font-size: .9em;
    color: #AFAFAF;
    position: relative;
    cursor: pointer;
    ${
      props.ativa
        ? `
      border-bottom-color: #FF9933;
      color: #FF9933;
    `
        : ''
    }
  `
  ),
  ForumItem: styled('div')`
    display: grid;
    grid-template-columns: 45px 1fr 64px;
    border-bottom: 1px solid #c4c4c4;
    row-gap: 6px;
    column-gap: 24px;
    padding: 16px 8px;
    position: relative;
    grid-template-areas:
      'avatar titulo participantes'
      'avatar modulo participantes'
      'avatar texto participantes'
      'avatar respostas participantes';
    cursor: pointer;
    &:hover {
      background: #efefef;
    }
    .avatar {
      grid-area: avatar;
    }
    .titulo {
      grid-area: titulo;
      color: var(--ion-color-primary);
      font-weight: bold;
      max-height: 2.2em;
      line-height: 1.1em;
      overflow: hidden;
    }
    .modulo {
      grid-area: modulo;
      color: #444444;
      font-size: 0.9em;
      font-weight: bold;
      font-style: italic;
    }
    .texto {
      grid-area: texto;
      font-size: 0.9em;
      color: #444444;
      max-height: 3.2em;
      line-height: 1.1em;
      overflow: hidden;
    }
    .qtd-respostas {
      grid-area: respostas;
      color: #afafaf;
      font-size: 0.9em;
    }
    .participantes {
      grid-area: participantes;
    }
  `,
  Participantes: styled('div')`
    display: flex;
    .avatar-container {
      margin-right: -18px;
    }
  `,
}
export { ForunsLista }

export const Foruns2 = ({ match }) => {
  const dispatch = useDispatch()
  const actions = bindActionCreators(forunsActions, dispatch)
  const cursoId = +match.params.contaId
  const { foruns, activeTab, activeModulo } = {} as any // useSelector((s: IStateRedux) => s.foruns)
  const { id: usuarioId } = useSelector(getUsuarioLogado)
  const selectModulo = function (moduloId) {}
  const selectTab = function (tab) {}

  let showSelecionaModulos = true
  useEffect(() => {
    actions.getForuns(cursoId)
  }, [activeModulo])
  useEffect(() => {
    if (!foruns || !foruns[activeTab]) actions.getForuns(cursoId)
  }, [activeTab, ''])

  return (
    <IonPage>
      <Header />

      <Styles.IonContent>
        <div className="container">
          <Show condition={showSelecionaModulos}>
            <div className="seletor-modulos">
              <div className="seletor-modulos-title" onClick={() => actions.hideSelecionaModulos()}>
                {activeModulo ? activeModulo.nome : 'TODOS OS MÓDULOS'}{' '}
                <IonIcon ios="ios-arrow-back" md="ios-arrow-back" color="primary" />
              </div>

              {/*
                modulos.map(modulo => {
                  <div className="seletor-modulo" onClick={() => selectModulo(modulo)} key={modulo.id}>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                      d="M0.740296 3.45515C1.25858 2.59137 2.61132 0.259143 3.84993 0.259143C4.40275 0.205627 5.15132 2.91972 5.61201 3.11477C10.0461 5.33182 19.1562 9.86958 20.1236 10.2842C21.3329 10.8025 23.1987 10.2842 23.6306 11.7526C23.9762 12.9274 21.8512 16.3768 21.0738 16.849C17.1867 19.21 8.72164 24.1087 7.59872 24.3639C6.4758 24.6191 4.5224 24.5702 3.45254 23.6729C1.69566 22.1993 1.78256 19.9874 2.07049 18.9221L3.27978 15.7261C3.45254 15.1163 3.53892 14.344 3.53892 13.653C3.53892 12.9619 3.07824 12.0982 2.84789 11.7526C2.46869 10.9701 0.738647 8.79317 0.342915 7.77922C-0.468187 5.701 0.365058 4.08053 0.740296 3.45515Z"
                      fill]="modulo.secondary" />
                    <path
                      d="M1.2432 1.21445C1.88348 0.580546 2.68233 0.00515029 3.92094 0.00515029C4.47377 -0.0483655 5.53334 0.328367 5.99402 0.523422C10.4281 2.74047 19.5382 7.27823 20.5056 7.69285C21.7149 8.21112 22.4923 9.16129 22.9242 10.6297C23.2698 11.8045 22.3723 13.5614 21.4558 14.2576C17.8343 17.0087 9.10365 21.5174 7.98073 21.7726C6.85781 22.0278 4.90442 21.9789 3.83456 21.0815C2.07768 19.608 2.16457 17.3961 2.4525 16.3307L3.6618 13.1347C3.92094 12.3521 3.92094 11.7526 3.92094 11.0616C3.92094 10.3706 3.46025 9.5068 3.22991 9.16129C2.74043 8.47026 1.46627 6.96034 0.724932 5.18787C0.000216693 3.45515 0.724926 1.72757 1.2432 1.21445Z"
                    [attr.fill]="modulo.primary" />
                    </svg>
                    {modulo.nome}
                  </div>
                })
              */}
            </div>
          </Show>
        </div>

        <Show condition={activeModulo}>
          <div style={{ textAlign: 'center' }}>
            <IonFabButton
              onClick={() => selectModulo(null)}
              color="default"
              style={{ margin: '0 auto' }}
            >
              <IonIcon icon={close} />
            </IonFabButton>
            Limpar
          </div>
        </Show>

        <div
          className="seletor-modulos-title ion-activatable"
          onClick={() => {
            showSelecionaModulos = true
          }}
        >
          <IonRippleEffect />
          {activeModulo ? activeModulo.nome : 'TODOS OS MÓDULOS'}{' '}
          <IonIcon ios="ios-arrow-down" md="ios-arrow-down" color="primary"></IonIcon>
        </div>

        <div className="tabs">
          <div
            onClick={() => selectTab('POPULAR')}
            className={`ion-activatable ${activeTab === 'POPULAR' ? 'active' : ''}`}
          >
            <IonRippleEffect />
            POPULAR
          </div>
          <div
            onClick={() => selectTab('NOVO')}
            className={`ion-activatable ${activeTab === 'NOVO' ? 'active' : ''}`}
          >
            <IonRippleEffect />
            NOVO
          </div>
          <div
            onClick={() => selectTab('SEUS_FORUNS')}
            className={`ion-activatable ${activeTab === 'SEUS_FORUNS' ? 'active' : ''}`}
          >
            <IonRippleEffect />
            SEUS FÓRUNS
          </div>
          <div />
        </div>

        <div>
          {/*
            forunsByTab.map(forum => {
              <div className="forum-item-container ion-activatable" onClick={() => goToForum(forum)}>
                {forum}
                <IonRippleEffect />
                {/*<app-avatar [tamanho]="tamanhoAvatar" className="avatar-op" [usuario]="forum.usuario"></app-avatar> --->>> deixar comentado}
                <div className="titulo">{forum.titulo}</div>
                <div className="modulo">{forum.modulo.nome}</div>
                <div className="forum-mensagem">{forum.texto}</div>
                <div className="comentarios">
                  {forum.forunsMensagens.length - 1 === 1 ? '+ ' + forum.forunsMensagens.length + ' Resposta' : ''}
                  {forum.forunsMensagens.length - 1 > 1 ? '+ ' + forum.forunsMensagens.length + ' Respostas' : ''}
                  {forum.forunsMensagens.length - 1 === 0 ? 'Nenhuma Resposta' : ''}
                </div>

                <div className="participantes">
                  <div className="participantes-container">
                    {/*  ---->>>> deixar comentado
                       <app-avatar *ngIf="forum.participantes[0]" [tamanho]="tamanhoAvatarPequeno" [usuario]="forum.participantes[0]" className="avatar-participante">
                       </app-avatar>
                       <app-avatar *ngIf="forum.participantes[1]" [tamanho]="tamanhoAvatarPequeno" [usuario]="forum.participantes[1]"
                         className="avatar-participante">
                       </app-avatar>
                       <app-avatar *ngIf="forum.participantes[2]" [tamanho]="tamanhoAvatarPequeno" [usuario]="forum.participantes[2]"
                         className="avatar-participante">
                       </app-avatar>
                       ----->>> deixar comentado

                  </div>
                  <Show condition={forum.participantes.length > 3}>
                    <div className="participantes-adicionais">+{forum.participantes.length - 3}
                    </div>
                  </Show>
                </div>
              </div>
            })
          */}

          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton color="primary">
              <IonIcon icon={add} />
            </IonFabButton>
          </IonFab>
        </div>
      </Styles.IonContent>
    </IonPage>
  )
}

const Styles2 = {
  IonContent: styled(IonContent)`
    font-family: 'Roboto Condensed', sans-serif;

    .ion-activatable {
      position: relative;
      cursor: pointer;
    }

    .seletor-modulos-title {
      font-size: 1.1em;
      padding: 12px;
      cursor: pointer;
    }
    .seletor-modulos {
      background: #f7f7f7;
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: 2;
      .seletor-modulo {
        cursor: pointer;
        font-weight: bold;
        text-transform: uppercase;
        color: #000000;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background: #ffffff;
        margin: 12px;
        padding: 12px;
        display: flex;
        align-items: center;
        &:hover {
          background: #efefef;
        }
        svg {
          width: 40px;
        }
      }
    }

    .tabs {
      width: 100%;
      & > div {
        border-bottom: 2px solid #afafaf;
        width: 33.33%;
        display: inline-block;
        text-align: center;
        padding: 8px;
        font-size: 0.9em;
        color: #afafaf;
        &.active {
          border-bottom-color: #ff9933;
          color: #ff9933;
        }
      }
    }

    .forum-item-container {
      display: grid;
      padding: 16px 8px;
      margin: 0 8px;
      grid-template:
        'avatar-op titulo participantes'
        'avatar-op modulo participantes'
        'avatar-op forum-mensagem participantes'
        'avatar-op comentarios participantes';
      grid-template-columns: auto 1fr 50px;
      row-gap: 6px;
      column-gap: 12px;
      border-bottom: 1px solid #c4c4c4;

      app-avatar.avatar-op {
        grid-area: avatar-op;
      }
      .titulo {
        grid-area: titulo;
        color: #ff9933;
        font-weight: bold;
        max-height: 2.2em;
        line-height: 1.1em;
        overflow: hidden;
      }
      .modulo {
        grid-area: modulo;
        color: #444444;
        font-size: 0.9em;
        font-weight: bold;
        font-style: italic;
      }
      .forum-mensagem {
        grid-area: forum-mensagem;
        font-size: 0.9em;
        color: #444444;
        max-height: 3.2em;
        line-height: 1.1em;
        overflow: hidden;
      }
      .comentarios {
        grid-area: comentarios;
        color: #afafaf;
        font-size: 0.9em;
      }
      .participantes {
        grid-area: participantes;
        position: relative;
        app-avatar {
          position: absolute;
          // top: 0;
          &:nth-child(1) {
            left: 20px;
          }
          &:nth-child(2) {
            left: 10px;
          }
          &:nth-child(3) {
            left: 0px;
          }
        }
        .participantes-adicionais {
          margin-top: 32px;
          text-align: center;
          color: #afafaf;
          font-size: 1.1em;
          font-weight: bold;
        }
      }
    }

    @media (max-width: 425px) {
      .container {
        box-shadow: none;
      }
      .tabs > div:last-child {
        display: none;
      }
    }

    @media (min-width: 426px) {
      :host {
        font-size: 1.2em;
      }
      ion-content {
        --background: #f7f7f7;
      }
      .container {
        margin-top: 24px;
      }
      .seletor-modulos-title {
        width: 433px;
      }
      .seletor-modulos {
        width: auto;
        height: auto;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
      }
      .tabs {
        display: flex;
        margin: 12px 0;
        & > div {
          padding: 8px 16px;
          width: auto;
          &:last-child {
            flex: 1;
          }
        }
      }
      .forum-item-container {
        row-gap: 6px;
        column-gap: 24px;
      }
    }
  `,
  IonTitle: styled(IonTitle)`
    font-style: italic;
  `,
}
