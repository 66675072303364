import React from 'react'
import { QuestaoStore } from './QuestoesStore'
import styled from '@emotion/styled'
import { QuestaoAlternativaModel } from '../models/QuestaoAlternativaModel'
import { useSpring, animated } from 'react-spring'
import { Button } from '../components'
import Svgs from '../svgs'
import { Show } from '../common/Show'
import AnimateHeightComp from 'react-animate-height'
const AnimateHeight: any = AnimateHeightComp

enum STATUS_ALTERNATIVA {
  VERDADEIRA_MARCADA_VERDADEIRA = 'VERDADEIRA_MARCADA_VERDADEIRA',
  VERDADEIRA_MARCADA_FALSA = 'VERDADEIRA_MARCADA_FALSA',
  FALSA_MARCADA_FALSA = 'FALSA_MARCADA_FALSA',
  FALSA_MARCADA_VERDADEIRA = 'FALSA_MARCADA_VERDADEIRA',
  MARCADA_FALSA = 'MARCADA_FALSA',
  MARCADA_VERDADEIRA = 'MARCADA_VERDADEIRA',
  NENHUM = '',
}

export const MultiploVerdadeiroFalso = () => {
  const { questao, respostasInformadas } = QuestaoStore.useState()
  const questoesAlternativas = questao.questoesAlternativas || []
  return (
    <>
      <Styles.AlternativaContainer>
        {questoesAlternativas.map((alternativa, index) => {
          return (
            <Alternativa
              key={alternativa.id}
              alternativa={alternativa}
              respostaInformada={
                respostasInformadas[alternativa.id]
                  ? respostasInformadas[alternativa.id].respostaInformada
                  : ''
              }
              index={index}
            />
          )
        })}
      </Styles.AlternativaContainer>
    </>
  )
}
function Alternativa({ alternativa = {} as QuestaoAlternativaModel, index, respostaInformada }) {
  const { questao, showRespostaIncorreta } = QuestaoStore.useState()
  const dispatch = QuestaoStore.useDispatch()

  const hasRespostaQuestaoCorreta = questao.respostas?.[0]?.possuiRespostaCorreta ?? false
  const hasRespostaAlternativaCorreta = questao.respostas?.[0]?.respostasAlternativas.some(
    (x) => x.questaoAlternativaId === alternativa.id && x.correta
  )

  const status = _getStatus(
    hasRespostaAlternativaCorreta,
    alternativa.respostaEsperada,
    showRespostaIncorreta,
    respostaInformada
  )
  const { styleVerdadeiro, styleFalso } = _getStylesVerdadeiroFalso(status)

  const configFast = { mass: 1, tension: 280, friction: 20 }
  const springVerdadeiro = useSpring({ ...styleVerdadeiro, config: configFast })
  const springFalso = useSpring({ ...styleFalso, config: configFast })

  function handleClickBotaoVF(resposta, alternativaId) {
    dispatch(
      QuestaoStore.thunks.markAlternativa({
        alternativaId,
        resposta: { respostaInformada: resposta },
      })
    )
  }

  const { ButtonVF, helpers } = Alternativa

  return (
    <>
      <Styles.Alternativa odd={index % 2 === 0}>
        <div className="v-f-container">
          <ButtonVF
            style={springVerdadeiro}
            onClick={() => handleClickBotaoVF('V', alternativa.id)}
          >
            Verdadeira
          </ButtonVF>
          <ButtonVF style={springFalso} onClick={() => handleClickBotaoVF('F', alternativa.id)}>
            Falsa
          </ButtonVF>
        </div>
        <div className="descricao-container">
          <Styles.Descricao
            dangerouslySetInnerHTML={{ __html: alternativa.conteudoAlternativa || '' }}
          />
          <Feedback showRespostaIncorreta={showRespostaIncorreta} statusAlternativa={status} />
        </div>
      </Styles.Alternativa>
    </>
  )
}
Alternativa.helpers = (() => {
  return {}
})()
Alternativa.ButtonVF = styled(animated.div)`
  font-size: 16em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2d2d2d;
  border: 2px solid;
  border-radius: 3px;
  height: 40px;
  width: 118px;
  &:last-child {
    margin-left: 19px;
  }
  user-select: none;

  cursor: pointer;
  &:hover {
    background: #f7f7f7;
  }
`

function Feedback({
  showRespostaIncorreta,
  statusAlternativa,
}: {
  showRespostaIncorreta: boolean
  statusAlternativa: STATUS_ALTERNATIVA
}) {
  const { somenteLeitura } = QuestaoStore.useState()
  const showFeedback = showRespostaIncorreta && !somenteLeitura

  const { Container, helpers } = Feedback
  const [incorreta, setIncorreta] = React.useState(
    helpers.getAlternativaIncorreta({ statusAlternativa })
  )
  const [texto, setTexto] = React.useState(helpers.getTexto({ statusAlternativa }))

  React.useEffect(() => {
    setTimeout(
      () => {
        setIncorreta(helpers.getAlternativaIncorreta({ statusAlternativa }))
        setTexto(helpers.getTexto({ statusAlternativa }))
      },
      showRespostaIncorreta ? 0 : 500
    )
  }, [showRespostaIncorreta, statusAlternativa])

  return (
    <AnimateHeight duration={500} height={showFeedback ? 'auto' : 0}>
      <Container incorreta={incorreta}>
        <Show condition={incorreta}>
          <Svgs.Atention color="#EB5757" />
        </Show>
        <Show condition={!incorreta}>
          <Svgs.CheckmarkCircleOutline color="#4AC758" />
        </Show>
        {texto}
      </Container>
    </AnimateHeight>
  )
}
Feedback.helpers = (() => {
  function getAlternativaIncorreta({
    statusAlternativa,
  }: {
    statusAlternativa: STATUS_ALTERNATIVA
  }) {
    if (
      statusAlternativa === STATUS_ALTERNATIVA.FALSA_MARCADA_VERDADEIRA ||
      statusAlternativa === STATUS_ALTERNATIVA.VERDADEIRA_MARCADA_FALSA
    )
      return true
    return false
  }
  function getTexto({ statusAlternativa }: { statusAlternativa: STATUS_ALTERNATIVA }) {
    if (statusAlternativa === STATUS_ALTERNATIVA.FALSA_MARCADA_VERDADEIRA)
      return 'Resposta incorreta. Essa alternativa é verdadeira.'
    if (statusAlternativa === STATUS_ALTERNATIVA.VERDADEIRA_MARCADA_FALSA)
      return 'Resposta incorreta. Essa alternativa é falsa.'
    return 'Resposta correta.'
  }
  return {
    getAlternativaIncorreta,
    getTexto,
  }
})()
Feedback.Container = styled.div<{ incorreta }>(
  (props) => `
  font-style: italic;
  font-size: 14em;
  display: grid;
  align-items: center;
  color: #2D2D2D;
  margin-top: 9px;
  grid-template-columns: auto 1fr;
  gap: 8px;
  ${props.incorreta ? 'color: #EB5757;' : ''}
`
)

function _getStatus(
  hasRespostaCorreta,
  respostaEsperada,
  showRespostaIncorreta,
  respostaInformada
): STATUS_ALTERNATIVA {
  if (hasRespostaCorreta) {
    if (respostaEsperada === 'V') return STATUS_ALTERNATIVA.VERDADEIRA_MARCADA_VERDADEIRA
    return STATUS_ALTERNATIVA.FALSA_MARCADA_FALSA
  }

  if (showRespostaIncorreta) {
    if (respostaEsperada === 'V') return STATUS_ALTERNATIVA.VERDADEIRA_MARCADA_FALSA
    return STATUS_ALTERNATIVA.FALSA_MARCADA_VERDADEIRA
  }

  if (respostaInformada === 'V') return STATUS_ALTERNATIVA.MARCADA_VERDADEIRA
  if (respostaInformada === 'F') return STATUS_ALTERNATIVA.MARCADA_FALSA
  return STATUS_ALTERNATIVA.NENHUM
}

function _getStylesVerdadeiroFalso(status: STATUS_ALTERNATIVA) {
  const green = '#27AE60'
  const white = 'white'
  const red = '#EB5757'
  const blue = '#2F80ED'
  let styleVerdadeiro: any = { background: white, borderColor: '#E0E0E0', color: '#2D2D2D' }
  let styleFalso: any = { background: white, borderColor: '#E0E0E0', color: '#2D2D2D' }

  switch (status) {
    case STATUS_ALTERNATIVA.FALSA_MARCADA_FALSA:
      styleFalso = { background: green, borderColor: green, color: white }
      break
    case STATUS_ALTERNATIVA.FALSA_MARCADA_VERDADEIRA:
      styleVerdadeiro = { background: red, borderColor: red, color: white }
      break
    case STATUS_ALTERNATIVA.VERDADEIRA_MARCADA_FALSA:
      styleFalso = { background: red, borderColor: red, color: white }
      break
    case STATUS_ALTERNATIVA.VERDADEIRA_MARCADA_VERDADEIRA:
      styleVerdadeiro = { background: green, borderColor: green, color: white }
      break
    case STATUS_ALTERNATIVA.MARCADA_FALSA:
      styleFalso = { background: blue, borderColor: blue, color: white }
      break
    case STATUS_ALTERNATIVA.MARCADA_VERDADEIRA:
      styleVerdadeiro = { background: blue, borderColor: blue, color: white }
      break
  }
  return { styleVerdadeiro, styleFalso }
}

const Styles = {
  AlternativaContainer: styled('div')`
    margin-top: 28px;
    @media (max-width: 425px) {
    }
  `,
  Alternativa: styled('div')<{ odd }>(
    (props) => `
    background: ${props.odd ? '#F7F7F7' : 'white'};
    padding: 16px 24px;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 18px;
    grid-template-rows: auto;
    align-items: center;
    grid-template-areas: "v-f descricao";
    .v-f-container {
      display: flex;
      grid-area: v-f;
      @media (max-width: 930px) {
        justify-content: center;
      }
    }
    .descricao-container {
      grid-area: descricao;
    }
    @media (max-width: 930px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      grid-row-gap: 16px;
      grid-template-areas:
        "descricao"
        "v-f";
    }
    @media (max-width: 425px) {
      padding: 8px 16px;
    }
  `
  ),
  ButtonVF: styled(animated.div)`
    width: 118px;
    height: 40px;
    background: white;
    border: 2px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #4d4d4d;
    justify-content: center;
    &:last-child {
      margin-left: 16px;
    }
    user-select: none;

    cursor: pointer;
    &:hover {
      background: #f7f7f7;
    }
  `,
  Feedback: styled('div')<{ correto }>(
    (props) => `
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin-top: 8px;
    color: ${props.correto ? '#27AE60' : '#EB5757'};
    @media (max-width: 425px) {
      font-size: 14px;
    }
  `
  ),
  Descricao: styled('div')`
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18em;
    color: #2d2d2d;
  `,
  Letra: styled('div')`
    border-radius: 50%;
    min-width: 39px;
    width: 39px;
    height: 39px;
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    color: #afafaf;
    font-size: 1.1em;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    margin: calc(var(--margin) * 0.66);
    margin-left: 0;
    border: 2px solid #afafaf;
  `,
  BotoesContainer: styled('div')`
    text-align: center;
    ion-button {
      height: 48px;
      --background: var(--primary-color);
      --background-focused: var(--primary-color);
      --color: var(--primary-color-contrast, #fff);
      --background-activated: var(--primary-color-shade, #fff);
    }
    ion-button.envia-resposta {
      --background: white;
      --background-focused: #f7f7f7;
      --background-activated: #f7f7f7;
      --color: var(--primary-color);
      --color-activated: var(--primary-color-shade);
      --color-focused: var(--primary-color);
      --border-width: 2px;
      --border-color: var(--primary-color);
      --border-style: solid;
    }
  `,
}
