import React from 'react'
import { SvgType } from './index'
const ReplySvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.4713 7.52861L9.13798 2.19528C9.04476 2.10204 8.92598 2.03854 8.79667 2.01282C8.66736 1.98709 8.53332 2.00028 8.41151 2.05073C8.28969 2.10119 8.18558 2.18663 8.11233 2.29625C8.03908 2.40588 7.99998 2.53476 7.99998 2.66661V5.02989C6.17885 5.19846 4.48615 6.04052 3.25313 7.3913C2.02011 8.74208 1.33552 10.5044 1.33331 12.3333V13.3333C1.3333 13.4717 1.3764 13.6067 1.45661 13.7196C1.53683 13.8324 1.65019 13.9175 1.78096 13.963C1.91172 14.0085 2.05341 14.0121 2.18634 13.9734C2.31927 13.9347 2.43685 13.8556 2.52277 13.747C3.17586 12.9703 3.9772 12.3315 4.87996 11.868C5.78272 11.4045 6.76879 11.1256 7.78058 11.0475C7.81378 11.0432 7.89711 11.0367 7.99998 11.0302V13.3333C7.99998 13.4651 8.03908 13.594 8.11233 13.7036C8.18558 13.8133 8.28969 13.8987 8.41151 13.9492C8.53332 13.9996 8.66736 14.0128 8.79667 13.9871C8.92598 13.9613 9.04476 13.8978 9.13798 13.8046L14.4713 8.47128C14.5332 8.40939 14.5823 8.33591 14.6159 8.25504C14.6494 8.17417 14.6666 8.08748 14.6666 7.99994C14.6666 7.9124 14.6494 7.82572 14.6159 7.74485C14.5823 7.66397 14.5332 7.5905 14.4713 7.52861ZM9.33331 11.7239V10.3333C9.33334 10.2457 9.31611 10.159 9.28261 10.0781C9.24912 9.99723 9.20001 9.92373 9.1381 9.86182C9.07619 9.79991 9.00269 9.75081 8.9218 9.71731C8.8409 9.68382 8.7542 9.66659 8.66665 9.66661C8.49673 9.66661 7.80271 9.69949 7.62563 9.72328C5.82869 9.88878 4.11822 10.5715 2.70115 11.6887C2.86191 10.2183 3.55943 8.85882 4.66016 7.87065C5.76088 6.88249 7.18744 6.33509 8.66665 6.33328C8.7542 6.3333 8.8409 6.31607 8.9218 6.28258C9.00269 6.24908 9.07619 6.19998 9.1381 6.13806C9.20001 6.07615 9.24912 6.00265 9.28261 5.92176C9.31611 5.84086 9.33334 5.75416 9.33331 5.66661V4.27598L13.0573 7.99994L9.33331 11.7239Z"
        fill={color}
      />
    </svg>
  )
}

export default ReplySvg
