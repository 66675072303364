import React from 'react'
import { SvgType } from './index'
const UserBadgeSvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="46"
      height="45"
      viewBox="0 0 46 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.3"
        d="M41 13.5H5V40.5H41V13.5ZM23 15.75C26.105 15.75 28.625 18.27 28.625 21.375C28.625 24.48 26.105 27 23 27C19.895 27 17.375 24.48 17.375 21.375C17.375 18.27 19.895 15.75 23 15.75ZM34.25 38.25H11.75V35.9775C11.75 31.275 19.1975 29.25 23 29.25C26.8025 29.25 34.25 31.275 34.25 35.9775V38.25Z"
        fill="#FFF4EA"
      />
      <path
        d="M5 45H41C43.475 45 45.5 42.975 45.5 40.5V13.5C45.5 11.025 43.475 9 41 9H5C2.525 9 0.5 11.025 0.5 13.5V40.5C0.5 42.975 2.525 45 5 45ZM5 13.5H41V40.5H5V13.5ZM5 0H41V4.5H5V0ZM23 27C26.105 27 28.625 24.48 28.625 21.375C28.625 18.27 26.105 15.75 23 15.75C19.895 15.75 17.375 18.27 17.375 21.375C17.375 24.48 19.895 27 23 27ZM23 19.125C24.2375 19.125 25.25 20.1375 25.25 21.375C25.25 22.6125 24.2375 23.625 23 23.625C21.7625 23.625 20.75 22.6125 20.75 21.375C20.75 20.1375 21.7625 19.125 23 19.125ZM23 29.25C19.1975 29.25 11.75 31.275 11.75 35.9775V38.25H34.25V35.9775C34.25 31.275 26.8025 29.25 23 29.25ZM15.8225 34.875C17.195 33.705 20.39 32.625 23 32.625C25.6325 32.625 28.8275 33.705 30.2 34.875H15.8225Z"
        fill="#F06F30"
      />
    </svg>
  )
}

export default UserBadgeSvg
