import { createSimpleStore } from 'react-simple-reducer'
import { toast } from 'react-toastify'
import Axios from 'axios'
import { Dispatch } from 'redux'
import { authActions } from '../auth/AuthStore'

export interface formValuesType {
  avatar?: string | null
  nome: string
  cpf: string
  email: string
  telefone: string
}

export interface AvatarCrop {
  unit: string
  width: number
  height: number
  x: number
  y: number
  aspect: number
}

export const initialAvatarCrop = {
  unit: 'px',
  width: 100,
  height: 100,
  x: 25,
  y: 25,
  aspect: 1 / 1,
} as Partial<AvatarCrop>

const PerfilStore = createSimpleStore(
  {
    edicaoPerfil: false,
    edicaoAvatar: false,
    alterandoSenha: false,
    loading: false,
    error: null as string | null,
    arquivoAvatar: null as string | null,
    avatarCrop: initialAvatarCrop,
    croppedAvatar: null as string | null,
    formValues: {
      avatar: null,
      nome: '',
      cpf: '',
      email: '',
      telefone: '',
    } as formValuesType,
  },
  {
    setLoading(state, loading) {
      state.loading = loading
    },
    ativarEdicaoPerfil(state) {
      state.edicaoPerfil = true
    },
    ativarEdicaoAvatar(state) {
      state.edicaoAvatar = true
    },
    desativarEdicaoAvatar(state) {
      state.edicaoAvatar = false
    },
    finalizarEdicao(state) {
      state.edicaoPerfil = false
    },
    ativarAlteracaoSenha(state) {
      state.alterandoSenha = true
    },
    desativarAlteracaoSenha(state) {
      state.alterandoSenha = false
    },
    updateFormValues(state, formValues: formValuesType) {
      state.formValues = formValues
    },
    updateAvatar(state, avatar: string) {
      state.formValues.avatar = avatar
    },
    resetAvatar(state) {
      state.formValues.avatar = null
    },
    setError(state, error: string | null) {
      state.error = error
    },
    resetError(state) {
      state.error = null
    },
    setAvatarCrop(state, avatarCrop: Partial<AvatarCrop>) {
      state.avatarCrop = avatarCrop
    },
    resetAvatarCrop(state) {
      state.avatarCrop = initialAvatarCrop
    },
    setArquivoAvatar(state, arquivo: string) {
      state.arquivoAvatar = arquivo
    },
    resetArquivoAvatar(state) {
      state.arquivoAvatar = ''
    },
    setCroppedAvatar(state, avatarCropped: string) {
      state.croppedAvatar = avatarCropped
    },
    resetCroppedAvatar(state) {
      state.croppedAvatar = ''
    },
  },
  {
    thunks: {
      updateUsuario(values, dispatchGlobal: Dispatch) {
        return async (dispatch) => {
          dispatch({ type: 'setLoading', payload: true })
          dispatch(PerfilStore.actions.updateFormValues(values))
          try {
            const { data } = await Axios({
              url: '/usuarios',
              method: 'POST',
              data: values,
            })
            dispatchGlobal(authActions.updateToken(data?.jwtToken))
            dispatch(PerfilStore.actions.setLoading(false))
            dispatch(PerfilStore.actions.finalizarEdicao())
            toast('Dados Atualizados com sucesso')
          } catch (error: any) {
            dispatch({ type: 'setLoading', payload: false })
            toast(error.response?.data?.message ?? 'Erro ao atualizar dados do cadastro')
          }
        }
      },
      updateSenhaUsuario(dadosSenha: { novaSenha: string; senhaAtual: string }) {
        return async (dispatch, getState) => {
          dispatch({ type: 'setLoading', payload: true })
          try {
            await Axios({
              url: '/usuarios/altera-senha',
              method: 'POST',
              data: dadosSenha,
            })
            toast('Senha atualizada com sucesso')
            dispatch({ type: 'setLoading', payload: false })
            dispatch({ type: 'desativarAlteracaoSenha' })
          } catch (error: any) {
            toast(error.response?.data?.message ?? 'Erro ao atualizar senha')
            dispatch({ type: 'setLoading', payload: false })
          }
        }
      },
      ativarBetaTesting(email: string, dispatchGlobal: Dispatch) {
        return async (dispatch, getState) => {
          dispatch({ type: 'setLoading', payload: true })
          try {
            const { data } = await Axios({
              url: '/usuarios/ativar-beta-testing',
              method: 'POST',
              data: { email },
            })
            dispatchGlobal(authActions.updateToken(data?.jwtToken))
            dispatch({ type: 'setLoading', payload: false })
            toast('Beta Teste ativado com sucesso')
          } catch (error: any) {
            dispatch({ type: 'setLoading', payload: false })
            toast(error.response?.data?.message ?? 'Erro ao ativar Beta Teste')
          }
        }
      },
    },
  }
)

export { PerfilStore }
