import { IonButton, IonCard, IonIcon, IonRippleEffect } from '@ionic/react'
import { time } from 'ionicons/icons'
import React, { useState } from 'react'
import { Show } from '../../common/Show'
import { useMedia } from 'use-media'

import styled from '@emotion/styled'

import CardBase from '../../components/Card'

const DEFAULT_ICONE_BACKGROUND = 'rgba(255, 184, 0, 0.07)'
const DEFAULT_ICONE_COLOR = '#FFB800'

const Card = styled(CardBase)`
  /* margin: 10px 10px 20px 10px; */
`

function CardSimples(props) {
  // const isWide = useMedia({ minWidth: '769px' })
  // if (isWide) {
  return <CardSimplesDesktop {...props} />
  // }

  // return <CardSimplesMobile {...props} />
}

const CardSimplesDesktop = ({
  icone,
  titulo,
  label,
  iconeBackground = DEFAULT_ICONE_BACKGROUND,
  iconeColor = DEFAULT_ICONE_COLOR,
  className = '',
}) => {
  return (
    <CardSimplesDesktop.Card className={className}>
      <CardSimplesLayout>
        <CardSimplesCirculo background={iconeBackground} color={iconeColor}>
          <CardSimplesIcone size="default" icon={icone} />
        </CardSimplesCirculo>
        <div>
          <CardSimplesTitulo>{titulo}</CardSimplesTitulo>
          <CardSimplesLabel>{label}</CardSimplesLabel>
        </div>
      </CardSimplesLayout>
    </CardSimplesDesktop.Card>
  )
}

CardSimplesDesktop.Card = styled(Card)`
  height: 84px;
  @media (max-width: 768px) {
    height: 70px;
  }
`

const CardSimplesMobile = ({
  icone,
  titulo,
  label,
  iconeBackground = DEFAULT_ICONE_BACKGROUND,
  iconeColor = DEFAULT_ICONE_COLOR,
  className = '',
  textoColor = 'black',
}) => {
  const { Layout, Content, Circulo, Icone, Titulo } = CardSimplesMobile
  return (
    <Card className={className}>
      <Layout>
        <Content>
          <Titulo color={textoColor}>{titulo}</Titulo>
          <CardSimplesLabel>{label}</CardSimplesLabel>
        </Content>
        <Circulo background={iconeBackground} color={iconeColor}>
          <Icone size="default" icon={icone} />
        </Circulo>
      </Layout>
    </Card>
  )
}

CardSimplesMobile.Titulo = styled('div')<{ color }>(
  (props) => `
  color: ${props.color};
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 5px;
`
)

CardSimplesMobile.Circulo = styled('div')<{ background; color }>(
  (props) => `
  display: grid;
  background-color: ${props.background};
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: ${props.color};
`
)

CardSimplesMobile.Icone = styled(IonIcon)`
  align-self: center;
  font-size: 0.8em;
  justify-self: center;
`

CardSimplesMobile.Layout = styled('div')`
  display: grid;
  grid-template-columns: 80% 20%;
`

CardSimplesMobile.Content = styled('div')`
  display: grid;
  margin-top: 15px;
  margin-bottom: 15px;
  align-self: center;
  justify-self: start;
`

const CardMedio = (props) => {
  // const isWide = useMedia({ minWidth: '768px' })
  // if (isWide) {
  return <CardMedioDesktop {...props} />
  // }

  // return <CardMedioMobile {...props} />
}

const CardMedioMobile = (props) => {
  const { titulo, icone, primaryColor, children, onClick = null, className = '' } = props

  return (
    <CardWithoutPadding className={className}>
      <ChildrenContainer>{children}</ChildrenContainer>
      <CardMedioFooter>
        <LinkButtonContainer>
          <LinkButton onClick={onClick} className="ion-activatable ripple-parent">
            Ver mais
            <IonRippleEffect type="unbounded" />
          </LinkButton>
        </LinkButtonContainer>
      </CardMedioFooter>
    </CardWithoutPadding>
  )
}
const CardMedioDesktop = (props) => {
  const { titulo, icone, primaryColor, children, onClick = null, className = '' } = props

  const showVerMais = !!onClick

  return (
    <CardWithoutPadding className={className}>
      <CardMedioHeader>
        <CardMedioIcone color={primaryColor}>
          <IonIcon icon={icone} />
        </CardMedioIcone>
        <CardMedioTitulo>{titulo}</CardMedioTitulo>
      </CardMedioHeader>
      <ChildrenContainer>{children}</ChildrenContainer>
      <CardMedioFooter>
        <Show condition={showVerMais}>
          <LinkButtonContainer>
            <LinkButton onClick={onClick} className="ion-activatable ripple-parent">
              Ver fórum
              <IonRippleEffect type="unbounded" />
            </LinkButton>
          </LinkButtonContainer>
        </Show>
      </CardMedioFooter>
    </CardWithoutPadding>
  )
}

const LinkButton = styled('div')`
  justify-self: center;
  position: relative;
  padding: 5px;
  border-radius: 10%;
`

const LinkButtonContainer = styled('div')`
  cursor: pointer;
  color: #007aff;
  text-align: center;
  text-decoration-line: underline;
  margin-bottom: 18px;
  display: grid;
  width: 100%;
  padding: 8px;
  border-radius: 10%;
`

const ChildrenContainer = styled('div')`
  padding: 18px;
  min-height: 130px;
`

const CardWithoutPadding = styled(Card)`
  padding: 0px;
  height: fit-content;
`

const CardMedioHeader = styled('div')`
  display: grid;
  padding: 8px;
  grid-template-columns: 40px 1fr;
  border-bottom: 1px solid #d9d9d9;
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 0.9em;
    padding: 6px;
    grid-template-columns: 32px 1fr;
  }
`

const CardMedioFooter = styled('div')`
  padding: 8px;
`

const CardMedioIcone = styled('div')`
  color: ${(props) => props.color};
  margin-top: 3px;
  font-size: 1.6em;
  align-self: center;
  justify-self: center;
`

const CardMedioTitulo = styled('div')`
  font-weight: 700;
  letter-spacing: 0.2px;
  color: #2d2d2d;
  align-self: center;
  justify-self: start;
`

const CardFoto = (props) => {
  const isWide = useMedia({ minWidth: '769px' })
  if (isWide) {
    return <CardFotoDesktop {...props} />
  }

  return <CardFotoMobile {...props} />
}

const CardFotoDesktop = ({
  onClick,
  primaryColor,
  secondaryColor,
  image,
  titulo,
  porcentagemConclusao,
}) => {
  return (
    <CardFotoBorder color={secondaryColor}>
      <CardFotoImage src={image} />
      <CardFotoLayout>
        <div>
          <CardFotoDescricao>Módulo em andamento</CardFotoDescricao>
          <CardFotoTitulo color={primaryColor}>{titulo}</CardFotoTitulo>
        </div>
        <CardFotoPorcentagemCirculo color={secondaryColor} porcentagem={porcentagemConclusao}>
          <CardFotoPorcentagem>{porcentagemConclusao || '0'}%</CardFotoPorcentagem>
        </CardFotoPorcentagemCirculo>
      </CardFotoLayout>
      <IonButton onClick={onClick} expand="full">
        {' '}
        Continuar{' '}
      </IonButton>
    </CardFotoBorder>
  )
}

const CardFotoMobile = ({
  onClick,
  primaryColor,
  secondaryColor,
  image,
  titulo,
  porcentagemConclusao,
}) => {
  const { Border, Layout, Image, Titulo, Observacao, Content, TextoObservacao, Icone } =
    CardFotoMobile
  const click = () => null
  return (
    <Border color={primaryColor} onClick={click}>
      <Layout>
        <Image src={image} />
        <Content>
          <Titulo>{titulo}</Titulo>
          <Observacao color={primaryColor}>
            <Icone icon={time} />
            <TextoObservacao> {porcentagemConclusao || '0'}% Completo </TextoObservacao>
          </Observacao>
        </Content>
      </Layout>
    </Border>
  )
}

CardFotoMobile.Border = styled(Card)`
  border-top: 8px solid ${(props) => props.color};
  width: 100%;
  padding: 0px;
  margin: 0px;
`

CardFotoMobile.Content = styled('div')`
  display: grid;
  padding: 8px 8px 8px 20px;
`

CardFotoMobile.Image = styled('img')`
  height: 138px;
`

CardFotoMobile.Observacao = styled('span')`
  align-self: top;
  justify-self: start;
  color: ${(props) => props.color};
`

CardFotoMobile.Icone = styled(IonIcon)`
  margin-top: 2px;
`

CardFotoMobile.TextoObservacao = styled('span')`
  font-weight: 700;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.2px;
  color: #2d2d2d;
`

CardFotoMobile.Titulo = styled('span')`
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.2px;
  color: #f06f30;
  align-self: center;
  justify-self: start;
  margin-bottom: 10px;
`

CardFotoMobile.Layout = styled('div')`
  display: grid;
  grid-template-columns: 30% 70%;
`

const CardSimplesCirculo = styled('div')<{ background }>(
  (props) => `
  display: grid;
  background-color: ${(props: any) => props.background};
  width: 47px;
  height: 47px;
  border-radius: 50%;
  justify-self: center;
  color: ${(props) => props.color};
`
)

const CardSimplesLayout = styled('div')`
  display: grid;
  grid-template-columns: 90px 1fr;
  height: 100%;
  align-items: center;
  @media (max-width: 769px) {
    grid-template-columns: 75px 1fr;
  }
`

const CardSimplesTitulo = styled('div')`
  color: black;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 5px;
`

const CardSimplesLabel = styled('div')`
  color: #88898c;
  font-size: 14px;
`

const CardSimplesIcone = styled(IonIcon)`
  align-self: center;
  justify-self: center;
  color: ${(props) => props.color};
`

const CardFotoBorder = styled(Card)`
  border-top: 8px solid ${(props) => props.color};
  width: 379px;
  padding: 8px;
`

const CardFotoLayout = styled('div')`
  display: grid;
  grid-template-columns: 70% 30%;
`

const CardFotoImage = styled('img')`
  border-radius: 4px;
`

const CardFotoDescricao = styled('div')`
  margin-top: 8px;
  margin-bottom: 4px;
  font-size: 14px;
  color: #2d2d2d;
`

const CardFotoTitulo = styled('div')<{ color }>(
  (props) => `
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 8px;
  color: ${props.color};
`
)

const CardFotoObservacao = styled('div')`
  margin-bottom: 16px;
  display: flex;
  flex: 1;
`

const CardFotoPorcentagem = styled('div')`
  position: absolute;
  width: 100%;
  text-align: center;
  top: 34%;
  font-size: 24px;
  color: #2d2d2d;
`

const Circulo = ({ className = '', children }) => {
  return (
    <div className={className}>
      <svg height="80" width="80">
        <circle className="background" cx="40" cy="40" r="36" />
        <circle className="primary" cx="40" cy="40" r="36" />
      </svg>
      {children}
    </div>
  )
}

const CardFotoPorcentagemCirculo = styled(Circulo)<{ color; porcentagem }>(
  (props) => `
  circle {
    fill-opacity: 0.0;
    stroke: ${props.color};
    stroke-width: 4;
    align-self: center;
  }

  circle.primary {
    stroke-dasharray: ${(props.porcentagem * 226) / 100}, 500;
    transform: rotate(90deg);
    transform-origin: center center;
    justify-self: end;
    animation: rotate 2s linear;
  }

  circle.background {
    stroke: #DDD;
    stroke-dasharray: 226, 500;
  }

  @keyframes rotate {
    from {
      stroke-dasharray: 0, 500;
    }

    to {
      stroke-dasharray: ${(props.porcentagem * 226) / 100}, 500;
    }
  }

  position: relative;
  width: 80px;
  height: 80px;
  align-self: center;
  justify-self: center;
  text-align: center;
  margin: 14px 8px;
`
)

const CardFotoIconeExpirar = styled('div')`
  margin-right: 5px;
  color: ${(props) => props.color};
`

const CardFotoTextoExpirar = styled('div')`
  font-size: 12px;
`

const MobileCardContainer = ({ children, titulo, icone = '', color = '' }) => {
  const { Container, Titulo, Icone } = MobileCardContainer
  return (
    <Container>
      {icone && (
        <Icone color={color}>
          <IonIcon icon={icone} />
        </Icone>
      )}
      <Titulo> {titulo} </Titulo>
      {children}
    </Container>
  )
}

MobileCardContainer.Icone = styled('div')`
  margin-left: 10px;
  margin-right: 13px;
  font-size: 1.5em;
  display: inline-block;
  color: ${(props) => props.color};
`

MobileCardContainer.Titulo = styled('span')`
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  font-weight: 700;
`

MobileCardContainer.Container = styled('div')`
  padding: 18px 10px 10px 10px;
  border-top: 1px solid #eef0f7;
  width: 100%;
`

export { CardSimples, MobileCardContainer, CardMedio, CardFoto }
