import { createSimpleStore } from 'react-simple-reducer'
import axios from '../common/axios'
import { MatriculaModel } from '../models/MatriculaModel'

export const CertificadoStore = createSimpleStore(
  {
    pdf: '' as string,
    matricula: {} as MatriculaModel,
    loading: true,
    errorReason: null as string | null,
  },
  {
    getCertificadoStarted(state) {
      state.loading = true
    },
    getCertificadoSuccess(state, payload: { pdf; matricula }) {
      state.pdf = payload.pdf
      state.matricula = payload.matricula
      state.loading = false
    },
    getCertificadoError(state, payload: string) {
      state.errorReason = payload
      state.loading = false
    },
  },
  {
    thunks: {
      getCertificadoValidacao(certificadoId) {
        return async (dispatch, getState) => {
          try {
            dispatch(CertificadoStore.actions.getCertificadoStarted())
            const { pdf, matricula } = await axios.Certificado.getCertificadoValidacao(
              certificadoId
            )
            const uint8Array = new Uint8Array(pdf.data)
            const blob = new Blob([uint8Array], { type: 'application/pdf' })
            const urlPdf = URL.createObjectURL(blob)

            dispatch(CertificadoStore.actions.getCertificadoSuccess({ pdf: urlPdf, matricula }))
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro não especificado'
            dispatch(CertificadoStore.actions.getCertificadoError(message))
          }
        }
      },
    },
  }
)
