import React from 'react'
import { SvgType } from './index'
const RefreshSvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.6644 9.92888C14.6644 13.3193 11.8992 15.7648 8.5 15.7648C5.10104 15.7648 2.33581 13.1042 2.33581 9.87734C2.33581 6.65052 5.10104 4.05206 8.5 4.05206H8.7362V5.57303L13.7789 2.78629L8.7362 0V1.79175H8.5C3.81302 1.79175 0 5.45142 0 9.91102C0 14.3706 3.81302 18 8.5 18C13.1872 18 17 13.9974 17 9.92888H14.6644Z"
        fill={color}
      />
    </svg>
  )
}

export default RefreshSvg
