import React from 'react'
import styled from '@emotion/styled'
import { Card, TituloPagina, Button } from '../components'
import useAxios from 'axios-hooks'
import PdfSvg from '../svgs/pdf'
import WordSvg from '../svgs/microsoft-word'
import ExcelSvg from '../svgs/microsoft-excel'
import FolderSvg from '../svgs/folder'
import VideoSvg from '../svgs/camera'
import LinkSvg from '../svgs/link'
import PlaySvg from '../svgs/play'
import DownloadSvg from '../svgs/download'
import LaptopSvg from '../svgs/laptop'
import { createSimpleStore } from 'react-simple-reducer'
import { DownloadModel } from '../models/DownloadModel'
import Axios from 'axios'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { useDecorateBlobWithSas } from '../common/hooks/useDecorateBlobWithSas'

const Store = createSimpleStore(
  {
    loading: false,
    arquivos: [] as DownloadModel[],
  },
  {
    getArquivosStarted(state) {
      state.loading = true
    },
    getArquivosSuccess(state, arquivos) {
      state.arquivos = arquivos
      state.loading = false
    },
    getArquivosError(state) {
      state.loading = false
    },
  },
  {
    thunks: {
      getArquivos({ cursoId }) {
        return async (dispatch) => {
          try {
            dispatch(Store.actions.getArquivosStarted())
            const arquivos = await Axios.get(`/app/downloads/curso/${cursoId}`).then((x) => x?.data)
            dispatch(Store.actions.getArquivosSuccess(arquivos))
          } catch (error: any) {
            toast(error.response?.data?.message ?? 'Ocorreu um erro')
            dispatch(Store.actions.getArquivosError())
          }
        }
      },
    },
  }
)

interface BibliotecaParams {
  cursoId: string
}
const Biblioteca = () => {
  const { Layout, Titulo, Observacao } = Biblioteca

  const { cursoId } = useParams<BibliotecaParams>()

  const init = React.useCallback(
    (dispatch: ReturnType<typeof Store.useDispatch>) => {
      dispatch(Store.thunks.getArquivos({ cursoId }))
    },
    [cursoId]
  )

  return (
    <Store.Provider init={init}>
      <Layout>
        <Titulo>Biblioteca</Titulo>
        <Observacao>Arquivos disponíveis para consulta:</Observacao>
        <ListagemAnexos />
      </Layout>
    </Store.Provider>
  )
}
Biblioteca.Layout = styled('div')`
  @media (max-width: 769px) {
    padding: 24px;
  }
  @media (max-width: 450px) {
    padding: 12px;
  }
  display: grid;
  grid-template-rows: auto auto;
`
Biblioteca.Titulo = styled(TituloPagina)`
  margin-bottom: 18px;
`
Biblioteca.Observacao = styled('div')`
  padding-left: 12px;
  margin-bottom: 28px;
  display: grid;
  grid-template-rows: 2;
`

const ListagemAnexos = () => {
  const { Layout } = ListagemAnexos
  const { arquivos } = Store.useState()
  if (!arquivos.length) {
    return <EmptyState />
  }
  return (
    <Layout>
      {arquivos.map((anexo, key) => {
        if (anexo.tipo === 'arquivo') {
          return <Arquivo key={key} arquivo={anexo} />
        }

        if (anexo.tipo === 'video') {
          return <Video key={key} anexo={anexo} />
        }

        if (anexo.tipo === 'link') {
          return <Link key={key} anexo={anexo} />
        }

        return <></>
      })}
    </Layout>
  )
}

ListagemAnexos.Layout = styled('div')`
  display: grid;
  grid-gap: 12px;
  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
  @media (max-width: 1024px) {
    grid-template-columns: auto;
  }
`

const EmptyState = () => {
  const { Layout } = EmptyState
  return (
    <Layout>
      <div>
        <strong>Nenhum arquivo encontrado no momento.</strong>
      </div>
      <div>Aqui você encontra os materiais complementares para seu estudo.</div>
    </Layout>
  )
}
EmptyState.Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  color: #333333;
`

const Link = (props) => {
  const { Layout, Icone, Conteudo, Titulo, Detalhes } = Arquivo

  const reg = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/gim as any
  const dominio = reg.exec(props.anexo.url)[1]

  return (
    <Layout>
      <Icone>
        <LinkSvg />
      </Icone>
      <Conteudo>
        <Titulo>{props.anexo.titulo}</Titulo>
        <Detalhes>
          <span style={{ marginRight: '8px' }}>{dominio}</span>
        </Detalhes>
        <div style={{ flex: 1 }} />
        <ButtonContainer
          svg={LaptopSvg}
          label="Ir para o site"
          onClick={() => window.open(props.anexo.url)}
        ></ButtonContainer>
      </Conteudo>
    </Layout>
  )
}

const Video = (props) => {
  const { Layout, Icone, Conteudo, Titulo, Detalhes } = Arquivo

  return (
    <Layout>
      <Icone>
        <VideoSvg />
      </Icone>
      <Conteudo>
        <Titulo>{props.anexo.titulo}</Titulo>
        <Detalhes>
          <span style={{ marginRight: '8px' }}>{props.anexo.url}</span>
        </Detalhes>
        <div style={{ flex: 1 }} />
        <ButtonContainer
          svg={PlaySvg}
          label="Ir para o video"
          onClick={() => window.open(props.anexo.url)}
        ></ButtonContainer>
      </Conteudo>
    </Layout>
  )
}

const Arquivo = ({ arquivo }: { arquivo: DownloadModel }) => {
  const ARQUIVOS_CONHECIDOS = {
    pdf: 'pdf',
    doc: 'word',
    docx: 'word',
    xls: 'excel',
    xlsx: 'excel',
  }

  const { Layout, Icone, Conteudo, Titulo, Detalhes, Svg } = Arquivo

  const linkArquivo = useDecorateBlobWithSas({ blobPath: arquivo.link })

  function handleClickDownload() {
    window.open(linkArquivo)
  }

  return (
    <Layout>
      <Icone>
        <Svg extensaoArquivo={ARQUIVOS_CONHECIDOS[arquivo.tipoArquivo]} />
      </Icone>
      <Conteudo>
        <Titulo>{arquivo.nome}</Titulo>
        <Detalhes>
          <div>
            <span style={{ marginRight: '8px' }}>{arquivo.tipoArquivo}</span>
            <span>{arquivo.tamanho}</span>
          </div>
        </Detalhes>
        <div style={{ flex: 1 }} />
        <ButtonContainer svg={DownloadSvg} label="Download" onClick={handleClickDownload} />
      </Conteudo>
    </Layout>
  )
}

const ButtonContainer = styled(Button)`
  display: block;
  margin-bottom: 18px;
  max-width: fit-content;
`

Arquivo.Svg = (props) => {
  const ARQUIVO_ICONE = {
    word: WordSvg,
    excel: ExcelSvg,
    pdf: PdfSvg,
    desconhecido: FolderSvg,
  }

  const SvgArquivo = ARQUIVO_ICONE[props.extensaoArquivo] || ARQUIVO_ICONE.desconhecido

  return <SvgArquivo {...props}></SvgArquivo>
}

Arquivo.Icone = styled('div')`
  padding-top: 10px;
  text-align: center;
  margin-top: 20px;
  margin-left: 25px;
  width: 50px;
  height: 50px;
  background-color: #f2f2f2;
  border-radius: 4px;
`

Arquivo.Titulo = styled('div')`
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  min-height: 28px;
  font-size: 18px;
  line-height: 21px;
  color: #2d2d2d;
  margin-bottom: 8px;
`

Arquivo.Detalhes = styled('div')`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #2d2d2d;
  margin-bottom: 18px;
`

Arquivo.Conteudo = styled('div')`
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`

Arquivo.Layout = styled(Card)`
  width: 100%;
  display: grid;
  grid-template-columns: 103px auto;
`

export default Biblioteca
