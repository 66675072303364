import { CursoModel } from './CursoModel'
import { UsuarioModel } from './UsuarioModel'

export class MatriculaModel {
  id: number
  createdAt: string
  matricula: string
  dataPagamento: Date
  referenciaPagamento: string
  uf: string
  statusDetran: STATUS_MATRICULA
  cpfInstrutor: string
  dataInicioCurso: Date
  dataFimCurso: Date
  dataInicioModuloTeorico: Date
  dataFimModuloTeorico: Date
  dataInicioModuloPratico: string
  dataFimModuloPratico: string
  dataValidadeCurso: Date
  identificacaoCertificado: string
  nota: string
  dataValidacaoFacialInicial: Date
  dataValidacaoFacialFinal: Date
  codTicket?: string
  cursoId: number
  curso: CursoModel

  alunoId: number
  aluno: UsuarioModel
  renach: string

  cupomId: number
  cupom: CupomModel

  permiteEmissaoCertificado: boolean
  dataEmissaoCertificado: Date

  parceiroId?: number
  captadorId?: number
  parceiroCursoPraticoId?: string
}

export enum STATUS_MATRICULA {
  AGUARDANDO_PAGAMENTO = 'AGUARDANDO_PAGAMENTO',
  MATRICULADO = 'MATRICULADO',
  FINALIZADO = 'FINALIZADO',
  AGUARDANDO_ENVIO = 'AGUARDANDO_ENVIO',
  AGUARDANDO_MATRICULA_MODULO_PRATICO = 'AGUARDANDO_MATRICULA_MODULO_PRATICO',
  AGUARDANDO_CONCLUSAO_MODULO_PRATICO = 'AGUARDANDO_CONCLUSAO_MODULO_PRATICO',
  AGUARDANDO_MATRICULA = 'AGUARDANDO_MATRICULA',
}

export type TSTATUS_MATRICULA = keyof typeof STATUS_MATRICULA

export type MEIO_PAGAMENTO = null | 'BOLETO' | 'CARTAO_CREDITO' | 'PIX'

export interface IContentPassoAPasso {
  titles: string[]
  imgs: any[]
  contents: string[]
}

export interface IContentComoFunciona {
  paraQuem: string
  objetivo: string
  tempoCurso: string
  tutores: string
}

export interface DadosAlunoModel {
  nome: string
  cpf: string
  renach: string
  dataNascimento: string
  dataPrimeiraHabilitacao?: string
  estado?: string
  municipio?: string
  email: string
  telefone: string
  cnh?: string
  categoriaCnh?: string
  codigoMunicipio?: string
}

export interface CupomModel {
  codigo: string
  valorDesconto: number
}

export interface Estado {
  nome: string
  uf: string
  link: string
  valor: number
  campos: string[]
  passoAPasso?: IContentPassoAPasso
  comoFunciona?: IContentComoFunciona
}

export interface CursoEstado {
  id: number
  curso: string
  estados: Estado[]
}
