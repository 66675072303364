import React from 'react'
import { SvgType } from './index'
const GraduationCapSvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="50"
      height="42"
      viewBox="0 0 50 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.3"
        d="M13.7493 21.6064V29.9764L24.9993 36.1189L36.2493 29.9764V21.6064L24.9993 27.7489L13.7493 21.6064ZM9.6543 14.2489L24.9993 22.6189L40.3443 14.2489L24.9993 5.87891L9.6543 14.2489Z"
        fill="#FF8718"
      />
      <path
        d="M25 0.75L0.25 14.25L9.25 19.155V32.655L25 41.25L40.75 32.655V19.155L45.25 16.7025V32.25H49.75V14.25L25 0.75ZM36.25 29.9775L25 36.12L13.75 29.9775V21.6075L25 27.75L36.25 21.6075V29.9775ZM25 22.62L9.655 14.25L25 5.88L40.345 14.25L25 22.62Z"
        fill="#F06F30"
      />
    </svg>
  )
}

export default GraduationCapSvg
