import React from 'react'
import { SvgType } from './index'
const ClockFilledSvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.3"
        d="M23 5C13.055 5 5 13.055 5 23C5 32.945 13.055 41 23 41C32.945 41 41 32.945 41 23C41 13.055 32.945 5 23 5ZM32.5625 32.3375L20.75 25.25V11.75H24.125V23.5625L34.25 29.57L32.5625 32.3375Z"
        fill="#FFDBBA"
      />
      <path
        d="M22.9775 0.5C10.5575 0.5 0.5 10.58 0.5 23C0.5 35.42 10.5575 45.5 22.9775 45.5C35.42 45.5 45.5 35.42 45.5 23C45.5 10.58 35.42 0.5 22.9775 0.5ZM23 41C13.055 41 5 32.945 5 23C5 13.055 13.055 5 23 5C32.945 5 41 13.055 41 23C41 32.945 32.945 41 23 41ZM24.125 11.75H20.75V25.25L32.5625 32.3375L34.25 29.57L24.125 23.5625V11.75Z"
        fill="#F06F30"
      />
    </svg>
  )
}

export default ClockFilledSvg
