import useAxios from 'axios-hooks'
import { useEffect } from 'react'

export const useDecorateBlobWithSas = ({
  blobPath,
}: {
  blobPath: string | undefined
}): string | undefined => {
  const [{ data }, request] = useAxios<{ blobUrl: string }>(`/arquivos/?blobPath=${blobPath}`, {
    manual: true,
  })
  useEffect(() => {
    if (blobPath) request()
  }, [blobPath])

  if (!blobPath) return undefined
  return data?.blobUrl
}
