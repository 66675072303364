import React, { useEffect, ReactChildren, useState } from 'react'
import { IonPage, IonContent, IonButton, IonIcon, IonSpinner } from '@ionic/react'
import Header from '../components/Header'
import styled from '@emotion/styled'
import { ForunsSelectors, ForunsStore } from './ForunsStore'
import { useHistory, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { modulosActions, getModulos } from '../modulos/ModulosStore'
import { AppAvatar } from '../components/AppAvatar'
import { format, parseISO, formatRelative } from 'date-fns'
import { getUsuarioLogado } from '../auth/AuthStore'
import { TextFieldInput } from '../common/TextField'
import { ForumMensagemModel } from '../models/ForumMensagemModel'
import { gridAreas } from '../common/styles/CommonStyles'
import { ForumMensagemReacaoModel } from '../models/ForumMensagemReacaoModel'
import { happy, happyOutline } from 'ionicons/icons'
import defaultUseAxios from 'axios-hooks'
import { toast } from 'react-toastify'
import { Show } from '../common/Show'
import ptBR from 'date-fns/locale/pt-BR'
import { ForumModel } from '../models/ForumModel'

interface ForumDetailParams {
  cursoId
  forumId
}
const ForumDetail = () => {
  const reduxDispatch = useDispatch()
  const dispatch = ForunsStore.useDispatch()
  const { cursoId, forumId } = useParams<ForumDetailParams>()
  useEffect(() => {
    reduxDispatch(modulosActions.loadModulos(cursoId))
    dispatch(ForunsStore.thunks.getForum({ forumId }))
  }, [])

  const { Layout } = ForumDetail
  return (
    <Layout>
      <div className="container">
        <Forum>
          <div /> <div />
          {/* <Reacoes forumId={+forumId!} /> */}
          {/* <NovaReacao forumId={+forumId!} /> */}
        </Forum>
        <NovaRespostaForum />
        <Respostas />
      </div>
    </Layout>
  )
}
ForumDetail.Layout = styled.div`
  max-width: 1024px;
  margin: 24px auto;
  font-size: 1em;
  @media (min-width: 425px) {
    font-size: 1.2em;
  }
  .container {
    background: white;
    max-width: 800px;
    @media (min-width: 800px) {
      margin: 12px auto;
    }
    @media (max-width: 425px) {
      padding: 8px;
    }

    .titulo {
      grid-area: titulo;
      margin: 0;
      font-size: 1.4em;
      font-weight: bold;
    }
    .modulo {
      grid-area: modulo;
      font-size: 1.2em;
      font-style: italic;
    }
    .avatar {
      grid-area: avatar;
    }
    .nome-usuario {
      grid-area: nome-usuario;
      color: var(--ion-color-primary);
      font-weight: 300;
      font-size: 0.9em;
    }
    .texto {
      grid-area: texto;
    }
    .reacoes {
      grid-area: reacoes;
    }
    .data {
      grid-area: data;
      color: #afafaf;
      font-size: 0.7em;
      align-self: center;
    }
    .nova-reacao {
      grid-area: nova-reacao;
    }
    .textarea {
      grid-area: textarea;
    }
    .botoes {
      grid-area: botoes;
    }
    .novo-comentario {
      grid-area: novo-comentario;
    }
  }
`

const Forum = ({ children }: { children }) => {
  const { forumId } = useParams<ForumDetailParams>()
  const { foruns } = ForunsStore.useState()
  const forum = foruns.find((f) => +f.id === +forumId)
  const modulos = useSelector(getModulos)
  const modulo = modulos.find((m) => m.id === forum?.moduloId)
  const compReacoes = children.find((c) => c.type === Reacoes)
  const compNovaReacao = children.find((c) => c.type === NovaReacao)
  if (!forum) return null
  return (
    <Styles.Forum>
      <h3 className="titulo">{forum.texto}</h3>
      <div className="modulo">{modulo?.nome}</div>
      <div className="avatar">
        <AppAvatar usuario={forum.usuario} tamanho={45} circular />
      </div>
      <div className="nome-usuario">{forum.usuario.nome}</div>
      <div className="texto">{forum.texto}</div>
      <div className="reacoes">{compReacoes}</div>
      <div className="data">
        {format(parseISO(forum.createdAt ?? new Date()), 'dd/MM/yyyy hh:mm')}
      </div>
      <div className="nova-reacao">{compNovaReacao}</div>
    </Styles.Forum>
  )
}

const DisplayFlex = styled('div', { label: 'DisplayFlex' })<{ marginBetween?: number }>(
  (props) => `
  display: flex;
  ${
    props.marginBetween
      ? `&>*:not(:first-child) {
          margin-left: ${props.marginBetween}px;
        }`
      : ''
  }
`
)
const Flex = styled('div')`
  flex: 1;
`

const Reacoes = ({
  forumId,
  reacoes,
}: {
  forumId?: number
  reacoes?: ForumMensagemReacaoModel[]
}) => {
  const state = ForunsStore.useState()
  if (forumId) reacoes = state.foruns.find((f) => f.id === forumId)?.forunsMensagensReacoes
  return (
    <>
      {' '}
      {reacoes?.map((r) => (
        <Reacao key={r.id} reacao={r} />
      ))}{' '}
    </>
  )
}

const Reacao = ({ reacao }: { reacao: ForumMensagemReacaoModel }) => {
  return <Styles.Reacao>(Y)</Styles.Reacao>
}

// const NovaReacao = ({forumId = null as number | null}) => {
const NovaReacao = ({
  forumId,
  forumMensagemId,
}: {
  forumId?: number
  forumMensagemId?: number
}) => {
  return (
    <>
      <IonButton size="small" color="dark" fill="clear">
        + <IonIcon icon={happyOutline} />
      </IonButton>
    </>
  )
}

const NovaRespostaForum = ({
  forumMensagemId,
  onCancel,
}: {
  forumMensagemId?: number
  onCancel?
}) => {
  const { cursoId, forumId } = useParams<ForumDetailParams>()
  const dispatch = ForunsStore.useDispatch()
  const history = useHistory()
  const usuarioLogado = useSelector(getUsuarioLogado)
  const [texto, setTexto] = useState('')
  const [{ loading }, reqEnviaResposta] = defaultUseAxios(
    {
      method: 'POST',
      url: '/app/foruns/mensagem',
      data: { texto, forumId, forumMensagemId },
    },
    { manual: true }
  )

  async function enviaResposta() {
    try {
      const data = await reqEnviaResposta().then((x) => x?.data)
      dispatch({ type: 'adicionaResposta', payload: data })
      setTexto('')
      toast('Resposta criada com sucesso')
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'Ocorreu um erro ao enviar uma resposta'
      toast(message)
    }
  }

  function handleClickCancel() {
    if (onCancel) return onCancel()
    history.replace(`/curso/${cursoId}/foruns`)
  }

  const small = !!forumMensagemId

  return (
    <Styles.NovaRespostaForum small={small}>
      <div className="avatar-resposta">
        <AppAvatar usuario={usuarioLogado} tamanho={small ? 36 : 45} circular />
      </div>
      <div className="textarea">
        <TextFieldInput value={texto} onChange={(e) => setTexto(e.target.value)}>
          <textarea style={{ height: small ? 120 : 180, paddingTop: 16 }} />
        </TextFieldInput>
      </div>
      <div className="botoes">
        <IonButton onClick={enviaResposta} size={small ? 'small' : 'default'}>
          {loading ? <IonSpinner /> : 'Responder'}
        </IonButton>
        <IonButton size={small ? 'small' : 'default'} onClick={handleClickCancel}>
          Cancelar
        </IonButton>
      </div>
    </Styles.NovaRespostaForum>
  )
}

const Respostas = () => {
  const { forumId } = useParams<ForumDetailParams>()
  const { foruns } = ForunsStore.useState()
  const forum = foruns.find((f) => +f.id === +forumId)
  return (
    <Styles.Respostas>
      <h3>
        {forum?.forunsMensagens.length} Resposta
        {(forum?.forunsMensagens.length ?? 0) <= 1 ? '' : 's'}
      </h3>
      {forum?.forunsMensagens.map((fm) => (
        <ForumMensagem key={fm.id} forumMensagem={fm} />
      ))}
    </Styles.Respostas>
  )
}

const ForumMensagem = ({
  forumMensagem,
  comentario = false,
}: {
  forumMensagem: ForumMensagemModel
  comentario?: boolean
}) => {
  const dispatch = ForunsStore.useDispatch()
  const { forumMensagemIdComentario } = ForunsStore.useState()
  return (
    <Styles.ForumMensagem comentario={comentario}>
      <div className="avatar">
        <AppAvatar usuario={forumMensagem.usuario} circular tamanho={45} />
      </div>
      <div className="nome-usuario">{forumMensagem.usuario.nome}</div>
      <div className="texto">
        <TextoForumMensagem forumMensagem={forumMensagem} />
      </div>
      <div className="reacoes">
        {/* <Reacoes reacoes={forumMensagem.forunsMensagensReacoes} /> */}
      </div>
      <div className="rodape">
        <Rodape forumMensagem={forumMensagem} showComentar={!comentario} />
      </div>
      <Show condition={forumMensagemIdComentario === forumMensagem.id}>
        <div className="novo-comentario">
          <NovaRespostaForum
            forumMensagemId={forumMensagem.id}
            onCancel={() => dispatch({ type: 'cancelarComentar' })}
          />
        </div>
      </Show>
      <div className="comentarios">
        {forumMensagem.forunsMensagens?.map((comentario) => (
          <ForumMensagem key={comentario.id} forumMensagem={comentario} comentario />
        ))}
      </div>
    </Styles.ForumMensagem>
  )
}

const TextoForumMensagem = ({ forumMensagem }: { forumMensagem: ForumMensagemModel }) => {
  const dispatch = ForunsStore.useDispatch()
  const { forumMensagemIdEdicao } = ForunsStore.useState()
  const { forumId } = useParams<ForumDetailParams>()
  const [texto, setTexto] = useState(forumMensagem.texto)
  useEffect(() => {
    setTexto(forumMensagem.texto)
  }, [forumMensagem.texto])

  const [{ loading }, reqAtualizaResposta] = defaultUseAxios(
    {
      method: 'POST',
      url: '/app/foruns/mensagem',
      data: { texto, id: forumMensagem.id },
    },
    { manual: true }
  )

  async function handleClickSalvar() {
    try {
      await reqAtualizaResposta()
      dispatch({
        type: 'atualizaForumMensagem',
        payload: {
          forumId,
          forumMensagemId: forumMensagem.id,
          forumMensagemPaiId: forumMensagem.forumMensagemId,
          texto,
        },
      })
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'Ocorreu um erro ao enviar uma resposta'
      toast(message)
    }
  }
  function handleClickCancelar() {
    dispatch({ type: 'cancelarEditarForumMensagem' })
  }
  if (forumMensagem.id !== forumMensagemIdEdicao) {
    return <>{forumMensagem.texto}</>
  }
  return (
    <div>
      <TextFieldInput value={texto} onChange={(e) => setTexto(e.target.value)}>
        <textarea style={{ paddingTop: 6 }} rows={3} />
      </TextFieldInput>
      <DisplayFlex marginBetween={6}>
        <IonButton size="small" onClick={handleClickSalvar}>
          {loading ? <IonSpinner /> : 'Salvar'}
        </IonButton>
        <IonButton size="small" onClick={handleClickCancelar}>
          Cancelar
        </IonButton>
      </DisplayFlex>
    </div>
  )
}

const Rodape = ({
  forum = null as ForumModel | null,
  forumMensagem = null as ForumMensagemModel | null,
  showComentar = false,
}) => {
  const dispatch = ForunsStore.useDispatch()
  const usuarioLogado = useSelector(getUsuarioLogado)
  const showEditar =
    forum?.usuarioId === usuarioLogado.id || forumMensagem?.usuarioId === usuarioLogado.id

  const data = forumMensagem?.createdAt || forum?.createdAt
  const dataRelativa = data ? formatRelative(parseISO(data), new Date(), { locale: ptBR }) : ''

  function handleClickBotaoComentar() {
    dispatch({ type: 'comentar', payload: forumMensagem?.id ?? 0 })
  }
  function handleClickBotaoEditar() {
    dispatch({ type: 'editarForumMensagem', payload: forumMensagem?.id ?? 0 })
  }

  return (
    <Styles.Rodape>
      <div className="data">{dataRelativa}</div>
      <div className="nova-reacao">
        {/* <NovaReacao forumId={forum?.id} forumMensagemId={forumMensagem?.id} /> */}
      </div>
      <Show condition={showComentar}>
        <div className="botao-comentar">
          <IonButton size="small" color="dark" fill="clear" onClick={handleClickBotaoComentar}>
            Comentar
          </IonButton>
        </div>
      </Show>
      <Show condition={showEditar}>
        <div className="botao-editar">
          <IonButton size="small" color="dark" fill="clear" onClick={handleClickBotaoEditar}>
            Editar
          </IonButton>
        </div>
      </Show>
    </Styles.Rodape>
  )
}

const Styles = {
  IonContent: styled(IonContent)`
    font-family: 'Roboto Condensed', sans-serif;
    --background: #f7f7f7;
    font-size: 1em;
    @media (min-width: 425px) {
      font-size: 1.2em;
    }
    .container {
      background: white;
      max-width: 800px;
      @media (min-width: 800px) {
        margin: 12px auto;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
      }
      @media (max-width: 425px) {
        padding: 8px;
      }

      .titulo {
        grid-area: titulo;
        margin: 0;
        font-size: 1.4em;
        font-weight: bold;
      }
      .modulo {
        grid-area: modulo;
        font-size: 1.2em;
        font-style: italic;
      }
      .avatar {
        grid-area: avatar;
      }
      .nome-usuario {
        grid-area: nome-usuario;
        color: var(--ion-color-primary);
        font-weight: 600;
      }
      .texto {
        grid-area: texto;
      }
      .reacoes {
        grid-area: reacoes;
      }
      .data {
        grid-area: data;
        color: #afafaf;
        font-size: 0.7em;
        align-self: center;
      }
      .nova-reacao {
        grid-area: nova-reacao;
      }
      .textarea {
        grid-area: textarea;
      }
      .botoes {
        grid-area: botoes;
      }
      .novo-comentario {
        grid-area: novo-comentario;
      }
    }
  `,
  Forum: styled('div')`
    display: grid;
    grid-template-areas:
      'titulo titulo titulo'
      'modulo modulo modulo'
      'avatar nome-usuario nome-usuario'
      'avatar texto texto'
      'avatar reacoes reacoes'
      'avatar data nova-reacao';
    grid-template-columns: 61px 120px 1fr;
  `,
  NovaRespostaForum: styled('div')<{ small }>(
    (props) => `
    display: grid;
    grid-template-columns: 61px;
    grid-template-areas:
      "avatar textarea"
      "avatar botoes";
    ${props.small ? 'grid-template-columns: 52px;' : ''}
  `
  ),
  Respostas: styled('div')``,
  ForumMensagem: styled('div')<{ comentario }>(
    (props) => `
    display: grid;
    grid-template-columns: 61px;
    padding-top: 12px;
    grid-template-areas:
      "avatar nome-usuario"
      "avatar texto"
      "avatar reacoes"
      "avatar novo-comentario"
      "avatar rodape"
      "avatar comentarios";
    border-top: 1px solid #ccc;
    ${
      props.comentario
        ? `
      font-size: .9em;
      padding-top: 9px;
    `
        : ''
    }
  `
  ),
  Reacao: styled('div')``,
  Rodape: styled('div')`
    display: flex;
    .data {
      padding-right: 12px;
    }
    & > *:not(:first-child) {
      position: relative;
      &:before {
        content: '';
        background: #c4c4c4;
        position: absolute;
        width: 1px;
        height: 10px;
        top: 12px;
      }
    }
  `,
}

export { ForumDetail }
