import React from 'react'
import { SvgType } from './index'
const CheckmarkCircleSvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="11.2324" cy="11" r="11" fill={color} />
      <path
        d="M14.4521 7.88666C14.745 7.59377 15.2199 7.59377 15.5128 7.88666C15.8056 8.17956 15.8056 8.65443 15.5128 8.94732L11.0128 13.4473C10.7199 13.7402 10.245 13.7402 9.95209 13.4473L7.45209 10.9473C7.1592 10.6544 7.1592 10.1796 7.45209 9.88666C7.74499 9.59377 8.21986 9.59377 8.51275 9.88666L10.4824 11.8563L14.4521 7.88666Z"
        fill="white"
      />
    </svg>
  )
}

export default CheckmarkCircleSvg
