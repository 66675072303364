import React from 'react'

const MicrosoftExcelSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M13.812 0.609997C13.6934 0.513622 13.5372 0.476778 13.388 0.510012L0.388054 3.51001C0.159257 3.56261 -0.00218085 3.76726 2.22753e-05 4.00201V21.002C0.000116025 21.2487 0.180163 21.4585 0.424007 21.496L13.424 23.4961C13.6969 23.538 13.9522 23.3508 13.9942 23.0779C13.998 23.0528 14 23.0274 14 23.0021V1.00201C14.0005 0.849528 13.9312 0.7052 13.812 0.609997Z"
          fill="#4CAF50"
        />
        <path
          d="M23.5 21.5019H13.5C13.2239 21.5019 13 21.2781 13 21.0019C13 20.7258 13.2239 20.5019 13.5 20.5019H23V3.50194H13.5C13.2239 3.50194 13 3.27806 13 3.00192C13 2.72578 13.2239 2.50195 13.5 2.50195H23.5C23.7761 2.50195 24 2.72583 24 3.00197V21.002C24 21.2781 23.7762 21.5019 23.5 21.5019Z"
          fill="#4CAF50"
        />
        <path
          d="M9.50011 16.5038C9.32771 16.5038 9.16749 16.415 9.07613 16.2688L4.07611 8.26884C3.92241 8.03943 3.98381 7.72884 4.21322 7.57513C4.44263 7.42143 4.75322 7.48284 4.90692 7.71224C4.91297 7.72124 4.91869 7.73043 4.92413 7.7398L9.92414 15.7398C10.0699 15.9743 9.99802 16.2826 9.7635 16.4285C9.68442 16.4776 9.59321 16.5037 9.50011 16.5038Z"
          fill="#FAFAFA"
        />
        <path
          d="M4.51485 16.5019C4.23871 16.5014 4.01531 16.2771 4.01587 16.0009C4.01606 15.9076 4.0424 15.8161 4.09185 15.7369L9.09187 7.73691C9.23034 7.49799 9.53629 7.41657 9.77517 7.55504C10.0141 7.69351 10.0955 7.99946 9.95704 8.23833C9.9516 8.24771 9.94589 8.2569 9.93984 8.2659L4.93987 16.2659C4.84856 16.4128 4.68782 16.5021 4.51485 16.5019Z"
          fill="#FAFAFA"
        />
        <path
          d="M17.5 21.502C17.2239 21.502 17 21.2781 17 21.002V3.00197C17 2.72583 17.2239 2.50195 17.5 2.50195C17.7762 2.50195 18 2.72583 18 3.00197V21.002C18 21.2782 17.7762 21.502 17.5 21.502Z"
          fill="#4CAF50"
        />
        <path
          d="M23.5 18.502H13.5C13.2239 18.502 13 18.2781 13 18.002C13 17.7258 13.2239 17.502 13.5 17.502H23.5C23.7761 17.502 24 17.7258 24 18.002C24 18.2782 23.7762 18.502 23.5 18.502Z"
          fill="#4CAF50"
        />
        <path
          d="M23.5 15.502H13.5C13.2239 15.502 13 15.2781 13 15.002C13 14.7258 13.2239 14.502 13.5 14.502H23.5C23.7761 14.502 24 14.7258 24 15.002C24 15.2782 23.7762 15.502 23.5 15.502Z"
          fill="#4CAF50"
        />
        <path
          d="M23.5 12.502H13.5C13.2239 12.502 13 12.2781 13 12.002C13 11.7258 13.2239 11.502 13.5 11.502H23.5C23.7761 11.502 24 11.7258 24 12.002C24 12.2782 23.7762 12.502 23.5 12.502Z"
          fill="#4CAF50"
        />
        <path
          d="M23.5 9.50198H13.5C13.2239 9.50198 13 9.27811 13 9.00197C13 8.72583 13.2239 8.50195 13.5 8.50195H23.5C23.7761 8.50195 24 8.72583 24 9.00197C24 9.27811 23.7762 9.50198 23.5 9.50198Z"
          fill="#4CAF50"
        />
        <path
          d="M23.5 6.50198H13.5C13.2239 6.50198 13 6.27811 13 6.00197C13 5.72583 13.2239 5.50195 13.5 5.50195H23.5C23.7761 5.50195 24 5.72583 24 6.00197C24 6.27815 23.7762 6.50198 23.5 6.50198Z"
          fill="#4CAF50"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MicrosoftExcelSvg
