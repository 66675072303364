import React, { ReactNode, useEffect, useRef, useState } from 'react'
import useAxios from 'axios-hooks'
import { useParams } from 'react-router'
import styled from '@emotion/styled'

import aplicacao_padrao from '../assets/marca/aplicacao_padrao.png'
import { Button, Collapse as CollapseAntd, Descriptions, Spin as SpinAntd } from 'antd'
const { Panel } = CollapseAntd
import { useWindowSize } from '../common/hooks/useWindowSize'
import { CloudDownloadOutlined } from '@ant-design/icons'
import { CertificadoStore } from './CertificadoStore'
import { endOfDay, format } from 'date-fns'
import { ReactComponent as Logo } from '../assets/matricula/logo.svg'
import { keyframes } from '@emotion/react'

const DadosAlunos_name = 'DadosAlunos'
const IframePdf_name = 'IframePdf'
const InformacaoAdicionais_name = 'InformacaoAdicionais'
const DadosCurso_name = 'DadosCurso'
const DadosEmissora_name = 'DadosEmissora'

export function Certificado() {
  const { Layout, Container } = Certificado
  return (
    <CertificadoStore.Provider>
      <Loading />
      <StoreShow when={({ loading, errorReason }) => !loading && !!errorReason}>
        <Header />
        <ErrorContainer />
      </StoreShow>
      <StoreShow when={({ errorReason }) => !errorReason}>
        <Layout id="Layout">
          <Header />
          <Container>
            <DadosAlunos />
            <IframePdf />
            <InformacaoAdicionais />
          </Container>
        </Layout>
      </StoreShow>
    </CertificadoStore.Provider>
  )
}
Certificado.Layout = styled.div`
  height: 100%;
  overflow-y: scroll;
  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }
`
Certificado.Container = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 100%;
  grid-template-areas:
    '${DadosAlunos_name} ${DadosAlunos_name}'
    '${IframePdf_name} ${InformacaoAdicionais_name}';

  @media (max-width: 720px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 80% auto;
    grid-template-areas:
      '${DadosAlunos_name}'
      '${IframePdf_name}'
      '${InformacaoAdicionais_name}';
  }
`

function StoreShow({
  children,
  when,
}: {
  children: ReactNode
  when: (state: ReturnType<typeof CertificadoStore.useState>) => boolean
}) {
  const state = CertificadoStore.useState()
  return when(state) ? <>{children}</> : null
}

function ErrorContainer() {
  const { errorReason } = CertificadoStore.useState()
  const { Container, Box } = ErrorContainer
  return (
    <Container>
      <h2>Não foi possível exibir o certificado</h2>
      <div style={{ marginTop: 24, textAlign: 'left' }}>
        Motivo do erro
        <Box>{errorReason}</Box>
      </div>
    </Container>
  )
}
ErrorContainer.Container = styled.div`
  height: 100vh;
  display: grid;
  align-content: center;
  width: 400px;
  margin: 0 auto;
`
ErrorContainer.Box = styled.div`
  margin-top: 8px;
  border-radius: 8px;
  padding: 24px;
  background: #ff972254;
  text-align: center;
`

function Loading() {
  const { Container } = Loading
  const { loading } = CertificadoStore.useState()

  if (!loading) return null
  return (
    <Container>
      <Logo />
      Carregando certificado...
    </Container>
  )
}
const animateLoading = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);  /* You can adjust scale factor */
  }
  100% {
    transform: scale(1);
  }
`
Loading.Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 12px;
  color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: white;
  z-index: 1000;
  svg {
    animation: ${animateLoading} 5s ease-in-out infinite;
    height: auto;
    width: 400px;
    max-width: 80vw;
  }
`

function Header() {
  const { Cabecalho, Logo } = Header
  return (
    <Cabecalho>
      <Logo src={aplicacao_padrao} />
    </Cabecalho>
  )
}
Header.Cabecalho = styled.div`
  height: 80px;
  background-color: #ff9722;
`
Header.Logo = styled.img`
  object-fit: contain;
  height: 100%;
  background-color: #fff;
  padding: 10px;
`

function DadosAlunos() {
  const { width } = useWindowSize()
  const { StatusCertificado, Container } = DadosAlunos
  const { matricula } = CertificadoStore.useState()
  const statusCertificado = matricula.dataValidadeCurso
    ? endOfDay(new Date(matricula.dataValidadeCurso)) > new Date()
    : true

  return (
    <Container>
      <Descriptions column={width! < 768 ? 1 : 2}>
        <Descriptions.Item label={<strong>Nome</strong>}>
          {matricula?.aluno?.nome.toUpperCase()}
        </Descriptions.Item>
        <Descriptions.Item label={<strong>CPF</strong>}>{matricula?.aluno?.cpf}</Descriptions.Item>
        <Descriptions.Item label={<strong>Status do certificado</strong>}>
          <StatusCertificado statusCertificado={statusCertificado}>
            {statusCertificado ? 'Ativo' : 'Vencido'}
          </StatusCertificado>
        </Descriptions.Item>
      </Descriptions>
    </Container>
  )
}
DadosAlunos.Container = styled.div`
  margin-left: 10px;
  grid-area: ${DadosAlunos_name};
`
type StatusCertificadoProps = {
  statusCertificado: boolean
}
DadosAlunos.StatusCertificado = styled.div<StatusCertificadoProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.statusCertificado ? '#c3ee66' : 'red')};
  border-radius: 0.313rem;
  padding: 0.5rem 0.75rem;
  width: 50%;
  font-weight: bold;
`

function IframePdf() {
  const { Container } = IframePdf
  const dispatch = CertificadoStore.useDispatch()
  const { pdf } = CertificadoStore.useState()
  const { certificadoId } = useParams<{ certificadoId: string }>()
  const size = { height: '100%', width: '98%' }
  useEffect(() => {
    if (!certificadoId) return
    dispatch(CertificadoStore.thunks.getCertificadoValidacao(certificadoId))

    return () => {
      URL.revokeObjectURL(pdf)
    }
  }, [certificadoId])

  return (
    <Container style={{ ...size }}>
      <iframe id="iframe-pdf" src={pdf} title="Visualização" allow="fullscreen" style={{ ...size }}>
        O seu certificado será exibido em breve...
      </iframe>
    </Container>
  )
}
IframePdf.Container = styled.div`
  grid-area: ${IframePdf_name};
  margin-left: 10px;
`

function InformacaoAdicionais() {
  const { Container } = InformacaoAdicionais
  return (
    <Container>
      <CollapseAntd defaultActiveKey={1}>
        <Panel key="1" header={<strong></strong>} collapsible="disabled" showArrow={false}>
          <BotaoDownload />
        </Panel>
        <Panel key="2" header={<strong>Dados do Curso</strong>}>
          <DadosCurso />
        </Panel>
        <Panel key="3" header={<strong>Dados da Emissora</strong>}>
          <DadosEmissora />
        </Panel>
      </CollapseAntd>
    </Container>
  )
}
InformacaoAdicionais.Container = styled.div`
  grid-area: ${InformacaoAdicionais_name};
  width: 99%;
  padding: 5px;
`

function BotaoDownload() {
  const { pdf } = CertificadoStore.useState()
  const handleDownload = () => {
    const link = document.createElement('a')
    link.href = pdf
    link.download = 'certificado.pdf'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Button
      shape="round"
      type="primary"
      style={{ display: 'flex', alignItems: 'center', gap: 5 }}
      onClick={handleDownload}
    >
      <CloudDownloadOutlined style={{ fontSize: 20 }} />
      <strong> BAIXAR PDF</strong>
    </Button>
  )
}

function DadosEmissora() {
  const { Container, Titulo } = DadosEmissora
  return (
    <Container>
      <Descriptions column={1}>
        <Descriptions.Item label={<strong>Nome</strong>}>New Driver</Descriptions.Item>
        <Descriptions.Item label={<strong>CNPJ</strong>}>31.665.008.0001/09</Descriptions.Item>
        <div>
          <Titulo>
            <strong>Endereço</strong>
          </Titulo>
        </div>
        <Descriptions.Item label={<strong>Logradouro</strong>}>R. C-139</Descriptions.Item>
        <Descriptions.Item label={<strong>Cidade</strong>}>Goiânia - GO</Descriptions.Item>
        <Descriptions.Item label={<strong>CEP</strong>}>74275-070</Descriptions.Item>
      </Descriptions>
    </Container>
  )
}
DadosEmissora.Container = styled.div`
  grid-area: ${DadosEmissora_name};
`
DadosEmissora.Titulo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
  background-color: rgba(255, 151, 34, 0.2);
  border-radius: 10px;
  width: 100%;
`

function DadosCurso() {
  const { Container } = DadosAlunos
  const { matricula } = CertificadoStore.useState()
  return (
    <Container>
      <Descriptions column={1}>
        <Descriptions.Item label={<strong>Nome do Curso</strong>}>
          {matricula?.curso?.nome}
        </Descriptions.Item>
        <Descriptions.Item label={<strong>Carga Horário</strong>}>
          {matricula?.curso?.cargaHoraria}
        </Descriptions.Item>
        {matricula?.dataInicioModuloTeorico && matricula?.dataFimModuloTeorico && (
          <Descriptions.Item label={<strong>Período</strong>}>
            {format(new Date(matricula?.dataInicioModuloTeorico), 'dd/MM/yyyy')} a{' '}
            {format(new Date(matricula?.dataFimModuloTeorico), 'dd/MM/yyyy')}
          </Descriptions.Item>
        )}
        {matricula?.dataValidadeCurso && (
          <Descriptions.Item label={<strong>Validade</strong>}>
            {format(new Date(matricula?.dataValidadeCurso), 'dd/MM/yyyy')}
          </Descriptions.Item>
        )}
      </Descriptions>
    </Container>
  )
}
DadosCurso.Container = styled.div`
  grid-area: ${DadosCurso_name};
`

export const RenderCertificado = () => {
  const { cursoId } = useParams<{ cursoId: string }>()
  console.log(cursoId)
  const [{ data, error, loading }] = useAxios(`/certificados/?cursoId=${cursoId}`)

  useEffect(() => {
    if (data?.identificacaoCertificado) {
      window.location.href = `${process.env.REACT_APP_API_URL}/certificados/render/${data?.identificacaoCertificado}`
    }
  }, [data?.identificacaoCertificado])

  if (loading) return <p>Gerando certificado</p>
  if (error)
    return <p style={{ textAlign: 'center', fontSize: 14 }}>{error?.response?.data.message}</p>
  return null
}
