import { createSlice } from '@reduxjs/toolkit'
import axios, { IQueryParams, ICounter } from '../common/axios'
import { IRequest } from '../common/IRequest'
import { ForumModel } from '../models/ForumModel'
import { createSimpleStore } from 'react-simple-reducer'
import { createSelector } from 'reselect'
import Axios from 'axios'
import { toast } from 'react-toastify'
import { ForumMensagemModel } from '../models/ForumMensagemModel'

type ABAS = 'POPULAR' | 'NOVO' | 'SEUS_FORUNS'
const ForunsStore = createSimpleStore(
  {
    activeTab: 'POPULAR' as ABAS,
    foruns: [] as ForumModel[],
    forumMensagemIdComentario: 0,
    forumMensagemIdEdicao: 0,
  },
  {
    alteraAba(state, payload: ABAS) {
      state.activeTab = payload
    },
    preencheForuns(state, foruns: ForumModel[]) {
      state.foruns = [
        ...foruns,
        ...state.foruns.filter((forum) => {
          return !foruns.some((newForum) => newForum.id === forum.id)
        }),
      ]
    },
    adicionaResposta(state, mensagem: ForumMensagemModel) {
      const { forumId, forumMensagemId } = mensagem
      const forum = state.foruns.find((x) => x.id === +forumId)
      if (!forumMensagemId) {
        const _ = forum?.forunsMensagens.push(mensagem)
      } else if (forum?.forunsMensagens) {
        forum.forunsMensagens = forum.forunsMensagens.map((fm) => {
          if (fm.id === +forumMensagemId) fm.forunsMensagens = [...fm.forunsMensagens, mensagem]
          return fm
        })
      }
      state.forumMensagemIdComentario = 0
    },
    comentar(state, forumMensagemId: number) {
      state.forumMensagemIdComentario = forumMensagemId
      state.forumMensagemIdEdicao = 0
    },
    cancelarComentar(state) {
      state.forumMensagemIdComentario = 0
    },
    editarForumMensagem(state, forumMensagemId: number) {
      state.forumMensagemIdEdicao = forumMensagemId
      state.forumMensagemIdComentario = 0
    },
    cancelarEditarForumMensagem(state) {
      state.forumMensagemIdEdicao = 0
    },
    atualizaForumMensagem(state, payload: { forumId; forumMensagemId; forumMensagemPaiId; texto }) {
      const { forumId, forumMensagemId, forumMensagemPaiId, texto } = payload
      const forum = state.foruns.find((x) => x.id === +forumId)
      state.forumMensagemIdEdicao = 0
      if (!forum) return
      const forumMensagem = forum.forunsMensagens.find((fm) => fm.id === +forumMensagemId)
      const comentario = forum.forunsMensagens
        .find((fm) => fm.id === +forumMensagemPaiId)
        ?.forunsMensagens.find((c) => c.id === forumMensagemId)

      if (forumMensagem) forumMensagem.texto = texto
      if (comentario) comentario.texto = texto
      // forum.forunsMensagens.find(fm => fm.id === +forumMensagemId)!.texto = texto
      // forum.forunsMensagens.find(fm => fm.id === +forumMensagemId)!.texto = texto

      // forum.forunsMensagens = forum.forunsMensagens.map(fm => {
      //   if (fm.id === +forumMensagemId) fm.texto = texto
      //   return fm
      // })
    },
  },
  {
    thunks: {
      getForuns({ cursoId, moduloId }) {
        return async (dispatch, getState) => {
          const state = getState()
          const proprios = state.activeTab === 'SEUS_FORUNS'
          const orderBy = state.activeTab === 'POPULAR' ? 'pontos' : 'createdAt'
          const forunsIgnorados = state.foruns.map((f) => f.id)
          try {
            const foruns = await Axios.get('/app/foruns', {
              params: { orderBy, proprios, moduloId, cursoId, forunsIgnorados },
            }).then((x) => x?.data)
            dispatch({ type: 'preencheForuns', payload: foruns })
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro ao buscar os fóruns'
            toast(message)
          }
        }
      },
      getForum({ forumId }) {
        return async (dispatch) => {
          try {
            const forum = await Axios.get(`/app/foruns/${forumId}`, { params: { forumId } }).then(
              (x) => x?.data
            )
            dispatch({ type: 'preencheForuns', payload: [forum] })
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro ao buscar os fóruns'
            toast(message)
          }
        }
      },
    },
  }
)

type IState = ReturnType<typeof ForunsStore.useState>

const getForuns = createSelector(
  (s: IState & { usuarioId }) => s.foruns,
  (s: IState & { usuarioId }) => s.activeTab,
  (s: IState & { usuarioId }) => s.usuarioId,
  (foruns, activeTab, usuarioId) => {
    switch (activeTab) {
      case 'NOVO':
        return getForunsRecentes(foruns)
      case 'POPULAR':
        return getForunsPopulares(foruns)
      case 'SEUS_FORUNS':
        return getForunsAluno(foruns, +usuarioId)
      default:
        return foruns
    }
  }
)

const getForunsRecentes = (foruns: ForumModel[]) => {
  return [...foruns].sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
}
const getForunsPopulares = (foruns: ForumModel[]) => {
  return [...foruns].sort((a, b) => (a.pontos < b.pontos ? 1 : -1))
}
const getForunsAluno = (foruns: ForumModel[], usuarioId) => {
  const forunsFiltrados = foruns.filter((forum) => {
    if (+forum.usuarioId === usuarioId) return true
    if (forum.forunsMensagens.some((fm) => +fm.usuarioId === usuarioId)) return true
    return false
  })

  return forunsFiltrados.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
}

const ForunsSelectors = {
  /**
   * Esse selector recebe o **state** junto com o **usuarioId**, portanto deve memoizar esse novo objeto criado
   */
  getForuns,
}

export { ForunsStore, ForunsSelectors }

export interface IState2 {
  request: IRequest
  showSelecionaModulos: boolean
  activeTab: string
  foruns: ForumModel[]
  activeModulo: any
  queryParams: IQueryParams
  tamanhoAvatar: string
  tamanhoAvatarPequeno: string
  cursoId: number
  forunsByCurso: any
}

export const initialState: IState2 = {
  request: {
    fetching: false,
    errorCode: null,
    message: '',
  },
  showSelecionaModulos: false,
  foruns: [],
  activeTab: 'POPULAR',
  activeModulo: null,
  queryParams: { limit: 10, offset: 0 },
  tamanhoAvatar: '36px',
  tamanhoAvatarPequeno: '28px',
  cursoId: 0,
  forunsByCurso: null,
}

const { reducer: forunsReducer, actions } = createSlice({
  name: 'foruns',
  initialState: initialState,
  reducers: {
    fetchStarted(state: IState2) {
      state.request.fetching = true
    },
    fetchError(state: IState2, { payload }: any) {
      const { errorCode, message } = payload
      state.request = { errorCode, message, fetching: false }
    },
    selectModulo(state: IState2, { payload }: any) {
      state.activeModulo = payload.modulo
      state.showSelecionaModulos = false
    },
    seleceTab(state: IState2, { payload }: any) {
      state.activeTab = payload.tab
    },
    getForunsNovos(state: IState2) {
      state.activeTab = 'NOVO'
    },
    hideSelecionaModulos(state: IState2) {
      state.showSelecionaModulos = false
    },
    showSelecionaModulos(state: IState2) {
      state.showSelecionaModulos = true
    },
  },
})

const forunsActions = {
  ...actions,
  getForuns(cursoId: number) {
    return async (dispatch, getState) => {
      dispatch(actions.fetchStarted())
      const { activeModulo, activeTab, queryParams } = getState()
      try {
        let params: any = { ...queryParams }
        if (activeModulo) params.moduloId = activeModulo.id
        if (cursoId) params.cursoId = cursoId
        if (activeTab === 'SEUS_FORUNS') params.proprios = true
        if (activeTab === 'POPULAR')
          params = { ...params, order: 'DESC', orderBy: 'pontos', orderByEntity: 'Forum' }

        const foruns = await axios.Foruns.getAllByCurso(params)
      } catch (error: any) {}
    }
  },
  // getForunsNovos() {
  //   reducers.getForunsNovos()
  //   const foruns = await axios.Foruns.
  // }
}

export { forunsReducer, forunsActions }
