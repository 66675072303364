import styled from '@emotion/styled'
import React from 'react'

interface BotoesTriplosProps extends React.HTMLAttributes<HTMLDivElement> {
  botoes: BotaoProps[]
}

interface BotaoProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string
  icon
}

/**
 * Esse componente é utilizado nas regras e resultado da avaliação e foi componentizado
 * para aproveitar a responsividade
 */
export const BotoesTriplos = ({ botoes, ...props }: BotoesTriplosProps) => {
  return (
    <ContainerBotoes {...props}>
      {botoes.map((botao, i) => {
        const { label, icon, ...botaoProps } = botao
        return (
          <Botao {...botaoProps} key={i}>
            <ContainerIcon>{icon}</ContainerIcon>
            {label}
          </Botao>
        )
      })}
    </ContainerBotoes>
  )
}

const ContainerBotoes = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 18px;
  font-size: 16px;
  @media (max-width: 580px) {
    flex-direction: column;
    max-width: 240px;
    margin: 0 auto;
    font-size: 14px;
    padding: 0 12px;
    svg {
      height: 20px;
    }
  }
  & > *:not(:first-child) {
    margin-left: 48px;
    @media (max-width: 780px) {
      margin-left: 18px;
    }
    @media (max-width: 580px) {
      margin-left: 0;
      margin-top: 18px;
    }
  }
`
const Botao = styled.div`
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08);
  border-radius: 8px;
  max-width: 245px;
  padding: 40px 18px;
  text-align: center;
  flex: 1;
  cursor: pointer;
  transition: 0.25s all;
  &:hover {
    background: #efefef;
  }
  @media (max-width: 580px) {
    padding: 20px 8px;
  }
`
const ContainerIcon = styled.div`
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
  @media (max-width: 580px) {
    height: 20px;
    margin-bottom: 8px;
  }
`
