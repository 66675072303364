import React from 'react'
import { SvgType } from './index'
const DecreaseFontSizeSvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.297752 9C0.222132 9 0.151239 8.97429 0.0850719 8.92286C0.0283573 8.86286 0 8.79857 0 8.73C0 8.67857 0.00472622 8.64 0.0141786 8.61429L3.3178 0.372858C3.41233 0.124286 3.60138 0 3.88495 0H6.09682C6.38039 0 6.56944 0.124286 6.66396 0.372858L9.96759 8.61429C9.97704 8.64 9.98177 8.67857 9.98177 8.73C9.98177 8.79857 9.94868 8.86286 9.88252 8.92286C9.8258 8.97429 9.75963 9 9.68402 9H7.84079C7.61393 9 7.45324 8.90571 7.35872 8.71714L6.84829 7.47H3.13348L2.62305 8.71714C2.52853 8.90571 2.36783 9 2.14098 9H0.297752ZM3.72898 5.54143H6.25278L4.99088 2.26286L3.72898 5.54143Z"
        fill={color}
      />
      <path
        d="M11.1793 5.18878C11.0753 5.18878 10.9855 5.15878 10.9099 5.09878C10.8437 5.03878 10.8106 4.96163 10.8106 4.86735V4.5C10.8106 4.40571 10.8437 4.32857 10.9099 4.26857C10.9855 4.20857 11.0753 4.17857 11.1793 4.17857H15.6455C15.7495 4.17857 15.8346 4.20857 15.9007 4.26857C15.9669 4.32857 16 4.40571 16 4.5V4.86735C16 4.95306 15.9669 5.0302 15.9007 5.09878C15.8346 5.15878 15.7495 5.18878 15.6455 5.18878H11.1793Z"
        fill={color}
      />
    </svg>
  )
}

export default DecreaseFontSizeSvg
