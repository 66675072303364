import React from 'react'

const MicrosoftWordSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M13.812 0.609997C13.6934 0.513622 13.5372 0.476778 13.388 0.510012L0.388054 3.51001C0.159257 3.56261 -0.00218085 3.76726 2.22753e-05 4.00201V21.002C0.000116025 21.2487 0.180163 21.4585 0.424007 21.496L13.424 23.4961C13.6969 23.538 13.9522 23.3508 13.9942 23.0779C13.998 23.0528 14 23.0274 14 23.0021V1.00201C14.0005 0.849528 13.9312 0.7052 13.812 0.609997Z"
          fill="#1565C0"
        />
        <path
          d="M23.5 21.5019H13.5C13.2239 21.5019 13 21.2781 13 21.0019C13 20.7258 13.2239 20.5019 13.5 20.5019H23V3.50194H13.5C13.2239 3.50194 13 3.27806 13 3.00192C13 2.72578 13.2239 2.50195 13.5 2.50195H23.5C23.7761 2.50195 24 2.72583 24 3.00197V21.002C24 21.2781 23.7762 21.5019 23.5 21.5019Z"
          fill="#1565C0"
        />
        <path
          d="M8.50005 16.502C8.27726 16.5017 8.08155 16.3541 8.02005 16.14L6.50004 10.8219L4.98002 16.14C4.87024 16.4053 4.56612 16.5315 4.30071 16.4217C4.17312 16.3689 4.07177 16.2676 4.01899 16.14L2.01902 9.13998C1.9353 8.87682 2.0808 8.59566 2.34396 8.51194C2.60712 8.42822 2.88827 8.57372 2.97199 8.83688C2.97495 8.84616 2.97762 8.85554 2.98005 8.86496L4.50002 14.1819L6.02004 8.86398C6.12982 8.59862 6.43395 8.47247 6.69935 8.58226C6.82694 8.63504 6.92829 8.73638 6.98107 8.86398L8.50005 14.182L10.0201 8.86501C10.092 8.60185 10.3637 8.44683 10.6269 8.51879C10.6306 8.51982 10.6343 8.5209 10.6381 8.52197C10.9034 8.59801 11.0569 8.87462 10.9811 9.13998L8.98104 16.14C8.91944 16.3545 8.72318 16.5022 8.50005 16.502Z"
          fill="#FAFAFA"
        />
        <path
          d="M21.5 6.50198H13.5C13.2239 6.50198 13 6.27811 13 6.00197C13 5.72583 13.2239 5.50195 13.5 5.50195H21.5C21.7762 5.50195 22 5.72583 22 6.00197C22 6.27815 21.7762 6.50198 21.5 6.50198Z"
          fill="#1565C0"
        />
        <path
          d="M21.5 9.50199H13.5C13.2239 9.50199 13 9.27811 13 9.00197C13 8.72583 13.2239 8.50195 13.5 8.50195H21.5C21.7762 8.50195 22 8.72583 22 9.00197C22 9.27811 21.7762 9.50199 21.5 9.50199Z"
          fill="#1565C0"
        />
        <path
          d="M21.5 12.502H13.5C13.2239 12.502 13 12.2781 13 12.002C13 11.7258 13.2239 11.502 13.5 11.502H21.5C21.7762 11.502 22 11.7258 22 12.002C22 12.2782 21.7762 12.502 21.5 12.502Z"
          fill="#1565C0"
        />
        <path
          d="M21.5 15.502H13.5C13.2239 15.502 13 15.2781 13 15.002C13 14.7258 13.2239 14.502 13.5 14.502H21.5C21.7762 14.502 22 14.7258 22 15.002C22 15.2782 21.7762 15.502 21.5 15.502Z"
          fill="#1565C0"
        />
        <path
          d="M21.5 18.502H13.5C13.2239 18.502 13 18.2781 13 18.002C13 17.7258 13.2239 17.502 13.5 17.502H21.5C21.7762 17.502 22 17.7258 22 18.002C22 18.2782 21.7762 18.502 21.5 18.502Z"
          fill="#1565C0"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MicrosoftWordSvg
