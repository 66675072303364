import React from 'react'
import { SvgType } from './index'
const NdCarSvg = ({ ...props }: SvgType) => {
  return (
    <svg
      width="36"
      height="31"
      viewBox="0 0 36 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34 28.75C34 29.854 33.104 30.75 32 30.75H29C27.896 30.75 27 29.854 27 28.75V20.75C27 19.646 27.896 18.75 29 18.75H32C33.104 18.75 34 19.646 34 20.75V28.75ZM9 28.75C9 29.854 8.104 30.75 7 30.75H4C2.896 30.75 2 29.854 2 28.75V20.75C2 19.646 2.896 18.75 4 18.75H7C8.104 18.75 9 19.646 9 20.75V28.75Z"
        fill="#292F33"
      />
      <path
        d="M9 0.75C13 -0.25 23 -0.25 27 0.75C30.881 1.72 32 11.75 32 11.75C32 11.75 34 11.75 34 15.75V23.75C34 23.75 33.881 26.78 30 27.75C26 28.75 23 28.75 18 28.75C13 28.75 10 28.75 6 27.75C2.12 26.78 2 23.75 2 23.75V15.75C2 15.75 2 11.75 4 11.75C4 11.75 5.12 1.72 9 0.75Z"
        fill="#3B88C3"
      />
      <path
        d="M18 10.75C21.905 10.75 26.623 10.95 30 11.311L29 5.75C28 2.75 22 2.75 18 2.75C14 2.75 8 2.75 7 5.75L6 11.311C9.377 10.95 14.095 10.75 18 10.75Z"
        fill="#BBDDF5"
      />
      <path
        d="M5 11.25C5 12.079 4.329 12.75 3.5 12.75H1.5C0.671 12.75 0 12.079 0 11.25C0 10.421 0.671 9.75 1.5 9.75H3.5C4.329 9.75 5 10.421 5 11.25ZM31 11.25C31 12.079 31.672 12.75 32.5 12.75H34.5C35.328 12.75 36 12.079 36 11.25C36 10.421 35.328 9.75 34.5 9.75H32.5C31.672 9.75 31 10.421 31 11.25Z"
        fill="#3B88C3"
      />
      <path
        d="M11 18.75C11 20.407 9.657 21.75 8 21.75H7C5.343 21.75 4 20.407 4 18.75C4 17.093 5.343 15.75 7 15.75H8C9.657 15.75 11 17.093 11 18.75ZM32 18.75C32 20.407 30.656 21.75 29 21.75H28C26.344 21.75 25 20.407 25 18.75C25 17.093 26.344 15.75 28 15.75H29C30.656 15.75 32 17.093 32 18.75Z"
        fill="#FFCC4D"
      />
      <path
        d="M12.0008 17.75C11.6778 17.75 11.3608 17.594 11.1678 17.305C9.42585 14.691 4.84885 13.74 4.80285 13.731C4.26185 13.622 3.91085 13.095 4.01985 12.553C4.12885 12.012 4.65185 11.662 5.19585 11.77C5.41685 11.814 10.6268 12.889 12.8318 16.196C13.1378 16.656 13.0138 17.276 12.5548 17.583C12.3838 17.695 12.1908 17.75 12.0008 17.75ZM23.9988 17.75C23.8088 17.75 23.6158 17.695 23.4448 17.582C22.9848 17.275 22.8608 16.655 23.1678 16.195C25.3718 12.888 30.5828 11.813 30.8038 11.769C31.3438 11.663 31.8708 12.011 31.9798 12.552C32.0888 13.094 31.7388 13.62 31.1978 13.73C31.1518 13.739 26.5748 14.69 24.8328 17.304C24.6398 17.594 24.3218 17.75 23.9988 17.75Z"
        fill="#226699"
      />
      <path
        d="M17.9998 24.7505C12.3368 24.7505 5.36076 24.5255 4.29276 23.4575C3.90176 23.0665 3.90176 22.4345 4.29276 22.0435C4.63676 21.6985 5.16976 21.6575 5.55976 21.9215C5.79176 22.0215 7.84476 22.7505 17.9998 22.7505C28.1548 22.7505 30.2078 22.0215 30.4398 21.9215C30.8308 21.6575 31.3618 21.6985 31.7068 22.0435C32.0978 22.4345 32.0978 23.0665 31.7068 23.4575C30.6388 24.5255 23.6628 24.7505 17.9998 24.7505Z"
        fill="#55ACEE"
      />
      <path
        d="M25 25.2496C25 26.7496 21.866 27.7496 18 27.7496C14.134 27.7496 11 26.7496 11 25.2496C11 24.4216 14.134 24.7496 18 24.7496C21.866 24.7496 25 24.4216 25 25.2496Z"
        fill="#226699"
      />
    </svg>
  )
}

export default NdCarSvg
