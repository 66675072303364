import * as YupOriginal from 'yup'
import * as validarCpf from 'validar-cpf'

YupOriginal.addMethod(YupOriginal.string, 'cpf', function (this: any, message: string) {
  return this.test('cpf', message || 'Número de CPF inválido', cpfValidate)
})

YupOriginal.addMethod(YupOriginal.string, 'minuto', function (this: any, message: string) {
  return this.test('minuto', message || 'Minutos inválidos', minutoValidate)
})

YupOriginal.addMethod(YupOriginal.string, 'mascaraCompleta', function (this: any, message: string) {
  return this.test('mascaraCompleta', message || 'Valor incompleto', mascaraIncompletaValidate)
})

function cpfValidate(value: string) {
  if (!value) return true
  return validarCpf(value)
}

function minutoValidate(value: string) {
  if (!value) return true
  const [hora, minuto] = value.split(':')
  if (isNaN(+hora) || isNaN(+minuto)) return false
  if (+hora > 23 || +minuto > 59) return false
  return true
}

function mascaraIncompletaValidate(value: string) {
  if (!value) return true
  return !value.includes('_')
}

YupOriginal.setLocale({
  mixed: {
    required: 'Campo obrigatório',
  },
  string: {
    email: 'E-mail inválido',
  },
})

export const Yup = YupOriginal
