import React from 'react'
import { SvgType } from './index'
const CupSvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M5.83335 17.3332H14.1667M5.00002 2.2915H15V9.4165C15 12.0399 12.7614 14.1665 10 14.1665C7.23859 14.1665 5.00002 12.0399 5.00002 9.4165V2.2915Z"
          stroke="#FFB800"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 14.1665V17.3332"
          stroke="#FFB800"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.00002 4.6665V9.4165H4.16669C2.78598 9.4165 1.66669 8.35318 1.66669 7.0415C1.66669 5.72983 2.78598 4.6665 4.16669 4.6665H5.00002ZM15 9.4165V4.6665H15.8334C17.2141 4.6665 18.3334 5.72983 18.3334 7.0415C18.3334 8.35318 17.2141 9.4165 15.8334 9.4165H15Z"
          stroke="#FFB800"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="19" fill="white" transform="translate(1.52588e-05 0.708252)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CupSvg
