import React from 'react'

export const responsiveSpacing = (attr: 'padding' | 'margin', mediaQueries = [1024, 758, 425]) => {
  return `
    ${attr}: 0 80px 24px 80px;
    @media (max-width: ${mediaQueries[0]}px) {
      ${attr}: 0 60px 24px 60px;
    }
    @media (max-width: ${mediaQueries[1]}px) {
      ${attr}: 0 40px 24px 40px;
    }
    @media (max-width: ${mediaQueries[2]}px) {
      ${attr}: 0 16px 16px 16px;
    }
  `
}
