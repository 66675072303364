import React from 'react'
import styled from '@emotion/styled'

const Card = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return <Container {...props} />
}

const Container = styled('div')`
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08);
  border-radius: 8px;
`

export default Card
