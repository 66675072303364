import React from 'react'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'
import { authReducer, IState as IStateAuth } from '../auth/AuthStore'
import { cursosReducer, IState as IStateCursos } from '../cursos/CursosStore'
import { modulosReducer, IState as IStateModulos } from '../modulos/ModulosStore'
import { progressoReducer, IState as IStateProgresso } from '../conteudo/ProgressoReduxStore'
import {
  validacaoFacialReducer,
  IState as IStateValidacaoFacial,
} from '../validacao-facial/ValidacaoFacialReduxStore'

export interface IStateRedux {
  auth: IStateAuth
  cursos: IStateCursos
  modulos: IStateModulos
  progresso: IStateProgresso
  validacaoFacial: IStateValidacaoFacial
}

export const store = configureStore({
  reducer: {
    auth: authReducer,
    cursos: cursosReducer,
    modulos: modulosReducer,
    progresso: progressoReducer,
    validacaoFacial: validacaoFacialReducer,
  },
  middleware: [...getDefaultMiddleware()],
  // middleware: [...getDefaultMiddleware(), save()],
  // preloadedState: load(),
})
