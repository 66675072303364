import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../common/axios'

const initialState = {
  loading: true,
  validacaoSerpro: null as any,
  ultimaValidacao: null as any,
}

const { reducer: validacaoFacialReducer, actions } = createSlice({
  name: 'validacaoFacial',
  initialState: initialState,
  reducers: {
    getValidacoesFaciaisStarted(state) {
      state.loading = true
    },
    getValidacoesFaciaisSuccess(
      state,
      { payload }: PayloadAction<{ validacaoSerpro; ultimaValidacao }>
    ) {
      state.validacaoSerpro = payload.validacaoSerpro
      state.ultimaValidacao = payload.ultimaValidacao
      state.loading = false
    },
    getValidacoesFaciaisError(state) {
      state.loading = false
    },
    changeUltimaValidacao(state, { payload }: PayloadAction<{ ultimaValidacao }>) {
      state.ultimaValidacao = payload.ultimaValidacao
      if (payload.ultimaValidacao.servicoValidacao === 'SERPRO') state.validacaoSerpro = true
    },
  },
})

const validacaoFacialActions = {
  ...actions,
  getValidacoesFaciais() {
    return async (dispatch) => {
      dispatch(actions.getValidacoesFaciaisStarted())
      try {
        const validacaoSerpro = await axios.ValidacaoFacial.getSerpro()
        const ultimaValidacao = await axios.ValidacaoFacial.getUltima()
        dispatch(actions.getValidacoesFaciaisSuccess({ validacaoSerpro, ultimaValidacao }))
      } catch (error: any) {
        dispatch(actions.getValidacoesFaciaisError())
      }
    }
  },
}

export type IState = typeof initialState

export { validacaoFacialReducer, validacaoFacialActions }
