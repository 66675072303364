import React from 'react'
import { IonPage, IonContent } from '@ionic/react'
import { Form, useFormik, FormikProvider } from 'formik'
import { TextField } from '../common/TextField'
import { bindActionCreators } from 'redux'
import { useSelector, useDispatch } from 'react-redux'
import { authActions } from './AuthStore'
import aplicacaoPadrao from '../assets/marca/aplicacao_padrao.png'
import styled from '@emotion/styled'
import { Button } from '../components'
import { Link } from 'react-router-dom'

export const EsqueceuSenha = () => {
  const { Layout, ImgMarca, Container, ContainerInner } = EsqueceuSenha
  const dispatch = useDispatch()
  const actions = bindActionCreators(authActions, dispatch)
  const loading = useSelector((s: any) => s.auth.request.fetching)

  const formik = useFormik({
    initialValues: { usuario: '' },
    onSubmit: (values) => {
      const cpf = values.usuario
      actions.recuperaSenha({ cpf })
    },
  })

  return (
    <>
      <IonPage>
        <IonContent>
          <Layout>
            <Container>
              <ContainerInner>
                <ImgMarca>
                  <img src={aplicacaoPadrao} alt="" />
                </ImgMarca>
                <p>Informe seu CPF e enviaremos por e-mail instruções para recuperar sua senha</p>
                <FormikProvider value={formik}>
                  <Form noValidate onSubmit={formik.handleSubmit}>
                    <TextField mask="cpf" name="usuario" type="cel" placeholder="Informe o CPF" />
                    <Button type="submit" loading={loading} label="Recuperar senha" />
                  </Form>
                </FormikProvider>
                <Link to="/" style={{ fontSize: 14, marginTop: 24, display: 'block' }}>
                  Voltar ao login
                </Link>
              </ContainerInner>
            </Container>
          </Layout>
        </IonContent>
      </IonPage>
    </>
  )
}
EsqueceuSenha.Layout = styled.div`
  background: #f2f2f2;
  min-height: 100vh;
  overflow: auto;
`
EsqueceuSenha.Container = styled.div`
  max-width: 661px;
  margin: 34px auto;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08);
  padding: 28px 0;
  @media (max-width: 689px) {
    margin: 0;
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
    padding: 12px;
  }
`
EsqueceuSenha.ContainerInner = styled.div`
  max-width: 400px;
  margin: 0 auto;
`
EsqueceuSenha.ImgMarca = styled.div`
  width: 147px;
  margin: 0 auto;
  display: block;
`
