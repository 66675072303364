import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Show } from '../common/Show'
import Svgs from '../svgs'
import {
  ConteudoStore as Store,
  ConteudoSelectors,
  IStateConteudo as IState,
} from './ConteudoStore'
import { BlocoNotaModel } from '../models/BlocoNotaModel'
import { useParams } from 'react-router'
import { ConteudoParamsType } from './Conteudo'
import { format, isToday, parseISO } from 'date-fns'
import debounce from 'lodash.debounce'
import { Flex } from '../common/styles/Flex'
import { IonAlert } from '@ionic/react'

const BlocoNota = () => {
  const {
    Container,
    RodapeOpen,
    RodapeReading,
    RodapeCreating,
    ContainerTextos,
    Separador,
    ContentCreating,
  } = BlocoNota

  const state = Store.useState()

  if (state.statusPainelNota === 'CLOSED') return null

  return (
    <Container>
      <Titulo />
      <Show condition={state.statusPainelNota === 'OPEN'}>
        <div style={{ overflow: 'auto' }}>
          <ListaNotasPagina />
          <ListaNotasOutrasPaginas />
        </div>
        <RodapeOpen>
          <div />
          <QtdNotas />
          <BotaoNovaNota />
        </RodapeOpen>
      </Show>
      <Show condition={state.statusPainelNota === 'CREATING'}>
        <ContentCreating>
          <Instrucoes />
          <MensagemEmEdicao />
        </ContentCreating>
        <RodapeCreating>
          <CampoTexto />
          <Flex spacing="between">
            <Cancelar />
            <Salvar />
          </Flex>
        </RodapeCreating>
      </Show>
      <Show condition={state.statusPainelNota === 'READING'}>
        <div style={{ overflow: 'auto' }}>
          <Voltar />
          <ContainerTextos>
            <TextoGrifado />
            <Show condition={state.notaAtiva?.referencia && state.notaAtiva.texto}>
              <Separador />
            </Show>
            <TextoNota />
            <IrConteudo />
          </ContainerTextos>
        </div>
        <RodapeReading>
          <Excluir />
          <Editar />
        </RodapeReading>
      </Show>
    </Container>
  )
}
BlocoNota.Container = styled.div`
  --width: 336px;
  width: var(--width);
  height: 578px;
  position: fixed;
  bottom: 0;
  right: 28px;
  box-shadow: 0px -1px 7px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0px 0px;
  background: white;
  z-index: 11;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: var(--width);
  @media (max-width: 749px) {
    --width: 100%;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    border-radius: 0;
  }
`
BlocoNota.RodapeOpen = styled.div`
  height: 70px;
  display: grid;
  grid-template-columns: 34px 1fr 34px;
  place-content: center;
  border-top: 1px solid #e4e5e8;
  padding: 0 18px;
  justify-items: center;
  place-items: center;
`
BlocoNota.RodapeReading = styled.div`
  height: 70px;
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 0 18px;
  align-items: center;
`
BlocoNota.RodapeCreating = styled.div`
  background: #ffffff;
  border: 1px solid #ff9933;
  border-radius: 8px;
  padding: 8px;
  margin: 16px 18px;
  overflow: auto;
`
BlocoNota.ContainerTextos = styled.div`
  overflow: auto;
  padding: 0 18px;
`
BlocoNota.Separador = styled.div`
  margin: 18px 0;
  height: 1px;
  width: 100%;
  background: #c4c4c4;
`
BlocoNota.ContentCreating = styled.div`
  padding: 0px 14px;
  display: flex;
  align-items: flex-end;
  overflow: auto;
`
/** Shared */
BlocoNota.Botao = styled.button`
  padding: 6px 11px;
  color: #007aff;
  font-size: 14px;
  line-height: 17px;
  transition: background 0.35s;
  border-radius: 4px;
  background: white;
  outline: none;
  &:hover {
    background: #f2f2f2;
  }
`
BlocoNota.BotaoSvg = styled.button`
  transition: background 0.35s;
  border-radius: 50%;
  background: white;
  outline: none;
  height: 36px;
  width: 36px;
  display: grid;
  place-content: center;
  &:hover {
    background: #f2f2f2;
  }
`
BlocoNota.TextoBordaEsquerda = styled.div`
  border-left: 2px solid;
  margin-bottom: 8px;
  padding: 2px 0 2px 8px;
  font-size: 14px;
`

const Titulo = () => {
  const { helpers, Container, Fechar } = Titulo
  const dispatch = Store.useDispatch()
  const { statusPainelNota, notaAtiva } = Store.useState()

  function handleClickFechar() {
    dispatch(Store.actions.closePainelNota())
  }

  const titulo = helpers.getTitulo(statusPainelNota, notaAtiva?.id)

  return (
    <Container>
      <Svgs.Note color="white" />
      {titulo}
      <div style={{ flex: 1 }} />
      <Fechar color="white" onClick={handleClickFechar} />
    </Container>
  )
}
Titulo.helpers = (() => {
  function getTitulo(statusPainelNota: IState['statusPainelNota'], notaAtivaId) {
    if (statusPainelNota === 'CREATING' && notaAtivaId) return 'Editar nota'
    if (statusPainelNota === 'CREATING') return 'Adicionar nota'
    return 'Bloco de notas'
  }
  return { getTitulo }
})()
Titulo.Container = styled.div`
  background: #f06f30;
  border-radius: 10px 10px 0px 0px;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: auto auto 1fr auto;
  height: 60px;
  align-items: center;
  padding: 0 18px;
  color: white;
  @media (max-width: 749px) {
    border-radius: 0;
  }
`
Titulo.Fechar = styled(Svgs.Close)`
  cursor: pointer;
`

const ListaNotasPagina = () => {
  const state = Store.useState()
  const notas = ConteudoSelectors.getNotasPaginaAtual(state)
  return <ListaSegmento subtitulo="Notas da página" notas={notas} />
}
const ListaNotasOutrasPaginas = () => {
  const state = Store.useState()
  const notasOutrasPaginas = ConteudoSelectors.getNotasOutrasPaginas(state)
  return <ListaSegmento subtitulo="Notas de outras páginas" notas={notasOutrasPaginas} />
}

const ListaSegmento = ({ subtitulo, notas }: { subtitulo: string; notas: BlocoNotaModel[] }) => {
  const { Subtitulo, NenhumaNota } = ListaSegmento
  return (
    <>
      <Subtitulo>{subtitulo}</Subtitulo>
      {notas.map((nota) => (
        <Nota key={nota.id} nota={nota} />
      ))}
      {!notas.length ? <NenhumaNota>Nenhuma nota ainda</NenhumaNota> : null}
    </>
  )
}
ListaSegmento.Subtitulo = styled.div`
  color: #f06f30;
  font-size: 12px;
  padding: 14px 18px 0 14px;
`
ListaSegmento.NenhumaNota = styled.div`
  color: #bdbdbd;
  font-size: 10px;
  height: 48px;
  display: grid;
  place-items: center;
`

const Nota = ({ nota }: { nota: BlocoNotaModel }) => {
  const { helpers, Container, Texto, Data } = Nota

  const dispatch = Store.useDispatch()

  function handleClick() {
    dispatch(Store.actions.openNota(nota))
  }

  const dataFormatada = helpers.formatData(nota.createdAt)

  return (
    <Container onClick={handleClick}>
      <Texto referencia={nota.referencia && !nota.texto}>
        {nota.texto ? nota.texto : nota.referencia}
      </Texto>
      <Data>{dataFormatada}</Data>
    </Container>
  )
}
Nota.helpers = (() => {
  function formatData(data) {
    const parsedData = parseISO(data)
    if (isToday(parsedData)) {
      return format(parsedData, 'HH:mm')
    }
    return format(parsedData, 'dd/MM')
  }
  return { formatData }
})()
Nota.Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px;
  grid-gap: 8px;
  color: #2d2d2d;
  padding: 18px 14px;
  border-bottom: 1px solid #e4e5e8;
  align-items: center;
  cursor: pointer;
  transition: background 0.25s;
  &:hover {
    background: #f2f2f2;
  }
`
Nota.Texto = styled.div<{ referencia }>(
  (props) => `
  white-space: nowrap;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  ${
    props.referencia
      ? `
  border-left: 2px solid #007AFF;
  padding-left: 4px;
  `
      : ''
  }
`
)
Nota.Data = styled.div`
  font-size: 10px;
`

const QtdNotas = () => {
  const { notas } = Store.useState()
  if (notas.length === 0) return <div> Nenhuma nota </div>
  if (notas.length === 1) return <div> 1 nota </div>
  if (notas.length > 1) return <div> {notas.length} notas </div>
  return null
}

const BotaoNovaNota = () => {
  const { cursoId, moduloId } = useParams<ConteudoParamsType>()
  const state = Store.useState()
  const dispatch = Store.useDispatch()

  const paginaIdUnico = state.paginas.find((x) => x.id === state.paginaAtualId)?.identificadorUnico

  function handleClick() {
    dispatch(Store.actions.newNota({ cursoId, moduloId, paginaIdUnico }))
  }

  const { Layout } = BotaoNovaNota
  return (
    <Layout>
      <Svgs.PlusCircle className="botao-nova-nota" onClick={handleClick} />
    </Layout>
  )
}
BotaoNovaNota.Layout = styled('div')`
  .botao-nova-nota:hover {
    cursor: pointer;
  }
`

const Voltar = () => {
  const { Container } = Voltar
  const dispatch = Store.useDispatch()
  function handleClick() {
    dispatch(Store.actions.backPainelNota())
  }
  return (
    <Container onClick={handleClick}>
      <Svgs.ChevronLeft color="#2d2d2d" /> Voltar
    </Container>
  )
}
Voltar.Container = styled.div`
  cursor: pointer;
  height: 34px;
  margin: 8px 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: 12px;
  &:hover {
    text-decoration: underline;
  }
`

const Instrucoes = () => {
  const { Container } = Instrucoes
  const { notaAtiva, novoTextoNota } = Store.useState()

  if (notaAtiva?.texto || notaAtiva?.referencia || novoTextoNota) return null

  return (
    <Container>Comece digitando ou selecionando uma parte do texto para salvar a nota</Container>
  )
}
Instrucoes.Container = styled.div`
  display: grid;
  place-items: center;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  padding-bottom: 72px;
`

const CampoTexto = () => {
  const { helpers, Editor } = CampoTexto
  const { novoTextoNota } = Store.useState()
  const dispatch = Store.useDispatch()
  const ref = useRef<HTMLDivElement>(null)

  const debouncedBlur = useRef(debounce(updateNotaAtiva, 500))
  useEffect(() => {
    if (ref.current && !helpers.stringsSaoQuaseIguais(novoTextoNota, ref.current?.innerText)) {
      ref.current.innerHTML = novoTextoNota || ''
    }
  }, [novoTextoNota])

  function handleInput() {
    debouncedBlur.current()
  }

  function updateNotaAtiva() {
    const texto = ref.current?.innerText || ''
    dispatch(Store.actions.updateNovoTextoNota(texto))
  }

  return (
    <Editor
      ref={ref}
      contentEditable={'plaintext-only' as any}
      onInput={handleInput}
      placeholder="Escreva a nota aqui"
    />
  )
}
CampoTexto.helpers = (() => {
  function stringsSaoQuaseIguais(string1, string2) {
    if (!string1 && !string2) return true
    if (!string1 && string2) return false
    if (string1 && !string2) return false
    if (string1.length < string2.length) return string2.includes(string1)
    return string1.includes(string2)
  }
  return { stringsSaoQuaseIguais }
})()
CampoTexto.Editor = styled.div`
  font-size: 14px;
  line-height: 17px;
  outline: none;
  padding-bottom: 32px;
  &:empty:before {
    content: attr(placeholder);
    color: #ccc;
  }
`

const MensagemEmEdicao = () => {
  const { notaAtiva } = Store.useState()
  const { Container, Label, Mensagem } = MensagemEmEdicao
  if (!notaAtiva?.texto) return null
  return (
    <Container>
      <Label>Editar mensagem</Label>
      <Mensagem>{notaAtiva.texto}</Mensagem>
    </Container>
  )
}
MensagemEmEdicao.Container = styled(BlocoNota.TextoBordaEsquerda)`
  border-color: #f06f30;
  width: 100%;
`
MensagemEmEdicao.Label = styled.div`
  font-family: Rubik;
  font-size: 12px;
  font-weight: 400;
  color: #007aff;
`
MensagemEmEdicao.Mensagem = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
`

const Cancelar = () => {
  const { Botao } = Cancelar
  const dispatch = Store.useDispatch()

  function handleClick() {
    dispatch(Store.actions.abortEdicaoNota())
  }

  return <Botao onClick={handleClick}>Cancelar</Botao>
}
Cancelar.Botao = styled(BlocoNota.Botao)``

const Salvar = () => {
  const dispatch = Store.useDispatch()
  const { notaAtiva, novoTextoNota } = Store.useState()

  function handleClick() {
    const nota = { ...notaAtiva!, texto: novoTextoNota }
    dispatch(Store.thunks.createOrUpdateNota(nota))
  }

  const { Layout } = Salvar
  return (
    <Layout>
      <Svgs.CheckmarkCircle className="botao-salvar-nota" onClick={handleClick} />
    </Layout>
  )
}
Salvar.Layout = styled('div')`
  display: flex;
  align-items: center;
  .botao-salvar-nota:hover {
    cursor: pointer;
  }
`

const TextoGrifado = () => {
  const { notaAtiva } = Store.useState()
  const { Container } = TextoGrifado
  if (!notaAtiva?.referencia) return null
  return <Container>{notaAtiva.referencia}</Container>
}
TextoGrifado.Container = styled(BlocoNota.TextoBordaEsquerda)`
  border-color: #007aff;
  font-style: italic;
`

const TextoNota = () => {
  const { Container } = TextoNota
  const { notaAtiva } = Store.useState()
  if (!notaAtiva?.texto) return null
  return <Container>{notaAtiva.texto}</Container>
}
TextoNota.Container = styled(BlocoNota.TextoBordaEsquerda)`
  white-space: pre-wrap;
  border-color: #f06f30;
`

const IrConteudo = () => {
  const { helpers, Botao } = IrConteudo
  const state = Store.useState()
  const notasPagina = ConteudoSelectors.getNotasPaginaAtual(state)
  const dispatch = Store.useDispatch()

  function handleClick() {
    const indexPagina = helpers.getNumeroPaginaByNotaAtiva(
      state.paginas,
      state.notaAtiva?.paginaIdUnico
    )
    if (!indexPagina) return
    dispatch(Store.actions.setPagina(indexPagina))
  }

  if (helpers.notaAtualRelacionadaPaginaAtual(notasPagina, state.notaAtiva?.id)) return null

  return (
    <div>
      <Botao onClick={handleClick}>
        <Svgs.Share color="#007AFF" style={{ marginRight: 8 }} />
        <div> Ir para o conteúdo </div>
      </Botao>
    </div>
  )
}
IrConteudo.helpers = (() => {
  function notaAtualRelacionadaPaginaAtual(notasPagina: IState['notas'], notaAtivaId) {
    return notasPagina.some((x) => x.id === notaAtivaId)
  }
  function getNumeroPaginaByNotaAtiva(paginas: IState['paginas'], notaAtivaPaginaIdUnico) {
    return paginas.findIndex((x) => x.identificadorUnico === notaAtivaPaginaIdUnico) + 1
  }
  return { notaAtualRelacionadaPaginaAtual, getNumeroPaginaByNotaAtiva }
})()
IrConteudo.Botao = styled(BlocoNota.Botao)`
  text-decoration: underline;
  font-size: 12px;
  display: flex;
  align-items: center;
`

const Editar = () => {
  const { Botao } = Editar
  const { notaAtiva } = Store.useState()
  const dispatch = Store.useDispatch()
  function handleClick() {
    dispatch(Store.actions.editNotaAtiva())
  }
  return (
    <div>
      <Botao onClick={handleClick}>{notaAtiva?.texto ? 'Editar nota' : 'Escrever nota'}</Botao>
    </div>
  )
}
Editar.Botao = styled(BlocoNota.Botao)`
  border: 1px solid #f06f30;
  border-radius: 4px;
  color: #f06f30;
`

const Excluir = () => {
  const { Botao } = Excluir

  const [showConfirm, setShowConfirm] = useState(false)
  const dispatch = Store.useDispatch()
  const { notaAtiva } = Store.useState()

  function handleClick() {
    setShowConfirm(true)
  }

  return (
    <div>
      <IonAlert
        isOpen={showConfirm}
        onDidDismiss={() => setShowConfirm(false)}
        header="Excluir nota"
        message="Tem certeza que deseja excluir esta nota? Essa ação não poderá ser desfeita."
        buttons={[
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => setShowConfirm(false),
          },
          {
            text: 'Excluir',
            handler: () => {
              dispatch(Store.thunks.deleteNota(notaAtiva?.id))
              setShowConfirm(false)
            },
          },
        ]}
      />
      <Botao onClick={handleClick}>Excluir</Botao>
    </div>
  )
}
Excluir.Botao = styled(BlocoNota.Botao)``

export { BlocoNota }
