import React, { useState, useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import { useSpring, animated } from 'react-spring'
import { QuestaoStore } from './QuestoesStore'
import { toast } from 'react-toastify'
import { useDecorateBlobWithSas } from '../common/hooks/useDecorateBlobWithSas'
import { QuestaoAlternativaModel } from '../models/QuestaoAlternativaModel'
import { darken } from 'polished'
import useMedia from 'use-media'

const PADROES_SVG = [
  (
    color
  ) => `<svg width="18" height="28" viewBox="0 0 18 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="28">
      <path d="M0 0H18V28H0V0Z" fill="#FFF8A1"/>
    </mask>
    <g mask="url(#mask0)">
      <circle cx="18" cy="18" r="4" fill="${color}"/>
      <rect x="-9" y="14" width="21" height="8" rx="4" fill="${color}"/>
      <rect width="21" height="8" rx="4" transform="matrix(-1 0 0 1 22 0)" fill="${color}"/>
    </g>
  </svg>`,
  (
    color
  ) => `<svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="13">
      <path d="M0 0H18V13H0V0Z" fill="#AFF1E3"/>
    </mask>
    <g mask="url(#mask0)">
      <rect x="-0.140625" y="-13" width="30.1923" height="5" transform="rotate(28.843 -0.140625 -13)" fill="${color}"/>
      <rect x="-0.140625" width="30.1923" height="5" transform="rotate(28.843 -0.140625 0)" fill="${color}"/>
    </g>
  </svg>
  `,
  (
    color
  ) => `<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="17">
      <path d="M0 0H18V17H0V0Z" fill="#FAF7E6"/>
    </mask>
    <g mask="url(#mask0)">
      <rect x="-2" y="7" width="6" height="14" rx="3" fill="${color}"/>
      <rect x="7" width="6" height="14" rx="3" fill="${color}"/>
      <rect x="15" y="11" width="6" height="14" rx="3" fill="${color}"/>
      <rect x="-2" y="-10" width="6" height="14" rx="3" fill="${color}"/>
      <rect x="15" y="-6" width="6" height="14" rx="3" fill="${color}"/>
    </g>
  </svg>`,
  (
    color
  ) => `<svg width="18" height="44" viewBox="0 0 18 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="44">
      <path d="M0 0H18V44H0V0Z" fill="#EFE5FD"/>
    </mask>
    <g mask="url(#mask0)">
      <path d="M-4 13H3V8H14.5V13H24" stroke="${color}" stroke-width="5" stroke-linejoin="round"/>
      <path d="M-0.5 26H8V21H19.5V26H29" stroke="${color}" stroke-width="5" stroke-linejoin="round"/>
      <path d="M-7 41H0V36H11.5V41H21" stroke="${color}" stroke-width="5" stroke-linejoin="round"/>
    </g>
  </svg>`,
  (
    color
  ) => `<svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="22">
      <rect width="18" height="22" fill="${color}"/>
    </mask>
    <g mask="url(#mask0)">
      <path d="M4 0L10.0622 10.5H-2.06218L4 0Z" fill="${color}"/>
      <path d="M14 11L20.0622 21.5H7.93782L14 11Z" fill="${color}"/>
    </g>
  </svg>`,
  (
    color
  ) => `<svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="21">
      <rect width="18" height="21" fill="${color}"/>
    </mask>
    <g mask="url(#mask0)">
      <path d="M3 0L4.5716 4.83688H9.6574L5.5429 7.82624L7.1145 12.6631L3 9.67376L-1.1145 12.6631L0.457101 7.82624L-3.6574 4.83688H1.4284L3 0Z" fill="${color}"/>
      <path d="M15 -12L16.5716 -7.16312H21.6574L17.5429 -4.17376L19.1145 0.663118L15 -2.32624L10.8855 0.663118L12.4571 -4.17376L8.3426 -7.16312H13.4284L15 -12Z" fill="${color}"/>
      <path d="M15 9L16.5716 13.8369H21.6574L17.5429 16.8262L19.1145 21.6631L15 18.6738L10.8855 21.6631L12.4571 16.8262L8.3426 13.8369H13.4284L15 9Z" fill="${color}"/>
    </g>
  </svg>`,
  (
    color
  ) => `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
      <rect width="18" height="18" fill="${color}"/>
    </mask>
    <g mask="url(#mask0)">
      <path d="M3 -6L3.63987 -1.59607L6.25306 -5.19819L4.77303 -1.00134L8.76089 -2.97645L5.50001 0.0518708L9.94896 0.156243L5.65427 1.32229L9.54511 3.48223L5.20046 2.51887L7.64186 6.23958L4.24256 3.3675L4.67521 7.79659L3 3.67376L1.32479 7.79659L1.75744 3.3675L-1.64186 6.23958L0.799537 2.51887L-3.54511 3.48223L0.345733 1.32229L-3.94896 0.156243L0.499989 0.0518708L-2.76089 -2.97645L1.22697 -1.00134L-0.253062 -5.19819L2.36013 -1.59607L3 -6Z" fill="${color}"/>
      <path d="M3 12L3.63987 16.4039L6.25306 12.8018L4.77303 16.9987L8.76089 15.0235L5.50001 18.0519L9.94896 18.1562L5.65427 19.3223L9.54511 21.4822L5.20046 20.5189L7.64186 24.2396L4.24256 21.3675L4.67521 25.7966L3 21.6738L1.32479 25.7966L1.75744 21.3675L-1.64186 24.2396L0.799537 20.5189L-3.54511 21.4822L0.345733 19.3223L-3.94896 18.1562L0.499989 18.0519L-2.76089 15.0235L1.22697 16.9987L-0.253062 12.8018L2.36013 16.4039L3 12Z" fill="${color}"/>
      <path d="M17 0L17.6399 4.40393L20.2531 0.801808L18.773 4.99866L22.7609 3.02355L19.5 6.05187L23.949 6.15624L19.6543 7.32229L23.5451 9.48223L19.2005 8.51887L21.6419 12.2396L18.2426 9.3675L18.6752 13.7966L17 9.67376L15.3248 13.7966L15.7574 9.3675L12.3581 12.2396L14.7995 8.51887L10.4549 9.48223L14.3457 7.32229L10.051 6.15624L14.5 6.05187L11.2391 3.02355L15.227 4.99866L13.7469 0.801808L16.3601 4.40393L17 0Z" fill="${color}"/>
    </g>
  </svg>`,
]
const LETRAS = 'ABCDEFGHIJKLMNOPQ'.split('')
const PADROES: PadraoType[] = [
  { cor: '#FF7741' },
  { cor: '#BB8AFF' },
  { cor: '#E0D111' },
  { cor: '#44CBAE' },
  { cor: '#B5EAFA' },
  { cor: '#E2DCD5' },
  { cor: '#C8D4EA' },
]
interface PadraoType {
  cor: string
  glifo?: string
  glifoErro?: string
}

export const RelacionarItensCores = ({ ionContentRef }) => {
  const { helpers, AlternativasContainerSide, Container, InfoSelecioneAlternativa } =
    RelacionarItensCores
  const isWide = useMedia({ minWidth: '749px' })
  const dispatch = QuestaoStore.useDispatch()
  const { questao, respostasInformadas, showRespostaIncorreta, somenteLeitura } =
    QuestaoStore.useState()
  const alternativas = React.useMemo(() => {
    if (!questao.questoesAlternativas) return []
    const padroes = [...PADROES]
    const padroesSvgs = [...PADROES_SVG]
    return questao.questoesAlternativas.map((a) => {
      const padrao = padroes.splice(Math.random() * padroes.length, 1)[0]
      const glifo = padroesSvgs.splice(Math.random() * padroes.length, 1)[0]
      padrao.glifo = btoa(glifo(darken(0.25, padrao.cor)))
      padrao.glifoErro = btoa(glifo('rgb(235, 87, 87)'))
      return {
        ...a,
        padrao,
      }
    })
  }, [questao.questoesAlternativas])
  const [alternativaSelecionadaEsquerda, setAlternativaSelecionadaEsquerda] = useState(null)
  const [alternativaSelecionadaDireita, setAlternativaSelecionadaDireita] = useState(null)
  const ref = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (alternativaSelecionadaEsquerda && alternativaSelecionadaDireita) {
      const alternativaId = alternativaSelecionadaEsquerda
      const respostaInformada = alternativas.find(
        (x) => x.id === alternativaSelecionadaDireita
      )!.respostaEsperada
      const questaoAlternativaEnviadaId = alternativaSelecionadaDireita
      const resposta = { questaoAlternativaEnviadaId, respostaInformada }

      dispatch(QuestaoStore.thunks.markAlternativa({ alternativaId, resposta }))

      setAlternativaSelecionadaEsquerda(null)
      setAlternativaSelecionadaDireita(null)
    }
  }, [alternativaSelecionadaDireita, alternativaSelecionadaEsquerda])
  const alternativasEsquerda = alternativas
  const alternativasDireita = [...alternativas].sort((a, b) =>
    a.ordenacao! < b.ordenacao! ? 1 : -1
  )

  const respostasAlternativas = questao.respostas.length
    ? questao.respostas[0].respostasAlternativas
    : []
  const respostasCorretas = respostasAlternativas
    .filter((x) => x.correta)
    .map((x) => x.questaoAlternativaId)

  function _getPadraoVisual(alternativa, lado) {
    if (respostasCorretas.includes(alternativa.id)) return alternativa.padrao

    if (lado === 'esquerda') {
      if (Object.keys(respostasInformadas).includes('' + alternativa.id)) return alternativa.padrao
      if (alternativaSelecionadaEsquerda === alternativa.id) return alternativa.padrao
    } else {
      const arrRespostasInformadas = Object.keys(respostasInformadas).map((x) => ({
        alternativaId: +x,
        ...respostasInformadas[x],
      }))

      const alternativaCorrespondendenteId = arrRespostasInformadas.find((x) => {
        return x.questaoAlternativaEnviadaId === alternativa.id
      })?.alternativaId
      if (!alternativaCorrespondendenteId) return

      return alternativas.find((x) => x.id === alternativaCorrespondendenteId)?.padrao
    }
  }

  function handleClickAlternativaEsquerda(alternativaId) {
    if (somenteLeitura) return
    if (showRespostaIncorreta) {
      return toast('Clique em Refazer Questão para tentar novamente selecionar')
    }
    if (respostasCorretas.includes(alternativaId)) {
      return toast('Não é possível selecionar alternativas que já tem correspondência correta')
    }
    setAlternativaSelecionadaEsquerda(alternativaId)
    dispatch({ type: 'unmarkAlternativa', payload: { alternativaId } })

    if (!isWide) helpers.scrollToAlternativasCorrespondentes(ionContentRef, ref)
  }

  function handleClickAlternativaDireita(alternativaId) {
    if (somenteLeitura) return
    if (showRespostaIncorreta) {
      return toast('Clique em Refazer Questão para tentar novamente selecionar')
    }
    if (!alternativaSelecionadaEsquerda) {
      return toast('Selecione uma alternativa da esquerda antes')
    }
    if (respostasCorretas.includes(alternativaId)) {
      return toast('Não é possível selecionar alternativas que já tem correspondência correta')
    }
    setAlternativaSelecionadaDireita(alternativaId)
    const alternativaIdPreviamenteMarcada = Object.keys(respostasInformadas).find(
      (x) => respostasInformadas[x].questaoAlternativaEnviadaId === alternativaId
    )
    if (alternativaIdPreviamenteMarcada) {
      dispatch({
        type: 'unmarkAlternativa',
        payload: { alternativaId: alternativaIdPreviamenteMarcada },
      })
    }
  }

  return (
    <Container ref={ref}>
      <div className="separador"></div>

      <InfoSelecioneAlternativa className="info-selecione-alternativa">
        Selecione uma alternativa
      </InfoSelecioneAlternativa>

      <AlternativasContainerSide className="alternativa-container-esquerda">
        {alternativasEsquerda.map((alternativaEsquerda, i) => (
          <AlternativaContainer
            key={i}
            alternativa={alternativaEsquerda}
            lado="esquerda"
            index={i}
            onClick={handleClickAlternativaEsquerda}
            padraoVisual={_getPadraoVisual(alternativaEsquerda, 'esquerda')}
          />
        ))}
      </AlternativasContainerSide>

      <InfoSelecioneAlternativa className="info-selecione-alternativa-correspondente">
        Selecione a alternativa correspondente
      </InfoSelecioneAlternativa>

      <AlternativasContainerSide className="alternativa-container-direita">
        {alternativasDireita.map((alternativaDireita, i) => (
          <AlternativaContainer
            key={i}
            alternativa={alternativaDireita}
            lado="direita"
            index={i}
            onClick={handleClickAlternativaDireita}
            padraoVisual={_getPadraoVisual(alternativaDireita, 'direita')}
          />
        ))}
      </AlternativasContainerSide>
    </Container>
  )
}
RelacionarItensCores.helpers = (() => {
  function scrollToAlternativasCorrespondentes(ionRef, ref) {
    const rects = ref.current
      ?.querySelector('.info-selecione-alternativa-correspondente')
      ?.getBoundingClientRect()
    if (!rects) return

    setTimeout(() => {
      ionRef.current?.scrollByPoint(0, rects.top - 100, 300)
    }, 400)
  }
  return {
    scrollToAlternativasCorrespondentes,
  }
})()
RelacionarItensCores.Container = styled.div`
  display: grid;
  grid-template-columns: auto 120px auto;
  overflow: auto;
  margin: 28px 16px 0 16px;
  .alternativa-container-esquerda {
    grid-area: alternativa-container-esquerda;
  }
  .info-selecione-alternativa {
    grid-area: info-selecione-alternativa;
  }
  .separador {
    grid-area: separador;
  }
  .info-selecione-alternativa-correspondente {
    grid-area: info-selecione-alternativa-correspondente;
  }
  .alternativa-container-linhas {
    grid-area: alternativa-container-linhas;
  }
  grid-template-areas: 'alternativa-container-esquerda separador alternativa-container-direita';
  @media screen and (min-width: 750px) {
    .info-selecione-alternativa {
      display: none;
    }
    .info-selecione-alternativa-correspondente {
      display: none;
    }
  }
  @media screen and (max-width: 749px) {
    /* margin-top: 20px; */
    .separador {
      display: none;
    }
    grid-template-areas:
      'info-selecione-alternativa'
      'alternativa-container-esquerda'
      'info-selecione-alternativa-correspondente'
      'alternativa-container-direita';
    grid-template-columns: auto;
    justify-content: start;
    margin-top: 8px;
    gap: 8px;
  }
`
RelacionarItensCores.AlternativasContainerSide = styled.div`
  display: grid;
  gap: 8px;
  @media screen and (max-width: 749px) {
    justify-items: start;
  }
`
RelacionarItensCores.InfoSelecioneAlternativa = styled.div`
  font-size: 16em;
  color: #88898c;
`

interface AlternativaContainerType {
  alternativa: QuestaoAlternativaModel
  lado: 'esquerda' | 'direita'
  index: number
  onClick
  padraoVisual: typeof PADROES[0]
}
function AlternativaContainer({
  alternativa,
  lado,
  index,
  onClick,
  padraoVisual,
}: AlternativaContainerType) {
  const { questao, showRespostaIncorreta } = QuestaoStore.useState()
  const item = JSON.parse(
    lado === 'esquerda' ? alternativa.conteudoAlternativa : alternativa.respostaEsperada
  )
  const indicador = lado === 'esquerda' ? LETRAS[index] : index + 1
  const { helpers, Container, VisualPattern, Indicador, Descricao } = AlternativaContainer

  const imagem = useDecorateBlobWithSas({ blobPath: item.tipo === 'IMAGEM' ? item.item : '' })

  const respostasAlternativas = questao.respostas.length
    ? questao.respostas[0].respostasAlternativas
    : []
  const respostaCorreta = respostasAlternativas.some(
    (x) => x.correta && x.questaoAlternativaId === alternativa.id
  )

  const containerSpring = useSpring(
    helpers.getContainerSpring(padraoVisual, respostaCorreta, showRespostaIncorreta)
  )
  const visualPatternSpring = useSpring(padraoVisual ? { opacity: 1 } : { opacity: 0 })

  return (
    <Container
      onClick={() => onClick(alternativa.id)}
      data-lado={lado}
      data-alternativa-id={alternativa.id}
      style={containerSpring as any}
    >
      <VisualPattern
        className="alternativa-visual-pattern"
        color={padraoVisual?.cor}
        pattern={
          showRespostaIncorreta && !respostaCorreta ? padraoVisual?.glifoErro : padraoVisual?.glifo
        }
        style={visualPatternSpring as any}
      />
      <Descricao>
        <Indicador>{indicador}.</Indicador>
        {item.tipo === 'IMAGEM' ? <img src={imagem} /> : item.item}
      </Descricao>
    </Container>
  )
}
AlternativaContainer.helpers = (() => {
  function getContainerSpring(
    padraoVisual: typeof PADROES[0],
    respostaCorreta: boolean,
    showRespostaIncorreta: boolean
  ) {
    if (!respostaCorreta && showRespostaIncorreta) {
      return {
        backgroundColor: '#ffffff',
        borderColor: '#EB5757',
      }
    }

    if (padraoVisual) {
      return {
        backgroundColor: padraoVisual.cor,
        borderColor: '#FFFFFF00',
      }
    }

    return {
      backgroundColor: '#ffffff',
      borderColor: '#E0E0E0',
    }
  }
  return {
    getContainerSpring,
  }
})()
AlternativaContainer.Container = styled(animated.div)`
  font-size: 18em;
  background: #f7f7f7;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 14px;
  cursor: pointer;
  color: #2d2d2d;
  align-items: center;
  /* padding: 18px; */
  border: 2px solid;
  border-radius: 8px;
  min-height: 60px;
`
AlternativaContainer.VisualPattern = styled(animated.div)<{ pattern; color }>(
  (props) => `
  width: 18px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-left: -2px;
  height: calc(100% + 4px);
  background-image: url(data:image/svg+xml;base64,${props.pattern});
`
)
AlternativaContainer.Indicador = styled.div`
  float: left;
  font-weight: bold;
  margin-right: 3px;
`
AlternativaContainer.Descricao = styled.div`
  padding: 8px 8px 8px 0;
`

const Styles = {
  AlternativasContainer: styled('div')`
    display: grid;
    grid-template-columns: auto 120px auto;
    overflow: auto;
    margin: 28px 16px 0 16px;
    .alternativa-container-esquerda {
      grid-area: alternativa-container-esquerda;
    }
    .alternativa-container-direita {
      grid-area: alternativa-container-direita;
    }
    .alternativa-container-linhas {
      grid-area: alternativa-container-linhas;
    }
    grid-template-areas: 'alternativa-container-esquerda alternativa-container-linhas alternativa-container-direita';
    @media screen and (max-width: 425px) {
      /* margin-top: 20px; */
    }
  `,
  Item: styled('div')`
    display: flex;
    align-items: strech;
    background: white;
    cursor: pointer;
    --border-radius: 3px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.08);
    &:not(:last-child) {
      margin-bottom: 16px;
      @media screen and (max-width: 425px) {
        margin-bottom: 12px;
      }
    }
    width: 100%;
    background: #ffffff;
    .alternativa-descricao {
      border-top: 2px solid;
      border-right: 2px solid;
      border-bottom: 2px solid;
      border-color: #e0e0e0;
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      color: #4d4d4d;
      flex: 1;
      padding-left: 24px;
      padding-right: 24px;
      display: flex;
      align-items: center;
    }
    .alternativa-letra {
      display: flex;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      width: 64px;
      min-height: 64px;
      background: #bdbdbd;
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 38px;
      display: flex;
      color: #ffffff;
    }
    &:hover {
      background: #f7f7f7;
    }
    @media screen and (max-width: 425px) {
      .alternativa-descricao {
        font-size: 16px;
        line-height: 19px;
        padding: 4px 12px 4px 12px;
      }
      .alternativa-letra {
        width: 52px;
        min-height: 52px;
        font-size: 28px;
        line-height: 34px;
      }
    }
  `,
}
