import React from 'react'

const DownloadSvg = ({ color = '#FF9933' }) => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.25 8.33325C1.44891 8.33325 1.63968 8.40349 1.78033 8.52851C1.92099 8.65354 2 8.82311 2 8.99992V11.6666C2 11.8434 2.07902 12.013 2.21967 12.138C2.36032 12.263 2.55109 12.3333 2.75 12.3333H13.25C13.4489 12.3333 13.6397 12.263 13.7803 12.138C13.921 12.013 14 11.8434 14 11.6666V8.99992C14 8.82311 14.079 8.65354 14.2197 8.52851C14.3603 8.40349 14.5511 8.33325 14.75 8.33325C14.9489 8.33325 15.1397 8.40349 15.2803 8.52851C15.421 8.65354 15.5 8.82311 15.5 8.99992V11.6666C15.5 12.197 15.2629 12.7057 14.841 13.0808C14.419 13.4559 13.8467 13.6666 13.25 13.6666H2.75C2.15326 13.6666 1.58097 13.4559 1.15901 13.0808C0.737055 12.7057 0.500003 12.197 0.500002 11.6666V8.99992C0.500002 8.82311 0.57902 8.65354 0.719671 8.52851C0.860324 8.40349 1.05109 8.33325 1.25 8.33325ZM8.5325 9.47325C8.46117 9.53395 8.37706 9.58152 8.285 9.61325C8.19523 9.64852 8.09815 9.66674 8 9.66674C7.90185 9.66674 7.80478 9.64852 7.715 9.61325C7.62294 9.58152 7.53883 9.53395 7.4675 9.47325L4.4675 6.80659C4.32627 6.68105 4.24693 6.51079 4.24693 6.33325C4.24693 6.15572 4.32627 5.98545 4.4675 5.85992C4.60873 5.73438 4.80028 5.66386 5 5.66386C5.19973 5.66386 5.39127 5.73438 5.5325 5.85992L7.25 7.39325V0.999919C7.25 0.823108 7.32902 0.653538 7.46967 0.528514C7.61032 0.40349 7.80109 0.333252 8 0.333252C8.19891 0.333252 8.38968 0.40349 8.53033 0.528514C8.67098 0.653538 8.75 0.823108 8.75 0.999919V7.39325L10.4675 5.85992C10.5374 5.79776 10.6204 5.74845 10.7118 5.71481C10.8032 5.68117 10.9011 5.66386 11 5.66386C11.0989 5.66386 11.1968 5.68117 11.2882 5.71481C11.3796 5.74845 11.4626 5.79776 11.5325 5.85992C11.6024 5.92208 11.6579 5.99587 11.6957 6.07709C11.7336 6.1583 11.7531 6.24535 11.7531 6.33325C11.7531 6.42116 11.7336 6.5082 11.6957 6.58942C11.6579 6.67063 11.6024 6.74443 11.5325 6.80659L8.5325 9.47325Z"
        fill="white"
      />
    </svg>
  )
}

export default DownloadSvg
