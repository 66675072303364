import { createSimpleStore } from 'react-simple-reducer'
import { ConteudoPaginaModel } from '../models/ConteudoPaginaModel'
import Axios from 'axios'
import { ModuloModel } from '../models/ModuloModel'
import { createSelector } from 'reselect'
import { ProgressoModel } from '../models/ProgressoModel'
import { BlocoNotaModel } from '../models/BlocoNotaModel'
import { toast } from 'react-toastify'
import { IStateRedux } from '../common/ReduxStore'
import { selectCursosUsuario } from '../cursos/CursosStore'

const TEMPO_SALVAR_PROGRESSO = 60 * 1000

const ConteudoStore = createSimpleStore(
  {
    loading: false,
    errorMessage: '',
    paginas: [] as ConteudoPaginaModel[],
    paginaAtualId: '',
    moduloAtual: null as ModuloModel | null,

    videoPlaying: false,
    forceUpdateProgresso: false,
    periodoRestanteHoje: null as number | null,
    periodoRestanteSessaoAtual: null as number | null,
    periodoRestantePausa: null as number | null,
    questoesPagina: [] as Array<{ questaoId; possuiRespostaCorreta }>,
    notas: [] as BlocoNotaModel[],
    notaAtiva: null as BlocoNotaModel | null,
    statusPainelNota: 'CLOSED' as 'CLOSED' | 'OPEN' | 'CREATING' | 'READING',
    isIndiceOpen: false,
    isTopbarActionsVisible: true,
    novoTextoNota: '',
    posicaoSelecaoTexto: null as { top: number; left: number } | null,
    fontSize: _getFontSize(),
  },
  {
    initStarted(state) {
      state.loading = true
    },
    initError(state, message: '') {
      state.errorMessage = message
      state.loading = false
    },
    initSuccess(state, payload: { modulo; paginas; notas }) {
      state.moduloAtual = payload.modulo
      state.paginas = payload.paginas
      state.paginaAtualId = payload.paginas[0]?.id
      state.notas = payload.notas
      state.loading = false
    },
    proximaPagina(state) {
      const index = state.paginas.findIndex((x) => x.id === state.paginaAtualId)
      if (index < state.paginas.length - 1) {
        state.paginaAtualId = state.paginas[index + 1].id
        state.isTopbarActionsVisible = true
        state.videoPlaying = false
      }
    },
    paginaAnterior(state) {
      const index = state.paginas.findIndex((x) => x.id === state.paginaAtualId)
      if (index > 0) {
        state.paginaAtualId = state.paginas[index - 1].id
        state.isTopbarActionsVisible = true
        state.videoPlaying = false
      }
    },
    setVideoPlaying(state, payload: boolean) {
      state.videoPlaying = payload
    },
    setPagina(state, payload: number) {
      state.paginaAtualId = state.paginas[payload - 1]?.id
      state.isIndiceOpen = false
      state.videoPlaying = false
    },
    addQuestaoPagina(state, payload: number) {
      state.questoesPagina = [
        ...state.questoesPagina,
        {
          possuiRespostaCorreta: false,
          questaoId: payload,
        },
      ]
    },
    removeQuestaoPagina(state, payload: number) {
      state.questoesPagina = state.questoesPagina.filter((x) => x.questaoId !== payload)
    },
    questaoPaginaCorreta(state, payload: number) {
      state.questoesPagina = state.questoesPagina.map((q) => {
        if (q.questaoId !== payload) return q
        return {
          questaoId: q.questaoId,
          possuiRespostaCorreta: true,
        }
      })
    },
    createOrUpdateNotaSuccess(state, payload: BlocoNotaModel) {
      state.notas = [...state.notas.filter((x) => x.id !== payload.id), payload]
      state.notaAtiva = payload
      state.statusPainelNota = 'READING'
    },
    createOrUpdateNotaStarted(state) {},
    createOrUpdateNotaError(state) {},
    openNota(state, payload: BlocoNotaModel) {
      state.notaAtiva = payload
      state.statusPainelNota = 'READING'
    },
    newNota(state, payload: { cursoId; moduloId; paginaIdUnico; referencia? }) {
      const { cursoId, moduloId, paginaIdUnico, referencia } = payload
      state.statusPainelNota = 'CREATING'
      state.notaAtiva = { cursoId, moduloId, paginaIdUnico, referencia } as any
      state.novoTextoNota = ''
    },
    updateNotaAtiva(state, payload: Partial<BlocoNotaModel>) {
      state.notaAtiva = { ...state.notaAtiva, ...payload } as BlocoNotaModel
      state.novoTextoNota = ''
    },
    updateNovoTextoNota(state, payload: string) {
      state.novoTextoNota = payload
    },
    editNotaAtiva(state) {
      state.statusPainelNota = 'CREATING'
      state.novoTextoNota = state.notaAtiva?.texto || ''
    },
    closeNotaAtiva(state) {
      state.notaAtiva = null
    },
    openPainelNota(state) {
      state.statusPainelNota = 'OPEN'
    },
    backPainelNota(state) {
      state.statusPainelNota = 'OPEN'
      state.notaAtiva = null
    },
    closePainelNota(state) {
      state.statusPainelNota = 'CLOSED'
      state.notaAtiva = null
    },
    abortEdicaoNota(state) {
      state.novoTextoNota = ''
      if (!state.notaAtiva?.id) {
        state.statusPainelNota = 'OPEN'
        state.notaAtiva = null
        return
      }
      state.statusPainelNota = 'READING'
    },
    openIndice(state) {
      state.isIndiceOpen = true
    },
    closeIndice(state) {
      state.isIndiceOpen = false
    },
    openTopbarActions(state) {
      state.isTopbarActionsVisible = true
    },
    closeTopbarActions(state) {
      state.isTopbarActionsVisible = false
    },
    deleteNotaStarted(state) {},
    deleteNotaSuccess(state, notaId: number) {
      state.notas = state.notas.filter((x) => x.id !== notaId)
      state.statusPainelNota = 'OPEN'
      if (notaId === state.notaAtiva?.id) state.notaAtiva = null
    },
    deleteNotaError(state) {},
    diminuiFonte(state) {
      if (state.fontSize < 0.4) return
      state.fontSize -= 0.1
    },
    aumentaFonte(state) {
      if (state.fontSize > 2) return
      state.fontSize += 0.1
    },
    openChatTutor(state) {},
    sairConteudo(state) {
      state.isIndiceOpen = false
    },
    changePosicaoSelecaoTexto(state, payload: { top; left }) {
      state.posicaoSelecaoTexto = payload
    },
    removeSelecaoTexto(state) {
      state.posicaoSelecaoTexto = null
    },
  },
  {
    thunks: {
      init(moduloId) {
        return async (dispatch) => {
          dispatch(ConteudoStore.actions.initStarted())
          try {
            const [modulo, paginas, notas] = await Promise.all([
              Axios.get(`/app/modulos/${moduloId}`).then((x) => x.data),
              Axios.get(`/app/conteudos-paginas/modulo/${moduloId}`).then((x) => x.data),
              Axios.get(`/app/bloco-notas/modulo/${moduloId}/aluno`).then((x) => x.data),
            ])
            dispatch(ConteudoStore.actions.initSuccess({ modulo, paginas, notas }))
          } catch (error: any) {
            const errorMessage =
              error.response?.data?.message ??
              'Ocorreu um erro ao buscar as informações do conteúdo'
            dispatch(ConteudoStore.actions.initError(errorMessage))
          }
        }
      },
      createOrUpdateNota(nota: BlocoNotaModel) {
        return async (dispatch) => {
          dispatch(ConteudoStore.actions.createOrUpdateNotaStarted())
          try {
            const response = await Axios.post('/app/bloco-notas', nota).then((x) => x.data)
            dispatch(ConteudoStore.actions.createOrUpdateNotaSuccess(response))
            toast('Nota salva com sucesso')
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro ao salvar a nota'
            toast(message)
            dispatch(ConteudoStore.actions.createOrUpdateNotaError())
          }
        }
      },
      deleteNota(notaId) {
        return async (dispatch) => {
          dispatch(ConteudoStore.actions.deleteNotaStarted())
          try {
            const response = await Axios.delete(`/app/bloco-notas/${notaId}`).then((x) => x.data)
            dispatch(ConteudoStore.actions.deleteNotaSuccess(notaId))
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro ao deletar a nota'
            toast(message)
            dispatch(ConteudoStore.actions.deleteNotaError())
          }
        }
      },
    },
  }
)

type IState = ReturnType<typeof ConteudoStore.useState>
export type IStateConteudo = IState
const getPaginasTitulo = createSelector(
  (s: IState) => s.paginas,
  (paginas) => {
    return paginas.map((p) => {
      const duracaoTotal = p.conteudosItens.reduce((prev, curr) => prev + curr.duracao, 0)
      return {
        pagina: p,
        titulos: p.conteudosItens
          .filter((c) => {
            return c.tipo === 'TITULO' || c.tipo === 'SUBTITULO'
          })
          .map((c) => {
            const duracaoPrevia = p.conteudosItens
              .filter((__c) => __c.ordenacao < c.ordenacao)
              .reduce((prev, curr) => prev + curr.duracao, 0)
            return { ...c, posicao: (duracaoPrevia / duracaoTotal) * 100 }
          }),
      }
    })
  }
)
const getNumeroPaginaAtual = createSelector(
  (s: IState) => s.paginas,
  (s: IState) => s.paginaAtualId,
  (paginas, paginaAtualId) => {
    return (paginas.findIndex((x) => x.id === paginaAtualId) ?? 0) + 1
  }
)
const getTotalPaginas = createSelector(
  (s: IState) => s.paginas,
  (paginas) => paginas.length
)
const geConteudosPagina = createSelector(
  (s: IState) => s.paginas,
  (s: IState) => s.paginaAtualId,
  (paginas, paginaAtualId) => {
    return paginas.find((p) => p.id === paginaAtualId)?.conteudosItens ?? []
  }
)
const getDuracaoTotalPaginaAtual = (state: IState, progressoAtual) => {
  const { paginas, paginaAtualId } = state
  const paginaAtual = paginas.find((x) => x.id === paginaAtualId)
  if (!paginaAtual) return 0
  const duracaoTotalPaginaAtual = paginaAtual.conteudosItens.reduce(
    (prev, curr) => prev + curr.duracao,
    0
  )

  const duracaoAteProgresso = _getDuracaoAteProgresso(paginaAtual, progressoAtual)

  return (duracaoAteProgresso / duracaoTotalPaginaAtual) * 100
}
const getQuestoesNaoRespondidasPagina = createSelector(
  (s: IState) => s.questoesPagina,
  (questoesPagina) => questoesPagina.some((x) => !x.possuiRespostaCorreta)
)
const getNotasPaginaAtual = createSelector(
  (s: IState) => s.notas,
  (s: IState) => s.paginaAtualId,
  (s: IState) => s.paginas,
  (notas, paginaAtualId, paginas) => {
    const paginaAtual = paginas.find((x) => x.id === paginaAtualId)
    return [...notas.filter((x) => x.paginaIdUnico === paginaAtual?.identificadorUnico)].sort(
      (a, b) => (a.updatedAt < b.updatedAt ? 1 : -1)
    )
  }
)
const getNotasOutrasPaginas = createSelector(
  (s: IState) => s.notas,
  (s: IState) => s.paginaAtualId,
  (s: IState) => s.paginas,
  (notas, paginaAtualId, paginas) => {
    const paginaAtual = paginas.find((x) => x.id === paginaAtualId)
    return [...notas.filter((x) => x.paginaIdUnico !== paginaAtual?.identificadorUnico)].sort(
      (a, b) => (a.updatedAt < b.updatedAt ? 1 : -1)
    )
  }
)

function _getDuracaoAteProgresso(paginaAtual: ConteudoPaginaModel, progressoAtual: ProgressoModel) {
  const conteudoItemProgresso = paginaAtual.conteudosItens.find(
    (x) => x.id === progressoAtual.conteudoItemId
  )

  if (!conteudoItemProgresso) return 0
  let duracao = 0

  paginaAtual.conteudosItens.forEach((c) => {
    if (c.ordenacao < conteudoItemProgresso.ordenacao) duracao += c.duracao
    else if (c.ordenacao === conteudoItemProgresso.ordenacao) {
      duracao += (c.duracao * progressoAtual.porcentagem) / 100
    }
  })

  return duracao
}

function _getFontSize() {
  const fs = localStorage.getItem('conteudo_font_size')
  if (fs && fs !== 'undefined' && !isNaN(+fs)) return +fs
  return 1
}

const ConteudoSelectors = {
  getPaginasTitulo,
  getNumeroPaginaAtual,
  getTotalPaginas,
  geConteudosPagina,
  getDuracaoTotalPaginaAtual,
  getQuestoesNaoRespondidasPagina,
  getNotasPaginaAtual,
  getNotasOutrasPaginas,
}

export { ConteudoStore, ConteudoSelectors }
