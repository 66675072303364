import React, { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { useSpring, animated } from 'react-spring'

type PositionType =
  | 'left'
  | 'bottomLeft'
  | 'bottom'
  | 'bottomRight'
  | 'right'
  | 'topRight'
  | 'top'
  | 'topLeft'
type ContextMenuType = {
  visible: boolean
  onClose: () => void
  position?: PositionType
} & React.HTMLAttributes<HTMLDivElement>
const ContextMenu = ({ visible, onClose, position = 'bottomRight', ...props }: ContextMenuType) => {
  const { Container } = ContextMenu
  const ref = useRef<any>(null)
  const animatedContextProps = useSpring({
    _display: visible ? 1 : 0,
    opacity: visible ? 1 : 0,
    transform: visible ? 'translate(0px, 0px)' : 'translate(0px, -20px)',
  })

  useEffect(() => {
    if (visible) {
      const handleClickOutside = (e) => {
        if (visible && !e?.path?.includes?.(ref?.current)) {
          // if (visible) {
          e.preventDefault()
          e.stopPropagation()
          onClose()
        }
      }
      const ev = document.querySelector('body')?.addEventListener('click', handleClickOutside)
      return () => document.querySelector('body')?.removeEventListener('click', handleClickOutside)
    }
  }, [visible])

  return (
    <div style={{ position: 'relative' }}>
      <Container
        ref={ref}
        {...props}
        style={{
          ...(props.style as any),
          ...animatedContextProps,
          display: animatedContextProps._display.interpolate((d) => (d === 0 ? 'none' : 'initial')),
        }}
        position={position}
      >
        {props.children}
      </Container>
    </div>
  )
}
ContextMenu.Container = styled(animated.div)<{ position: PositionType }>(
  (props) => `
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  box-shadow: 0px -1px 7px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  ${_getPosition(props.position)}
  z-index: 2;
`
)

function _getPosition(position: PositionType) {
  switch (position) {
    case 'left':
      return 'right: 0;'
    case 'bottomLeft':
      return 'right: 0;top: 0;'
    case 'bottom':
      return 'top: 0;'
    case 'bottomRight':
      return 'top: 0;left: 0;'
    case 'right':
      return 'left: 0;'
    case 'topRight':
      return 'left: 0;bottom: 0;'
    case 'top':
      return 'bottom: 0;'
    case 'topLeft':
      return 'bottom: 0;right: 0;'
  }
}

type ContextMenuItemType = { icon?: any; label: string } & React.HTMLAttributes<HTMLDivElement>
const ContextMenuItem = ({ icon, label, ...props }: ContextMenuItemType) => {
  const { Botao } = ContextMenuItem
  return (
    <Botao {...props}>
      {icon && icon} {label}
    </Botao>
  )
}

ContextMenuItem.Botao = styled.div`
  color: #2d2d2d;
  border-radius: 4px;
  height: 24px;
  transition: background 0.25s;
  font-size: 0.9em;
  display: grid;
  place-items: center;
  cursor: pointer;
  margin-left: 8px;
  width: 186px;
  height: 42px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 8px;
  place-items: center;
  margin: 0;
  justify-items: start;
  border-radius: 0;
  padding-left: 8px;
  &:hover {
    background: #f2f2f2;
  }
`

export { ContextMenu, ContextMenuItem }
