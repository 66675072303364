import React from 'react'
import { IonSlide, IonSlides } from '@ionic/react'
import { ModalTutorial } from '../components'
import styled from '@emotion/styled'
import Svgs from '../svgs'
import { GlobalStore } from '../GlobalStore'

export const Tutorial = () => {
  const dispatch = GlobalStore.useDispatch()
  const { Modal, Layout, SlideRight, SlideLeft } = Tutorial
  const ref = React.useRef<any>()
  return (
    <Modal onEnd={() => dispatch(GlobalStore.actions.endTutorial('conteudo'))}>
      <Layout>
        <SlideLeft onClick={() => ref.current.slidePrev()}>
          <Svgs.ChevronLeft color="#333" />
        </SlideLeft>
        <IonSlides
          ref={ref}
          pager={true}
          options={{ initialSlide: 0, height: '100%' }}
          style={{ maxWidth: 'calc(100vw - 24px)' }}
        >
          <IonSlide>
            <AreaEstudo />
          </IonSlide>
        </IonSlides>
        <SlideRight onClick={() => ref.current.slideNext()}>
          <Svgs.ChevronLeft style={{ transform: 'rotate(180deg)' }} color="#333" />
        </SlideRight>
      </Layout>
    </Modal>
  )
}
Tutorial.Modal = styled(ModalTutorial)`
  max-width: 777px;
  @media (max-width: 801px) {
    max-width: calc(100vw - 24px);
  }
`
Tutorial.Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
`
Tutorial.SlideButton = styled.div`
  min-width: 30px;
  min-height: 30px;
  background: #f2f2f2;
  border-radius: 4px;
  display: grid;
  place-content: center;
  @media (max-width: 768px) {
    display: none;
  }
`
Tutorial.SlideRight = styled(Tutorial.SlideButton)``
Tutorial.SlideLeft = styled(Tutorial.SlideButton)``

const AreaEstudo = () => {
  return (
    <Container>
      <Titulo>Inicie seus estudos</Titulo>
      <Content>
        <div>
          <Svgs.HandWriting />
        </div>
        A área de estudo é a parte onde você acessa todo o <strong>conteúdo</strong> que foi
        preparado para <strong>você estudar</strong>. Nela contém vídeos, textos, exercícios e no
        final de cada módulo, uma <strong>avaliação</strong>.
      </Content>
    </Container>
  )
}
const Container = styled.div`
  color: #333333;
  font-size: 14px;
  max-width: 500px;
  margin: 0 auto;
  min-height: 50vh;
  max-height: 90vh;
  @media (max-width: 768px) {
    max-width: calc(90vw - 48px);
  }
`
const Titulo = styled.div`
  margin-top: 38px;
  font-weight: bold;
`
const Content = styled.div`
  margin-top: 125px;
  line-height: 2em;
`
