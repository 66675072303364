import { IonAlert } from '@ionic/react'
import React from 'react'
import ReactDOM from 'react-dom'

const confirm = async ({ header, message }: { header?: string; message: string }) => {
  return await new Promise((resolve, reject) => {
    const modalRoot = document.getElementById('confirm-containers')!
    const el = document.createElement('div')
    modalRoot.appendChild(el)

    function handleDismiss() {
      modalRoot.removeChild(el)
      resolve(false)
    }
    ReactDOM.render(
      <IonAlert
        isOpen={true}
        onDidDismiss={handleDismiss}
        header={header}
        message={message}
        buttons={[
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              resolve(false)
            },
          },
          {
            text: 'Ok',
            handler: () => {
              resolve(true)
            },
          },
        ]}
      />,
      el
    )
  })
}

export default confirm
