import React from 'react'
import styled from '@emotion/styled'
import { useModuloAtual } from './ModulosStore'
import Svgs from '../svgs'
import { useHistory, useParams } from 'react-router'
import { BotoesTriplos } from '../components/BotoesTriplos'
import { AnimateEnter } from '../components/AnimateEnter'

interface ModuloFinalizadoParams {
  cursoId
  moduloId
}

export const ModuloFinalizado = () => {
  const { Layout, Modulo, OnboardingImgContainer, Titulo, Subtitulo, ContainerTextos } =
    ModuloFinalizado

  const modulo = useModuloAtual()
  const history = useHistory()
  const { cursoId, moduloId } = useParams<ModuloFinalizadoParams>()

  function handleClickResponderAvaliacao() {
    history.push(`/curso/${cursoId}/regras-avaliacao/${moduloId}`)
  }

  function handleClickVerGabarito() {
    history.push(`/curso/${cursoId}/gabarito/${moduloId}/exercicios`)
  }

  function handleClickFazerAvaliacaoDepois() {
    history.replace(`/curso/${cursoId}/home`)
  }

  return (
    <Layout>
      <AnimateEnter delay={0}>
        <OnboardingImgContainer>
          <Svgs.OnboardingModuloFinalizado />
        </OnboardingImgContainer>
      </AnimateEnter>
      <ContainerTextos>
        <AnimateEnter delay={300}>
          <Titulo>Você finalizou o conteúdo do módulo de</Titulo>
          <Modulo>{modulo?.nome}</Modulo>
        </AnimateEnter>
        <AnimateEnter delay={700}>
          <Subtitulo>Chegou a hora de fazer a avaliação final do módulo</Subtitulo>
        </AnimateEnter>
      </ContainerTextos>
      <AnimateEnter delay={1500}>
        <BotoesTriplos
          botoes={[
            {
              label: 'Responder avaliação',
              icon: <Svgs.PencilLine />,
              onClick: handleClickResponderAvaliacao,
            },
            {
              label: 'Ver gabarito das questões',
              icon: <Svgs.Notes height={37} width={30} color="#F06F30" />,
              onClick: handleClickVerGabarito,
            },
            {
              label: 'Fazer avaliação depois',
              icon: <Svgs.ArrowLeft />,
              onClick: handleClickFazerAvaliacaoDepois,
            },
          ]}
        />
      </AnimateEnter>
      <br />
      <br />
      <br />
    </Layout>
  )
}

ModuloFinalizado.Layout = styled.div`
  height: 100%;
  overflow: auto;
`
ModuloFinalizado.OnboardingImgContainer = styled.div`
  text-align: center;
  @media (max-width: 580px) {
    svg {
      width: 224px;
      height: 156px;
    }
  }
`
ModuloFinalizado.ContainerTextos = styled.div`
  margin: 62px 0 44px 0;
  @media (max-width: 580px) {
    margin: 25px 0 18px 0;
  }
`
ModuloFinalizado.Titulo = styled.div`
  font-size: 24px;
  color: #2d2d2d;
  text-align: center;
  @media (max-width: 580px) {
    font-size: 18px;
  }
`
ModuloFinalizado.Modulo = styled.div`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  color: #f06f30;
  margin-top: 18px;
  @media (max-width: 580px) {
    font-size: 18px;
    margin-top: 8px;
  }
`
ModuloFinalizado.Subtitulo = styled.div`
  font-size: 18px;
  color: #2d2d2d;
  margin-top: 58px;
  text-align: center;
  @media (max-width: 580px) {
    font-size: 14px;
    margin-top: 28px;
  }
`
