import React from 'react'
import { SvgType } from './index'
const ShareSvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.4713 7.52861L9.13792 2.19528C9.0447 2.10204 8.92592 2.03854 8.79661 2.01282C8.66729 1.98709 8.53326 2.00028 8.41145 2.05073C8.28963 2.10119 8.18552 2.18663 8.11227 2.29625C8.03902 2.40588 7.99992 2.53476 7.99992 2.66661V5.02989C6.17878 5.19846 4.48609 6.04052 3.25307 7.3913C2.02005 8.74208 1.33546 10.5044 1.33325 12.3333V13.3333C1.33324 13.4717 1.37634 13.6067 1.45655 13.7196C1.53677 13.8324 1.65013 13.9175 1.7809 13.963C1.91166 14.0085 2.05335 14.0121 2.18628 13.9734C2.31921 13.9347 2.43679 13.8556 2.52271 13.747C3.1758 12.9703 3.97714 12.3315 4.8799 11.868C5.78265 11.4045 6.76873 11.1256 7.78052 11.0475C7.81372 11.0432 7.89705 11.0367 7.99992 11.0302V13.3333C7.99992 13.4651 8.03902 13.594 8.11227 13.7036C8.18552 13.8133 8.28963 13.8987 8.41145 13.9492C8.53326 13.9996 8.66729 14.0128 8.79661 13.9871C8.92592 13.9613 9.0447 13.8978 9.13792 13.8046L14.4713 8.47128C14.5332 8.40939 14.5823 8.33591 14.6158 8.25504C14.6493 8.17417 14.6665 8.08748 14.6665 7.99994C14.6665 7.9124 14.6493 7.82572 14.6158 7.74485C14.5823 7.66397 14.5332 7.5905 14.4713 7.52861ZM9.33325 11.7239V10.3333C9.33327 10.2457 9.31605 10.159 9.28255 10.0781C9.24906 9.99723 9.19995 9.92373 9.13804 9.86182C9.07613 9.79991 9.00263 9.75081 8.92173 9.71731C8.84084 9.68382 8.75414 9.66659 8.66659 9.66661C8.49667 9.66661 7.80265 9.69949 7.62557 9.72328C5.82863 9.88878 4.11816 10.5715 2.70109 11.6887C2.86185 10.2183 3.55937 8.85882 4.6601 7.87065C5.76082 6.88249 7.18738 6.33509 8.66659 6.33328C8.75414 6.3333 8.84084 6.31607 8.92173 6.28258C9.00263 6.24908 9.07613 6.19998 9.13804 6.13806C9.19995 6.07615 9.24906 6.00265 9.28255 5.92176C9.31605 5.84086 9.33327 5.75416 9.33325 5.66661V4.27598L13.0572 7.99994L9.33325 11.7239Z"
        fill={color}
      />
    </svg>
  )
}

export default ShareSvg
