import React from 'react'
import styled from '@emotion/styled'

const TituloPagina = styled('span')`
  border-left: 4px solid #1f3a93;
  padding-left: 8px;
  font-size: 24px;
  font-weight: 700;
  color: #2d2d2d;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 21px;
  }
`

export default TituloPagina
