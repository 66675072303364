import React from 'react'
import { SvgType } from './index'
const DeleteSvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.3333 7.99992H14.6667V7.33325C14.6667 6.80282 14.456 6.29411 14.0809 5.91904C13.7058 5.54397 13.1971 5.33325 12.6667 5.33325H11.3333C10.8029 5.33325 10.2942 5.54397 9.91912 5.91904C9.54405 6.29411 9.33333 6.80282 9.33333 7.33325V7.99992H6.66667C6.48986 7.99992 6.32029 8.07016 6.19526 8.19518C6.07024 8.32021 6 8.48977 6 8.66659C6 8.8434 6.07024 9.01297 6.19526 9.13799C6.32029 9.26301 6.48986 9.33325 6.66667 9.33325H7.33333V16.6666C7.33333 17.197 7.54405 17.7057 7.91912 18.0808C8.29419 18.4559 8.8029 18.6666 9.33333 18.6666H14.6667C15.1971 18.6666 15.7058 18.4559 16.0809 18.0808C16.456 17.7057 16.6667 17.197 16.6667 16.6666V9.33325H17.3333C17.5101 9.33325 17.6797 9.26301 17.8047 9.13799C17.9298 9.01297 18 8.8434 18 8.66659C18 8.48977 17.9298 8.32021 17.8047 8.19518C17.6797 8.07016 17.5101 7.99992 17.3333 7.99992ZM10.6667 7.33325C10.6667 7.15644 10.7369 6.98687 10.8619 6.86185C10.987 6.73682 11.1565 6.66659 11.3333 6.66659H12.6667C12.8435 6.66659 13.013 6.73682 13.1381 6.86185C13.2631 6.98687 13.3333 7.15644 13.3333 7.33325V7.99992H10.6667V7.33325ZM15.3333 16.6666C15.3333 16.8434 15.2631 17.013 15.1381 17.138C15.013 17.263 14.8435 17.3333 14.6667 17.3333H9.33333C9.15652 17.3333 8.98695 17.263 8.86193 17.138C8.7369 17.013 8.66667 16.8434 8.66667 16.6666V9.33325H15.3333V16.6666Z"
        fill={color}
      />
    </svg>
  )
}

export default DeleteSvg
