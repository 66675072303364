import React, { HTMLAttributes, useCallback, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { ReactComponent as Logo } from '../assets/matricula/logo.svg'
import { ReactComponent as Camera } from '../assets/matricula/camera.svg'
import { ReactComponent as Cert } from '../assets/matricula/cert.svg'
import { ReactComponent as Users } from '../assets/matricula/users.svg'
import bannerTeste from '../assets/matricula/banner-teste.jpg'
import { TextField, TextFieldInput } from '../common/TextField'
import { Button } from './MatriculasComponents'
import AliceCarousel from 'react-alice-carousel'
import { ReactComponent as CheckIcon } from '../assets/matricula/check.svg'
import 'react-alice-carousel/lib/alice-carousel.css'
import { ReactComponent as RightArrow } from '../assets/matricula/right-arrow.svg'
import { ReactComponent as LeftArrow } from '../assets/matricula/left-arrow.svg'
import { ReactComponent as Laptop } from '../assets/matricula/laptop.svg'
import { ReactComponent as Books } from '../assets/matricula/books.svg'
import { ReactComponent as LogoIcon } from '../assets/matricula/logo-icon.svg'
import { CATEGORIA_CURSO, MatriculaStore, selectEstado } from './MatriculasStore'
import { isValid as isCpfValid } from '@fnando/cpf'
import { useHistory, useLocation, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { ESTADOS, ETAPAS } from './MatriculasConstants'
import { ReactComponent as Danger } from '../assets/matricula/danger.svg'
import { Show } from '../common/Show'
import { keyframes } from '@emotion/react'
import Svgs from '../svgs'
import { HyperpayCobranca } from '@hyperdev-tech/hyperpay-cobranca'
import { ResponsiveDivider } from '../components/Divider'
import { Flex } from '../components/Flex'
import { formatMoeda } from '../common/Utils'
import { LoadingSpinner } from '../components/LoadingSpinner'
import Lottie from 'react-lottie'
import successAnimationData from '../assets/lotties/success.json'
import { isValid as isDateValid } from 'date-fns'
import { DadosAlunoModel } from '../models/MatriculaModel'
import { useWindowSize } from '../common/hooks/useWindowSize'
import { Form, useFormik, FormikProvider, useFormikContext } from 'formik'
import { isCNH } from 'validation-br'
import toast from 'react-hot-toast'
import { Radio, Select as SelectAntd, Spin } from 'antd'

interface MatriculaParams {
  curso: string
}

interface MatriculaSearchParams {
  estado: string
  categoria: string
}

const cursos: {
  nome: string
  urlSlug: string
  urlSlugCapacitacao: string
  urlSlugAtualizacao?: string
}[] = [
  // prettier-ignore
  { nome: 'Reciclagem para Condutores Infratores', urlSlug: 'reciclagem-para-condutores-infratores', urlSlugCapacitacao: 'reciclagem-para-condutores-infratores' },
  // prettier-ignore
  { nome: 'Preventivo de Reciclagem', urlSlug: 'preventivo-de-reciclagem', urlSlugCapacitacao: 'preventivo-de-reciclagem' },
  // prettier-ignore
  { nome: 'Atualização para Renovação', urlSlug: 'atualizacao-para-renovacao', urlSlugCapacitacao: 'atualizacao-para-renovacao' },
  // prettier-ignore
  { nome: 'Mototaxi', urlSlug: 'mototaxi', urlSlugCapacitacao: 'especializado-para-mototaxista', urlSlugAtualizacao: 'atualizacao-para-mototaxista' },
  // prettier-ignore
  { nome: 'Motofrete', urlSlug: 'motofrete', urlSlugCapacitacao: 'especializado-para-motofretista', urlSlugAtualizacao: 'atualizacao-para-motofretista' },
  // prettier-ignore
  { nome: 'Direção Defensiva', urlSlug: 'direcao-defensiva', urlSlugCapacitacao: 'direcao-defensiva' },
  // prettier-ignore
  { nome: 'Condutores de Transporte Coletivo de Passageiros', urlSlug: 'condutores-de-transporte-coletivo-de-passageiros', urlSlugCapacitacao: 'especializado-para-condutores-de-transporte-coletivo-de-passageiros', urlSlugAtualizacao: 'atualizacao-para-condutores-de-transporte-coletivo-de-passageiros' },
  // prettier-ignore
  { nome: 'Condutores de Transporte de Produtos Perigosos', urlSlug: 'condutores-de-transporte-de-produtos-perigosos', urlSlugCapacitacao: 'especializado-para-condutores-de-transporte-de-produtos-perigosos', urlSlugAtualizacao: 'atualizacao-para-condutores-de-transporte-de-produtos-perigosos' },
  // prettier-ignore
  { nome: 'Condutores de Transporte de Cargas Indivisíveis', urlSlug: 'condutores-de-transporte-de-cargas-indivisiveis', urlSlugCapacitacao: 'especializado-para-condutores-de-transporte-de-cargas-indivisiveis', urlSlugAtualizacao: 'atualizacao-para-condutores-de-transporte-de-cargas-indivisiveis' },
  // prettier-ignore
  { nome: 'Condutores de Transporte de Emergência', urlSlug: 'condutores-de-transporte-de-emergencia', urlSlugCapacitacao: 'especializado-para-condutores-de-transporte-de-emergencia', urlSlugAtualizacao: 'atualizacao-para-condutores-de-transporte-de-emergencia' },
  // prettier-ignore
  { nome: 'Condutores de Transporte Escolar', urlSlug: 'condutores-de-transporte-escolar', urlSlugCapacitacao: 'especializado-para-condutores-de-transporte-escolar', urlSlugAtualizacao: 'atualizacao-para-condutores-de-transporte-escolar' },
  // prettier-ignore
  { nome: 'NR-20 Básico', urlSlug: 'nr-20-basico', urlSlugCapacitacao: 'nr-20-basico' },
  // prettier-ignore
  { nome: 'NR-20 Intermediário', urlSlug: 'nr-20-intermediario', urlSlugCapacitacao: 'nr-20-intermediario' },
  // prettier-ignore
  { nome: 'NR-35 Trabalho em Altura', urlSlug: 'nr-35-trabalho-em-altura', urlSlugCapacitacao: 'nr-35-trabalho-em-altura' },
]

export const MatriculaEstadoCurso = () => {
  const { Header, Title, Layout, Footer, getCursos, LoadingContainer } = MatriculaEstadoCurso
  const [loading, setLoading] = useState(true)

  const params = useParams<MatriculaParams>()
  const cursoSlug = params?.curso?.toLowerCase()

  const init = useCallback(
    (dispatch) => {
      const curso = cursos.find((f) => f.urlSlug === cursoSlug)
      if (!curso) {
        setTimeout(() => {
          toast.error('Curso inexistente!')
        }, 1000)
        if (process.env.NODE_ENV === 'production') {
          setTimeout(() => {
            window.location.href = 'https://newdriver.com.br/cursos-estados'
          }, 5000)
        }
        return
      }
      dispatch(MatriculaStore.actions.init({ curso: { nome: curso.nome } } as any))
    },
    [cursoSlug]
  )

  return (
    <MatriculaStore.Provider init={init}>
      <MatriculaStore.GetState>
        {({ loading }) => (
          <Layout>
            <Spin spinning={loading.campos}>
              <Header>
                <Logo />
                <Title href="https://newdriver.com.br/cursos-estados/">Ver outros cursos </Title>
              </Header>
              <ResponsiveDivider
                style={{
                  marginTop: '50px',
                  justifyContent: 'center',
                  alignItems: 'start',
                }}
              >
                <DetalhesCurso />
                <MatriculaForm />
              </ResponsiveDivider>
              <Sucesso />
            </Spin>
          </Layout>
        )}
      </MatriculaStore.GetState>
    </MatriculaStore.Provider>
  )
}
const animateLoading = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);  /* You can adjust scale factor */
  }
  100% {
    transform: scale(1);
  }
`
MatriculaEstadoCurso.LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 12px;
  color: rgba(0, 0, 0, 0.5);
  svg {
    animation: ${animateLoading} 5s ease-in-out infinite;
    height: auto;
    width: 400px;
    max-width: 80vw;
  }
`
MatriculaEstadoCurso.Layout = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  scroll-behavior: smooth;
  * {
    font-family: Rubik;
  }
`
MatriculaEstadoCurso.Header = styled.div`
  display: flex;
  align-items: center;
`
MatriculaEstadoCurso.Title = styled.a`
  color: rgba(51, 51, 51, 1);
  margin-left: auto;
  font-weight: Bold;
  font-size: 22px;
  text-decoration: underline #c9c6c6 2px;
  margin-right: 18px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`
MatriculaEstadoCurso.Footer = styled.div`
  height: 204px;
  background-color: #ff9722;
  text-align: center;

  .title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #ffffff;
    padding: 20px;
  }

  .btn-suport {
    max-width: 283px;
    width: 100%;
    height: 44px;
    background: #534848;
    border-radius: 8px;
    color: #ffffff;
  }

  /* For mobile phones: */
  @media only screen and (min-width: 768px) {
    * {
      display: none;
    }
  }
`
MatriculaEstadoCurso.getCursos = (uf: string): { nome; url } | undefined => {
  return ESTADOS[uf.toUpperCase()]
}

const DetalhesCurso = () => {
  const { Layout, Title, Subtitle } = DetalhesCurso
  const { uf, curso, valorCurso, etapa } = MatriculaStore.useState()
  const dispatch = MatriculaStore.useDispatch()
  const desconto = uf === 'PE' ? 21 : 40

  useEffect(() => {
    if (!uf) return
    dispatch(MatriculaStore.thunks.getMunicipios({ estados: uf }))
  }, [uf])

  if (etapa === ETAPAS.SUCESSO) return null

  const urlImg = process.env.PUBLIC_URL + '/assets/cursos/' + curso?.id + '.webp'

  return (
    <Layout>
      <div>
        <img alt="" src={urlImg} />
      </div>
      <Subtitle>{curso?.categorias}</Subtitle>
      <Title>{curso?.nome}</Title>
      {!!valorCurso && (
        <Flex style={{ gap: '15px' }}>
          <h2 style={{ color: '#C4C4C4', textDecoration: 'line-through 2px' }}>
            R$ {formatMoeda(+valorCurso + desconto)}{' '}
          </h2>
          <h2 style={{ color: '#007AFF' }}>R$ {formatMoeda(+valorCurso)} </h2>
        </Flex>
      )}
      <div>
        <Icon icon={<Camera width={29} height={29} />}>Aulas 100% Online</Icon>
        <Icon icon={<Cert width={29} height={29} />}>Certificado Integrado ao DETRAN</Icon>
        <Icon icon={<Users width={29} height={29} />}>Tutores Virtuais</Icon>
      </div>
    </Layout>
  )
}
DetalhesCurso.Layout = styled.div`
  padding: 0px 50px;
`
DetalhesCurso.Subtitle = styled.h3`
  color: rgba(51, 51, 51, 0.7);
  font-weight: Bold;
  font-size: 18px;
  opacity: 1;
  text-align: left;
  line-height: 0;
  margin-top: 30px;
`
DetalhesCurso.Title = styled.h2`
  color: rgba(51, 51, 51, 1);
  font-weight: Bold;
  font-size: 24px;
  opacity: 1;
  text-align: left;
`

const MatriculaForm = () => {
  const { Layout, Header, ErrorMessage } = MatriculaForm
  const { etapa, error } = MatriculaStore.useState()
  const { width } = useWindowSize()
  const history = useHistory()

  useEffect(() => {
    if (etapa !== ETAPAS.SUCESSO) return
    const pathname = history.location.pathname.replace(/\/$/, '')
    history.push(`${pathname}#sucesso`)
  }, [etapa])

  if (etapa === ETAPAS.SUCESSO) return null
  return (
    <Layout id="matricula-form">
      <Header etapa={etapa} width={width} />
      <DadosAluno />
      <Contato />
      <Pagamento />
      <Sucesso />
      <Show condition={!!error}>
        <ErrorMessage>
          <Danger />
          <p>{error}</p>
        </ErrorMessage>
      </Show>
    </Layout>
  )
}
MatriculaForm.Layout = styled.div`
  width: 600px;
  box-shadow: 0px 2px 16px rgba(51, 51, 51, 0.301);
  border-radius: 7px;
  overflow: hidden;
  margin-bottom: 50px;

  /* For mobile phones: */
  @media only screen and (max-width: 768px) {
    width: 100vw;
    margin-top: 60px;
    background: #ff9722;
    border-radius: 7px 7px 0px 0px;
    padding-bottom: 40px;
  }
`
MatriculaForm.Header = ({ etapa, width }) => {
  const { HeaderLayout, Title, Subtitle } = MatriculaForm

  if (etapa === ETAPAS.DADOS_ALUNO || (etapa === ETAPAS.CONTATO && width < 768)) {
    return (
      <HeaderLayout titleMode={true}>
        <Title>MATRICULE-SE!</Title>
        <Subtitle>Preencha o formulário para realizar o curso</Subtitle>
      </HeaderLayout>
    )
  }
  if (etapa === ETAPAS.PAGAMENTO && width < 768) {
    return (
      <HeaderLayout titleMode={true}>
        <Title>PAGAMENTO</Title>
        <Subtitle>Insira seus dados para finalizar a matrícula</Subtitle>
      </HeaderLayout>
    )
  }
  return (
    <HeaderLayout titleMode={false}>
      <Passo indice={1} label="Dados Aluno" />
      <Passo indice={2} label="Contato" />
      <Passo indice={3} label="Pagamento" />
    </HeaderLayout>
  )
}
MatriculaForm.HeaderLayout = styled.div<{ titleMode }>(
  ({ titleMode }) => `
  height: 135px;
  text-align: center;
  color: #FFFFFF;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${titleMode ? '#FF9722' : ''};
  flex-direction: ${titleMode ? 'column' : ''};
`
)
MatriculaForm.Title = styled.h2`
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
`
MatriculaForm.Subtitle = styled.p`
  font-size: 20px;
  line-height: 28px;
`
MatriculaForm.ErrorMessage = styled.div`
  height: 110px;
  background-color: #ffe0e0;
  display: grid;
  align-items: center;
  grid-template-columns: 100px 1fr;
  padding: 0px 20px;

  /* For mobile phones: */
  @media only screen and (max-width: 768px) {
    margin: 0px 20px;
    border-radius: 0px 0px 7px 7px;
  }
`

const Passo = ({ indice, label }: { indice: number; label: string }) => {
  const { Layout, Indicador, Label } = Passo
  const { etapa: etapaAtual } = MatriculaStore.useState()
  const active = etapaAtual === indice
  return (
    <Layout>
      <Indicador active={active}>{indice}</Indicador>
      <Label active={active}>{label}</Label>
    </Layout>
  )
}
Passo.Layout = styled.div`
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-left: 28px;
    @media (max-width: 780px) {
      margin-left: 4px;
    }
  }
`
Passo.Indicador = styled.div<{ active: boolean }>(
  ({ active }) => `
  height: 39px;
  width: 39px;
  font-size: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${
    active
      ? `
    background-color: #F06F30;
    color: white;
    font-weight: 700;
  `
      : `
    background-color: #F2F2F2;
    color: #4F4F4F;
  `
  }
`
)
Passo.Label = styled.div<{ active: boolean }>(
  ({ active }) => `
  margin-left: 8px;
  font-size: 16px;
  color: #000000;
  font-weight: ${active ? '500' : '300'};
`
)

const DadosAluno = () => {
  const { Layout, Body, ResponsiveLayout, isFormFilled, validate } = DadosAluno
  const { etapa, dadosAluno, validacaoAluno, error, uf, campos, categoriaCurso } =
    MatriculaStore.useState()
  const dispatch = MatriculaStore.useDispatch()

  const handleValidate = (dadosAluno) => {
    dispatch(MatriculaStore.actions.changeError(null))
    const validation = validate(dadosAluno, campos, categoriaCurso)
    return validation
  }

  const formik = useFormik({
    initialValues: dadosAluno,
    validate: (values) => {
      const validation = handleValidate(values)
      if (validation.isValid) {
        values.estado = uf
        dispatch(MatriculaStore.actions.updateDadosAluno(values))
      }
    },
    onSubmit: async (values) => {
      const changeError = (error: string | null) =>
        dispatch(MatriculaStore.actions.changeError(error))
      changeError(null)
      const validation = handleValidate(values)
      if (!validation.isValid) {
        toast.error(validation.message)
        return changeError(validation.message)
      }
      dispatch(MatriculaStore.thunks.validaAluno(values))
    },
  })

  if (etapa !== ETAPAS.DADOS_ALUNO) return null
  return (
    <Layout>
      <Body>
        <h2 className="dados-aluno">Dados do aluno</h2>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <ComboBoxEstados />
            <CategoriaCurso />
            <Nome />
            <ResponsiveLayout>
              {campos.map(
                (campo, index) =>
                  ({
                    cpf: <Cpf key={index} />,
                    renach: <Renach key={index} />,
                    dataNascimento: <DataNascimento key={index} />,
                    cnh: <Cnh key={index} />,
                    dataPrimeiraHabilitacao: <DataPrimeiraHabilitacao key={index} />,
                    categoriaCnh: <ComboBoxCategoriaCNH key={index} />,
                    municipio: <ComboBoxMunicipios key={index} />,
                  }[campo] ?? null)
              )}
            </ResponsiveLayout>
            <Button
              label="Continuar"
              color="#F06F30"
              disabled={!isFormFilled(dadosAluno) && validacaoAluno.valido}
              style={{ width: '100%', borderRadius: '8px' }}
              type={'submit'}
              // onClick={handleClickContinuar}
            />
          </Form>
        </FormikProvider>
        <JaPossuiConta />
      </Body>
      <Show condition={!error && isFormFilled(dadosAluno)}>
        <StatusValidacao />
      </Show>
    </Layout>
  )
}

DadosAluno.Layout = styled.div`
  /* For mobile phones: */
  @media only screen and (max-width: 768px) {
    margin: 0px 20px 0px 20px;
  }
`
DadosAluno.Body = styled.div`
  padding: 10px 70px;
  background-color: #ffffff;

  .dados-aluno {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
  }

  @media (max-width: 1300px) {
    padding: 38px 30px;
  }
`
DadosAluno.ResponsiveLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`
DadosAluno.isFormFilled = (dadosAluno: DadosAlunoModel) => {
  const { nome, cpf } = dadosAluno
  return nome !== '' && getNumbers(cpf).length === 11
}
DadosAluno.validate = (
  dadosAluno: DadosAlunoModel,
  campos: string[],
  categoriaCurso: CATEGORIA_CURSO
) => {
  const {
    nome,
    cpf,
    dataNascimento,
    cnh,
    municipio,
    categoriaCnh,
    dataPrimeiraHabilitacao,
    renach,
    estado,
  } = dadosAluno
  const invalid = (message) => ({ isValid: false, message })

  if (!estado) return invalid('Selecione o seu estado!')
  if (!categoriaCurso) return invalid('Selecione o tipo de curso!')
  if (!nome?.trim().includes(' ')) return invalid('Informe o nome completo!')
  if (getNumbers(cpf).length !== 11) return invalid('O CPF deve possuir 11 dígitos!')
  if (!isCpfValid(cpf)) return invalid('CPF Inválido!')

  if (campos.includes('dataNascimento')) {
    if (!getData(dataNascimento)) return invalid('Informe a Data de Nascimento!')
    if (!isDateValid(new Date(getData(dataNascimento))))
      return invalid('Data de Nascimento inválida!')

    // const dataAtual = new Date()
    // const data100Anos = new Date(new Date().setFullYear(new Date().getFullYear() - 100))
    // const data21Anos = new Date(new Date().setFullYear(new Date().getFullYear() - 21))
    // if (new Date(getData(dataNascimento)) <= data21Anos)
    //   return invalid('É necessário ter mais de 21 anos para se matricular!')
    // if (
    //   new Date(getData(dataNascimento)) >= dataAtual ||
    //   new Date(getData(dataNascimento)) <= data100Anos
    // )
    //   return invalid('Data de Nascimento inválida!')
  }

  if (campos.includes('dataPrimeiraHabilitacao')) {
    if (!getData(dataPrimeiraHabilitacao)) return invalid('Informe a Data da Primeira Habilitação!')
    if (!isDateValid(new Date(getData(dataPrimeiraHabilitacao))))
      return invalid('Data da Primeira Habilitação inválida!')
    if (new Date(getData(dataPrimeiraHabilitacao)) >= new Date())
      return invalid('Data da Primeira Habilitação maior que a data atual!')
  }

  if (campos.includes('categoriaCnh')) {
    if (!categoriaCnh) return invalid('Informe a categoria da CNH')
  }

  if (campos.includes('cnh')) {
    if (!cnh) return invalid('Informe uma CNH')
    if (!isCNH(cnh)) return invalid('CNH inválida!')
  }

  if (campos.includes('municipio')) {
    if (!municipio) return invalid('Informe um município!')
  }

  if (campos.includes('renach')) {
    if (!renach) return invalid('Informe um Renach!')
  }

  return { isValid: true, message: '' }
}

function Nome() {
  return <TextField name="nome" label="Nome Completo:" placeholder="Digite seu nome completo" />
}

function Cpf() {
  return <TextField name="cpf" label="CPF:" placeholder="___.___.___-__" mask="cpf" />
}
function Renach() {
  return <TextField name="renach" label="Renach:" mask="renach" />
}

function DataNascimento() {
  return (
    <TextField
      name="dataNascimento"
      label="Data de Nascimento:"
      placeholder="__/__/____"
      mask="data"
    />
  )
}

function Cnh() {
  return (
    <TextField
      name="cnh"
      label={<span style={{ whiteSpace: 'nowrap' }}>Nº Carteira Nacional de Habilitação:</span>}
      placeholder="Digite o número de CNH"
      mask="cnh"
    />
  )
}

function DataPrimeiraHabilitacao() {
  return (
    <TextField
      name="dataPrimeiraHabilitacao"
      label={<span style={{ whiteSpace: 'nowrap' }}>Data da Primeira Habilitação</span>}
      placeholder="__/__/____"
      mask="data"
    />
  )
}

function getData(data: string | Date | undefined) {
  if (!data) return ''
  if (typeof data !== 'string') return data
  if (/\d\d\/\d\d\/\d\d\d\d/.test(data)) {
    return new Date(data.slice(0, 10).split('/').reverse().join('-') + ' 00:00')
  }
  if (/\d\d\d\d-\d\d-\d\d/.test(data)) {
    return new Date(data.slice(0, 10) + ' 00:00')
  }
  return new Date(data)
}

function CategoriaCurso() {
  const params = useParams<MatriculaParams>()
  const cursoSlug = params?.curso?.toLowerCase()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const categoriaParam = queryParams.get('categoria' as keyof MatriculaSearchParams)

  const dispatch = MatriculaStore.useDispatch()
  const { categoriaCurso, uf, curso } = MatriculaStore.useState()
  const cursoFind = cursos.find((f) => f.urlSlug === cursoSlug)

  useEffect(() => {
    if (!uf) return

    if (
      !categoriaCurso &&
      categoriaParam &&
      ['especializacao', 'especialização'].includes(categoriaParam?.toLowerCase())
    ) {
      return handleCategoriaCurso(CATEGORIA_CURSO.CAPACITACAO)
    }

    if (
      !categoriaCurso &&
      categoriaParam &&
      ['atualizacao', 'atualização'].includes(categoriaParam?.toLowerCase())
    ) {
      return handleCategoriaCurso(CATEGORIA_CURSO.ATUALIZACAO)
    }

    handleCategoriaCurso(categoriaCurso)
  }, [categoriaParam, uf])

  const handleCategoriaCurso = (categoriaCurso = CATEGORIA_CURSO.CAPACITACAO) => {
    if (!cursoFind?.urlSlugAtualizacao && uf) {
      const urlSlug = cursoFind?.urlSlugCapacitacao
      categoriaCurso = CATEGORIA_CURSO.CAPACITACAO
      dispatch(MatriculaStore.thunks.getValorCurso(uf, urlSlug!, categoriaCurso))
      return
    }

    if (categoriaCurso && uf) {
      const urlSlug =
        categoriaCurso === CATEGORIA_CURSO.CAPACITACAO
          ? cursoFind?.urlSlugCapacitacao
          : cursoFind?.urlSlugAtualizacao
      dispatch(MatriculaStore.thunks.getValorCurso(uf, urlSlug!, categoriaCurso))
      return
    }
  }

  return (
    <Show condition={cursoFind?.urlSlugAtualizacao}>
      <div style={{ marginBottom: 10 }}>
        <label>Tipo de curso:</label>
        <br />
        <Radio.Group
          name="categoriaCurso"
          value={categoriaCurso}
          onChange={(e) => {
            handleCategoriaCurso(e.target.value)
          }}
        >
          <Radio value={CATEGORIA_CURSO.CAPACITACAO}>{CATEGORIA_CURSO.CAPACITACAO}</Radio>
          <Radio value={CATEGORIA_CURSO.ATUALIZACAO}>{CATEGORIA_CURSO.ATUALIZACAO}</Radio>
        </Radio.Group>
      </div>
    </Show>
  )
}

function ComboBoxEstados() {
  const { Layout, Label, Bandeira } = ComboBoxEstados
  const dispatch = MatriculaStore.useDispatch()
  const { uf } = MatriculaStore.useState()
  const formikContext = useFormikContext()
  const params = useParams<MatriculaParams>()
  const cursoSlug = params?.curso?.toLowerCase()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const estadoParam = queryParams.get('estado' as keyof MatriculaSearchParams)

  // prettier-ignore
  const [ufs,setUfs] = useState([
    'RO','AC','AM','RR','PA',
    'AP','TO','MA','PI','CE',
    'RN','PB','PE','AL','SE',
    'BA','MG','ES','RJ','SP',
    'PR','SC','RS','MS','MT',
    'GO','DF',
  ])

  useEffect(() => {
    if (cursoSlug === 'reciclagem-para-condutores-infratores') {
      setUfs(['PE', 'MG', 'MT', 'MA'])
    }
    if (cursoSlug === 'preventivo-de-reciclagem') {
      setUfs(['PE'])
    }
    if (cursoSlug === 'atualizacao-para-renovacao') {
      setUfs(['PE', 'MG'])
    }
    if (cursoSlug === 'mototaxi' || cursoSlug === 'motofrete') {
      setUfs(['MG'])
    }
  }, [cursoSlug])

  useEffect(() => {
    if (!estadoParam) return
    handleUf(estadoParam)
  }, [estadoParam])

  const handleUf = (uf: string) => {
    uf = uf.toUpperCase()
    dispatch(MatriculaStore.actions.init({ uf } as any))
    formikContext.setValues({ estado: uf, uf })
  }

  return (
    <Layout>
      <Label>
        Estado:
        <SelectAntd
          onChange={handleUf}
          options={ufs.sort().map((uf) => ({ value: uf, label: uf }))}
          style={{ width: 200 }}
          showSearch
          value={uf}
        />
      </Label>
      <Bandeira />
    </Layout>
  )
}
ComboBoxEstados.Label = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`
ComboBoxEstados.Layout = styled.div`
  display: flex;
`
ComboBoxEstados.Bandeira = () => {
  const uf = MatriculaStore.useState().uf?.toUpperCase()
  const Svg = process.env.PUBLIC_URL + '/assets/estados/' + uf + '.svg'
  return (
    <>
      {uf && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <img src={Svg} height={80} style={{ borderStyle: 'solid', borderWidth: 0.1 }} />
        </div>
      )}
    </>
  )
}

const ComboBoxMunicipios = () => {
  const { ContainerComboBox, ContainerScrollComboBox, InputSearchComboBox, SelectComboBox } =
    ComboBoxMunicipios
  const { dadosAluno, uf, municipios } = MatriculaStore.useState()
  const [inputValue, setInputValue] = useState<string>(dadosAluno.municipio || '')
  const [visibleScrollComboBox, setVisibleScrollComboBox] = useState<boolean>(Boolean())
  const dataLabel = 'nome'

  const handleInput = (input: any) => {
    setInputValue(input.target.value)
  }

  return (
    <ContainerComboBox>
      <TextField
        id="municipio"
        name="municipio"
        label="Município"
        onInput={handleInput}
        value={inputValue}
        onFocus={() => {
          setVisibleScrollComboBox(true)
        }}
        onBlur={() => {
          setTimeout(() => {
            setVisibleScrollComboBox(false)
          }, 300)
        }}
      />

      {visibleScrollComboBox && (
        <ContainerScrollComboBox>
          {municipios?.map((m, key) => {
            const temp = {
              displayHidden: !String(m[dataLabel]).toUpperCase().includes(inputValue.toUpperCase()),
              ...m,
            }

            return (
              <SelectComboBox displayHidden={temp.displayHidden} key={key}>
                <label htmlFor={String(key)}>{temp[dataLabel]}</label>
                <input
                  id={String(key)}
                  style={{ display: 'none' }}
                  onClick={(e: any) => {
                    setInputValue(e.target.value)
                    document.getElementById('municipio')?.focus()
                  }}
                  defaultValue={temp[dataLabel]}
                />
              </SelectComboBox>
            )
          })}
        </ContainerScrollComboBox>
      )}
    </ContainerComboBox>
  )
}
ComboBoxMunicipios.ContainerComboBox = styled.div`
  position: relative;
  box-sizing: border-box;
  outline: none;
  font-size: 0.9rem;
`
ComboBoxMunicipios.InputSearchComboBox = styled.input`
  background: #ffffff;
  border: 1px solid #828282;
  border-radius: 4px;
  height: 2.9em;
  padding-left: 1em;
  width: 100%;
  outline: none;
  transition: border 0.3s;
  margin-top: 3px;
`
ComboBoxMunicipios.ContainerScrollComboBox = styled.div`
  height: 150px;
  margin-left: 4px;
  position: absolute;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  z-index: 3;
  overflow-y: scroll;
  background: white;
  width: 96%;
`
ComboBoxMunicipios.SelectComboBox = styled.div(
  ({ displayHidden }: { displayHidden?: boolean }) => `
  display: ${displayHidden ? 'none' : 'flex'};
  flex-direction: row;

  :hover {
    background: #d7d7d7;
  }

  input {
    padding: 0.4rem;
  }

  label {
    padding: 0.4rem;
    width: 100%;
  }
`
)

const ComboBoxCategoriaCNH = () => {
  const { ContainerComboBox } = ComboBoxCategoriaCNH

  return (
    <ContainerComboBox>
      <TextField
        name="categoriaCnh"
        label="Categoria da CNH"
        title="Selecione a categoria da sua habilitação"
        type="select"
      >
        <option value="">Selecione a categoria da CNH</option>
        <option value="A">A</option>
        <option value="AB">AB</option>
        <option value="AC">AC</option>
        <option value="AD">AD</option>
        <option value="AE">AE</option>
        <option value="B">B</option>
        <option value="C">C</option>
        <option value="D">D</option>
        <option value="E">E</option>
        <option value="ACC">ACC - Autorização para Conduzir Ciclomotor</option>
        <option value="ACCB">ACC B</option>
        <option value="ACCC">ACC C</option>
        <option value="ACCD">ACC D</option>
        <option value="ACCE">ACC E</option>
      </TextField>
    </ContainerComboBox>
  )
}
ComboBoxCategoriaCNH.ContainerComboBox = styled.div`
  position: relative;
  box-sizing: border-box;
  outline: none;
  margin-bottom: 18px;
  font-size: 0.9rem;
`
ComboBoxCategoriaCNH.Select = styled.select`
  border: 1px solid #828282;
  border-radius: 4px;
  background: #ffffff;
  height: 2.7rem;
  padding-left: 0.4rem;
  margin-top: 3px;
`

const StatusValidacao = () => {
  const { Layout, Text, Icon, MagnifyingGlassAnimated } = StatusValidacao
  const { etapa, validacaoAluno, uf } = MatriculaStore.useState()
  const estado = MatriculaStore.useSelector(selectEstado)

  if (etapa !== ETAPAS.DADOS_ALUNO) return null

  if (validacaoAluno.loading) {
    return (
      <Layout type="WARNING">
        <Icon>
          <MagnifyingGlassAnimated color="#F06F30" className="animate-searching" />
        </Icon>
        <Text>Buscando os dados do aluno na base do Detran. Aguarde um momento.</Text>
      </Layout>
    )
  }

  if (validacaoAluno.executouValidacao && validacaoAluno.valido && uf !== 'MG') {
    return (
      <Layout type="SUCCESS">
        <Icon>
          <Svgs.CheckmarkUser />
        </Icon>
        <Text>
          Aluno encontrado na base do Detran e apto a se matricular. Clique em continuar para
          prosseguir com a matrícula.
        </Text>
      </Layout>
    )
  }

  if (validacaoAluno.executouValidacao && !validacaoAluno.valido) {
    return (
      <Layout type="ERROR">
        <Icon>
          <Svgs.ExclamationTriangle />
        </Icon>
        <Text>
          Não foi possível continuar com sua matricula. É necessário seguir as instruções no site e
          verificar as pendências junto ao Detran do seu estado. &nbsp;
          <a href={estado?.link}>Ver instruções.</a>
          <Show condition={validacaoAluno.mensagemErro}>
            <div>
              <em>Motivo do erro: </em> {validacaoAluno.mensagemErro}
            </div>
          </Show>
        </Text>
      </Layout>
    )
  }

  return null
}
StatusValidacao.Layout = styled.div<{ type: 'ERROR' | 'WARNING' | 'SUCCESS' }>(
  (props) => `
  background: ${{ ERROR: '#FFE0E0', SUCCESS: '#E8FFE0', WARNING: '#FFFFFF' }[props.type]};
  display: flex;
  align-items: center;
  padding: 8px 0;
  min-height: 110px;
  @media (max-width: 780px) {
    margin: 28px 24px;
  }
  @media (max-width: 458px) {
    margin: 8px;
  }
`
)
StatusValidacao.Text = styled.div`
  line-height: 1.5em;
  font-size: 16px;
  margin-right: 18px;
`
StatusValidacao.Icon = styled.div`
  margin: 0 18px;
`
const animateSearching = keyframes`
  0%, 100% { transform: translate(10px, 0); }
  25% { transform: translate(0, 10px); }
  50% { transform: translate(-10px, 0); }
  75% { transform: translate(0, -10px); }
`
StatusValidacao.MagnifyingGlassAnimated = styled(Svgs.MagnifyingGlass)`
  animation: ${animateSearching} 1.5s cubic-bezier(0.29, 0.46, 0.65, 0.39) infinite;
`

const Contato = () => {
  const { Layout, ButtonsContainer, isFormFilled, validate } = Contato
  const {
    etapa,
    dadosAluno,
    loading: { matricula: loading },
  } = MatriculaStore.useState()
  const dispatch = MatriculaStore.useDispatch()

  const handleClickContinuar = () => {
    const validation = validate(dadosAluno)
    if (!validation.isValid) return dispatch(MatriculaStore.actions.changeError(validation.message))
    dispatch(MatriculaStore.thunks.matricular())
  }

  const handleClickVoltar = () => {
    dispatch(MatriculaStore.actions.etapaAnterior())
  }

  if (etapa !== ETAPAS.CONTATO) return null
  return (
    <Layout>
      <h2>Contato</h2>
      <div style={{ marginTop: 18 }}>
        <TextFieldInput
          name="email"
          value={dadosAluno.email}
          label="E-mail"
          placeholder="nome@provedor.com.br"
          onChange={(e) =>
            dispatch(MatriculaStore.actions.updateDadosAluno({ email: e.target.value }))
          }
          style={{ width: 385, maxWidth: '100%' }}
        />
      </div>
      <div style={{ marginTop: 18 }}>
        <TextFieldInput
          name="telefone"
          value={dadosAluno.telefone}
          label="Número do celular"
          mask="telefone"
          placeholder="(99) 99999-9999"
          onChange={(e) =>
            dispatch(
              MatriculaStore.actions.updateDadosAluno({
                telefone: e.target.value,
              })
            )
          }
          style={{ width: 200 }}
        />
      </div>
      <ButtonsContainer>
        <Button label="Voltar" onClick={handleClickVoltar} color="#BDBDBD" />
        <Button
          label="Continuar"
          loading={loading}
          onClick={handleClickContinuar}
          disabled={!isFormFilled(dadosAluno)}
        />
      </ButtonsContainer>
      <JaPossuiConta />
    </Layout>
  )
}
Contato.Layout = styled.div`
  background: #ffffff;
  padding: 38px 127px 0 127px;
  @media (max-width: 780px) {
    padding: 38px 0 0 0;
    margin: 0 auto;
    max-width: 436px;
  }
  @media (max-width: 458px) {
    padding: 28px 12px;
  }
`
Contato.ButtonsContainer = styled.div`
  margin-top: 38px;
  display: flex;
  justify-content: center;

  & > *:first-child {
    margin-right: 28px;
  }

  button {
    margin: 0px auto;
    border-radius: 8px;
  }

  @media (max-width: 458px) {
    justify-content: center;
    & > *:first-child {
      margin-right: 12px;
    }
  }
`
Contato.isFormFilled = (form) => {
  const { email, telefone } = form

  return !!email && !!telefone
}
Contato.validate = (dadosAluno) => {
  const { email, telefone } = dadosAluno
  const invalid = (message) => ({ isValid: false, message })

  if (!email) return invalid('Informe o e-mail!')
  if (!/\S+@\S+\.\S+/.test(email)) return invalid('Informe um e-mail válido!')
  if (!telefone) return invalid('Informe o número de telefone!')
  if (telefone.includes('_')) return invalid('Informe um telefone válido!')

  return { isValid: true, message: '' }
}

const Pagamento = () => {
  const { Layout, CupomContainer, ButtonsContainer, HyperpayCobranca } = Pagamento
  const {
    etapa,
    loading: { cupom: loadingCupom },
    matricula,
    valorCurso,
    curso,
    dadosAluno,
  } = MatriculaStore.useState()
  const cobrancaId = matricula?.referenciaPagamento ? +matricula.referenciaPagamento : null
  const cupom = matricula?.cupom
  const dispatch = MatriculaStore.useDispatch()
  const [cobrancaIdReloadable, setCobrancaIdReloadable] = useState<number | null>(cobrancaId)
  const [cupomCodigo, setCupomCodigo] = useState('')
  const history = useHistory()

  const handleAplicarCupom = () => {
    if (cupomCodigo === '' || !cupomCodigo) return
    dispatch(MatriculaStore.thunks.applyCupom(cupomCodigo))
  }

  const handleSucess = (meioPagamento) => {
    if (meioPagamento) {
      history.push(
        `/obrigado?meiopagamento=${meioPagamento.toLowerCase()}&curso=${curso?.nomeReduzido}`,
        {
          matricula,
          curso,
          dadosAluno,
          valorCurso,
          meioPagamento,
        }
      )
    }
  }

  useEffect(() => {
    setCobrancaIdReloadable(cobrancaId)
  }, [cobrancaId])

  useEffect(() => {
    setCobrancaIdReloadable(null)
    requestAnimationFrame(() => {
      setCobrancaIdReloadable(cobrancaId)
    })
  }, [cupom?.valorDesconto])

  if (etapa !== ETAPAS.PAGAMENTO) return null
  return (
    <Layout>
      <CupomContainer>
        <Show condition={cupom}>
          <Flex style={{ alignItems: 'center' }}>
            <h4>
              <span style={{ color: '#828282', borderBottom: '2px solid #FF9722' }}>Total:</span> R${' '}
              {formatMoeda(valorCurso - (cupom?.valorDesconto || 0))}
            </h4>
            <TabelaDesconto valor={valorCurso} desconto={cupom?.valorDesconto} />
          </Flex>
        </Show>
        <p>Possui um cupom de desconto?</p>
        <Flex>
          <TextFieldInput
            name="field-cupom"
            className="field-cupom"
            value={cupomCodigo}
            onChange={(e) => setCupomCodigo(e.target.value)}
            onKeyDown={(e) => (e.keyCode === 13 ? handleAplicarCupom() : null)}
          />
          <Button
            onClick={handleAplicarCupom}
            disabled={cupomCodigo === ''}
            loading={loadingCupom}
            label="Aplicar cupom"
            className="btn-cupom"
          />
        </Flex>
        <Show condition={cupom?.valorDesconto}>
          <Icon icon={<CheckIcon />}>Desconto aplicado</Icon>
        </Show>
      </CupomContainer>
      {cobrancaId ? (
        <HyperpayCobranca
          token="dc2ded67-efd6-4cc2-b2d4-5549b32f98eb"
          cobrancaId={+(cobrancaIdReloadable || 0)}
          onCobrancaSuccess={handleSucess}
          defaultOpened="PIX"
          env={process.env.REACT_APP_ENV || (process.env.NODE_ENV as any)}
        />
      ) : (
        <div style={{ textAlign: 'center', margin: '60px' }}>
          <LoadingSpinner fill="#000" />
        </div>
      )}
      <ButtonsContainer>
        <Button
          label="Voltar"
          onClick={() => dispatch(MatriculaStore.actions.etapaAnterior())}
          color="#BDBDBD"
        />
      </ButtonsContainer>
      <PagamentoVia style={{ padding: '20px' }} />
    </Layout>
  )
}
Pagamento.Layout = styled.div`
  background-color: #ffffff;

  .heading-container {
    background-color: #ffffff;
  }

  .heading {
    display: none;
  }
`
Pagamento.CupomContainer = styled.div`
  padding: 10px 55px;

  .field-cupom {
    width: 100%;
    margin-right: 10px;
  }

  .btn-cupom {
    height: 42px;
    width: 100px;
    margin-top: 8px;
  }

  /* For mobile phones: */
  @media only screen and (max-width: 768px) {
    padding: 10px 25px;

    .buttom {
      margin-top: 10px;
    }
  }
`
Pagamento.ButtonsContainer = styled.div`
  display: flex;
  padding: 0px 20px 10px 20px;
  background-color: #ffffff;

  button {
    margin: 0px auto;
    border-radius: 8px;
  }
`
Pagamento.HyperpayCobranca = styled(HyperpayCobranca)`
  --primary-color: #ff9722;
  --primary-color-shade: #db801a;
  --secondary-color: #ffb80019;
  --titles-color: white;

  .subheading-container {
    padding: 0;
  }

  @media (min-width: 768px) {
    .data-expiracao {
      width: 120px !important;
    }
    .codigo-seguranca {
      width: 140px !important;
    }
    .email-titular {
      width: calc(120px + 10px + 140px) !important;
    }
  }
`

const TabelaDesconto = ({ valor, desconto }: { valor?: number; desconto?: number }) => {
  const { Layout } = TabelaDesconto

  if (!valor || !desconto) return null

  return (
    <Layout>
      <h5 style={{ textAlign: 'right', color: '#828282' }}>Total</h5>
      <table>
        <tbody>
          <tr>
            <td>R$ {formatMoeda(valor)}</td>
          </tr>
          <tr>
            <td>R$ {formatMoeda(desconto > 0 ? desconto * -1 : desconto)}</td>
          </tr>
          <tr style={{ borderTop: '2px solid #000000' }}>
            <td>
              <strong>R$ {formatMoeda(valor - desconto)}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </Layout>
  )
}
TabelaDesconto.Layout = styled.div`
  margin: 10px 0px;
  margin-left: auto;

  table {
    min-width: 100px;
    margin-left: auto;
  }

  td {
    text-align: right;
  }
`

const JaPossuiConta = () => {
  const { Layout } = JaPossuiConta
  return (
    <Layout>
      Já tem uma conta? <Link to="/">Fazer login</Link>
    </Layout>
  )
}
JaPossuiConta.Layout = styled.div`
  margin-top: 38px;
  padding-bottom: 28px;
  font-size: 16px;
  color: #2d2d2d;
  text-align: center;
`

const Sucesso = () => {
  const { Layout } = Sucesso
  const { etapa, curso, meioPagamento } = MatriculaStore.useState()

  if (etapa !== ETAPAS.SUCESSO) return null
  return (
    <Layout id="sucesso">
      <h1>
        <strong>Matrícula e pagamento efetuados com sucesso!</strong>
      </h1>
      <h2>{curso?.nome}</h2>
      <Lottie
        style={{ width: 194, height: 194 }}
        options={{
          loop: false,
          autoplay: true,
          animationData: successAnimationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
      />
      {meioPagamento === 'BOLETO' ? (
        <div className="boleto-msg">
          <h2>
            Você receberá um e-mail com <strong>ACESSO LIMITADO</strong> à plataforma New Driver.
          </h2>
        </div>
      ) : (
        <h2>
          Você receberá um e-mail com <strong>ACESSO IMEDIATO</strong> à plataforma New Driver.
        </h2>
      )}
      <p>
        <em>
          Não se esqueça de verificar a pasta de spam. Em caso de dúvidas, entre em contato com
          nossos tutores.
        </em>
      </p>
      <Button
        label="Ver mais cursos"
        style={{ margin: '0px auto' }}
        onClick={() => {
          window.location.href = 'https://newdriver.com.br/#one'
        }}
      />
    </Layout>
  )
}
Sucesso.Layout = styled.div`
  box-shadow: 0px -1px 16px rgba(51, 51, 51, 0.356);
  border-radius: 7px;
  padding: 10px;
  margin: 10px auto;
  margin-bottom: 30px;
  width: 100%;
  max-width: 1000px;
  text-align: center;

  h1 {
    font-size: 20px;
    line-height: 36px;
    color: #000000;
  }

  h2 {
    font-size: 17px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #333333;
  }

  p {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.02em;

    /* Gray 1 */
    color: #333333;
  }

  .boleto-msg {
    background: #ffebb2;
    padding: 10px;
    margin: 0px 10%;
  }
`

const TesteGratis = () => {
  const { Layout, Title, BtnTeste } = TesteGratis

  return (
    <Layout>
      <Title>Está com dúvidas se este é o curso certo para você?</Title>
      <BtnTeste label="Fazer teste grátis!" />
    </Layout>
  )
}
TesteGratis.Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  height: 340px;
  text-align: center;
  background: url(${bannerTeste});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 50px;
  margin-bottom: 0px;
`
TesteGratis.Title = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
`
TesteGratis.BtnTeste = styled(Button)`
  border: none;
  max-width: 616px;
  width: 100%;
  margin-top: 40px;
  border-radius: 8px;
  font-weight: bold;

  /* For mobile phones: */
  @media only screen and (max-width: 768px) {
    max-width: 283px;
  }
`

const PassosCnh = () => {
  const { Layout, Title, PrevButton, NextButton, Link } = PassosCnh
  const ref = useRef() as any
  const [loading, setLoading] = useState(true)
  const { passoAPasso } = MatriculaStore.useState()

  useEffect(() => {
    setTimeout(() => setLoading(false), 100)
  }, [])

  if (loading) return null

  const items = passoAPasso?.titles?.map((item, index) => (
    <PassoCnh key={index} img={passoAPasso?.imgs[index]} title={item}>
      <div dangerouslySetInnerHTML={{ __html: passoAPasso?.contents[index] }} />
    </PassoCnh>
  ))

  return (
    <Layout id="passos-cnh">
      <Title style={{ textAlign: 'center' }}>
        Confira os {`${passoAPasso?.titles?.length}`} passos para recuperar sua CNH
      </Title>
      <AliceCarousel
        ref={ref}
        renderPrevButton={() => <PrevButton />}
        renderNextButton={() => <NextButton />}
        items={items}
      />
    </Layout>
  )
}
PassosCnh.Layout = styled.div`
  background-color: #ff9933;
  padding-top: 20px;
`
PassosCnh.Title = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #ffffff;
`
PassosCnh.PrevButton = styled(LeftArrow)`
  position: relative;
  float: left;
  margin-top: -280px;
  z-index: 1;
  cursor: pointer;
  margin-left: 20px;

  /* For mobile phones: */
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
PassosCnh.NextButton = styled(RightArrow)`
  position: relative;
  float: right;
  margin-top: -280px;
  z-index: 1;
  cursor: pointer;
  margin-right: 20px;

  /* For mobile phones: */
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
PassosCnh.Link = styled.a`
  color: #133bc7;
  font-weight: bold;
`

const PassoCnh = ({ img, title, children }) => {
  const { Layout, Title, Text } = PassoCnh

  return (
    <Layout>
      <div className="passo-cnh-img">
        <img src={img} />
      </div>
      <Title className="passo-cnh-title">{title}</Title>
      <Text className="passo-cnh-text">{children}</Text>
    </Layout>
  )
}
PassoCnh.Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  column-gap: 30px;

  .passo-cnh-img {
    grid-area: passo-cnh-img;
    text-align: right;
  }
  .passo-cnh-title {
    grid-area: passo-cnh-title;
  }
  .passo-cnh-text {
    grid-area: passo-cnh-text;
  }

  grid-template-areas:
    'passo-cnh-img passo-cnh-title'
    'passo-cnh-img passo-cnh-text';

  /* For mobile phones: */
  @media only screen and (max-width: 768px) {
    grid-template-areas:
      'passo-cnh-img passo-cnh-title'
      'passo-cnh-text passo-cnh-text';

    padding: 10px;
  }
`
PassoCnh.Title = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #333333;
  display: flex;
  align-items: flex-end;
`
PassoCnh.Text = styled.div`
  color: #ffffff;
  max-width: 537px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  max-height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 7px;
  a {
    color: #133bc2;
    text-decoration: none;
    font-size: 17px;
  }
  span {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
  ul {
    text-align: center;
  }
  li {
    list-style: disc;
    font-size: 18px;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`

const ComoFunciona = () => {
  const location = useLocation()

  const { comoFunciona } = MatriculaStore.useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 100)
  }, [])

  if (loading) return null

  const { Layout, BtnMatricula } = ComoFunciona
  return (
    <Layout>
      <Duvida icon={<Laptop width={50} height={50} />} title="Para quem é este curso?">
        <div dangerouslySetInnerHTML={{ __html: comoFunciona.paraQuem }} />
      </Duvida>
      <Duvida
        icon={<Books width={50} height={50} />}
        title="Em quanto tempo posso concluir o curso?"
      >
        <div dangerouslySetInnerHTML={{ __html: comoFunciona.tempoCurso }} />
      </Duvida>
      <Duvida icon={<LogoIcon width={50} height={50} />} title="Objetivo do curso">
        <div dangerouslySetInnerHTML={{ __html: comoFunciona.objetivo }} />
      </Duvida>
      <Duvida icon={<Users width={50} height={50} />} title="Tutores Virtuais">
        <div dangerouslySetInnerHTML={{ __html: comoFunciona.tutores }} />
      </Duvida>
      <a href={`${location.pathname}#matricula-form`}>
        <BtnMatricula>Matricular agora!</BtnMatricula>
      </a>
    </Layout>
  )
}
ComoFunciona.Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 50px;
  padding: 100px 50px;

  /* For mobile phones: */
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
ComoFunciona.BtnMatricula = styled.button`
  background-color: #f06f30;
  max-width: 283px;
  width: 100%;
  border-radius: 8px;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  height: 44px;

  /* For mobile phones: */
  @media only screen and (min-width: 768px) {
    display: none;
  }
`

const Duvida = ({ icon, title, children }) => {
  const { Layout, Title } = Duvida
  return (
    <Layout>
      <Flex style={{ columnGap: '20px', alignItems: 'center' }}>
        {icon} <Title>{title}</Title>
      </Flex>
      <div>{children}</div>
    </Layout>
  )
}
Duvida.Layout = styled.div`
  max-width: 500px;
  margin: 0px auto;
`
Duvida.Title = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #333333;
`

export const PagamentoVia = ({ style, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <Flex
      style={{
        justifyContent: 'center',
        gap: '10px',
        color: '#575353',
        ...style,
      }}
      {...props}
    >
      Pagamento via Hyperpay
      {/* <HyperpayLogo style={{ marginTop: '5px' }} /> */}
    </Flex>
  )
}

const Icon = ({ icon, children }) => {
  const { Layout } = Icon
  return (
    <Layout>
      {icon} {children}
    </Layout>
  )
}
Icon.Layout = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  display: flex;
  align-items: center;
  gap: 20px;

  img {
    margin-right: 8px;
    margin-top: -3px;
    width: 30px;
  }
`

export const getNumbers = (text: string) => {
  return text?.replace(/\D/g, '')
}
