import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit'
import axios, { IQueryParams, ICounter } from '../common/axios'
import { IRequest } from '../common/IRequest'
import { CursoModel } from '../models/CursoModel'
import { IStateRedux } from '../common/ReduxStore'
import Axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useParams } from 'react-router'

export interface IState {
  cursosUsuario: CursoModel[]
  request: IRequest
}

export const initialState: IState = {
  cursosUsuario: [],
  request: {
    fetching: false,
    errorCode: null,
    message: '',
  },
}

const { reducer: cursosReducer, actions } = createSlice({
  name: 'cursos',
  initialState: initialState,
  reducers: {
    fetchStarted(state: IState) {
      state.request.fetching = false
    },
    fetchError(state: IState, { payload }: PayloadAction<{ errorCode; message }>) {
      const { errorCode, message } = payload
      state.request = { errorCode, message, fetching: false }
    },
    getCursosAlunoSuccess(state: IState, { payload }: PayloadAction<{ cursos }>) {
      state.cursosUsuario = payload.cursos
      state.request = { ...initialState.request, fetching: false }
    },
    limparCursosAluno(state) {
      state.cursosUsuario = initialState.cursosUsuario
      state.request = { ...initialState.request, fetching: false }
    },
  },
})

const cursosActions = {
  ...actions,
  getCursosAluno() {
    return async (dispatch: any, getState) => {
      const state: IStateRedux = getState()
      if (state.cursos.cursosUsuario?.length) return
      dispatch(actions.fetchStarted())
      try {
        const cursos = await Axios.get('app/cursos/usuario').then((x) => x.data)
        // const cursos = await axios.Usuarios.getCursosVinculados()
        dispatch(actions.getCursosAlunoSuccess({ cursos }))
      } catch (error: any) {
        dispatch(
          actions.fetchError({ errorCode: 400, message: 'Falha ao buscar os cursos do aluno' })
        )
      }
    }
  },
}

const selectCursosUsuario = createSelector(
  (state: IStateRedux) => state.cursos.cursosUsuario,
  (cursosUsuario) => cursosUsuario
)

const useCursoAtual = ({ cursoId } = { cursoId: 0 }) => {
  const { cursoId: routeCursoId } = useParams<{ cursoId }>()
  cursoId = cursoId || +(routeCursoId || 0)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(cursosActions.getCursosAluno())
  }, [])
  const curso = useSelector((s: IStateRedux) =>
    s.cursos.cursosUsuario.find((c) => +c.id === +cursoId)
  )

  if (!cursoId) throw new Error('Curso não presente. Verifique sua rota')

  return curso
}

export { cursosReducer, cursosActions, selectCursosUsuario, useCursoAtual }
