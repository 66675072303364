import React from 'react'
import { QuestaoStore } from './QuestoesStore'
import styled from '@emotion/styled'
import { QuestaoAlternativaModel } from '../models/QuestaoAlternativaModel'
import { animated, useSpring } from 'react-spring'
import { RespostaModel } from '../models/RespostaModel'
import { ConteudoStore } from '../conteudo/ConteudoStore'
import useMedia from 'use-media'
import { darken } from 'polished'
import Svgs from '../svgs'
import { Show } from '../common/Show'
import AnimateHeightComp from 'react-animate-height'

const AnimateHeight: any = AnimateHeightComp

const LETRA = 'ABCDEFGHIJKLMNOPQRSTU'.split('')
type TStatusAlternativa = 'correta' | 'incorreta' | 'marcada' | ''

export const MultiplaEscolha = () => {
  const { questao, respostasInformadas, isGabarito } = QuestaoStore.useState()
  const questoesAlternativas = questao.questoesAlternativas || []
  const alternativaCorreta = questoesAlternativas.find((x) => x.respostaEsperada === 'true')
  if (!alternativaCorreta) return <div>A Questão não está configurada corretamente</div>
  const { Layout } = MultiplaEscolha

  return (
    <Layout>
      {questoesAlternativas.map((alternativa, index) => {
        const questaoAlternativaId =
          questao.respostas?.[0]?.respostasAlternativas?.[0]?.questaoAlternativaId
        const alternativaId = alternativa.id
        const possuiRespostaCorreta =
          alternativaId === questaoAlternativaId
            ? questao.respostas?.[0]?.possuiRespostaCorreta
            : false

        const statusAlternativa = _getStatusAlternativa(
          questao.respostas,
          respostasInformadas,
          alternativa.id
        )
        return (
          <Alternativa
            key={alternativa.id}
            alternativa={alternativa}
            index={index}
            statusAlternativa={statusAlternativa}
            possuiRespostaCorreta={possuiRespostaCorreta}
            isGabarito={isGabarito}
          />
        )
      })}
    </Layout>
  )
}
MultiplaEscolha.Layout = styled.div`
  margin-top: 32px;
  @media (max-width: 750px) {
    margin-top: 18px;
  }
`

const Alternativa = ({
  alternativa = {} as QuestaoAlternativaModel,
  index,
  statusAlternativa = '' as TStatusAlternativa,
  possuiRespostaCorreta = false as boolean,
  isGabarito = false,
}) => {
  const dispatch = QuestaoStore.useDispatch()
  const isWide = useMedia({ minWidth: '769px' })
  const respostaEsperada = alternativa.respostaEsperada
  const { getSpringContainer, getSpringLetra, Container, Descricao, Letra } = Alternativa

  if (possuiRespostaCorreta && statusAlternativa === 'incorreta') {
    statusAlternativa = ''
  }

  const springLetra = useSpring(
    getSpringLetra({ respostaEsperada, statusAlternativa, isWide, isGabarito })
  )
  const springContainer = useSpring(
    getSpringContainer({ statusAlternativa, isWide, isGabarito, respostaEsperada })
  )

  function handleClickAlternativa() {
    dispatch(QuestaoStore.thunks.selecionaAlternativaMultiplaEscolha(alternativa.id))
  }

  return (
    <Container onClick={handleClickAlternativa} style={springContainer}>
      <Letra id="Letra" style={springLetra}>
        {LETRA[index]}
      </Letra>
      <div>
        <Descricao dangerouslySetInnerHTML={{ __html: alternativa.conteudoAlternativa || '' }} />
        <Feedback
          texto={alternativa.feedback}
          statusAlternativa={statusAlternativa}
          possuiRespostaCorreta={possuiRespostaCorreta}
        />
      </div>
    </Container>
  )
}
Alternativa.getSpringLetra = ({
  respostaEsperada,
  statusAlternativa = '' as TStatusAlternativa,
  isWide,
  isGabarito = false as boolean,
}) => {
  statusAlternativa = isGabarito && respostaEsperada === 'true' ? 'correta' : statusAlternativa
  if (!isWide) {
    return { background: '#FFFFFF', borderColor: '#FFFFFF00', color: '#2D2D2D' }
  }

  const background = {
    '': '#FFFFFF',
    incorreta: '#EB5757',
    correta: '#4AC758',
    marcada: '#2F80ED',
  }[statusAlternativa]
  const borderColor = {
    '': '#D9D9D9',
    incorreta: '#EB5757',
    correta: '#4AC758',
    marcada: '#2F80ED',
  }[statusAlternativa]
  const color = {
    '': '#2D2D2D',
    incorreta: '#FFFFFF',
    correta: '#FFFFFF',
    marcada: '#FFFFFF',
  }[statusAlternativa]

  return { background, borderColor, color }
}
Alternativa.getSpringContainer = ({
  statusAlternativa = '' as TStatusAlternativa,
  isWide,
  isGabarito,
  respostaEsperada,
}) => {
  statusAlternativa = isGabarito && respostaEsperada === 'true' ? 'correta' : statusAlternativa
  if (isWide) {
    return { borderColor: '#FFFFFF00' }
  }
  const borderColor = {
    '': '#D9D9D9',
    incorreta: '#EB5757',
    correta: '#4AC758',
    marcada: '#2F80ED',
  }[statusAlternativa]

  return { borderColor }
}
Alternativa.Container = styled(animated.div)`
  background: #ffffff;
  border: 2px solid;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 18px;
  font-style: normal;
  font-weight: normal;
  color: #2d2d2d;
  padding: 13px 18px;
  transition: 0.25s background;
  @media (max-width: 750px) {
    border-radius: 8px;
    margin: 8px 18px 0 18px;
    min-height: 64px;
    gap: 8px;
    &:nth-child(1) {
      margin-top: 0px;
    }
  }
  @media (min-width: 749px) {
    &:nth-child(odd) {
      background: #f7f7f7;
    }
    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    &:hover {
      background: ${darken(0.02, '#F7F7F7')};
      cursor: pointer;
    }
  }
`
Alternativa.Descricao = styled.div`
  font-size: 16em;
`
Alternativa.Letra = styled(animated.div)`
  font-weight: 500;
  font-size: 18em;
  @media (max-width: 770px) {
    &:after {
      content: '.';
    }
  }
  @media (min-width: 769px) {
    width: 58px;
    height: 58px;
    border: 2px solid #d9d9d9;
    border-radius: 8px;
    border: 2px solid;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

function Feedback({ texto, statusAlternativa, possuiRespostaCorreta }) {
  let showFeedback = false
  let tipo: 'correta' | 'incorreta' | 'neutra' = 'neutra'
  const { somenteLeitura } = QuestaoStore.useState()

  const { Container } = Feedback

  if (possuiRespostaCorreta) {
    if (statusAlternativa === 'correta') tipo = 'correta'
    if (texto) showFeedback = true
  } else {
    if (statusAlternativa === 'incorreta') {
      tipo = 'incorreta'
      if (!texto && !somenteLeitura) texto = 'Resposta incorreta!'
      showFeedback = true
    }
  }
  return (
    <AnimateHeight duration={500} height={showFeedback ? 'auto' : 0}>
      <Container tipo={tipo}>
        <Show condition={statusAlternativa === 'incorreta'}>
          <Svgs.Atention color="#EB5757" />
        </Show>
        <Show condition={statusAlternativa === 'correta'}>
          <Svgs.CheckmarkCircleOutline color="#4AC758" />
        </Show>
        {texto}
      </Container>
    </AnimateHeight>
  )
}
Feedback.Container = styled.div<{ tipo }>(
  (props) => `
  font-style: italic;
  font-size: 14em;
  display: grid;
  align-items: center;
  color: #2D2D2D;
  margin-top: 9px;
  grid-template-columns: auto 1fr;
  gap: 8px;
  ${props.tipo === 'incorreta' ? 'color: #EB5757;' : ''}
`
)

function _getStatusAlternativa(
  respostas: RespostaModel[],
  respostasInformadas,
  alternativaId
): TStatusAlternativa {
  const resposta = respostas?.[0]?.respostasAlternativas.find(
    (x) => x.questaoAlternativaId === alternativaId
  )
  if (resposta && resposta.correta) return 'correta'
  if (resposta && !resposta.correta) return 'incorreta'
  if (respostasInformadas[+alternativaId]) return 'marcada'
  return ''
}
