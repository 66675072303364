import BookSvg from './book'
import UsersSvg from './users'
import TasksSvg from './tasks'
import ReplySvg from './reply'
import ProgressSvg from './progress'
import NotesSvg from './notes'
import MailSvg from './mail'
import HomeSvg from './home'
import ExclamationCircleSvg from './exclamation-circle'
import CupSvg from './cup'
import CommentQuestionSvg from './comment-question'
import CameraSvg from './camera'
import ExitSvg from './exit'
import IncreaseFontSizeSvg from './increase-font-size'
import DecreaseFontSizeSvg from './decrease-font-size'
import CloseSvg from './close'
import NoteSVg from './note'
import ChevronLeftSvg from './chevron-left'
import SendCircleSvg from './send-circle'
import ShareSvg from './share'
import DeleteSvg from './delete'
import PlusCircleSvg from './plus-circle'
import CheckmarkCircleSvg from './checkmark-circle'
import CheckmarkSvg from './checkmark'
import RaceFlagSvg from './race-flag'
import PictureSvg from './picture'
import LoadingSpinnerSvg from './loading-spinner'
import PhoneSvg from './phone'
import AttentionSvg from './attention'
import CheckmarkCircleOutlineSvg from './checkmark-circle-outline'
import SendSvg from './send'
import RefreshSvg from './refresh'
import PlaySvg from './play'
import ClockSvg from './clock'
import NdCarSvg from './nd-car'
import ClockFilledSvg from './clock-filled'
import GraduationCapSvg from './graduation-cap'
import GraphBarsSvg from './graph-bars'
import UserBadgeSvg from './user-badge'
import UsersFilledSvg from './users-filled'
import HandWritingSvg from './hand-writing'
import ExclamationTriangleSvg from './exclamation-triangle'
import MagnifyingGlassSvg from './magnifying-glass'
import CheckmarkUserSvg from './checkmark-user'
import MenuSvg from './menu'
import OnboardingModuloFinalizadoSvg from './onboarding-modulo-finalizado'
import PencilLineSvg from './pencil-line'
import ArrowLeftSvg from './arrow-left'
import BooksSvg from './books'
import WhatsAppSvg from './whatsapp'

export type SvgType = { color?: string } & React.SVGProps<SVGSVGElement>

export default {
  Book: BookSvg,
  Books: BooksSvg,
  Users: UsersSvg,
  Tasks: TasksSvg,
  Reply: ReplySvg,
  Progress: ProgressSvg,
  Notes: NotesSvg,
  Mail: MailSvg,
  Home: HomeSvg,
  ExclamationCircle: ExclamationCircleSvg,
  Cup: CupSvg,
  CommentQuestion: CommentQuestionSvg,
  Camera: CameraSvg,
  Exit: ExitSvg,
  IncreaseFontSize: IncreaseFontSizeSvg,
  DecreaseFontSize: DecreaseFontSizeSvg,
  Close: CloseSvg,
  Note: NoteSVg,
  ChevronLeft: ChevronLeftSvg,
  SendCircle: SendCircleSvg,
  Share: ShareSvg,
  Delete: DeleteSvg,
  PlusCircle: PlusCircleSvg,
  CheckmarkCircle: CheckmarkCircleSvg,
  Checkmark: CheckmarkSvg,
  RaceFlag: RaceFlagSvg,
  Picture: PictureSvg,
  LoadingSpinner: LoadingSpinnerSvg,
  Phone: PhoneSvg,
  Atention: AttentionSvg,
  CheckmarkCircleOutline: CheckmarkCircleOutlineSvg,
  Send: SendSvg,
  Refresh: RefreshSvg,
  Play: PlaySvg,
  Clock: ClockSvg,
  NdCar: NdCarSvg,
  ClockFilled: ClockFilledSvg,
  GraduationCap: GraduationCapSvg,
  GraphBars: GraphBarsSvg,
  UserBadge: UserBadgeSvg,
  UsersFilled: UsersFilledSvg,
  HandWriting: HandWritingSvg,
  ExclamationTriangle: ExclamationTriangleSvg,
  MagnifyingGlass: MagnifyingGlassSvg,
  CheckmarkUser: CheckmarkUserSvg,
  Menu: MenuSvg,
  OnboardingModuloFinalizado: OnboardingModuloFinalizadoSvg,
  PencilLine: PencilLineSvg,
  ArrowLeft: ArrowLeftSvg,
  WhatsApp: WhatsAppSvg,
}
