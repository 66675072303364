import React from 'react'
import styled from '@emotion/styled'
import { ModalTutorial } from '../components'
import { GlobalStore } from '../GlobalStore'

export const TermosUso = () => {
  const globalDispatch = GlobalStore.useDispatch()

  const handleEnd = () => {
    globalDispatch(GlobalStore.actions.closeTermoUso())
  }

  return (
    <Burocracia
      src="https://hyperpay.blob.core.windows.net/noticias/TERMO-DE-USO-NEW-DRIVER.pdf"
      onEnd={handleEnd}
    />
  )
}

export const PoliticaPriv = () => {
  const globalDispatch = GlobalStore.useDispatch()

  const handleEnd = () => {
    globalDispatch(GlobalStore.actions.closePoliticaPriv())
  }

  return (
    <Burocracia
      src="https://hyperpay.blob.core.windows.net/noticias/AVISO-DE-PRIVACIDADE-NEW-DRIVER.pdf"
      onEnd={handleEnd}
    />
  )
}

const Burocracia = ({ src, onEnd, ...props }) => {
  const { Modal, Layout, Embed } = Burocracia

  return (
    <Modal onEnd={onEnd}>
      <Layout {...props}>
        <Embed src={src} />
      </Layout>
    </Modal>
  )
}
Burocracia.Modal = styled(ModalTutorial)`
  border-radius: 10px;
  width: 100%;
`
Burocracia.Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`
Burocracia.Embed = styled.embed`
  border-radius: 10px;
  display: block;
  width: 100%;
  height: calc(100vh - 80px);
  border: solid gray 2px;
`
