import React, { useEffect } from 'react'
import { IonPage, IonContent } from '@ionic/react'
import { Form, useFormik, FormikProvider, ErrorMessage } from 'formik'
import { Link, useHistory, useParams } from 'react-router-dom'
import { TextField } from '../common/TextField'
import { bindActionCreators } from 'redux'
import { useSelector, useDispatch } from 'react-redux'
import { authActions } from './AuthStore'
import aplicacaoPadrao from '../assets/marca/aplicacao_padrao.png'
import styled from '@emotion/styled'
import { Button } from '../components'
import axios from '../common/axios'

export const MudarSenhaToken = () => {
  const { Layout, ImgMarca, Container, ContainerInterno } = MudarSenhaToken
  const { token } = useParams<{ token }>()
  const dispatch = useDispatch()
  const actions = bindActionCreators(authActions, dispatch)
  const loading = useSelector((s: any) => s.auth.request.fetching)
  const senhaMudada = useSelector((s: any) => s.auth.senhaMudada)
  const history = useHistory()

  useEffect(() => {
    if (senhaMudada) {
      setTimeout(() => history.push('/'), 1500)
    }
  }, [senhaMudada])

  useEffect(() => {
    axios.Usuarios.getByToken(token).then((data) => {
      if (!data) history.push('/')
    })
  }, [])

  const formik = useFormik({
    initialValues: { novaSenha: '', novaSenhaConfirmacao: '' },
    validate: (values) => {
      const errors: any = {}
      if (!values.novaSenha) errors.novaSenha = 'Nova senha requerida'
      if (values.novaSenha.length < 6)
        errors.novaSenha = 'A senha precisa ter pelo menos 6 caracteres'
      if (!values.novaSenhaConfirmacao)
        errors.novaSenhaConfirmacao = 'Confirmação da Nova senha requerida'
      if (values.novaSenha !== values.novaSenhaConfirmacao)
        errors.novaSenhaConfirmacao = 'Senhas incompatíveis'
      return errors
    },
    onSubmit: (values, meta) => {
      const { novaSenha } = values
      actions.mudarSenhaToken({ senha: novaSenha, token: token || '' })
    },
  })

  return (
    <>
      <IonPage>
        <IonContent>
          <Layout>
            <Container>
              <ContainerInterno>
                <ImgMarca>
                  <img src={aplicacaoPadrao} alt="" />
                </ImgMarca>
                <FormikProvider value={formik}>
                  <Form noValidate onSubmit={formik.handleSubmit}>
                    <TextField name="novaSenha" type="password" placeholder="Nova senha" />
                    <TextField
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') formik.handleSubmit()
                      }}
                      name="novaSenhaConfirmacao"
                      type="password"
                      placeholder="Confirme a nova senha"
                    />
                    <Button type="submit" loading={loading} label="Mudar senha" />
                  </Form>
                </FormikProvider>
                <Link to="/" style={{ fontSize: 14, marginTop: 24, display: 'block' }}>
                  Voltar ao login
                </Link>
              </ContainerInterno>
            </Container>
          </Layout>
        </IonContent>
      </IonPage>
    </>
  )
}

MudarSenhaToken.Layout = styled.div`
  background: #f2f2f2;
  min-height: 100vh;
  overflow: auto;
`
MudarSenhaToken.ImgMarca = styled.div`
  width: 147px;
  margin: 0 auto;
  display: block;
  margin-bottom: 48px;
`
MudarSenhaToken.Container = styled.div`
  max-width: 661px;
  margin: 34px auto;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08);
  padding: 28px 0;
  @media (max-width: 689px) {
    margin: 0;
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
    padding: 12px;
  }
`
MudarSenhaToken.ContainerInterno = styled.div`
  max-width: 400px;
  margin: 0 auto;
`
