import React, { useEffect } from 'react'
import { Show } from '../common/Show'
import { IonToast } from '@ionic/react'
import { QuestaoStore, QuestaoSelectors } from './QuestoesStore'
import styled from '@emotion/styled'
import * as _ from 'lodash'
import { MultiplaEscolha } from './MultiplaEscolha'
import { RelacionarItens } from './RelacionarItens'
import { RelacionarItensCores } from './RelacionarItensCores'
import { MultiploVerdadeiroFalso } from './MultiploVerdadeiroFalso'
import { animated, useSpring } from 'react-spring'
import Axios from 'axios'
import fx from 'fireworks'
import { Button } from '../components'
import Svgs from '../svgs'
import useMeasure from 'react-use-measure'
import { TIPO_QUESTAO } from '../models/QuestaoModel'
import useMedia from 'use-media'

interface QuestaoType {
  questaoId
  ionContentRef?
  showBotaoEnviar?: boolean
  embaralharAlternativas?: boolean
  somenteLeitura?: boolean
  onAlternativaSelecionada?: (questao, alternativa, respostaInformada) => any
  onRespostaCorretaEnviada?: () => any
}
export const Questao = ({
  questaoId,
  showBotaoEnviar = true,
  embaralharAlternativas = false,
  somenteLeitura = false,
  isAvaliacao = false,
  isGabarito = false,
  ionContentRef,
  onAlternativaSelecionada = (questao, alternativa, respostaInformada) => null as any,
  onRespostaCorretaEnviada = () => null as any,
}: QuestaoType & { isAvaliacao: boolean; isGabarito?: boolean }) => {
  const { Layout, Topo } = Questao

  const isWide = useMedia({ minWidth: '769px' })
  const tipoQuestaoRelacionarItens = Questao.getTipoQuestaoRelacionarItens(questaoId, isWide)

  return (
    <QuestaoStore.Provider>
      <Layout>
        <Topo>
          <Enunciado />
          <QuantidadeTentativas />
        </Topo>
        <QuestaoStore.GetState>
          {(state) => (
            <>
              <Show condition={state.questao.tipoQuestao === TIPO_QUESTAO.MULTIPLA_ESCOLHA}>
                <MultiplaEscolha />
              </Show>
              <Show condition={state.questao.tipoQuestao === TIPO_QUESTAO.RELACIONAR_ITENS}>
                <Show condition={tipoQuestaoRelacionarItens === 'CORES'}>
                  <RelacionarItensCores ionContentRef={ionContentRef} />
                </Show>
                <Show condition={tipoQuestaoRelacionarItens === 'LINHAS'}>
                  <RelacionarItens />
                </Show>
              </Show>
              <Show
                condition={state.questao.tipoQuestao === TIPO_QUESTAO.MULTIPLO_VERDADEIRO_FALSO}
              >
                <MultiploVerdadeiroFalso />
              </Show>
            </>
          )}
        </QuestaoStore.GetState>
        <Show condition={showBotaoEnviar && !somenteLeitura}>
          <RodapeQuestao />
        </Show>
      </Layout>
      <>
        <Init
          questaoId={questaoId}
          somenteLeitura={somenteLeitura}
          onRespostaCorretaEnviada={onRespostaCorretaEnviada}
          onAlternativaSelecionada={onAlternativaSelecionada}
          embaralharAlternativas={embaralharAlternativas}
          isAvaliacao={isAvaliacao}
          isGabarito={isGabarito}
        />
        <Toast />
      </>
    </QuestaoStore.Provider>
  )
}
Questao.getTipoQuestaoRelacionarItens = (questaoId: number, isWide) => {
  if (!isWide) return 'CORES'
  if (questaoId % 2 === 0) return 'CORES'
  return 'LINHAS'
}
Questao.Layout = styled.div`
  background: #ffffff;
  @media (min-width: 750px) {
    border: 1px solid #d9d9d9;
    box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08);
    border-radius: 8px;
  }
`
Questao.Topo = styled.div`
  margin: 16px 16px 0 16px;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 12px;
  align-items: center;
  @media (max-width: 876px) {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    gap: 8px;
  }
`

function Init({
  questaoId,
  onRespostaCorretaEnviada,
  onAlternativaSelecionada,
  embaralharAlternativas,
  isAvaliacao,
  somenteLeitura,
  isGabarito,
}) {
  const dispatch = QuestaoStore.useDispatch()
  const { questao, respostasInformadas } = QuestaoStore.useState()
  const possuiRespostaCorreta = questao.respostas?.[0]?.possuiRespostaCorreta || false

  useEffect(() => {
    dispatch(
      QuestaoStore.thunks.getQuestaoById(
        questaoId,
        embaralharAlternativas,
        somenteLeitura,
        !isAvaliacao,
        isGabarito
      )
    )
  }, [])

  useEffect(() => {
    if (possuiRespostaCorreta) onRespostaCorretaEnviada()
  }, [possuiRespostaCorreta])

  useEffect(() => {
    if (Object.keys(respostasInformadas).length) {
      const [alternativaId, respostaInformada] = Object.entries(respostasInformadas)[0]
      const alternativa = questao.questoesAlternativas.find((x) => +x.id === +alternativaId)
      onAlternativaSelecionada(questao, alternativa, respostaInformada)
    }
  }, [respostasInformadas])

  return null
}

export const EditorPreview = ({ html, ...props }) => {
  const { Layout } = EditorPreview
  const ref = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (html && ref.current) {
      ref.current.querySelectorAll('img').forEach((img) => {
        const blobPath = img.getAttribute('data-src')
        Axios.get(`/arquivos/?blobPath=${blobPath}`).then((r) => {
          const blobUrl = r.data.blobUrl
          img.setAttribute('src', blobUrl)
        })
      })
    }
  }, [html, ref])

  return <Layout ref={ref} dangerouslySetInnerHTML={{ __html: html }} {...props} />
}
EditorPreview.Layout = styled.div`
  img {
    max-width: 100%;
  }
`

function Enunciado() {
  const { questao } = QuestaoStore.useState()
  const textoQuestao = questao.textoQuestao || ''
  const { Container } = Enunciado
  return <Container html={textoQuestao} />
}
Enunciado.Container = styled(EditorPreview)`
  font-weight: 500;
  font-size: 18em;
  line-height: 1.2em;
  color: #2d2d2d;
`
enum STATE_RESPOSTA_CORRETA {
  NAO_POSSUI_RESPOSTA_CORRETA,
  POSSUI_RESPOSTA_CORRETA_TRANSICAO,
  POSSUI_RESPOSTA_CORRETA_INICIO,
  NENHUM = '',
}

function RodapeQuestao() {
  const { Layout } = RodapeQuestao
  const state = QuestaoStore.useState()
  const possuiRespostaCorreta = QuestaoSelectors.getPossuiRespostaCorreta(state)
  const [stateRespostaCorreta, setStateRespostaCorreta] = React.useState<STATE_RESPOSTA_CORRETA>(
    STATE_RESPOSTA_CORRETA.NENHUM
  )
  useEffect(() => {
    if (!state.questao?.id) return
    if (
      possuiRespostaCorreta &&
      stateRespostaCorreta === STATE_RESPOSTA_CORRETA.NAO_POSSUI_RESPOSTA_CORRETA
    ) {
      setStateRespostaCorreta(STATE_RESPOSTA_CORRETA.POSSUI_RESPOSTA_CORRETA_TRANSICAO)
      return
    }
    if (!possuiRespostaCorreta)
      setStateRespostaCorreta(STATE_RESPOSTA_CORRETA.NAO_POSSUI_RESPOSTA_CORRETA)
    if (possuiRespostaCorreta)
      setStateRespostaCorreta(STATE_RESPOSTA_CORRETA.POSSUI_RESPOSTA_CORRETA_INICIO)
  }, [possuiRespostaCorreta, state.questao, stateRespostaCorreta])
  return (
    <Layout>
      <Show condition={possuiRespostaCorreta}>
        <DisplayRespostaCorreta
          showAnimation={
            stateRespostaCorreta === STATE_RESPOSTA_CORRETA.POSSUI_RESPOSTA_CORRETA_TRANSICAO
          }
        />
      </Show>
      <Show condition={!possuiRespostaCorreta}>
        <BotaoEnviarResposta />
      </Show>
    </Layout>
  )
}
RodapeQuestao.Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
`

function QuantidadeTentativas() {
  const state = QuestaoStore.useState()
  const quantidadeTentativas = QuestaoSelectors.getQuantidadeTentativas(state)
  const { Layout } = QuantidadeTentativas
  return <Layout>Tentativa número: {('' + quantidadeTentativas).padStart(2, '0')}</Layout>
}
QuantidadeTentativas.Layout = styled.div`
  font-size: 16em;
  line-height: 1.5em;
  color: #88898c;
`

function DisplayRespostaCorreta({ showAnimation }: { showAnimation: boolean }) {
  const { Layout } = DisplayRespostaCorreta
  const [ref, bounds] = useMeasure()
  const boundsRef = React.useRef<any>()
  useEffect(() => {
    boundsRef.current = bounds
  }, [bounds])
  useEffect(() => {
    if (showAnimation) {
      setTimeout(() => {
        const { left, width, top, height } = boundsRef.current
        fx({
          x: left + width / 2,
          y: top + height / 2,
          colors: ['#cc3333', '#4CAF50', '#81C784'],
        })
      }, 100)
    }
  }, [showAnimation])
  return (
    <Layout ref={ref}>
      <Svgs.Checkmark color="#4AC758" height="34" width="34" /> Resposta correta
    </Layout>
  )
}
DisplayRespostaCorreta.Layout = styled.div`
  font-weight: bold;
  font-size: 18em;
  color: #4ac758;
  display: flex;
  align-items: center;
  justify-content: center;
`

function BotaoEnviarResposta() {
  const state = QuestaoStore.useState()
  const dispatch = QuestaoStore.useDispatch()
  const { showRespostaIncorreta, questao, request } = state
  const possuiRespostaCorreta = QuestaoSelectors.getPossuiRespostaCorreta(state)
  const { helpers, Botao } = BotaoEnviarResposta

  function handleClickBotao() {
    if (possuiRespostaCorreta) return
    if (showRespostaIncorreta) return dispatch({ type: 'refazerQuestao' })
    if (questao.tipoQuestao === TIPO_QUESTAO.MULTIPLA_ESCOLHA) {
      return dispatch(QuestaoStore.thunks.sendRespostaMultiplaEscolha())
    }
    dispatch(QuestaoStore.thunks.sendRespostaLigarItens())
  }

  const labelBotao = helpers.getLabelBotao(showRespostaIncorreta)
  const svgBotao = helpers.getSvgBotao(showRespostaIncorreta)

  return (
    <Botao
      label={labelBotao}
      loading={request.fetching}
      onClick={handleClickBotao}
      svg={svgBotao}
      svgPosition="LEFT"
    />
  )
}
BotaoEnviarResposta.helpers = (() => {
  function getLabelBotao(showRespostaIncorreta: boolean) {
    if (showRespostaIncorreta) return 'Refazer questão'
    return 'Enviar resposta'
  }
  function getSvgBotao(showRespostaIncorreta: boolean) {
    if (showRespostaIncorreta) {
      return <Svgs.Refresh color="#ffffff" />
    }
    return <Svgs.Send color="#ffffff" />
  }
  return {
    getLabelBotao,
    getSvgBotao,
  }
})()
BotaoEnviarResposta.Botao = styled(Button)`
  width: 170px;
`

interface AnimatedButtonType extends React.HTMLAttributes<HTMLButtonElement> {
  buttonType
  children
}
function AnimatedButton({ buttonType, children, ...props }: AnimatedButtonType) {
  let cssProps: any = {
    background: '#FFFFFF',
    color: '#FF9933',
    borderColor: '#FF9933',
  }
  if (buttonType === 'success') {
    cssProps = {
      background: '#27AE60',
      color: 'white',
      borderColor: 'transparent',
    }
  }
  if (buttonType === 'error') {
    cssProps = {
      background: '#EB5757',
      color: 'white',
      borderColor: 'transparent',
    }
  }

  const spring = useSpring({ ...cssProps, config: { tension: 100 } })
  return (
    <div>
      <Styles.Button {...props} style={spring}>
        {children}
      </Styles.Button>
    </div>
  )
}
AnimatedButton.Button = styled.div``

function Toast() {
  const { message, request } = QuestaoStore.useState()
  const toastMessage = request.message || message || ''

  const dispatch = QuestaoStore.useDispatch()
  return (
    <IonToast
      isOpen={!!toastMessage}
      onDidDismiss={() => dispatch({ type: 'clearMessage' })}
      message={toastMessage}
      position="bottom"
      buttons={[
        {
          side: 'end',
          text: 'Ok',
          handler: () => dispatch({ type: 'clearMessage' }),
        },
      ]}
      duration={4000}
    />
  )
}

const Styles = {
  ContainerQuestao: styled('div')`
    display: block;
    padding: var(--padding);
    /* padding: 80px; */
    /* @media screen and (max-width: 1024px) {
      padding: 60px;
    }
    @media screen and (max-width: 768px) {
      padding: 40px;
    }
    @media screen and (max-width: 425px) {
      padding: 16px;
    } */
  `,
  Enunciado: styled('div')`
    color: #4d4d4d;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 8px;
    @media screen and (max-width: 425px) {
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 6px;
    }
  `,
  Instrucao: styled('div')`
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #88898c;
    margin-bottom: 32px;
    @media screen and (max-width: 425px) {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 24px;
    }
  `,
  Button: styled(animated.button)`
    /* width: 409px; */
    height: 64px;
    border: 1px solid;
    border-color: #ff9933;
    box-sizing: border-box;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    background: #ffffff;
    color: #ff9933;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    :hover {
      background: #f7f7f7;
    }
    :active {
      outline: none;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
      transform: translate3d(0, 1px, 0);
      background: #f1f1f1;
    }
    svg {
      margin-left: 14px;
    }
    span {
      display: flex;
      align-items: center;
    }
    @media screen and (max-width: 620px) {
      width: 100%;
      height: 52px;
      padding: 0;
      font-size: 20px;
    }
  `,
  RodapeContainer: styled('div')`
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas: 'tentativas botao';
    align-items: center;
    .tentativas {
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #88898c;
      grid-area: tentativas;
    }
    .botao {
      grid-area: botao;
    }
    @media screen and (max-width: 620px) {
      grid-template-columns: 1fr;
      grid-template-areas:
        'botao'
        'tentativas';
      grid-gap: 8px;
      .tentativas {
        text-align: center;
      }
    }
  `,
  ButtonError: null as any,
  ButtonSuccess: null as any,
}

Styles.ButtonError = styled(Styles.Button)`
  background: #eb5757;
  color: white;
  border: none;
  :hover {
    background: #eb5757;
  }
  :active {
    background: #eb5757;
  }
`
Styles.ButtonSuccess = styled(Styles.Button)`
  background: #27ae60;
  color: white;
  border: none;
  :hover {
    background: #27ae60;
  }
  :active {
    background: #27ae60;
  }
`
