import React, { useEffect, createContext, useContext, useReducer } from 'react'
import Axios from 'axios'
import { ValidacaoModel } from '../models/ValidacaoModel'
import { createSimpleStore } from 'react-simple-reducer'

type statusTypes =
  | 'CAPTURAR_FOTO'
  | 'CARREGANDO'
  | 'SUCESSO'
  | 'INDISPONIVEL'
  | 'FALHA'
  | 'INICIALIZANDO'
  | 'CAMERA_INDISPONIVEL'
  | 'PERMISSAO_NECESSARIA'
  | 'SEM_MEDIA_DEVICE'

export const ValidacaoFacialStore = createSimpleStore(
  {
    statusAtual: 'INICIALIZANDO' as statusTypes,
    message: '',
    ultimaValidacao: null as ValidacaoModel | null,
  },
  {
    changeStatus: (state, status: statusTypes) => ({ ...state, statusAtual: status }),
    success: (state, { validacao }) => ({
      ...state,
      ultimaValidacao: validacao,
      statusAtual: 'SUCESSO',
    }),
    error: (state, { message }) => ({ ...state, message, statusAtual: 'FALHA' }),
  },
  {
    thunks: {
      efetuarValidacao(foto, servicoValidacao, tipo, cursoId) {
        return async (dispatch, getState) => {
          dispatch(ValidacaoFacialStore.actions.changeStatus('CARREGANDO'))
          try {
            const retornoValidacao: ValidacaoModel = await Axios.post('/validacoes', {
              foto,
              servicoValidacao,
              tipo,
              cursoId,
            }).then((x) => x.data)

            if (!retornoValidacao.disponivel) {
              dispatch(ValidacaoFacialStore.actions.changeStatus('INDISPONIVEL'))
              return
            }

            if (!retornoValidacao.sucesso) {
              dispatch(
                ValidacaoFacialStore.actions.error({
                  message: 'Não foi possível garantir com precisão sua identidade',
                })
              )
              return
            }

            dispatch(ValidacaoFacialStore.actions.success({ validacao: retornoValidacao }))
          } catch (error: any) {
            const message = 'Ocorreu um erro ao validar a foto'
            dispatch(ValidacaoFacialStore.actions.error({ message }))
          }
        }
      },
    },
  }
)
