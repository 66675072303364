import React, { useEffect, useState } from 'react'
import { IonButton, IonSpinner, IonIcon, IonFabButton } from '@ionic/react'
import styled from '@emotion/styled'
import { Show } from '../common/Show'
import { ValidacaoFacialStore } from './ValidacaoFacialStore'
import { handLeft, alert, checkmark } from 'ionicons/icons'
import { useCursoAtual } from '../cursos/CursosStore'
import 'react-html5-camera-photo/build/css/index.css'
import Camera from 'react-html5-camera-photo'
import IconCameraBrowser from '../assets/icons/icon-camera-browser.png'

function getChild(children, type) {
  if (!Array.isArray(children)) children = [children]
  return children.find((c) => c.type === type)
}

const ValidacaoFacial = ({
  servico = 'SERPRO' as 'SERPRO' | 'AZURE',
  tipo = null as string | null,
  children,
  ...props
}) => {
  const childSuccess = getChild(children, ValidacaoFacial.Success)
  const childFechar = getChild(children, ValidacaoFacial.Fechar)

  const verificarDisponibilidadeCamera = async (dispatch) => {
    dispatch(ValidacaoFacialStore.actions.changeStatus('INICIALIZANDO'))
    const timeOut = setTimeout(() => {
      dispatch(ValidacaoFacialStore.actions.changeStatus('PERMISSAO_NECESSARIA'))
    }, 2000)

    try {
      await navigator.mediaDevices.getUserMedia({ video: true })
      dispatch(ValidacaoFacialStore.actions.changeStatus('CAPTURAR_FOTO'))
    } catch (error: any) {
      if (error?.name === 'NotFoundError')
        dispatch(ValidacaoFacialStore.actions.changeStatus('SEM_MEDIA_DEVICE'))
      if (error?.name === 'NotAllowedError')
        dispatch(ValidacaoFacialStore.actions.changeStatus('CAMERA_INDISPONIVEL'))
    } finally {
      clearTimeout(timeOut)
    }
  }

  function init(dispatch) {
    void verificarDisponibilidadeCamera(dispatch)
  }

  return (
    <ValidacaoFacialStore.Provider init={init}>
      <Styles.Container {...props}>
        <ValidacaoFacialStore.GetState>
          {({ statusAtual }) => (
            <>
              <Show condition={statusAtual === 'CARREGANDO'}>
                <Carregando />
              </Show>
              <Show condition={statusAtual === 'SUCESSO'}>{childSuccess}</Show>
              <Show condition={statusAtual === 'INDISPONIVEL'}>
                <Indisponivel />
                {childFechar}
              </Show>
              <Show condition={statusAtual === 'FALHA'}>
                <Falha />
                {childFechar}
              </Show>
              <Show condition={statusAtual === 'INICIALIZANDO'}>
                Carregando informações do dispositivo...
              </Show>
              <Show condition={statusAtual === 'CAMERA_INDISPONIVEL'}>
                <ErrorCameraBloqueada />
              </Show>
              <Show condition={statusAtual === 'SEM_MEDIA_DEVICE'}>
                <ErrorCameraBloqueada />
              </Show>
              <Show condition={statusAtual === 'PERMISSAO_NECESSARIA'}>
                <InfoPermissaoCamera />
              </Show>
              <Show condition={statusAtual === 'CAPTURAR_FOTO'}>
                <CapturarFoto servico={servico} tipo={tipo} />
              </Show>
            </>
          )}
        </ValidacaoFacialStore.GetState>
      </Styles.Container>
    </ValidacaoFacialStore.Provider>
  )
}

const InfoPermissaoCamera = () => {
  const { Layout, Grid, Icon, Title, Text, Selecione } = InfoPermissaoCamera
  return (
    <Layout>
      <Grid>
        <Icon src={IconCameraBrowser} />
        <Title>A New Driver precisa usar sua câmera</Title>
        <div className="container-content-device-text">
          <Text>Precisamos da sua permissão para ativar a câmera</Text>
          <Text>Na mensagem aberta pelo seu navegador</Text>
        </div>
        <Selecione>
          <Text>
            Selecione <strong>PERMITIR</strong>
          </Text>
        </Selecione>
      </Grid>
    </Layout>
  )
}

InfoPermissaoCamera.Layout = styled.div`
  padding: 0px 30px 30px 30px;
  background-color: #ffffff;
  border-radius: 18px;
`
InfoPermissaoCamera.Grid = styled.div`
  display: grid;
  place-items: center;
  gap: 15px;
  .container-content-device-text {
    display: grid;
    span:last-child {
      font-weight: bold;
    }
  }
`
InfoPermissaoCamera.Icon = styled.img`
  width: 60px;
  filter: invert(30%);
`
InfoPermissaoCamera.Title = styled.span`
  font-size: 22px;
  font-weight: 500;
  text-align: center;
`

InfoPermissaoCamera.Text = styled.span`
  font-size: 15px;
  text-align: center;
`
InfoPermissaoCamera.Selecione = styled.span`
  font-size: 18px;
  font-weight: 450;
  strong {
    color: #ff9933;
  }
`

const ErrorCameraBloqueada = () => {
  const { Layout, ContainerCamDisabled } = ErrorCameraBloqueada
  const { statusAtual } = ValidacaoFacialStore.useState()
  return (
    <Layout>
      <ContainerCamDisabled>
        <span className="title-cam-error">
          Sua câmera está bloqueada. A New Driver precisa ter acesso à câmera para realizar a sua
          validação facial. Para ativá-la:
        </span>
        <div>
          <ul>
            <li>1 - Clique no ícone de câmera bloqueada</li>
            <li>
              2 - Selecione <strong>Sempre permitir</strong>
            </li>
            <li>3 - Em seguida atualize esta página</li>
            <li>
              4 - Depois clique em <strong>X</strong> para tirar sua foto
            </li>
          </ul>
        </div>
      </ContainerCamDisabled>
    </Layout>
  )
}

const ErrorSemMediaDevice = () => {
  const { Layout, ContainerNoHaveCam } = ErrorSemMediaDevice
  const { statusAtual } = ValidacaoFacialStore.useState()

  return (
    <Layout>
      <ContainerNoHaveCam>
        <span className="title-cam-error">
          Não foi possível encontrar uma webcam ou não está funcionando corretamente. Verifique seu
          dispositivo
        </span>
      </ContainerNoHaveCam>
    </Layout>
  )
}

ErrorCameraBloqueada.Layout = styled.div`
  width: fit-content;
  .title-cam-error {
    font-size: 24px;
    font-weight: 500;
  }
`

ErrorCameraBloqueada.ContainerCamDisabled = styled.div`
  div > ul {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
    text-align: start;
    margin-top: 30px;
  }
`

ErrorCameraBloqueada.ContainerNoHaveCam = styled.div`
  max-width: 400px;
  margin-bottom: 200px;
`

ErrorSemMediaDevice.Layout = styled(ErrorCameraBloqueada.Layout)``
ErrorSemMediaDevice.ContainerNoHaveCam = styled(ErrorCameraBloqueada.ContainerNoHaveCam)``

function CapturarFoto({ servico, tipo }) {
  const { LayoutCamera, ImagePreview, ButtonsContainer, IonButtonRound, Photo } = CapturarFoto
  const [dataUri, setDataUri] = useState('')

  useEffect(() => {
    return () => {
      setDataUri('')
    }
  }, [])

  const dispatch = ValidacaoFacialStore.useDispatch()
  const curso = useCursoAtual()

  async function handleCapturarFoto() {
    const resizedImage = await _resizeImage(dataUri)
    dispatch(ValidacaoFacialStore.thunks.efetuarValidacao(resizedImage, servico, tipo, curso?.id))
  }

  function handleClickCapturarNovamente() {
    setDataUri('')
  }

  if (dataUri) {
    return (
      <ImagePreview>
        <Photo src={dataUri} />
        <ButtonsContainer>
          <IonButtonRound fill="outline" onClick={handleClickCapturarNovamente}>
            Capturar Novamente
          </IonButtonRound>
          <IonButtonRound
            color="primary"
            style={{ fontWeight: 'bold', fontSize: '1rem' }}
            onClick={() => {
              handleCapturarFoto()
            }}
          >
            Confirmar
          </IonButtonRound>
        </ButtonsContainer>
      </ImagePreview>
    )
  }

  return (
    <LayoutCamera>
      <Camera onTakePhotoAnimationDone={(e) => setDataUri(e)} />
    </LayoutCamera>
  )
}

CapturarFoto.ImagePreview = styled.div`
  padding: 24px;
  background: #444444;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  img {
    width: 100%;
  }
`
CapturarFoto.ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  margin-top: 24px;
`
CapturarFoto.IonButtonRound = styled(IonButton)`
  height: 54px;
  --border-radius: 28px;
`
CapturarFoto.LayoutCamera = styled.div<{}>(
  () => `
  .react-html5-camera-photo>img {
    display: none;
  }
  .react-html5-camera-photo>video {
    width: 100%;
    max-height: 100%;
  }
  .display-error h1 {
    display: none !important;
  }
  #outer-circle {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.9);
  }
  #inner-circle {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.9);
  }
`
)

CapturarFoto.Photo = styled.div<{ src }>(
  ({ src }) => `
  background-image: url('${src}');
  flex: 1;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`
)

function Carregando() {
  return (
    <>
      <IonSpinner style={{ width: '120px', height: '120px' }} color="primary" />
      <div>
        <h2> Foto capturada com sucesso. </h2>
        <h3> Aguarde enquanto a foto é processada... </h3>
      </div>
    </>
  )
}

function Indisponivel() {
  return (
    <div>
      <IonIcon icon={alert} color="warning" />
      <p>Sua foto não está disponível para validação junto ao órgão responsável</p>
      <p>Por favor, diriga-se ao DETRAN do seu estado e solicite a atualização da sua foto</p>
      <p>Após atualização, você poderá continuar seus estudos</p>
    </div>
  )
}
function Falha() {
  const { message } = ValidacaoFacialStore.useState()
  const dispatch = ValidacaoFacialStore.useDispatch()
  return (
    <>
      <IonIcon
        icon={handLeft}
        style={{
          fontSize: '150px',
          color: 'var(--ion-color-danger)',
        }}
      />
      <p>{message}</p>
      <IonButton
        onClick={() => dispatch(ValidacaoFacialStore.actions.changeStatus('CAPTURAR_FOTO'))}
        size="large"
      >
        Tentar Novamente
      </IonButton>
    </>
  )
}

async function _resizeImage(image): Promise<string> {
  return await new Promise((resolve) => {
    const img = document.createElement('img')

    img.onload = function () {
      const canvas = document.createElement('canvas')
      const ctx: any = canvas.getContext('2d')
      ctx.imageSmoothingEnabled = true
      ctx.imageSmoothingQuality = 'high'

      const ratio = img.width > 640 ? 640 / img.width : 1

      canvas.width = img.width * ratio
      canvas.height = img.height * ratio

      ctx.drawImage(this, 0, 0, canvas.width, canvas.height)

      const dataURL = canvas.toDataURL('image/png', 0.5)
      resolve(dataURL.replace(/^data:image\/(png|jpg);base64,/, ''))
    }

    img.src = image
  })
}

const Styles = {
  Container: styled('div')`
    flex: 1;
    ion-icon {
      width: 120px;
      height: 120px;
      margin: 40px 0;
      @media (max-width: 425px) {
        width: 100px;
        height: 100px;
        margin: 20px 0;
      }
    }
  `,
}

ValidacaoFacial.Success = ({ children }) => <>{children}</>
ValidacaoFacial.Fechar = ({ children }) => <>{children}</>

export { ValidacaoFacial }
