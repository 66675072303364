import React from 'react'
import { SvgType } from './index'
const PictureSvg = ({ color = '#000', ...props }: SvgType) => {
  return (
    <svg
      width="34"
      height="27"
      viewBox="0 0 34 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.9 0H5.1C3.7474 0 2.45019 0.533369 1.49376 1.48277C0.53732 2.43217 0 3.71984 0 5.0625V21.9375C0 23.2802 0.53732 24.5678 1.49376 25.5172C2.45019 26.4666 3.7474 27 5.1 27H28.9C30.2526 27 31.5498 26.4666 32.5062 25.5172C33.4627 24.5678 34 23.2802 34 21.9375V5.0625C34 3.71984 33.4627 2.43217 32.5062 1.48277C31.5498 0.533369 30.2526 0 28.9 0ZM5.1 23.625C4.64913 23.625 4.21673 23.4472 3.89792 23.1307C3.57911 22.8143 3.4 22.3851 3.4 21.9375V17.8538L9.01 12.3019C9.32778 11.9927 9.75502 11.8195 10.2 11.8195C10.645 11.8195 11.0722 11.9927 11.39 12.3019L22.797 23.625H5.1ZM30.6 21.9375C30.6 22.3851 30.4209 22.8143 30.1021 23.1307C29.7833 23.4472 29.3509 23.625 28.9 23.625H27.591L21.114 17.1619L22.61 15.6769C22.9278 15.3677 23.355 15.1945 23.8 15.1945C24.245 15.1945 24.6722 15.3677 24.99 15.6769L30.6 21.2288V21.9375ZM30.6 16.47L27.404 13.3144C26.4351 12.3904 25.1436 11.8743 23.8 11.8743C22.4564 11.8743 21.1649 12.3904 20.196 13.3144L18.7 14.7994L13.804 9.93938C12.8351 9.01536 11.5436 8.49931 10.2 8.49931C8.85639 8.49931 7.5649 9.01536 6.596 9.93938L3.4 13.095V5.0625C3.4 4.61495 3.57911 4.18573 3.89792 3.86926C4.21673 3.55279 4.64913 3.375 5.1 3.375H28.9C29.3509 3.375 29.7833 3.55279 30.1021 3.86926C30.4209 4.18573 30.6 4.61495 30.6 5.0625V16.47Z"
        fill={color}
      />
    </svg>
  )
}

export default PictureSvg
