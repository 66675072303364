import React from 'react'
import { SvgType } from './index'
const ArrowLeftSvg = ({ color = '#F06F30', ...props }: SvgType) => {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.1673 10.1668H6.25229L12.3023 4.13518C12.6475 3.78995 12.8415 3.32173 12.8415 2.83351C12.8415 2.34529 12.6475 1.87707 12.3023 1.53184C11.9571 1.18662 11.4888 0.992676 11.0006 0.992676C10.5124 0.992676 10.0442 1.18662 9.69896 1.53184L0.53229 10.6985C0.365383 10.8729 0.234547 11.0785 0.14729 11.3035C-0.036076 11.7499 -0.036076 12.2505 0.14729 12.6968C0.234547 12.9219 0.365383 13.1275 0.53229 13.3018L9.69896 22.4685C9.86939 22.6403 10.0722 22.7767 10.2956 22.8698C10.519 22.9629 10.7586 23.0108 11.0006 23.0108C11.2426 23.0108 11.4823 22.9629 11.7057 22.8698C11.9291 22.7767 12.1319 22.6403 12.3023 22.4685C12.4741 22.2981 12.6105 22.0953 12.7036 21.8719C12.7967 21.6485 12.8446 21.4089 12.8446 21.1668C12.8446 20.9248 12.7967 20.6852 12.7036 20.4618C12.6105 20.2384 12.4741 20.0356 12.3023 19.8652L6.25229 13.8335H20.1673C20.6535 13.8335 21.1198 13.6404 21.4637 13.2965C21.8075 12.9527 22.0006 12.4864 22.0006 12.0002C22.0006 11.5139 21.8075 11.0476 21.4637 10.7038C21.1198 10.36 20.6535 10.1668 20.1673 10.1668Z"
        fill={color}
      />
    </svg>
  )
}

export default ArrowLeftSvg
