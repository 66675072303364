import { useEffect, useRef, useState } from 'react'

const useTimeout = (
  callback,
  delay,
  opts: { delayInSeconds: boolean } = { delayInSeconds: false }
) => {
  const savedCallback = useRef(callback)
  const [forceReset, setForceReset] = useState(Math.random())

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const handler = (...args) => savedCallback.current(...args)

    if (delay != null) {
      const _delay = opts.delayInSeconds ? delay * 1000 : delay
      const id = setTimeout(handler, _delay)
      return () => clearTimeout(id)
    }
  }, [delay, forceReset])

  return {
    reset() {
      setForceReset(Math.random())
    },
  }
}

export default useTimeout
