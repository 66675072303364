import React from 'react'
import { SvgType } from './index'
const ExclamationCircleSvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.0419 12C9.79465 12 9.55297 12.0733 9.34741 12.2107C9.14185 12.348 8.98164 12.5432 8.88703 12.7716C8.79242 13.0001 8.76766 13.2514 8.81589 13.4939C8.86412 13.7363 8.98318 13.9591 9.15799 14.1339C9.33281 14.3087 9.55554 14.4277 9.79801 14.476C10.0405 14.5242 10.2918 14.4995 10.5202 14.4049C10.7486 14.3102 10.9439 14.15 11.0812 13.9445C11.2186 13.7389 11.2919 13.4972 11.2919 13.25C11.2919 12.9185 11.1602 12.6005 10.9258 12.3661C10.6913 12.1317 10.3734 12 10.0419 12ZM10.0419 10.5C10.3071 10.5 10.5614 10.3946 10.749 10.2071C10.9365 10.0196 11.0419 9.76522 11.0419 9.5V6.5C11.0419 6.23478 10.9365 5.98043 10.749 5.79289C10.5614 5.60536 10.3071 5.5 10.0419 5.5C9.77666 5.5 9.5223 5.60536 9.33477 5.79289C9.14723 5.98043 9.04187 6.23478 9.04187 6.5V9.5C9.04187 9.76522 9.14723 10.0196 9.33477 10.2071C9.5223 10.3946 9.77666 10.5 10.0419 10.5ZM10.0419 0C8.06406 0 6.13066 0.58649 4.48617 1.6853C2.84168 2.78412 1.55996 4.3459 0.803079 6.17317C0.046203 8.00043 -0.15183 10.0111 0.234022 11.9509C0.619874 13.8907 1.57228 15.6725 2.97081 17.0711C4.36933 18.4696 6.15116 19.422 8.09097 19.8079C10.0308 20.1937 12.0414 19.9957 13.8687 19.2388C15.696 18.4819 17.2578 17.2002 18.3566 15.5557C19.4554 13.9112 20.0419 11.9778 20.0419 10C20.0389 7.34874 18.9844 4.80691 17.1097 2.93219C15.235 1.05746 12.6931 0.00294858 10.0419 0ZM10.0419 18C8.45962 18 6.91291 17.5308 5.59731 16.6518C4.28172 15.7727 3.25634 14.5233 2.65084 13.0615C2.04534 11.5997 1.88691 9.99113 2.19559 8.43928C2.50427 6.88743 3.2662 5.46197 4.38502 4.34315C5.50384 3.22433 6.9293 2.4624 8.48115 2.15372C10.033 1.84504 11.6415 2.00346 13.1033 2.60896C14.5652 3.21447 15.8146 4.23984 16.6936 5.55544C17.5727 6.87103 18.0419 8.41775 18.0419 10C18.0394 12.121 17.1958 14.1544 15.696 15.6542C14.1963 17.1539 12.1629 17.9976 10.0419 18Z"
        fill={color}
      />
    </svg>
  )
}

export default ExclamationCircleSvg
