import React from 'react'
import { SvgType } from './index'

const LoadingSpinner = ({ color = '#FFF', ...props }: SvgType) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 2C9.21997 2 7.47991 2.52784 5.99987 3.51677C4.51983 4.50571 3.36627 5.91131 2.68508 7.55585C2.0039 9.20038 1.82567 11.01 2.17293 12.7558C2.5202 14.5016 3.37737 16.1053 4.63604 17.364C5.89471 18.6226 7.49836 19.4798 9.24419 19.8271C10.99 20.1743 12.7996 19.9961 14.4441 19.3149C16.0887 18.6337 17.4943 17.4802 18.4832 16.0001C19.4722 14.5201 20 12.78 20 11"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LoadingSpinner
