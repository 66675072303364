import React, { useRef, useEffect } from 'react'
import { ConteudoStore, ConteudoSelectors } from './ConteudoStore'
import { ConteudoItemModel } from '../models/ConteudoItemModel'
import { useSelector, useDispatch } from 'react-redux'
import { IStateRedux } from '../common/ReduxStore'
import styled from '@emotion/styled'
import { Show } from '../common/Show'
import { IonIcon, IonMenu } from '@ionic/react'
import { checkmark } from 'ionicons/icons'
import { EstatisticaEstudoModel } from '../models/EstatisticaEstudoModel'
import { ProgressoModel } from '../models/ProgressoModel'
import { progressoActions } from './ProgressoReduxStore'
import { ConteudoPaginaModel } from '../models/ConteudoPaginaModel'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { menuController } from '@ionic/core'
import { createSelector } from 'reselect'
import { useWindowSize } from '../common/hooks/useWindowSize'

enum STATUS_PAGINA {
  NENHUM = '',
  VISUALIZADA_ATIVA = 'VISUALIZADA_ATIVA',
  ATIVA = 'ATIVA',
  VISUALIZADA = 'VISUALIZADA',
}

enum STATUS_TITULO {
  NENHUM = '',
  VISUALIZADO_ATIVO = 'VISUALIZADO_ATIVO',
  ATIVO = 'ATIVO',
  VISUALIZADO = 'VISUALIZADO',
}

export const Indice = () => {
  const dispatch = ConteudoStore.useDispatch()
  const state = ConteudoStore.useState()
  const paginasTitulo = ConteudoSelectors.getPaginasTitulo(state)
  const ref = useRef<any>()
  const { width: windowWidth } = useWindowSize()
  useEffect(() => {
    try {
      if (state.isIndiceOpen) setTimeout(async () => await menuController.open())
      if (!state.isIndiceOpen) setTimeout(async () => await menuController.close())
    } catch (e) {}
  }, [state.isIndiceOpen, ref.current])
  useEffect(() => {
    ref.current
      ?.querySelector(`[data-pagina-id="${state.paginaAtualId}"]`)
      ?.scrollIntoViewIfNeeded?.() ??
      ref.current?.querySelector(`[data-pagina-id="${state.paginaAtualId}"]`)?.scrollIntoView?.()
  }, [state.paginaAtualId])
  return (
    <>
      <IonMenu
        contentId="main"
        side={(windowWidth ?? 0) < 750 ? 'end' : 'start'}
        onIonDidOpen={() => dispatch({ type: 'openIndice' })}
        onIonDidClose={() => dispatch({ type: 'closeIndice' })}
        ref={ref}
      >
        <Styles.Container>
          {paginasTitulo.map((p, i) => (
            <Pagina key={i} pagina={p} paginaNumero={i + 1} />
          ))}
        </Styles.Container>
      </IonMenu>
    </>
  )
}

function _getTituloAtivoId(pagina, paginaIdAtual, ordenacaoItemAtivo) {
  let id = pagina[0]?.id
  if (paginaIdAtual !== pagina[0]?.conteudoPaginaId) return
  pagina?.slice?.().forEach?.((p) => {
    if (ordenacaoItemAtivo >= p.ordenacao) id = p.id
  })
  return id
}

const selectEstatisticaByModulo = createSelector(
  (s: { estatisticasEstudos; moduloId }) => s.estatisticasEstudos,
  (s: { estatisticasEstudos; moduloId }) => s.moduloId,
  (estatisticasEstudos, moduloId) => estatisticasEstudos?.find((e) => e.moduloId === moduloId)
)

const Pagina = ({
  pagina,
  paginaNumero,
}: {
  pagina: { pagina: ConteudoPaginaModel; titulos: ConteudoItemModel[] }
  paginaNumero
}) => {
  const moduloId = pagina.pagina.moduloId
  const estatisticasEstudos = useSelector((s: IStateRedux) => s.progresso.estatisticasEstudos)
  const estatisticaEstudos = selectEstatisticaByModulo({ estatisticasEstudos, moduloId })
  const state = ConteudoStore.useState()
  const dispatch = ConteudoStore.useDispatch()
  const statusPagina = _getStatusPagina(state)
  const conteudoItemIdAtual = useSelector(
    (s: IStateRedux) => s.progresso.progressoAtual.conteudoItemId
  )
  const conteudosPaginaAtual = ConteudoSelectors.geConteudosPagina(state)
  const ordenacaoItemAtivo = conteudosPaginaAtual.find(
    (x) => x.id === conteudoItemIdAtual
  )?.ordenacao
  const tituloAtivoId = _getTituloAtivoId(pagina, state.paginaAtualId, ordenacaoItemAtivo)

  function _getStatusPagina(_state): STATUS_PAGINA {
    if (estatisticaEstudos?.paginaNumeroMaisAvancada > paginaNumero) {
      if (estatisticaEstudos?.paginaNumero === paginaNumero) return STATUS_PAGINA.VISUALIZADA_ATIVA
      if (_state?.paginaAtualId === pagina[0]?.conteudoPaginaId)
        return STATUS_PAGINA.VISUALIZADA_ATIVA
    }
    if (estatisticaEstudos?.paginaNumero === paginaNumero) return STATUS_PAGINA.ATIVA
    if (_state?.paginaAtualId === pagina[0]?.conteudoPaginaId) return STATUS_PAGINA.ATIVA
    if (estatisticaEstudos?.paginaNumeroMaisAvancada >= paginaNumero)
      return STATUS_PAGINA.VISUALIZADA
    return STATUS_PAGINA.NENHUM
  }

  function handleClick() {
    if (statusPagina === '') return toast('Você ainda não pode visualizar essa página')
    dispatch({ type: 'setPagina', payload: paginaNumero })
  }

  return (
    <Styles.Pagina status={statusPagina} onClick={handleClick} data-pagina-id={pagina.pagina.id}>
      <Styles.NumeroPagina>PÁGINA {paginaNumero}</Styles.NumeroPagina>
      {pagina.titulos.map((c: ConteudoItemModel, i) => {
        const statusTitulo = _getStatusTitulo(statusPagina, c, ordenacaoItemAtivo, tituloAtivoId)
        return <Titulo key={c.id} conteudo={c} index={i} statusTitulo={statusTitulo} />
      })}
    </Styles.Pagina>
  )
}

const Titulo = ({
  conteudo,
  index,
  statusTitulo,
}: {
  conteudo: ConteudoItemModel
  index: number
  statusTitulo: STATUS_TITULO
}) => {
  return (
    <Styles.Titulo statusTitulo={statusTitulo} firstOfType={index === 0}>
      {conteudo.conteudo}
      <Show
        condition={
          statusTitulo === STATUS_TITULO.VISUALIZADO ||
          statusTitulo === STATUS_TITULO.VISUALIZADO_ATIVO
        }
      >
        <IonIcon icon={checkmark} className="icon-visualizado" />
      </Show>
    </Styles.Titulo>
  )
}

const Styles = {
  Container: styled('div')`
    background: #f6f8fd;
    width: 100%;
    overflow: auto;
    height: 100%;
  `,
  Backdrop: styled('div')`
    background: rgba(0, 0, 0, 0.69);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  `,
  Pagina: styled('div')<{ status: STATUS_PAGINA }>(
    (props) => `
    padding: 18px;
    border-bottom: 1px solid #e4e5e8;
    background-color: ${
      props.status === STATUS_PAGINA.ATIVA || props.status === STATUS_PAGINA.VISUALIZADA_ATIVA
        ? '#EBEEF5'
        : 'transparent'
    };
    cursor: pointer;
    transition: background-color .35s;
    &:hover {
      background-color: #EBEEF5;
    }
  `
  ),
  NumeroPagina: styled('div')`
    font-family: 'Rubik', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    font-variant: small-caps;
    color: #2d2d2d;
  `,
  Titulo: styled('div')<{ statusTitulo: STATUS_TITULO; firstOfType: boolean }>(
    (props) => `
    font-family: 'Rubik',serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: ${_getColorByStatus(props.statusTitulo)};
    margin-top: ${props.firstOfType ? '8px' : '18px'};
    display: flex;
    justify-content: space-between;
    .icon-visualizado {
      color: #27AE60;
      font-size: 1.8em;
      margin-top: -10px;
      min-width: 26px;
    }
  `
  ),
}

function _getColorByStatus(status: STATUS_TITULO) {
  if (status === STATUS_TITULO.ATIVO || status === STATUS_TITULO.VISUALIZADO_ATIVO)
    return 'var(--ion-color-primary-shade)'
  if (status === STATUS_TITULO.VISUALIZADO) return '#88898C'
  return '#2D2D2D'
}

function _getStatusTitulo(
  statusPagina: STATUS_PAGINA,
  conteudoItem,
  ordenacaoItemAtivo,
  tituloAtivoId
): STATUS_TITULO {
  if (statusPagina === STATUS_PAGINA.VISUALIZADA) return STATUS_TITULO.VISUALIZADO
  if (statusPagina === '') return STATUS_TITULO.NENHUM
  if (tituloAtivoId === conteudoItem.id && statusPagina === STATUS_PAGINA.VISUALIZADA_ATIVA)
    return STATUS_TITULO.VISUALIZADO_ATIVO
  if (tituloAtivoId === conteudoItem.id) return STATUS_TITULO.ATIVO
  if (ordenacaoItemAtivo > conteudoItem.ordenacao) return STATUS_TITULO.VISUALIZADO
  return STATUS_TITULO.NENHUM
}
