import React from 'react'
import { SvgType } from './index'
const CheckmarkSvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.2024 7.57023C14.5183 7.25436 15.0304 7.25436 15.3463 7.57023C15.6621 7.88609 15.6621 8.39821 15.3463 8.71408L10.4933 13.567C10.1775 13.8829 9.66535 13.8829 9.34948 13.567L6.6534 10.8709C6.33754 10.5551 6.33754 10.043 6.6534 9.72709C6.96927 9.41122 7.48139 9.41122 7.79725 9.72709L9.92141 11.8512L14.2024 7.57023Z"
        fill={color}
      />
    </svg>
  )
}

export default CheckmarkSvg
