import React, { useEffect } from 'react'
import { Route, Redirect, BrowserRouter, Switch, useHistory, useLocation } from 'react-router-dom'
import { IonApp, IonPage, IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { Helmet } from 'react-helmet'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
import '@ionic/react/css/ionic.bundle.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme.css'

import { Provider, useSelector, useDispatch } from 'react-redux'
import { authActions } from './auth/AuthStore'
import { bindActionCreators } from 'redux'

import { store, IStateRedux } from './common/ReduxStore'
import { Login } from './auth/Login'
import { EsqueceuSenha } from './auth/EsqueceuSenha'
import { MudarSenhaToken } from './auth/MudarSenhaToken'
import { SelecionaCurso } from './cursos/SelecionaCurso'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import Axios from 'axios'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import Layout from './home/Layout'
import { SvgsPreview } from './svgs/SvgsPreview'
import { GlobalStore } from './GlobalStore'
import { Matricula } from './matricula/Matricula'
import { MatriculaEstadoCurso } from './matricula/MatriculaEstadoCurso'
import { SucessoMatricula } from './matricula/SucessoMatricula'
import { ConfirmacaoMatricula } from './home/ConfirmacaoMatricula'
import { Certificado } from './certificado/Certificado'
import { Toaster } from 'react-hot-toast'

export const apiBaseUrl =
  process.env.REACT_APP_API_URL || process.env.react_app_api_url || 'http://localhost:3500'

declare global {
  interface Window {
    jivo_api: any // eslint-disable-line
    Hls: any
  }
}

declare global {
  namespace NodeJS {
    interface ProcessEnv {
      readonly REACT_APP_API_URL: string
    }
  }
}

const App: React.FC = () => (
  <IonApp>
    <GlobalStore.Provider>
      <ToastContainer progressClassName="toast-progress" bodyClassName="toast-body" />
      <Toaster toastOptions={{ style: { background: '#ff9722', color: 'white' } }} />
      <Provider store={store}>
        <IonReactRouter>
          <ConditionalShowJivo />
          <ConfigureAxios />
          <IonPage>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route path="/svgs-preview" component={SvgsPreview} />
              <Route path="/recupera-senha" component={EsqueceuSenha} />
              <Route path="/mudar-senha/:token" component={MudarSenhaToken} />
              <Route path="/criar-senha/:token" component={MudarSenhaToken} />
              <Route path="/seleciona-curso" component={SelecionaCurso} />
              <Route path="/curso/:cursoId/" component={Layout} />
              <Route path="/pe/matricula" component={Matricula} />
              <Route exact path="/matricula/:curso?" component={MatriculaEstadoCurso} />
              <Route exact path="/matricula/:uf?/:curso?" component={Matricula} />
              <Route path="/bemvindo" component={ConfirmacaoMatricula} />
              <Route path="/obrigado" component={SucessoMatricula} />
              <Route path="/certificado/:certificadoId" component={Certificado} />
              <Redirect from="/curso/:cursoId/" to="/curso/:cursoId/home" />
              <Redirect from="/" to="/seleciona-curso" />
            </Switch>
          </IonPage>
        </IonReactRouter>
      </Provider>
    </GlobalStore.Provider>
  </IonApp>
)
defineCustomElements(window)
export default App

const ConditionalShowJivo = () => {
  const location = useLocation()
  const rotasComJivo = [
    '/',
    '/matricula',
    '/recupera-senha',
    '/seleciona-curso',
    '/matricula/pe/reciclagem-para-condutores-infratores',
  ]
  const rotasInternasComJivo = [
    '/home',
    '/modulos',
    '/tutoria',
    '/progresso',
    '/bloco-notas',
    '/biblioteca',
    // '/validacao-facial',
    '/modulo-finalizado',
    '/regras-avaliacao',
    '/resultado-avaliacao',
    '/gabarito',
  ]
  const pathname = location.pathname
  if (rotasComJivo.includes(pathname)) return null
  if (pathname.includes('/criar-senha/')) return null
  if (pathname.startsWith('/curso/') && rotasInternasComJivo.some((x) => pathname.includes(x)))
    return null
  return (
    <Helmet>
      <style>
        {`
        .__jivoMobileButton {
          display: none !important;
        }
        jdiv#jvlabelWrap {
          display: none !important;
        }
        `}
      </style>
    </Helmet>
  )
}

const ConfigureAxios = () => {
  const token = useSelector((s: IStateRedux) => s.auth.token)
  const history = useHistory()
  const dispatch = useDispatch()
  const actions = bindActionCreators(authActions, dispatch)

  useEffect(() => {
    Axios.defaults.baseURL = apiBaseUrl
    Axios.defaults.headers.common = { platform: 'APP' }
  }, [])

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', '' + token)
      Axios.defaults.headers.common.Authorization = `Bearer ${token}`
    } else {
      localStorage.removeItem('token')
      Axios.defaults.headers.common.Authorization = ''
    }
  }, [token])

  useEffect(() => {
    Axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response?.status === 401) {
          actions.logout()
          history.replace('/')
        }
        return await Promise.reject(error)
      }
    )
  }, [history])

  return null
}
