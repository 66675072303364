import { QuestaoModel, TIPO_QUESTAO } from '../models/QuestaoModel'
import axios from '../common/axios'
import { RespostaAlternativaModel } from '../models/RespostaAlternativaModel'
import { RespostaModel } from '../models/RespostaModel'
import { QuestaoAlternativaModel } from '../models/QuestaoAlternativaModel'
import { createSimpleStore } from 'react-simple-reducer'
import { createSelector } from 'reselect'

const initialState = {
  request: {
    fetching: false,
    errorCode: null,
    message: '',
  },
  questao: { questoesAlternativas: [] } as any as QuestaoModel,
  respostasInformadas: {} as {
    [alternativaId: string]: { respostaInformada: string; questaoAlternativaEnviadaId?: number }
  },
  message: '',
  showRespostaIncorreta: false,
  somenteLeitura: false,
  isGabarito: false,
}

const QuestaoStore = createSimpleStore(
  initialState,
  {
    initQuestao(state, payload: Partial<typeof initialState>) {
      return { ...initialState, ...payload }
    },
    fetchStarted(state) {
      state.request.fetching = true
    },
    fetchError(state, payload: { errorCode; message }) {
      const { errorCode, message } = payload
      state.request = { errorCode, message, fetching: false }
    },
    setMessage(state, payload: { message }) {
      const { message } = payload
      state.message = message
    },
    clearMessage(state) {
      state.request.message = ''
      state.message = ''
    },
    getQuestaoByIdSuccess(state, payload: { questao: QuestaoModel }) {
      const questao = { ...payload.questao }
      questao.questoesAlternativas = questao.questoesAlternativas.map((q) => ({
        ...q,
        ordenacao: Math.random(),
      }))
      state.questao = questao
      state.request.fetching = false
    },
    selectAlternativaMultiplaEscolhaSuccess(state, payload: { alternativaId }) {
      const { alternativaId } = payload
      state.respostasInformadas = { ['' + alternativaId]: { respostaInformada: 'true' } }
    },
    sendRespostaMultiplaEscolhaSuccess(state, payload: { resposta: RespostaModel }) {
      const { resposta } = payload
      state.request.fetching = false
      state.questao.respostas = [resposta]
      state.questao.questoesAlternativas.forEach((x) => (x.marcada = false))
    },
    selectAlternativaRelacionarItensSuccess(state, payload: { alternativaId }) {
      const { alternativaId } = payload
      state.questao.questoesAlternativas.find((x) => x.id === alternativaId)!.marcada = true
    },
    sendRespostaLigarItensCorretaSuccess(state, payload: { resposta }) {
      const { resposta } = payload
      state.questao.questoesAlternativas.forEach((x) => (x.marcada = true))
      state.questao.respostas = [resposta]
    },
    sendRespostaLigarItensIncorretaSuccess(state) {
      state.questao.questoesAlternativas.forEach((x) => (x.marcada = false))
    },
    markAlternativaSuccess(state, payload: { alternativaId; resposta }) {
      const { alternativaId, resposta } = payload
      state.respostasInformadas[alternativaId] = resposta
    },
    sendRespostaVerdadeiroFalsoSuccess(state, payload: { resposta }) {
      const { resposta } = payload
      state.request.fetching = false
      state.questao.respostas = [resposta]
    },
    refazerQuestao(state) {
      state.showRespostaIncorreta = false
      state.respostasInformadas = {}
    },
    sendRespostasSuccess(state, payload: { resposta: RespostaModel }) {
      const { resposta } = payload
      state.request.fetching = false
      state.questao.respostas = [resposta]
      if (!resposta.possuiRespostaCorreta) state.showRespostaIncorreta = true
    },
    unmarkAlternativa(state, payload: { alternativaId }) {
      const respostasInformadas = { ...state.respostasInformadas }
      delete respostasInformadas[payload.alternativaId]
      state.respostasInformadas = respostasInformadas
    },
  },
  {
    thunks: {
      markAlternativa({ alternativaId, resposta }) {
        return async (dispatch, getState) => {
          const state = getState()

          if (state.somenteLeitura) return

          const _alternativaPossuiRespostaCorreta = () => {
            const questao = state.questao
            const respostas = questao.respostas
            const alternativas = questao.questoesAlternativas
            if (!respostas || !respostas.length) return false
            const alternativa = alternativas.find((x) => x.id === alternativaId)
            return respostas[0].respostasAlternativas.some((r) => {
              return (
                r.questaoAlternativaId === alternativaId &&
                alternativa!.respostaEsperada === r.respostaEnviada
              )
            })
          }

          if (_alternativaPossuiRespostaCorreta()) {
            return dispatch({
              type: 'setMessage',
              payload: { message: 'Alternativa já possui resposta correta' },
            })
          }

          if (state.questao.tipoQuestao !== TIPO_QUESTAO.MULTIPLO_VERDADEIRO_FALSO) {
            const respostasInformadas = state.respostasInformadas
            const alternativaIdPreviamenteMarcada = Object.keys(respostasInformadas).find((x) => {
              return (
                respostasInformadas[x].questaoAlternativaEnviadaId ===
                resposta.questaoAlternativaEnviadaId
              )
            })
            if (alternativaIdPreviamenteMarcada) {
              dispatch({
                type: 'unmarkAlternativa',
                payload: { alternativaId: alternativaIdPreviamenteMarcada },
              })
            }
          }
          dispatch({ type: 'markAlternativaSuccess', payload: { alternativaId, resposta } })
        }
      },
      getQuestaoById(
        questaoId,
        embaralharAlternativas = false,
        somenteLeitura = false,
        mostrarRespostasEnviadas = false,
        isGabarito = false
      ) {
        return async (dispatch) => {
          dispatch({ type: 'initQuestao', payload: { somenteLeitura, isGabarito } })
          dispatch({ type: 'fetchStarted' })
          try {
            const questao = await axios.Questoes.getById(questaoId)
            if (embaralharAlternativas) {
              questao.questoesAlternativas = questao.questoesAlternativas
                .map((x) => ({ ...x, ordenacao: Math.random() }))
                .sort((a, b) => (a.ordenacao < b.ordenacao ? 1 : -1))
            }
            if (!mostrarRespostasEnviadas) questao.respostas = []
            dispatch({ type: 'getQuestaoByIdSuccess', payload: { questao } })
          } catch (error: any) {
            dispatch({
              type: 'fetchError',
              payload: { message: 'Ocorreu um erro ao buscar a questão', errorCode: 400 },
            })
          }
        }
      },
      selecionaAlternativaMultiplaEscolha(alternativaId) {
        return (dispatch, getState) => {
          const state = getState()
          const { questao, somenteLeitura } = state
          if (somenteLeitura) return
          if (questao.respostas?.[0]?.possuiRespostaCorreta) {
            return dispatch({
              type: 'setMessage',
              payload: { message: 'Essa questão já possui resposta correta' },
            })
          }

          const respostasAlternativas = questao.respostas?.[0]?.respostasAlternativas ?? []
          if (respostasAlternativas.some((x) => x.questaoAlternativaId === alternativaId)) {
            return dispatch({
              type: 'setMessage',
              payload: { message: 'Você já tentou responder essa alternativa' },
            })
          }

          dispatch({ type: 'selectAlternativaMultiplaEscolhaSuccess', payload: { alternativaId } })
        }
      },
      sendRespostaMultiplaEscolha() {
        return async (dispatch, getState) => {
          const state = getState()
          const { questao, respostasInformadas } = state
          if (!questao) return

          let alternativaId: any = Object.keys(respostasInformadas).pop()
          // é necessário ter uma alternativa selecionada
          if (!alternativaId) {
            return dispatch({
              type: 'setMessage',
              payload: { message: 'É necessário selecionar uma alternativa' },
            })
          }
          alternativaId = +alternativaId

          // Multipla escolha não pode responder a mesma alternativa mais do que uma vez
          const respostasAlternativas = questao?.respostas[0]?.respostasAlternativas ?? []
          if (respostasAlternativas.some((x) => x.questaoAlternativaId === +alternativaId)) {
            const message = 'Você já tentou responder essa alternativa'
            return dispatch({ type: 'setMessage', payload: { message } })
          }

          dispatch({ type: 'fetchStarted' })
          try {
            const respostaCriada = await axios.Questoes.createRespostaMultiplaEscolha({
              questaoId: questao.id,
              alternativaId,
              resposta: true,
            })
            dispatch({
              type: 'sendRespostaMultiplaEscolhaSuccess',
              payload: { resposta: respostaCriada },
            })
          } catch (error: any) {
            const message = error?.response?.data?.message ?? 'Ocorreu um erro ao enviar a resposta'
            dispatch({ type: 'fetchError', payload: { message, errorCode: 400 } })
          }
        }
      },
      sendRespostaLigarItens() {
        return async (dispatch, getState) => {
          const { questao, respostasInformadas } = getState()
          try {
            const respostasInformadasComAlternativa = Object.entries(respostasInformadas).map(
              ([key, value]) => ({ ...value, alternativaId: +key })
            )

            // todas as questões precisam ser respondidas
            const respostasCorretas =
              questao.respostas && questao.respostas.length
                ? questao.respostas[0].respostasAlternativas.filter((x) => x.correta)
                : []

            const alternativasIdsRespostasInformadas = Object.keys(
              respostasInformadasComAlternativa
            ).filter((a) => !respostasCorretas.some((r) => +r.questaoAlternativaId === +a))

            if (
              respostasCorretas.length !== alternativasIdsRespostasInformadas.length &&
              respostasCorretas.length + alternativasIdsRespostasInformadas.length !==
                questao.questoesAlternativas.length
            ) {
              dispatch({
                type: 'setMessage',
                payload: { message: 'É necessário preencher todas as alternativas' },
              })
              return
            }

            dispatch({ type: 'fetchStarted' })

            const resposta = await axios.Questoes.createRespostaRelacionarItens(
              questao.id,
              respostasInformadas
            )
            dispatch({ type: 'sendRespostasSuccess', payload: { resposta } })
          } catch (error: any) {
            const message =
              error.response?.data?.message ?? 'Ocorreu um erro ao enviar as respostas'
            dispatch({ type: 'fetchError', payload: { message, errorCode: 400 } })
          }
        }
      },
      selecionaAlternativaRelacionarItens(questaoId, respostaEsperadaId, respostaEnviadaId) {
        return async (dispatch, getState) => {
          if (getState().somenteLeitura) return
          const questoesAlternativas: QuestaoAlternativaModel[] =
            getState().questao.questoesAlternativas
          const totalAlternativasRespondidas = questoesAlternativas.filter((x) => x.marcada).length

          if (
            respostaEsperadaId === respostaEnviadaId &&
            totalAlternativasRespondidas !== questoesAlternativas.length - 1
          ) {
            return dispatch({
              type: 'selectAlternativaRelacionarItensSuccess',
              payload: { alternativaId: respostaEsperadaId },
            })
          }
          const questao: QuestaoModel = getState().questao

          const alternativasCorretas = questao.questoesAlternativas
            .filter((x) => x.marcada)
            .map((x) => ({ questaoAlternativaId: x.id, questaoAlternativaEnviadaId: x.id }))

          const alternativas = [
            ...alternativasCorretas,
            {
              questaoAlternativaId: respostaEsperadaId,
              questaoAlternativaEnviadaId: respostaEnviadaId,
            },
          ]

          const resposta = await axios.Questoes.createRespostaRelacionarItens(
            questaoId,
            alternativas
          )

          if (respostaEsperadaId === respostaEnviadaId) {
            return dispatch({ type: 'sendRespostaLigarItensCorretaSuccess', payload: { resposta } })
          }

          dispatch({ type: 'sendRespostaLigarItensIncorretaSuccess' })
        }
      },
    },
  }
)

type IState = typeof initialState

const QuestaoSelectors = {
  getQuantidadeTentativas: createSelector(
    (s: IState) => ({ questao: s.questao }),
    ({ questao }) => {
      const possuiRespostaCorreta = questao.respostas?.[0]?.possuiRespostaCorreta ?? false
      const qtdTentativas = questao.respostas?.[0]?.quantidadeTentativas ?? 0
      return possuiRespostaCorreta ? qtdTentativas : qtdTentativas + 1
    }
  ),
  getPossuiRespostaCorreta: createSelector(
    (s: IState) => ({ questao: s.questao }),
    ({ questao }) => questao.respostas?.[0]?.possuiRespostaCorreta ?? false
  ),
}

export { QuestaoStore, QuestaoSelectors }
