import styled from '@emotion/styled'
import { useIonViewDidEnter } from '@ionic/react'
import Axios from 'axios'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { toast } from 'react-toastify'
import { createSelector } from 'reselect'
import { Show } from '../common/Show'
import { createSimpleStore } from 'react-simple-reducer'
import { Button, TituloPagina } from '../components'
import { RespostaModel } from '../models/RespostaModel'
import { useModuloAtual } from '../modulos/ModulosStore'
import { Questao } from '../questoes/Questao'
import Svgs from '../svgs'
import AnimateHeightComp from 'react-animate-height'
const AnimateHeight: any = AnimateHeightComp

type AbasType = 'EXERCICIOS' | 'AVALIACAO'
const Store = createSimpleStore(
  {
    respostas: [] as RespostaModel[],
    questoesAbertasId: [] as number[],
    loading: false,
    abaAtiva: 'EXERCICIOS' as AbasType,
  },
  {
    getQuestoesStarted(state) {
      state.loading = true
    },
    getQuestoesSuccess(state, questoes) {
      state.respostas = questoes
      state.loading = false
    },
    getQuestoesError(state) {
      state.loading = false
    },
    openQuestao(state, questaoId: number) {
      state.questoesAbertasId = [
        questaoId,
        ...state.questoesAbertasId.filter((x) => x !== questaoId),
      ]
    },
    closeQuestao(state, questaoId: number) {
      state.questoesAbertasId = state.questoesAbertasId.filter((x) => x !== questaoId)
    },
    changeAbaAtiva(state, aba: AbasType) {
      state.abaAtiva = aba
    },
  },
  {
    thunks: {
      getQuestoes(moduloId, local?) {
        return async (dispatch) => {
          try {
            dispatch(Store.actions.getQuestoesStarted())
            const questoes = await Axios.get(
              `/app/questoes/respondidas/modulo/${moduloId}/${local || ''}`
            ).then((x) => x?.data)
            dispatch(Store.actions.getQuestoesSuccess(questoes))
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro'
            toast.error(message)
            dispatch(Store.actions.getQuestoesError())
          }
        }
      },
    },
  }
)

type IState = ReturnType<typeof Store.useState>
const selectQuestoesAba = createSelector(
  (state: IState) => state.abaAtiva,
  (state: IState) => state.respostas,
  (abaAtiva, respostas) => {
    if (abaAtiva === 'AVALIACAO') return respostas.filter((q) => q.questao.local === 'AVALIACAO')
    return respostas.filter((q) => q.questao.local === 'CONTEUDO')
  }
)

interface QuestoesRespondidasParams {
  moduloId
  cursoId
  tipo: 'exercicios' | 'avaliacao'
}
const QuestoesRespondidas = () => {
  const { Layout, Abas } = QuestoesRespondidas
  return (
    <Store.Provider>
      <Layout>
        <Voltar />
        <Titulo />
        <Abas>
          <Exercicios />
          <Avaliacao />
        </Abas>
        <Aproveitamento />
        <ListaQuestoes>
          {(resposta) => (
            <React.Fragment key={resposta.id}>
              <EnunciadoQuestao resposta={resposta} />
              <QuestaoContainer resposta={resposta} />
            </React.Fragment>
          )}
        </ListaQuestoes>
        <EmptyState />
        <VerMais />
      </Layout>
      <Init />
    </Store.Provider>
  )
}
QuestoesRespondidas.Layout = styled.div`
  @media (max-width: 768px) {
    margin: 0 24px;
  }
  @media (max-width: 450px) {
    margin: 0 12px;
  }
`
QuestoesRespondidas.Abas = styled.div`
  border-bottom: 1px solid #d9d9d9;
  display: grid;
  grid-template-columns: auto auto 1fr;
  column-gap: 8px;
  margin-bottom: 21px;
`
QuestoesRespondidas.AbaItem = styled('div')<{ active: boolean }>(
  (props) => `
  padding: 8px 22px;
  font-size: 14px;
  color: #2D2D2D;
  display: flex;
  align-items: center;
  border-bottom: 4px solid;
  border-bottom-color: ${props.active ? '#F06F30' : 'transparent'};
  cursor: pointer;
`
)

const Init = () => {
  const dispatch = Store.useDispatch()
  const { moduloId, tipo } = useParams<QuestoesRespondidasParams>()
  useEffect(() => {
    if (tipo === 'exercicios') dispatch(Store.actions.changeAbaAtiva('EXERCICIOS'))
    if (tipo === 'avaliacao') dispatch(Store.actions.changeAbaAtiva('AVALIACAO'))
  }, [tipo])
  useIonViewDidEnter(() => {
    dispatch(Store.thunks.getQuestoes(moduloId))
  })
  React.useEffect(() => {
    dispatch(Store.thunks.getQuestoes(moduloId))
  }, [])
  return null
}

const Voltar = () => {
  const { Layout, Botao } = Voltar
  const { cursoId } = useParams<QuestoesRespondidasParams>()
  const history = useHistory()
  function handleClick() {
    history.replace(`/curso/${cursoId}/progresso`)
  }

  return (
    <Layout>
      <Botao
        onClick={handleClick}
        label="Voltar"
        svgPosition="LEFT"
        fill="CLEAR"
        svg={<Svgs.ChevronLeft color="#2D2D2D" />}
      />
    </Layout>
  )
}
Voltar.Layout = styled.div`
  margin-bottom: 18px;
`
Voltar.Botao = styled(Button)`
  justify-content: flex-end;
  width: auto;
  padding-right: 15px;
  .label {
    font-weight: bold;
  }
`

const Titulo = () => {
  const { Layout, Subtitulo } = Titulo
  const moduloAtual = useModuloAtual()
  if (!moduloAtual) return null

  return (
    <Layout>
      <TituloPagina>
        <Subtitulo>Gabarito:</Subtitulo>
        {moduloAtual.nome}
      </TituloPagina>
    </Layout>
  )
}
Titulo.Layout = styled.div`
  margin-bottom: 25px;
`
Titulo.Subtitulo = styled.span`
  font-weight: normal;
  font-size: 0.85em;
  margin-right: 6px;
`

const Exercicios = () => {
  const { abaAtiva } = Store.useState()
  const dispatch = Store.useDispatch()

  function handleClick() {
    dispatch(Store.actions.changeAbaAtiva('EXERCICIOS'))
  }

  return (
    <QuestoesRespondidas.AbaItem active={abaAtiva === 'EXERCICIOS'} onClick={handleClick}>
      <Svgs.Notes style={{ marginRight: 8 }} /> Exercícios
    </QuestoesRespondidas.AbaItem>
  )
}

const Avaliacao = () => {
  const { abaAtiva } = Store.useState()
  const dispatch = Store.useDispatch()

  function handleClick() {
    dispatch(Store.actions.changeAbaAtiva('AVALIACAO'))
  }

  return (
    <QuestoesRespondidas.AbaItem active={abaAtiva === 'AVALIACAO'} onClick={handleClick}>
      <Svgs.Notes style={{ marginRight: 8 }} /> Avaliação
    </QuestoesRespondidas.AbaItem>
  )
}

const Aproveitamento = () => {
  const { Layout } = Aproveitamento
  const state = Store.useState()
  const questoesDaAba = selectQuestoesAba(state)

  if (questoesDaAba.length === 0) return null

  return (
    <Layout>
      Você respondeu <strong>{qtdQuestoes()}</strong> e teve um aproveitamento de{' '}
      <strong>{aproveitamento()}</strong>
    </Layout>
  )

  function qtdQuestoes() {
    if (questoesDaAba.length === 1) return '1 questão'
    if (questoesDaAba.length > 1) return `${questoesDaAba.length} questões`
    throw Error('Quantidade de questões inválida')
  }

  function aproveitamento() {
    const somaAcertos = questoesDaAba.reduce((prev, curr) => {
      return prev + curr.percentualAcerto
    }, 0)
    const resultado = Math.ceil(somaAcertos / questoesDaAba.length)
    return `${resultado}%`
  }
}
Aproveitamento.Layout = styled.div`
  margin-bottom: 21px;
`

const ListaQuestoes = ({
  children,
}: {
  children: (resposta: RespostaModel) => React.ReactNode
}) => {
  const state = Store.useState()
  const questoesDaAba = selectQuestoesAba(state)

  return <>{questoesDaAba.map(children)}</>
}

const EnunciadoQuestao = ({ resposta }: { resposta: RespostaModel }) => {
  const { Layout, TextoQuestao } = EnunciadoQuestao
  const { questoesAbertasId } = Store.useState()
  const dispatch = Store.useDispatch()
  const questaoAberta = questoesAbertasId.includes(resposta.questaoId)

  function handleClick() {
    if (!questaoAberta) {
      dispatch(Store.actions.openQuestao(resposta.questaoId))
    } else {
      dispatch(Store.actions.closeQuestao(resposta.questaoId))
    }
  }

  return (
    <Layout title="Clique para visualizar a questão" onClick={handleClick}>
      <IndicadorAcertos resposta={resposta} />
      <TextoQuestao dangerouslySetInnerHTML={{ __html: resposta.questao.textoQuestao }} />
      <Svgs.ChevronLeft
        style={{ transform: questaoAberta ? 'rotate(-90deg)' : 'rotate(90deg)' }}
        color="#4D4D4D"
      />
    </Layout>
  )
}
EnunciadoQuestao.Layout = styled.div`
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08);
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 18px;
  display: grid;
  grid-gap: 16px;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  cursor: pointer;
`
EnunciadoQuestao.TextoQuestao = styled.div`
  max-height: 3.6em;
  overflow: hidden;
`

const IndicadorAcertos = ({ resposta }: { resposta: RespostaModel }) => {
  const { Circulo } = IndicadorAcertos
  if (resposta.percentualAcerto === 100) {
    return (
      <Circulo status="ACERTO" title="Você acertou a questão">
        <Svgs.Checkmark color="white" />
      </Circulo>
    )
  }

  if (resposta.percentualAcerto === 0) {
    return (
      <Circulo status="ERRO" title="Você errou a questão">
        <Svgs.Close color="white" height={16} width="auto" />
      </Circulo>
    )
  }

  return (
    <Circulo
      status="ACERTO_PARCIAL"
      title={`Você precisou de ${resposta.quantidadeTentativas} tentativas`}
    >
      {resposta.quantidadeTentativas}
    </Circulo>
  )
}
IndicadorAcertos.Circulo = styled.div<{ status: 'ACERTO' | 'ERRO' | 'ACERTO_PARCIAL' }>(
  (props) => `
  background: ${{ ACERTO: '#27AE60', ERRO: '#EB5757', ACERTO_PARCIAL: 'orange' }[props.status]};
  border-radius: 50%;
  height: 22px;
  width: 22px;
  display: grid;
  place-items: center;
  font-size: .8em;
  color: white;
  cursor: pointer;
`
)

const QuestaoContainer = ({ resposta }: { resposta: RespostaModel }) => {
  const { Layout } = QuestaoContainer
  const { questoesAbertasId } = Store.useState()
  const questaoAberta = questoesAbertasId.includes(resposta.questaoId)
  const [carregouUmaVez, setCarregouUmaVez] = React.useState(false)

  useEffect(() => {
    if (questaoAberta) setCarregouUmaVez(true)
  }, [questaoAberta])

  return (
    <AnimateHeight height={questaoAberta ? 'auto' : 0}>
      <Show condition={questaoAberta || carregouUmaVez}>
        <Layout style={{ fontSize: 1 }}>
          <Questao
            questaoId={resposta.questaoId}
            somenteLeitura={true}
            isAvaliacao={false}
            isGabarito={true}
          />
        </Layout>
      </Show>
    </AnimateHeight>
  )
}
QuestaoContainer.Layout = styled.div`
  padding: 0 24px 24px 24px;
`

const EmptyState = () => {
  const { Layout, Titulo, Subtitulo } = EmptyState
  const state = Store.useState()
  const questoesAba = selectQuestoesAba(state)
  if (questoesAba.length) return null
  return (
    <Layout>
      <div>
        <Titulo>Você ainda não respondeu nenhum exercício.</Titulo>
        <Subtitulo>As informações dos exercícios respondidos aparecerão aqui.</Subtitulo>
      </div>
    </Layout>
  )
}
EmptyState.Layout = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #2d2d2d;
  text-align: center;
  display: grid;
  place-items: center;
  height: 50vh;
`
EmptyState.Titulo = styled.div`
  font-weight: bold;
`
EmptyState.Subtitulo = styled.div``

const VerMais = () => {
  return null
}

export { QuestoesRespondidas }
