import { ConteudoItemModel } from './ConteudoItemModel'
import { QuestaoAlternativaModel } from './QuestaoAlternativaModel'
import { RespostaModel } from './RespostaModel'
import { ModuloModel } from './ModuloModel'

export class QuestaoModel {
  constructor(local?: 'AVALIACAO' | 'CONTEUDO', moduloId?: number) {
    this.questoesAlternativas = []
    this.local = local!
    this.moduloId = moduloId!
  }

  id: number
  tipoQuestao: TIPO_QUESTAO
  textoQuestao: string
  ordenacao: number
  local: 'AVALIACAO' | 'CONTEUDO'
  configuracaoAdicional: string
  moduloId: number

  questoesAlternativas: QuestaoAlternativaModel[]
  respostas: RespostaModel[]
  conteudos: ConteudoItemModel[]
  modulo: ModuloModel
}

export enum TIPO_QUESTAO {
  MULTIPLA_ESCOLHA = 'MULTIPLA_ESCOLHA',
  RELACIONAR_ITENS = 'RELACIONAR_ITENS',
  MULTIPLO_VERDADEIRO_FALSO = 'MULTIPLO_VERDADEIRO_FALSO',
}
