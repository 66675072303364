import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit'
import axios, { IQueryParams, ICounter } from '../common/axios'
import { IRequest } from '../common/IRequest'
import axiosConfig from 'axios'
import jwtDecode from 'jwt-decode'
import { IStateRedux } from '../common/ReduxStore'
import { toast } from 'react-toastify'
import { UsuarioModel } from '../models/UsuarioModel'

export interface IState {
  token: string | null
  senhaMudada: boolean
  request: IRequest
}

const token = localStorage.getItem('token')

export const initialState: IState = {
  token: token !== 'undefined' && token ? token : null,
  senhaMudada: false,
  request: {
    fetching: false,
    errorCode: null,
    message: '',
  },
}

const { reducer: authReducer, actions } = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    loginFetch(state): IState {
      state.request.fetching = false
      return state
    },
    loginSuccess(state, { payload }: PayloadAction<{ token }>) {
      localStorage.setItem('token', payload.token)
      state.request = { ...initialState.request, fetching: false }
      state.token = payload.token
    },
    loginError(state, { payload }: PayloadAction<{ errorCode; message }>) {
      state.request = {
        errorCode: payload.errorCode,
        message: payload.message,
        fetching: false,
      }
    },
    logout(state) {
      localStorage.removeItem('token')
      state.token = null
    },
    recuperarSenha(state): IState {
      state.request.fetching = true
      return state
    },
    recuperarSenhaSuccess(state) {
      state.request = { ...initialState.request, fetching: false }
    },
    recuperarSenhaError(state, { payload }: any) {
      state.request = {
        errorCode: payload.errorCode,
        message: payload.message,
        fetching: false,
      }
    },
    mudarSenha(state): IState {
      state.request.fetching = true
      return state
    },
    updateToken(state, { payload }) {
      localStorage.setItem('token', payload)
      state.token = payload
    },
    mudarSenhaSuccess(state) {
      state.request = { ...initialState.request, fetching: false }
      state.senhaMudada = true
    },
    mudarSenhaError(state, { payload }: any) {
      state.request = {
        errorCode: payload.errorCode,
        message: payload.message,
        fetching: false,
      }
    },
  },
})

const authActions = {
  ...actions,
  login({ cpf, senha }: { cpf: string; senha: string }) {
    return async (dispatch: any) => {
      dispatch(actions.loginFetch())
      try {
        const { token, alterarSenha } = await axios.Usuarios.login(cpf, senha)
        dispatch(actions.loginSuccess({ token }))
      } catch (error: any) {
        dispatch(actions.loginError({ errorCode: 400, message: 'Falha ao realizar login' }))
        toast(error.response?.data?.message || 'Falha ao realizar login')
      }
    }
  },
  newTokenMatricula({ matriculaId, callback }: { matriculaId: string; callback: () => void }) {
    return async (dispatch: any) => {
      dispatch(actions.loginFetch())
      try {
        const { token } = await axios.Usuarios.newTokenMatricula(matriculaId)
        dispatch(actions.loginSuccess({ token }))

        setTimeout(() => {
          callback?.()
        }, 1000)
      } catch (error: any) {
        dispatch(actions.loginError({ errorCode: 400, message: 'Falha ao realizar login' }))
        toast(error.response?.data?.message || 'Falha ao realizar login')
      }
    }
  },
  recuperaSenha({ cpf }: { cpf: string }) {
    return async (dispatch: any) => {
      dispatch(actions.recuperarSenha())
      try {
        await axios.Usuarios.recuperarSenha(cpf)
        dispatch(actions.recuperarSenhaSuccess())
        toast('E-mail de recuperação de senha enviado')
      } catch (error: any) {
        dispatch(actions.recuperarSenhaError(error))
        toast('Falha ao enviar o e-mail de recuperação de senha')
      }
    }
  },
  mudarSenhaToken({ senha, token }: { senha: string; token: string }) {
    return async (dispatch: any) => {
      dispatch(actions.mudarSenha())
      try {
        await axios.Usuarios.alterarSenhaToken(senha, token)
        dispatch(actions.mudarSenhaSuccess())
        toast('Senha alterada com sucesso')
      } catch (error: any) {
        dispatch(actions.mudarSenhaError(error))
        toast('Erro ao alterar senha')
      }
    }
  },
  alterarSenha({
    usuarioId,
    senhaAtual,
    novaSenha,
  }: {
    usuarioId: string
    senhaAtual: string
    novaSenha: string
  }) {
    return async (dispatch: any) => {
      dispatch(actions.mudarSenha())
      try {
        await axios.Usuarios.alterarSenha(usuarioId, senhaAtual, novaSenha)
        dispatch(actions.mudarSenhaSuccess())
        toast('Senha alterada com sucesso')
      } catch (error: any) {
        dispatch(actions.mudarSenhaError(error))
        toast(error.response?.data?.message ?? 'Erro ao alterar senha')
      }
    }
  },
}

export const getUsuarioLogado = createSelector(
  (s: IStateRedux) => s.auth.token,
  (token) => {
    try {
      /* #eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
      return jwtDecode(token || '') as UsuarioModel
    } catch (e) {
      return {} as UsuarioModel
    }
  }
)

export { authReducer, authActions }
