import React from 'react'

const FolderSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.1717 4.64185H9.57409L7.48132 3H0V18.115C0 19.3333 0.990333 20.3504 2.20812 20.3822L18.8262 20.3915H18.8275C19.834 20.3915 20.721 19.7133 20.9847 18.7418L23.9807 7.7034H19.1717V4.64185ZM2.3795 18.9733L2.23569 18.9732V18.9734C1.78669 18.9568 1.40899 18.5657 1.40899 18.115V4.40899H6.99461L9.08738 6.05084H17.7627V7.70344H6.28899L3.46753 18.0893C3.33071 18.593 2.8913 18.9452 2.3795 18.9733ZM22.1383 9.11244L19.6249 18.3728C19.5274 18.7319 19.1995 18.9826 18.8274 18.9826C18.8273 18.9826 18.8271 18.9826 18.8269 18.9826L4.62713 18.9746C4.71044 18.8129 4.77794 18.6403 4.8273 18.4588L7.36635 9.11244H22.1383Z"
        fill="#FF9933"
      />
      <path
        d="M8.80591 17.5625L8.80732 16.1535L17.3138 16.162L17.3124 17.571L8.80591 17.5625Z"
        fill="#FF9933"
      />
    </svg>
  )
}

export default FolderSvg
