import React from 'react'
import styled from '@emotion/styled'
import { Button } from '.'
import Svgs from '../svgs'
import Card from './Card'

const ModalIniciarTutorial = ({
  titulo,
  onStart,
  onEnd,
  children,
  ...props
}: { titulo: string; onStart; onEnd } & React.HTMLAttributes<HTMLDivElement>) => {
  const { Modal, Car, Container, Titulo, ButtonGrey, Button, ButtonsContainer } =
    ModalIniciarTutorial

  return (
    <Modal {...props}>
      <Car>
        <Svgs.NdCar />
      </Car>
      <Container>
        <Titulo>{titulo}</Titulo>
        {children}
      </Container>
      <ButtonsContainer>
        <ButtonGrey label="Pular tutorial" fill="CLEAR" onClick={onEnd} />
        <Button label="Iniciar primeiros passos" fill="CLEAR" onClick={onStart} />
      </ButtonsContainer>
    </Modal>
  )
}
ModalIniciarTutorial.Modal = styled(Modal)`
  padding: 56px;
`
ModalIniciarTutorial.Car = styled.div`
  position: absolute;
  width: 89px;
  height: 89px;
  top: -45px;
  background: white;
  margin: 0 auto;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  left: 50%;
  transform: translate(-50%, 0);
`
ModalIniciarTutorial.Container = styled.div``
ModalIniciarTutorial.Titulo = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: #333333;
  text-align: center;
`
ModalIniciarTutorial.ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 468px) {
    flex-direction: column;
  }
`
ModalIniciarTutorial.ButtonGrey = styled(Button)`
  --text-color: #333;
  width: 102px;
`
ModalIniciarTutorial.Button = styled(Button)`
  width: 186px;
  white-space: nowrap;
  .label {
    font-weight: bold;
  }
`

const ModalTutorial = ({
  onEnd,
  children,
  ...props
}: { onEnd } & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <Modal {...props}>
      {children}
      <Button label="Fechar" onClick={onEnd} fill="CLEAR" style={{ margin: '12px auto' }} />
    </Modal>
  )
}

function Modal(props) {
  const { Backdrop, FloatingContainer } = Modal
  return (
    <Backdrop>
      <FloatingContainer {...props} />
    </Backdrop>
  )
}
Modal.Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000030;
`
Modal.FloatingContainer = styled(Card)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: calc(100vw - 24px);
  max-height: calc(100vh - 24px);
  min-width: 260px;
  overflow: auto;
`

export { ModalIniciarTutorial, ModalTutorial }
