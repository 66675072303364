import styled from '@emotion/styled'
import React, { HTMLAttributes } from 'react'

export const Divider = ({ style, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', ...style }} {...props} />
}

export const ResponsiveDivider = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;

  /* For mobile phones: */
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
