import { format, parseISO } from 'date-fns'

export const formatDate = (data: any, dateFormat?: string) =>
  format(parseISO(data), dateFormat ?? 'dd/MM/yyyy')

export const formatMoeda = (amount: number | null | undefined, fixed = 2, locale = 'pt-BR') => {
  if ([undefined, null].includes(amount as any)) return null
  amount = +String(amount).replace(',', '.')
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: fixed,
    maximumFractionDigits: fixed,
  }).format(amount)
}
