import React, { useRef, useEffect, useState } from 'react'
import { IonButton } from '@ionic/react'
import { modulosActions, useModuloAtual } from '../modulos/ModulosStore'
import styled from '@emotion/styled'
import useAxios from 'axios-hooks'
import { useHistory, useParams } from 'react-router'
import { useSpring, animated, interpolate, useTransition } from 'react-spring'
import { Show } from '../common/Show'
import { BotoesTriplos } from '../components/BotoesTriplos'
import Svgs from '../svgs'
import { AnimateEnter } from '../components/AnimateEnter'
import { useDispatch } from 'react-redux'
import { progressoActions } from '../conteudo/ProgressoReduxStore'

interface ResultadoAvaliacaoParams {
  cursoId
  moduloId
}
export const ResultadoAvaliacao = () => {
  const { Layout } = ResultadoAvaliacao
  const { moduloId, cursoId } = useParams<ResultadoAvaliacaoParams>()
  const history = useHistory()
  const dispatch = useDispatch()

  const [{ data }] = useAxios(`/app/avaliacoes/resultado?moduloId=${moduloId}`, { useCache: false })
  const [{ data: proximoModulo }] = useAxios(`/app/modulos/modulo-sucessor/${moduloId}`)

  function handleClickComecarProximoModuloFinalizarCurso() {
    if (proximoModulo) {
      history.push(`/curso/${cursoId}/conteudo/${proximoModulo.id}`)
    } else {
      history.push(`/curso/${cursoId}/validacao-facial/final`)
    }
  }
  function handleClickVoltarInicio() {
    history.push(`/curso/${cursoId}/home`)
  }
  function handleClickVerGabarito() {
    history.push(`/curso/${cursoId}/gabarito/${moduloId}/avaliacao`)
  }

  function navigate(destination) {
    history.push(`/curso/${cursoId}/${destination}`)
  }

  const handleRefazerAvaliacao = () => {
    dispatch(
      modulosActions.refazerAvaliacao(moduloId, () => {
        dispatch(modulosActions.loadModulos(+cursoId))
        dispatch(progressoActions.loadEstatisticasEstudo(+cursoId))
        navigate(`regras-avaliacao/${moduloId}`)
      })
    )
  }

  return (
    <Layout>
      <AnimateEnter delay={0}>
        <Titulo pontuacao={data?.pontuacao} />
      </AnimateEnter>
      <AnimateEnter delay={500}>
        <Pontuacao pontuacao={data?.pontuacao} />
      </AnimateEnter>
      <AnimateEnter delay={1000}>
        <QtdAcertos qtdQuestoes={data?.qtdQuestoes} qtdAcertos={data?.qtdQuestoesCorretas} />
      </AnimateEnter>
      <AnimateEnter delay={1500}>
        <AvisoBaixaPontuacao pontuacao={data?.pontuacao} />
      </AnimateEnter>
      <AnimateEnter delay={2500}>
        <BotoesTriplos
          botoes={[
            {
              label: 'Ver gabarito',
              icon: <Svgs.Notes height={37} width={30} color="#F06F30" />,
              onClick: handleClickVerGabarito,
            },
            data?.pontuacao >= 70
              ? {
                  label: proximoModulo ? 'Começar o próximo módulo' : 'Finalizar curso',
                  icon: <Svgs.Books height={35} width={38} color="#F06F30" />,
                  onClick: handleClickComecarProximoModuloFinalizarCurso,
                }
              : {
                  label: 'Refazer Avaliação',
                  icon: <Svgs.Books height={35} width={38} color="#F06F30" />,
                  onClick: handleRefazerAvaliacao,
                },
            {
              label: 'Voltar para o início',
              icon: <Svgs.ArrowLeft />,
              onClick: handleClickVoltarInicio,
            },
          ]}
        />
      </AnimateEnter>
      <br />
      <br />
    </Layout>
  )
}
ResultadoAvaliacao.Layout = styled('div')`
  overflow: auto;
  padding-bottom: 24px;
`

const Titulo = ({ pontuacao }) => {
  const { Container } = Titulo

  if (pontuacao == null) return null

  if (pontuacao >= 70) {
    return (
      <Container>
        <strong>Parabéns</strong>
        <div>Você finalizou a avaliação, seu aproveitamento foi de:</div>
      </Container>
    )
  }

  return (
    <Container>
      <strong>Atenção!</strong>
      <div>Você finalizou a avaliação, mas seu aproveitamento foi de:</div>
    </Container>
  )
}
Titulo.Container = styled.div`
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: #2d2d2d;
  margin-top: 123px;
  @media (max-width: 575px) {
    font-size: 18px;
    margin-top: 32px;
  }
`

const Pontuacao = ({ pontuacao }) => {
  const { Container } = Pontuacao

  const springPontuacao = useSpring({
    number: pontuacao || 0,
    from: { number: 0 },
    config: { mass: 1, tension: 380, friction: 220 },
  })

  const borderColorSpring = useSpring({
    borderColor: '#4AC758',
    from: { borderColor: '#EB5757' },
    config: { mass: 1, tension: 380, friction: 220 },
  })

  const borderColor = Pontuacao.getBorderColor(pontuacao)

  if (pontuacao == null) return null

  return (
    <Container style={{ borderColor }}>
      <animated.span>{springPontuacao.number.interpolate(Math.ceil as any)}</animated.span>
      <small>%</small>
    </Container>
  )
}
Pontuacao.getBorderColor = (pontuacao) => {
  if (pontuacao < 40) return '#EB5757'
  if (pontuacao < 70) return '#F2994A'
  return '#4AC758'
}
Pontuacao.Container = styled(animated.div)`
  border-radius: 50%;
  height: 132px;
  width: 132px;
  border: 10px solid transparent;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 36px;
  color: #333333;
  margin: 48px auto 0 auto;
  @media (max-width: 575px) {
    margin-top: 24px;
  }
`

const QtdAcertos = ({ qtdQuestoes, qtdAcertos }) => {
  const { Container } = QtdAcertos
  if (qtdAcertos == null) return null

  return (
    <Container>
      Você acertou <strong>{qtdAcertos}</strong> de <strong>{qtdQuestoes}</strong> questões
    </Container>
  )
}
QtdAcertos.Container = styled.div`
  text-align: center;
  margin: 18px 0px;
`

const AvisoBaixaPontuacao = ({ pontuacao }) => {
  const { Layout } = AvisoBaixaPontuacao
  if (pontuacao == null || pontuacao >= 70) return null

  return (
    <Layout>
      Para conseguir a <strong>aprovação na prova teórica do Detran</strong>, é necessário acertar
      mais do que isso. Recomendamos que você revise o conteúdo novamente e então{' '}
      <strong>refaça a avaliação</strong>
    </Layout>
  )
}
AvisoBaixaPontuacao.Layout = styled.div`
  max-width: 831px;
  background: #f5f5f5;
  border: 1px solid #f06f30;
  padding: 25px 70px;
  margin: 55px auto 28px auto;
  @media (max-width: 850px) {
    margin: 55px 18px 28px 18px;
  }
  @media (max-width: 575px) {
    margin-top: 18px;
  }
`
