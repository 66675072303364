import React from 'react'
import { SvgType } from './index'
const MailSvg = ({ color = '#FF9933', ...props }: SvgType) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.25 3H3.75C3.15326 3 2.58097 3.23705 2.15901 3.65901C1.73705 4.08097 1.5 4.65326 1.5 5.25V12.75C1.5 13.3467 1.73705 13.919 2.15901 14.341C2.58097 14.7629 3.15326 15 3.75 15H14.25C14.8467 15 15.419 14.7629 15.841 14.341C16.2629 13.919 16.5 13.3467 16.5 12.75V5.25C16.5 4.65326 16.2629 4.08097 15.841 3.65901C15.419 3.23705 14.8467 3 14.25 3ZM13.9425 4.5L9.5325 8.91C9.46278 8.9803 9.37983 9.03609 9.28843 9.07417C9.19704 9.11225 9.09901 9.13185 9 9.13185C8.90099 9.13185 8.80296 9.11225 8.71157 9.07417C8.62017 9.03609 8.53722 8.9803 8.4675 8.91L4.0575 4.5H13.9425ZM15 12.75C15 12.9489 14.921 13.1397 14.7803 13.2803C14.6397 13.421 14.4489 13.5 14.25 13.5H3.75C3.55109 13.5 3.36032 13.421 3.21967 13.2803C3.07902 13.1397 3 12.9489 3 12.75V5.5575L7.41 9.9675C7.83188 10.3889 8.40375 10.6255 9 10.6255C9.59625 10.6255 10.1681 10.3889 10.59 9.9675L15 5.5575V12.75Z"
        fill={color}
      />
    </svg>
  )
}

export default MailSvg
