import React from 'react'

const PlaySvg = ({ color = '#FF9933' }) => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.96535 3.33271L3.10977 0.254644C2.79292 0.0869396 2.43328 -0.000913969 2.0674 7.16964e-06C1.70151 0.000928308 1.34241 0.0905912 1.02657 0.259889C0.710727 0.429187 0.449393 0.672088 0.269114 0.96392C0.0888341 1.25575 -0.00396917 1.58612 0.000130151 1.92147V8.09982C0.000130151 8.60378 0.218545 9.0871 0.607325 9.44345C0.996105 9.7998 1.5234 10 2.07322 10C2.43719 9.99943 2.79462 9.9113 3.10977 9.74442L8.96535 6.66635C9.27999 6.49944 9.54121 6.25963 9.72281 5.97098C9.90441 5.68232 10 5.35497 10 5.02176C10 4.68855 9.90441 4.36119 9.72281 4.07254C9.54121 3.78388 9.27999 3.54407 8.96535 3.37716V3.33271ZM8.35918 5.66071L2.5036 8.78322C2.37236 8.85139 2.22408 8.88723 2.07322 8.88723C1.92237 8.88723 1.77409 8.85139 1.64284 8.78322C1.51197 8.71396 1.4033 8.61434 1.32775 8.49438C1.2522 8.37442 1.21244 8.23834 1.21247 8.09982V1.89924C1.21244 1.76073 1.2522 1.62465 1.32775 1.50468C1.4033 1.38472 1.51197 1.2851 1.64284 1.21585C1.77463 1.1487 1.92247 1.11244 2.07322 1.11028C2.22388 1.11311 2.37154 1.14933 2.5036 1.21585L8.35918 4.31614C8.4901 4.38536 8.59883 4.48497 8.67442 4.60493C8.75002 4.7249 8.78982 4.86099 8.78982 4.99953C8.78982 5.13807 8.75002 5.27417 8.67442 5.39413C8.59883 5.5141 8.4901 5.6137 8.35918 5.68293V5.66071Z"
        fill="white"
      />
    </svg>
  )
}

export default PlaySvg
