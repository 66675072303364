import React, { useEffect, useState } from 'react'
import { responsiveSpacing } from '../common/styles/ResponsiveSpacing'
import { IonIcon, IonButton } from '@ionic/react'
import styled from '@emotion/styled'
import { useHistory, useLocation, useParams } from 'react-router'
import { ValidacaoFacial } from './ValidacaoFacial'
import { checkmarkCircleOutline } from 'ionicons/icons'
import { validacaoFacialActions } from './ValidacaoFacialReduxStore'
import { ValidacaoFacialStore } from './ValidacaoFacialStore'
import { useDispatch } from 'react-redux'
import useAxios from 'axios-hooks'
import { MatriculaModel } from '../models/MatriculaModel'
import { toast } from 'react-toastify'
import { progressoActions } from '../conteudo/ProgressoReduxStore'
import { useCursoAtual } from '../cursos/CursosStore'

interface ValidacaoFacialParams {
  cursoId: string
}

const ValidacaoFacialSerpro = () => {
  const { cursoId } = useParams<ValidacaoFacialParams>()
  const history = useHistory()
  const location = useLocation()
  const final = location.pathname.includes('/final')

  const [{ data: matricula }] = useAxios<MatriculaModel>(`/matriculas/curso/${cursoId}/self`)
  const [{ error: permissaoValidacaoFacialFinalInvalida }] = useAxios(
    `/matriculas/valida-conclusao/curso/${cursoId}`,
    { manual: !final }
  )

  if (final && matricula?.dataValidacaoFacialFinal) {
    toast('Já existe uma validação facial Final')
    history.replace(`/curso/${cursoId}/home`)
  }
  if (!final && matricula?.dataValidacaoFacialInicial) {
    toast('Já existe uma validação facial Inicial')
    history.replace(`/curso/${cursoId}/home`)
  }
  if (final && permissaoValidacaoFacialFinalInvalida) {
    toast(permissaoValidacaoFacialFinalInvalida.response?.data?.message)
    history.replace(`/curso/${cursoId}/home`)
  }

  return (
    <div>
      <Styles.IonContent>
        <div className="container">
          {final ? <InstrucoesFinal /> : <Instrucoes />}
          <Styles.ValidacaoContainer>
            <ValidacaoFacial tipo={final ? 'FINAL' : 'INICIAL'}>
              <ValidacaoFacial.Success>
                {final ? <SucessoFinal /> : <Sucesso />}
              </ValidacaoFacial.Success>
            </ValidacaoFacial>
          </Styles.ValidacaoContainer>
          <Fechar />
        </div>
      </Styles.IonContent>
    </div>
  )
}

function Instrucoes() {
  return (
    <>
      <h1>Reconhecimento Facial Necessário</h1>
      <p>
        Antes de iniciar seus estudos é preciso garantir sua identidade através de uma validação
        facial junto à base de dados nacional
      </p>
      <p>Após isso, a cada aula deverá ser feito uma nova validação</p>
      <p>Tire uma foto de seu rosto, de frente, com boa iluminação</p>
    </>
  )
}

function InstrucoesFinal() {
  return (
    <>
      <h1>Reconhecimento Facial Necessário</h1>
      <p>
        Antes de <strong>encerrar seu curso</strong> é preciso garantir sua identidade através de
        uma validação facial junto à base de dados nacional
      </p>
      <p>Tire uma foto de seu rosto, de frente, com boa iluminação</p>
    </>
  )
}

function Sucesso() {
  const { cursoId } = useParams<ValidacaoFacialParams>()
  const history = useHistory()
  const dispatch = useDispatch()
  const { ultimaValidacao } = ValidacaoFacialStore.useState()
  const [{ data: modulos }] = useAxios(`/modulos?cursoId=${cursoId}`)
  dispatch(validacaoFacialActions.changeUltimaValidacao({ ultimaValidacao }))
  dispatch(progressoActions.validatePermissaoAlunoEstudar(cursoId))

  useEffect(() => {
    if (modulos?.length) {
      const moduloInicialId =
        modulos.sort((a, b) => (a.ordenacao < b.ordenacao ? -1 : 1))[0]?.id ?? 0

      setTimeout(() => {
        history.replace(`/curso/${cursoId}/conteudo/${moduloInicialId}`)
      }, 1500)
    }
  }, [modulos])

  return (
    <>
      <IonIcon
        icon={checkmarkCircleOutline}
        color="success"
        style={{
          width: '120px',
          height: '120px',
          marginBottom: '80px',
        }}
      />
      <p>Foto validada com sucesso.</p>
      <p>Boa aula!</p>
    </>
  )
}

function SucessoFinal() {
  const { cursoId } = useParams<ValidacaoFacialParams>()
  const history = useHistory()
  setTimeout(() => {
    history.replace(`/curso/${cursoId}/home`)
  }, 1500)
  return (
    <>
      <IonIcon
        icon={checkmarkCircleOutline}
        color="success"
        style={{
          width: '120px',
          height: '120px',
          marginBottom: '80px',
        }}
      />
      <p>Foto validada com sucesso.</p>
      <p>Parabéns, você finalizou o curso</p>
    </>
  )
}

function Fechar() {
  const curso = useCursoAtual()
  const history = useHistory()
  return (
    <Styles.FecharContainer>
      <IonButton fill="outline" onClick={() => history.replace(`/curso/${curso?.id ?? 1}/home`)}>
        Voltar para a página inicial
      </IonButton>
    </Styles.FecharContainer>
  )
}

const Styles = {
  IonContent: styled('div')`
    --background: #efefef;
    font-family: 'Rubik', serif;
    h1 {
      border-bottom: 3px solid var(--ion-color-primary);
    }
    .container {
      ${responsiveSpacing('padding')}
      padding-top: 24px;
      margin-top: 35px;
    }
  `,
  ValidacaoContainer: styled('div')`
    text-align: center;
    min-height: calc(100vh - 440px);
    display: flex;
  `,
  FecharContainer: styled('div')`
    margin-top: 24px;
    text-align: right;
  `,
}

export { ValidacaoFacialSerpro }
