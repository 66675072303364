import React from 'react'
import { IonSpinner, IonButton, IonContent, IonPage } from '@ionic/react'
import { Form, useFormik, FormikProvider } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import { TextField } from '../common/TextField'
import styled from '@emotion/styled'
import Header from '../components/Header'
import { toast } from 'react-toastify'
import useAxios from 'axios-hooks'

interface TrocarSenhaParams {
  cursoId
}

export const TrocarSenha = () => {
  const history = useHistory()
  const { cursoId } = useParams<TrocarSenhaParams>()

  const [{ loading }, reqAlteraSenha] = useAxios(
    { url: '/usuarios/altera-senha', method: 'post' },
    { manual: true }
  )

  const formik = useFormik({
    initialValues: { senhaAtual: '', novaSenha: '', novaSenhaConfirmacao: '' },
    validate: (values) => {
      const errors: any = {}
      if (!values.senhaAtual) errors.senhaAtual = 'Senha atual requerida'
      if (!values.novaSenha) errors.novaSenha = 'Nova senha requerida'
      if (values.novaSenha.length < 6)
        errors.novaSenha = 'A senha precisa ter pelo menos 6 caracteres'
      if (!values.novaSenhaConfirmacao)
        errors.novaSenhaConfirmacao = 'Confirmação da nova senha requerida'
      if (values.novaSenha !== values.novaSenhaConfirmacao)
        errors.novaSenhaConfirmacao = 'Novas Senhas incompatíveis'
      return errors
    },
    onSubmit: async (values) => {
      const { senhaAtual, novaSenha } = values
      try {
        await reqAlteraSenha({ data: { senhaAtual, novaSenha } })
        toast('Senha alterada com sucesso')
        history.replace(`/curso/${cursoId}/home`)
      } catch (error: any) {
        toast(error.response?.data?.message ?? 'Erro ao alterar senha')
      }
    },
  })

  return (
    <div>
      <Styles.IonContent className="padding">
        <Styles.Container className="container padding">
          <Styles.Titulo>
            <h1>TROCA DE SENHA</h1>
          </Styles.Titulo>
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={formik.handleSubmit}>
              <TextField
                label="Senha Atual"
                name="senhaAtual"
                type="password"
                placeholder="Informe a senha atual"
              />
              <TextField
                label="Nova Senha"
                name="novaSenha"
                type="password"
                placeholder="Informe a nova senha"
              />
              <TextField
                label="Confirmar Nova Senha"
                name="novaSenhaConfirmacao"
                type="password"
                placeholder="Digite novamente a nova senha"
              />
              {/* <IonButton type="submit" expand="block" size="large" color="primary">{loading ? <IonSpinner />  : 'Alterar Senha'}</IonButton> */}
              <Styles.Footer>
                <div className="form-button">
                  <IonButton id="btn-atualizar-senha" type="submit">
                    {loading ? <IonSpinner /> : 'Atualizar'}
                  </IonButton>
                  <IonButton type="button" color="light" onClick={() => history.goBack()}>
                    {' '}
                    Cancelar{' '}
                  </IonButton>
                </div>
              </Styles.Footer>
            </Form>
          </FormikProvider>
        </Styles.Container>
      </Styles.IonContent>
    </div>
  )
}

const Styles = {
  IonContent: styled('div')`
    --background: #f7f7f7;
    font-family: 'Roboto Condensed', sans-serif;
  `,
  Container: styled('div')`
    max-width: 800px;
    margin: 24px auto;
    padding: 24px;
    background: white;
    border-radius: 4px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
      input {
        background: #ffffff;
        border: 1px solid #ededed;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
        transition: 0.35s box-shadow;
        border-radius: 3px;
        height: 3em;
        padding-left: 1em;
        width: 100%;
      }
    }
    #btn-atualizar-senha {
      min-width: 120px;
    }
    @media (max-width: 450px) {
      width: 100%;
      margin: 0;
      box-shadow: none;
      border-radius: 0;
      min-height: 100%;
    }
  `,
  Titulo: styled('div')`
    h1 {
      font-weight: bold;
      font-size: 24px;
    }
    margin: 24px 0;
  `,
  Footer: styled('div')`
    display: table;
    width: 100%;
    .form-button {
      ion-button {
        float: right;
      }
      ion-button:first-of-type {
        margin-left: 15px;
      }
    }
  `,
}
